import { Color } from "@material-ui/lab";
import { action, makeObservable, observable } from "mobx";
import { clearPersist, startPersist, stopPersist } from "mobx-persist-store";
import { Repository } from "../api/types/repository";
import { RootStore } from "./Root/RootStore";

export interface MobXStoreActions<outputType> {
  setItem(item: outputType): void;
  resetItem(): void;
  setItemList(itemList: outputType[]): void;
  resetItemList(): void;
  setAndShowMessage(error: string): void;
  resetApiResponse(): void;
  stopPersist(): void;
  startPersist(): void;
}

export interface MobXStoreAsyncApi<inputType> {
  getItemAsync(id: number): Promise<void>;
  getItemListAsync(): Promise<void>;
  getItemListByForeignKeyAsync?(id: number, foreignKey: string): Promise<void>;
  insertItemAsync(item: inputType): Promise<void>;
  updateItemAsync(item: inputType): Promise<void>;
  deleteItemAsync(id: number): Promise<void>;
  clearStoreAsync(): Promise<void>;
}

/**
 * Abstract template class for MobX datastore, used as the state management solution for the app
 * The properties of the class can be made observable, which allows for auto page refreshes on change
 * of the value of one of the observed properties
 */
export abstract class MobXStore<
  outputType extends { id: number },
  inputType extends { id?: number }
> implements MobXStoreActions<outputType>, MobXStoreAsyncApi<inputType>
{
  public readonly root: RootStore;
  public readonly api: Repository<outputType, inputType>;
  public readonly endpoint: string;
  public readonly singularDisplayName: string;
  public readonly pluralDisplayName: string;
  public readonly defaultItem: outputType;
  public item: outputType;
  public itemList: outputType[];
  public message: string | null = null;
  public showMessage: boolean = false;
  public messageSeverity: Color | undefined = undefined;

  constructor(
    root: RootStore,
    api: Repository<outputType, inputType>,
    endpoint: string,
    singularDisplayName: string,
    pluralDisplayName: string,
    defaultItem: outputType
  ) {
    this.root = root;
    this.api = api;
    this.endpoint = endpoint;
    this.singularDisplayName = singularDisplayName;
    this.pluralDisplayName = pluralDisplayName;
    this.defaultItem = defaultItem;
    this.item = defaultItem;
    this.itemList = [];
    makeObservable(this, {
      item: observable,
      itemList: observable,
      message: observable,
      showMessage: observable,
      messageSeverity: observable,
      setItem: action,
      resetItem: action,
      setItemList: action,
      resetItemList: action,
      setAndShowMessage: action,
      setShowMessage: action,
      setMessageSeverity: action,
      resetApiResponse: action,
      stopPersist: action,
      startPersist: action,
      getItemAsync: action,
      getItemListAsync: action,
      getItemListByForeignKeyAsync: action,
      insertItemAsync: action,
      updateItemAsync: action,
      deleteItemAsync: action,
    });
  }

  setItem(item: outputType) {
    this.item = item;
  }
  resetItem() {
    this.item = this.defaultItem;
  }
  setItemList(itemList: outputType[]) {
    this.itemList = itemList;
  }
  resetItemList() {
    this.itemList = [];
  }
  setAndShowMessage(response: string) {
    this.message = response;
    this.showMessage = true;
  }
  setShowMessage(value: boolean) {
    this.showMessage = value;
  }
  setMessageSeverity(color: Color) {
    this.messageSeverity = color;
  }
  resetApiResponse() {
    this.message = null;
  }

  stopPersist() {
    stopPersist(this);
  }
  startPersist() {
    startPersist(this);
  }
  async getItemAsync(id: number): Promise<void> {
    const result = await this.api.getOne(Number(id));
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.resetApiResponse();
    this.setItem(result.data);
  }
  async getItemListAsync(): Promise<void> {
    const result = await this.api.getAll();
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.resetApiResponse();
    this.setItemList(result.data);
  }
  async getItemListByForeignKeyAsync(
    id: number,
    foreignKey: string
  ): Promise<void> {
    const result = await this.api.getByForeignKeyId!(id, foreignKey);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.resetApiResponse();
    this.setItemList(result.data);
  }
  async insertItemAsync(item: inputType): Promise<void> {
    const result = await this.api.insert(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully inserted!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }
  async updateItemAsync(item: inputType): Promise<void> {
    const result = await this.api.update(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully updated!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }
  async deleteItemAsync(id: number): Promise<void> {
    const result = await this.api.delete(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully deleted!`
    );
    this.setMessageSeverity("success");
    this.getItemListAsync();
  }
  async clearStoreAsync() {
    await clearPersist(this);
  }

  getItemFromList(id: number): outputType {
    return this.itemList.find((element) => Number(id) === Number(element.id))!;
  }
}
