import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  DeviceSense,
  DeviceSpecificationSenseInput,
} from "./DeviceSenseInterface";

export class DeviceSpecificationSenseApi extends AbstractApi<
  DeviceSense,
  DeviceSpecificationSenseInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "deviceSpecificationSense");
  }
}
