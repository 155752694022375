import { makeAutoObservable } from "mobx";
import { TbCustomerApi } from "../../api/TbCustomer/TbCustomer";
import {
  TbCreateCustomerRequestBody,
  TbCustomer,
  TbUpdateCustomerRequestBody,
} from "../../api/TbCustomer/TbCustomerInterface";
import { RootStore } from "../Root/RootStore";

export class TbCustomerStore {
  public root: RootStore;
  public api: TbCustomerApi;
  public defaultItem: TbCustomer;
  public item: TbCustomer;
  public itemList: TbCustomer[];

  constructor(root: RootStore, api: TbCustomerApi, defaultItem: TbCustomer) {
    this.root = root;
    this.api = api;
    this.defaultItem = defaultItem;
    this.item = defaultItem;
    this.itemList = [defaultItem];
    makeAutoObservable(this);
  }

  setItem(item: TbCustomer) {
    this.item = item;
  }
  setItemList(itemList: TbCustomer[]) {
    this.itemList = itemList;
  }
  resetItem() {
    this.item = this.defaultItem;
  }
  async getItemListAsync(): Promise<void> {
    const result = await this.api.getAll();
    this.setItemList(result);
  }

  async getItemAsync(id: number): Promise<void> {
    const result = await this.api.getOne(id);
    this.setItem(result);
  }

  async insertItemAsync(item: TbCreateCustomerRequestBody): Promise<void> {
    const result = await this.api.insert(item);
    this.setItem(result);
  }
  async updateItemAsync(
    item: TbUpdateCustomerRequestBody,
    customerId: number
  ): Promise<void> {
    const result = await this.api.update(item, customerId);
    this.setItem(result);
  }
  async deleteItemAsync(id: number): Promise<void> {
    const result = await this.api.delete(id);
    this.resetItem();
  }

  async assignAssetToCustomer(customerUuid: string, assetId: string) {
    const result = await this.api.assignAssetToCustomer(customerUuid, assetId);
  }

  getItemFromList(id: number): TbCustomer {
    return this.itemList.find((element) => Number(id) === Number(element.id))!;
  }
}
