import { deviceDatabaseColumnNameApi } from "../../api/api";
import { DeviceDatabaseColumnName } from "../../api/DeviceDatabaseColumnName/DeviceDatabaseColumnNameInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDeviceDatabaseColumnName } from "./DefaultDeviceDatabaseColumnName";

export class DeviceDatabaseColumnNameStore extends MobXStore<
  DeviceDatabaseColumnName,
  DeviceDatabaseColumnName
> {
  constructor(root: RootStore) {
    super(
      root,
      deviceDatabaseColumnNameApi,
      "deviceDatabaseColumnName",
      "Device Database Column Name",
      "Device Database Column Names",
      defaultDeviceDatabaseColumnName
    );
  }
}
