import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import {
  DeviceConfigInput,
  DeviceConfigLevel,
  DeviceConfigSchema,
  DeviceConfigType,
} from "../../../../api/DeviceConfig/DeviceConfigInterface";
import {
  DeviceModeInput,
  DeviceRoleModeInput,
  DeviceSpecificationModeInput,
} from "../../../../api/DeviceMode/DeviceModeInterface";
import {
  DeviceRuleInput,
  DeviceRoleRuleInput,
  DeviceSpecificationRuleInput,
} from "../../../../api/DeviceRule/DeviceRuleInterface";
import {
  DeviceSenseInput,
  DeviceRoleSenseInput,
  DeviceSpecificationSenseInput,
} from "../../../../api/DeviceSense/DeviceSenseInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./DeviceConfigValidation";

const DeviceForm: FC<FormProps> = ({ id }: FormProps) => {
  const router = useRouter();
  const {
    deviceConfigStore,
    deviceStore,
    deviceRoleStore,
    deviceSpecificationStore,
    userInterfaceStore,
  } = useRootStore();

  const deviceConfigSchema = useMemo(() => generateSchema(), []);

  const resolver = useYupValidationResolver(deviceConfigSchema);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    reset,
    watch,
  } = useForm({
    defaultValues: deviceConfigStore.defaultItem,
    resolver,
  });

  useEffect(() => {
    autorun(async () => {
      reset(deviceConfigStore.item);
    });
  }, [deviceConfigStore.item, reset]);

  const level = watch("level");

  const endpoint = router.query.endpoint;
  const rowId = router.query.id;
  console.log(rowId);

  async function processUpdateDelete(
    deviceConfig: DeviceConfigInput,
    command: string,
    endpoint: string
  ) {
    switch (endpoint) {
      case "deviceMode":
        deviceConfig.mode = command;
        if (
          deviceConfig.type === DeviceConfigType.MODE &&
          deviceConfig.level === DeviceConfigLevel.DEVICE
        ) {
          await deviceConfigStore.updateDeviceModeItemAsync(
            deviceConfig as DeviceModeInput
          );
          break;
        }
        await deviceConfigStore.deleteDeviceModeItemAsync(Number(rowId));
        handleInsert(deviceConfig, command);
        break;
      case "deviceRoleMode":
        deviceConfig.mode = command;
        if (
          deviceConfig.type === DeviceConfigType.MODE &&
          deviceConfig.level === DeviceConfigLevel.DEVICE_ROLE
        ) {
          await deviceConfigStore.updateDeviceRoleModeItemAsync(
            deviceConfig as DeviceRoleModeInput
          );
          break;
        }
        await deviceConfigStore.deleteDeviceRoleModeItemAsync(Number(rowId));
        handleInsert(deviceConfig, command);
        break;
      case "deviceSpecificationMode":
        deviceConfig.mode = command;
        if (
          deviceConfig.type === DeviceConfigType.MODE &&
          deviceConfig.level === DeviceConfigLevel.DEVICE_SPECIFICATION
        ) {
          await deviceConfigStore.updateDeviceSpecificationModeItemAsync(
            deviceConfig as DeviceSpecificationModeInput
          );
          break;
        }
        await deviceConfigStore.deleteDeviceSpecificationModeItemAsync(
          Number(rowId)
        );
        handleInsert(deviceConfig, command);
        break;
      case "deviceSense":
        deviceConfig.sense = command;
        if (
          deviceConfig.type === DeviceConfigType.SENSE &&
          deviceConfig.level === DeviceConfigLevel.DEVICE
        ) {
          await deviceConfigStore.updateDeviceSenseItemAsync(
            deviceConfig as DeviceSenseInput
          );
          break;
        }
        await deviceConfigStore.deleteDeviceSenseItemAsync(Number(rowId));
        handleInsert(deviceConfig, command);
        break;
      case "deviceRoleSense":
        deviceConfig.sense = command;
        if (
          deviceConfig.type === DeviceConfigType.SENSE &&
          deviceConfig.level === DeviceConfigLevel.DEVICE_ROLE
        ) {
          await deviceConfigStore.updateDeviceRoleSenseItemAsync(
            deviceConfig as DeviceRoleSenseInput
          );
          break;
        }
        await deviceConfigStore.deleteDeviceRoleSenseItemAsync(Number(rowId));
        handleInsert(deviceConfig, command);
        break;
      case "deviceSpecificationSense":
        deviceConfig.sense = command;
        if (
          deviceConfig.type === DeviceConfigType.SENSE &&
          deviceConfig.level === DeviceConfigLevel.DEVICE_SPECIFICATION
        ) {
          await deviceConfigStore.updateDeviceSpecificationSenseItemAsync(
            deviceConfig as DeviceSpecificationSenseInput
          );
          break;
        }
        await deviceConfigStore.deleteDeviceSpecificationSenseItemAsync(
          Number(rowId)
        );
        handleInsert(deviceConfig, command);
        break;
      case "deviceRule":
        deviceConfig.rule = command;
        if (
          deviceConfig.type === DeviceConfigType.RULE &&
          deviceConfig.level === DeviceConfigLevel.DEVICE
        ) {
          await deviceConfigStore.updateDeviceRuleItemAsync(
            deviceConfig as DeviceRuleInput
          );
          break;
        }
        await deviceConfigStore.deleteDeviceRuleItemAsync(Number(rowId));
        handleInsert(deviceConfig, command);
        break;
      case "deviceRoleRule":
        deviceConfig.rule = command;
        if (
          deviceConfig.type === DeviceConfigType.RULE &&
          deviceConfig.level === DeviceConfigLevel.DEVICE_ROLE
        ) {
          await deviceConfigStore.updateDeviceRoleRuleItemAsync(
            deviceConfig as DeviceRoleRuleInput
          );
          break;
        }
        await deviceConfigStore.deleteDeviceRoleRuleItemAsync(Number(rowId));
        handleInsert(deviceConfig, command);
        break;
      case "deviceSpecificationRule":
        deviceConfig.rule = command;
        if (
          deviceConfig.type === DeviceConfigType.RULE &&
          deviceConfig.level === DeviceConfigLevel.DEVICE_SPECIFICATION
        ) {
          await deviceConfigStore.updateDeviceSpecificationRuleItemAsync(
            deviceConfig as DeviceSpecificationRuleInput
          );
          break;
        }
        await deviceConfigStore.deleteDeviceSpecificationRuleItemAsync(
          Number(rowId)
        );
        handleInsert(deviceConfig, command);
        break;
    }
  }

  async function handleInsert(
    deviceConfig: DeviceConfigInput,
    command: string
  ) {
    switch (deviceConfig.type) {
      case DeviceConfigType.MODE:
        deviceConfig.mode = command;
        switch (deviceConfig.level) {
          case DeviceConfigLevel.DEVICE:
            await deviceConfigStore.insertDeviceModeItemAsync(
              deviceConfig as DeviceModeInput
            );
            router.history.push(
              `/${deviceConfigStore.endpoint}/edit/deviceMode/${deviceConfigStore.item.id}`
            );
            break;
          case DeviceConfigLevel.DEVICE_ROLE:
            await deviceConfigStore.insertDeviceRoleModeItemAsync(
              deviceConfig as DeviceRoleModeInput
            );
            router.history.push(
              `/${deviceConfigStore.endpoint}/edit/deviceRoleMode/${deviceConfigStore.item.id}`
            );
            break;
          case DeviceConfigLevel.DEVICE_SPECIFICATION:
            await deviceConfigStore.insertDeviceSpecificationModeItemAsync(
              deviceConfig as DeviceSpecificationModeInput
            );
            router.history.push(
              `/${deviceConfigStore.endpoint}/edit/deviceSpecificationMode/${deviceConfigStore.item.id}`
            );
            break;
        }
        break;
      case DeviceConfigType.SENSE:
        deviceConfig.sense = command;
        switch (deviceConfig.level) {
          case DeviceConfigLevel.DEVICE:
            await deviceConfigStore.insertDeviceSenseItemAsync(
              deviceConfig as DeviceSenseInput
            );
            router.history.push(
              `/${deviceConfigStore.endpoint}/edit/deviceSense/${deviceConfigStore.item.id}`
            );
            break;
          case DeviceConfigLevel.DEVICE_ROLE:
            await deviceConfigStore.insertDeviceRoleSenseItemAsync(
              deviceConfig as DeviceRoleSenseInput
            );
            router.history.push(
              `/${deviceConfigStore.endpoint}/edit/deviceRoleSense/${deviceConfigStore.item.id}`
            );
            break;
          case DeviceConfigLevel.DEVICE_SPECIFICATION:
            await deviceConfigStore.insertDeviceSpecificationSenseItemAsync(
              deviceConfig as DeviceSpecificationSenseInput
            );
            router.history.push(
              `/${deviceConfigStore.endpoint}/edit/deviceSpecificationSense/${deviceConfigStore.item.id}`
            );
            break;
        }
        break;
      case DeviceConfigType.RULE:
        deviceConfig.rule = command;
        switch (deviceConfig.level) {
          case DeviceConfigLevel.DEVICE:
            await deviceConfigStore.insertDeviceRuleItemAsync(
              deviceConfig as DeviceRuleInput
            );
            router.history.push(
              `/${deviceConfigStore.endpoint}/edit/deviceRule/${deviceConfigStore.item.id}`
            );
            break;
          case DeviceConfigLevel.DEVICE_ROLE:
            await deviceConfigStore.insertDeviceRoleRuleItemAsync(
              deviceConfig as DeviceRoleRuleInput
            );
            router.history.push(
              `/${deviceConfigStore.endpoint}/edit/deviceRoleRule/${deviceConfigStore.item.id}`
            );
            break;
          case DeviceConfigLevel.DEVICE_SPECIFICATION:
            await deviceConfigStore.insertDeviceSpecificationRuleItemAsync(
              deviceConfig as DeviceSpecificationRuleInput
            );
            router.history.push(
              `/${deviceConfigStore.endpoint}/edit/deviceSpecificationRule/${deviceConfigStore.item.id}`
            );
            break;
        }
        break;
    }
  }

  const onSubmit = async (values: DeviceConfigSchema) => {
    let deviceConfig: DeviceConfigInput = {
      id: Number(rowId),
      type: values.type,
      level: values.level,
      deviceId: values.deviceId,
      deviceRoleId: values.deviceRoleId,
      deviceSpecificationId: values.deviceSpecificationId,
    };
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
      case PageType.Inserted:
        processUpdateDelete(deviceConfig, values.command, endpoint!);
        break;
      case PageType.New:
      case PageType.NewDuplicate:
        handleInsert(deviceConfig, values.command);
        userInterfaceStore.setPageType(PageType.Inserted);
        break;
    }
  };

  let deviceConfigTypeOptions: { id: string; value: string }[] = [];
  for (let item in DeviceConfigType) {
    deviceConfigTypeOptions.push({ id: item, value: item });
  }
  let deviceConfigLevelOptions: { id: string; value: string }[] = [];
  for (let item in DeviceConfigLevel) {
    deviceConfigLevelOptions.push({ id: item, value: item });
  }

  const deviceRoleList = deviceRoleStore.itemList;
  let deviceRoleOptions: { id: number; value: string }[] = [];
  if (deviceRoleList.length) {
    deviceRoleOptions = deviceRoleList.map((deviceRole) => {
      return {
        id: deviceRole.id,
        value: `${deviceRole.name}`,
      };
    });
  }
  const deviceList = deviceStore.itemList;
  let deviceOptions: { id: number; value: string }[] = [];
  if (deviceList.length) {
    deviceOptions = deviceList.map((device) => {
      return {
        id: device.id,
        value: `${device.macAddress}`,
      };
    });
  }
  const deviceSpecificationList = deviceSpecificationStore.itemList;
  let deviceSpecificationOptions: { id: number; value: string }[] = [];
  if (deviceSpecificationList.length) {
    deviceSpecificationOptions = deviceSpecificationList.map(
      (deviceSpecification) => {
        return {
          id: deviceSpecification.id,
          value: `${deviceSpecification.name}`,
        };
      }
    );
  }

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={deviceConfigStore} />
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <SelectInputField
          name="type"
          displayName="Type"
          options={deviceConfigTypeOptions}
          errors={errors}
          control={control}
          readOnly={false}
        />
        <SelectInputField
          name="level"
          displayName="Level"
          options={deviceConfigLevelOptions}
          errors={errors}
          control={control}
          readOnly={false}
        />
        {level === DeviceConfigLevel.DEVICE && (
          <SelectInputField
            name="deviceId"
            displayName="Device"
            options={deviceOptions}
            errors={errors}
            control={control}
            readOnly={false}
          />
        )}
        {level === DeviceConfigLevel.DEVICE_ROLE && (
          <SelectInputField
            name="deviceRoleId"
            displayName="Device Role"
            options={deviceRoleOptions}
            errors={errors}
            control={control}
            readOnly={false}
          />
        )}
        {level === DeviceConfigLevel.DEVICE_SPECIFICATION && (
          <SelectInputField
            name="deviceSpecificationId"
            displayName="Device Specification"
            options={deviceSpecificationOptions}
            errors={errors}
            control={control}
            readOnly={false}
          />
        )}
        <TextInputField
          name="command"
          displayName="Command"
          errors={errors}
          control={control}
          readOnly={false}
          numberOfLines={4}
        />
      </form>
    </Fragment>
  );
};

export default observer(DeviceForm);
