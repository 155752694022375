import { deviceSenseApi } from "../../api/api";
import {
  DeviceSense,
  DeviceSenseInput,
} from "../../api/DeviceSense/DeviceSenseInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDeviceSense } from "./DefaultDeviceSense";

export class DeviceSenseStore extends MobXStore<DeviceSense, DeviceSenseInput> {
  constructor(root: RootStore) {
    super(
      root,
      deviceSenseApi,
      "deviceSense",
      "Device Sense",
      "Device Sense",
      defaultDeviceSense
    );
  }
}
