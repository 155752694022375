import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import {
  BuildingInput,
  BuildingSchema,
} from "../../../../api/Building/BuildingInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import calculateReadOnly from "../../../../utils/calculateReadOnly";
import NumberInputField from "../../../Form/NumberInputField/NumberInputField";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./BuildingValidation";

const BuildingForm: FC<FormProps> = ({ id }: FormProps) => {
  const router = useRouter();
  const { buildingStore, userInterfaceStore, tbCustomerStore, tbAssetStore } =
    useRootStore();

  const buildingSchema = useMemo(
    () => generateSchema(userInterfaceStore.pageType!),
    [userInterfaceStore.pageType]
  );

  const resolver = useYupValidationResolver(buildingSchema);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    reset,
  } = useForm({
    defaultValues: buildingStore.defaultItem,
    resolver,
  });

  const onSubmit = async (values: BuildingSchema) => {
    let building: BuildingInput = {
      customerId: values.customer.id,
      buildingUuid: values.buildingUuid,
      siteName: values.siteName,
      latitude: values.latitude,
      longitude: values.longitude,
    };
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
      case PageType.Inserted:
        building.id = Number(router.query.id);
        let updateResult = await buildingStore.updateItemAsync(building);

        break;
      case PageType.New:
      case PageType.NewDuplicate:
        await buildingStore.insertItemAsync(building);
        userInterfaceStore.setPageType(PageType.Inserted);
        router.history.push(
          `/${buildingStore.endpoint}/edit/${buildingStore.item.id}`
        );
        break;
    }
  };

  useEffect(() => {
    autorun(async () => {
      reset(buildingStore.item);
    });
  }, [buildingStore, reset]);
  const readOnly = calculateReadOnly(userInterfaceStore.pageType);

  const customerList = tbCustomerStore.itemList;
  let customerOptions: { id: number; value: string }[] = [];
  if (customerList.length) {
    customerOptions = customerList.map((customer) => {
      return {
        id: customer.id,
        value: `${customer.title}`,
      };
    });
  }

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={buildingStore} />
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <TextInputField
          name="siteName"
          displayName="Site Name"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <SelectInputField
          name="customer.id"
          options={customerOptions}
          displayName="Customer"
          errors={errors}
          control={control}
          readOnly={
            userInterfaceStore.pageType !== PageType.New &&
            userInterfaceStore.pageType !== PageType.NewDuplicate &&
            true
          }
        />
        <NumberInputField
          name="latitude"
          displayName="Latitude"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <NumberInputField
          name="longitude"
          displayName="Longitude"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
      </form>
    </Fragment>
  );
};

export default observer(BuildingForm);
