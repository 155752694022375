import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { observer } from "mobx-react";
import React, { FC, Fragment } from "react";
import { useRootStore } from "../../hooks/useRootStore";
import { DeviceConfigStore } from "../../state/DeviceConfig/DeviceConfigStore";
import SnackbarAlert from "../SnackbarAlert/SnackbarAlert";

type DeviceConfigDataTableDeleteProps = {
  store: DeviceConfigStore;
};

const DeviceConfigDataTableDelete: FC<DeviceConfigDataTableDeleteProps> = ({
  store,
}: DeviceConfigDataTableDeleteProps) => {
  const { userInterfaceStore } = useRootStore();

  const handleClose = () => {
    userInterfaceStore.setDialogOpen(false);
  };

  const handleDelete = () => {
    userInterfaceStore.setDialogOpen(false);
    processDeleteDeviceConfig(String(userInterfaceStore.id), store);
  };

  return (
    <Fragment>
      <SnackbarAlert store={store} />
      <Dialog
        open={userInterfaceStore.dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Delete ${store.singularDisplayName}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const DateTableDeleteObserver = observer(DeviceConfigDataTableDelete);

async function processDeleteDeviceConfig(
  stringId: string,
  store: DeviceConfigStore
) {
  const splitString = stringId.split("/");
  // index 0 is the endpoint, index 1 is the value
  switch (splitString[0]) {
    case "deviceMode":
      await store.deleteDeviceModeItemAsync(Number(splitString[1]));
      break;
    case "deviceRoleMode":
      await store.deleteDeviceRoleModeItemAsync(Number(splitString[1]));
      break;
    case "deviceSpecificationMode":
      await store.deleteDeviceSpecificationModeItemAsync(
        Number(splitString[1])
      );
      break;
    case "deviceSense":
      await store.deleteDeviceSenseItemAsync(Number(splitString[1]));
      break;
    case "deviceRoleSense":
      await store.deleteDeviceRoleSenseItemAsync(Number(splitString[1]));
      break;
    case "deviceSpecificationSense":
      await store.deleteDeviceSpecificationSenseItemAsync(
        Number(splitString[1])
      );
      break;
    case "deviceRule":
      await store.deleteDeviceRuleItemAsync(Number(splitString[1]));
      break;
    case "deviceRoleRule":
      await store.deleteDeviceRoleRuleItemAsync(Number(splitString[1]));
      break;
    case "deviceSpecificationRule":
      await store.deleteDeviceSpecificationRuleItemAsync(
        Number(splitString[1])
      );
      break;
  }
}
export default DateTableDeleteObserver;
