import { deviceSpecificationApi } from "../../api/api";
import {
  DeviceSpecification,
  DeviceSpecificationInput,
} from "../../api/DeviceSpecification/DeviceSpecificationInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDeviceSpecification } from "./DefaultDeviceSpecification";

export class DeviceSpecificationStore extends MobXStore<
  DeviceSpecification,
  DeviceSpecificationInput
> {
  constructor(root: RootStore) {
    super(
      root,
      deviceSpecificationApi,
      "deviceSpecification",
      "Device Specification",
      "Device Specifications",
      defaultDeviceSpecification
    );
  }
}
