import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  DeviceMode,
  DeviceSpecificationModeInput,
} from "./DeviceModeInterface";

export class DeviceSpecificationModeApi extends AbstractApi<
  DeviceMode,
  DeviceSpecificationModeInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "deviceSpecificationMode");
  }
}
