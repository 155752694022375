import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  ApiArrayResponseSuccess,
  ApiResponseFailure,
} from "../types/apiResponse";
import { axiosGetByForeignKeyIdRequest } from "../util/axiosRequest";
import { PersonRoom, PersonRoomInput } from "./PersonRoomInterface";
export class PersonRoomApi extends AbstractApi<PersonRoom, PersonRoomInput> {
  constructor(baseUrl: string) {
    super(baseUrl, "personRoom");
  }
  public async getByForeignKeyId(
    id: number
  ): Promise<ApiArrayResponseSuccess<PersonRoom> | ApiResponseFailure> {
    return axiosGetByForeignKeyIdRequest(
      this.baseUrl,
      this.endpoint,
      "person",
      id
    );
  }
}
