import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { PersonArea } from "../../../../api/PersonArea/PersonAreaInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import { ActionType } from "../../types";
import { generateSchema } from "./PersonAreaValidation";

const PersonAreaDialogForm: FC<FormProps> = ({ id }: FormProps) => {
  const { personAreaStore, areaStore } = useRootStore();

  const handleClose = () => {
    personAreaStore.setDialogOpen(false);
  };

  const handleDelete = () => {
    personAreaStore.handleDelete();
    handleClose();
  };

  const onSubmit = (values: PersonArea) => {
    const deviceFunctionInstanceDevice = areaStore.getItemFromList(
      values.area.id
    );
    switch (personAreaStore.actionType) {
      case ActionType.Edit:
        personAreaStore.handleEdit(values, deviceFunctionInstanceDevice);
        break;
      case ActionType.New:
        personAreaStore.handleInsert(values, deviceFunctionInstanceDevice);
        break;
    }
    handleClose();
  };

  const personAreaSchema = useMemo(
    () => generateSchema(personAreaStore.itemList),
    [personAreaStore.itemList]
  );

  const resolver = useYupValidationResolver(personAreaSchema);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: personAreaStore.defaultItem,
    resolver,
  });

  useEffect(() => {
    autorun(async () => {
      reset(personAreaStore.item);
    });
  }, [reset, personAreaStore.item]);

  let areaOptions = null;
  if (areaStore.itemList) {
    const deviceFunctionInstanceDevices = areaStore.itemList;
    areaOptions = deviceFunctionInstanceDevices.map((area) => {
      return {
        id: area.id,
        value: `${area.name}`,
      };
    });
  }

  return (
    <Dialog
      open={personAreaStore.dialogOpen}
      onClose={handleClose}
      aria-labelledby="person-area-form-dialog-title"
    >
      <DialogTitle id="person-area-form-dialog-title">
        {personAreaStore.actionType === ActionType.Edit && "Modify "}
        {personAreaStore.actionType === ActionType.New && "Add a new "}
        Area
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {personAreaStore.actionType === ActionType.Edit && "Modify area "}
          {personAreaStore.actionType === ActionType.New && "Add a new area "}
          for the person
        </DialogContentText>
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
          <SelectInputField
            name="area.id"
            options={areaOptions}
            displayName="Area"
            errors={errors}
            control={control}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {personAreaStore.actionType === ActionType.Edit && (
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        )}
        <Button form={id} type="submit" color="primary">
          {personAreaStore.actionType === ActionType.Edit && "Update"}
          {personAreaStore.actionType === ActionType.New && "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(PersonAreaDialogForm);
