import { makeAutoObservable } from "mobx";
import { TbEntityRelationApi } from "../../api/TbEntityRelation/TbEntityRelation";
import {
  TbEntityRelation,
  TbEntityRelationRequestBody,
} from "../../api/TbEntityRelation/TbEntityRelationInterface";
import { RootStore } from "../Root/RootStore";

export class TbEntityRelationStore {
  public root: RootStore;
  public api: TbEntityRelationApi;
  public defaultItem: TbEntityRelation;
  public item: TbEntityRelation;
  public itemList: TbEntityRelation[];

  constructor(
    root: RootStore,
    api: TbEntityRelationApi,
    defaultItem: TbEntityRelation
  ) {
    this.root = root;
    this.api = api;
    this.defaultItem = defaultItem;
    this.item = defaultItem;
    this.itemList = [defaultItem];
    makeAutoObservable(this);
  }

  setItem(item: TbEntityRelation) {
    this.item = item;
  }
  resetItem() {
    this.item = this.defaultItem;
  }

  async insertItemAsync(item: TbEntityRelationRequestBody): Promise<void> {
    await this.api.insert(item);
  }
}
