import { DeviceSpecification } from "../../api/DeviceSpecification/DeviceSpecificationInterface";
import { defaultActuatorSpecification } from "../ActuatorSpecification/DefaultActuatorSpecification";
import { defaultPlatformSpecification } from "../PlatformSpecification/DefaultPlatformSpecification";
import { defaultSensorSpecification } from "../SensorSpecification/DefaultSensorSpecification";

export const defaultDeviceSpecification: DeviceSpecification = {
  id: 0,
  platformSpecification: defaultPlatformSpecification,

  actuatorList: [
    {
      id: 0,
      actuatorSpecification: defaultActuatorSpecification,
      quantity: 0,
    },
  ],
  sensorList: [
    {
      id: 0,
      sensorSpecification: defaultSensorSpecification,
      quantity: 0,
    },
  ],
  name: "",
  manufacturer: "",
  enclosure: "",
  model: "",
  supplyVoltage: 0,
  token: "",
  versionNumber: 0,
};
