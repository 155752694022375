import { ServiceAllocation } from "../../api/ServiceAllocation/ServiceAllocationInterface";
import { defaultPeriod } from "../Period/DefaultPeriod";
import { defaultResource } from "../Resource/DefaultResource";
import { defaultServicePackage } from "../ServicePackage/DefaultServicePackage";

export const defaultServiceAllocation: ServiceAllocation = {
  id: 0,
  servicePackage: defaultServicePackage,
  resource: defaultResource,
  period: defaultPeriod,
  allocation: 0,
  carryOver: false,
};
