import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  ActuatorSpecification,
  ActuatorSpecificationInput,
} from "./ActuatorSpecificationInterface";
export class ActuatorSpecificationApi extends AbstractApi<
  ActuatorSpecification,
  ActuatorSpecificationInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "actuatorSpecification");
  }
}
