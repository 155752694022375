import { date, number, object, ref, string } from "yup";
import { personApi } from "../../../../api/api";
import { Person } from "../../../../api/Person/PersonInterface";
import { occurrencesInNumberArray } from "../../../../utils/occurrencesInArray";
import { PageType } from "../../types";

export function generateSchema(type: PageType, person: Person) {
  const schema = object().shape({
    tag: object().shape({
      id: number().test(
        "checkTagUnique",
        "The tag is already in use!",
        async (value: unknown): Promise<boolean> => {
          if (typeof value !== "number") {
            return false;
          }
          if (value === 0) {
            return true;
          }
          const personList = await personApi.getAll();
          if ("error" in personList) {
            return false;
          }
          if (!personList.data.length) {
            return true;
          }
          if (type === PageType.Existing || type === PageType.Inserted) {
            personList.data = personList.data.filter(
              (personInList) => personInList.id !== person.id
            );
          }
          const personTags = personList.data.map((person) =>
            Number(person.tag.id)
          );
          const occurrences = occurrencesInNumberArray(value, personTags);

          return occurrences < 1;
        }
      ),
    }),
    personType: object().shape({
      id: number().required().positive(),
    }),
    personStatus: object().shape({
      id: number().required().positive(),
    }),
    firstName: string().required(),
    lastName: string().required(),
    dateOfBirth: date().required().max(new Date()),
    emailAddress: string().email().required(),
    employer: string().required(),
    startDate: date().required().max(ref("endDate")),
    endDate: date().required().min(ref("startDate")),
  });
  return schema;
}
