import { GridColumns } from "@material-ui/data-grid";
import { Tag } from "../../../../api/Tag/TagInterface";
import { format } from "date-fns";

function getTagModel(params: any) {
  const tag: Tag = params.row;
  if (tag.tagModel) {
    return tag.tagModel.name;
  }
}
function getTagStatus(params: any) {
  const tag: Tag = params.row;
  if (tag.tagStatus.status) {
    return tag.tagStatus.status;
  }
}
function getFormattedActivationDateLocal(params: any) {
  const tag: Tag = params.row;
  if (tag.activationDate) {
    const date = new Date(tag.activationDate);
    return format(date, "yyyy-MM-dd, HH:mm:ss");
  }
}
function getFormattedActivationDateUtc(params: any) {
  const tag: Tag = params.row;
  const timezoneOffset = new Date().getTimezoneOffset();
  if (tag.activationDate) {
    const date = new Date(tag.activationDate);
    const utcDate = new Date(date.getTime() + timezoneOffset * 60000);
    return format(utcDate, "yyyy-MM-dd, HH:mm:ss");
  }
}
function getAssignedPersonName(params: any) {
  const tag: Tag = params.row;
  if (tag.person) {
    return `${tag.person.firstName} ${tag.person.lastName}`;
  }
}
function getHexTagValue(params: any) {
  const tag: Tag = params.row;
  if (tag.tagValue) {
    return `0x${Number(tag.tagValue).toString(16)}`;
  }
}

export const tagListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "tagModel",
    headerName: "Tag Model",
    valueGetter: getTagModel,
    flex: 1,
  },
  {
    field: "personName",
    headerName: "Assigned Person",
    valueGetter: getAssignedPersonName,
    flex: 1,
  },
  {
    field: "tagStatus",
    headerName: "Tag Status",
    valueGetter: getTagStatus,
    flex: 1,
  },

  {
    field: "activationDate",
    headerName: "Activation Date (Local)",
    valueGetter: getFormattedActivationDateLocal,
    flex: 1,
  },
  {
    field: "activationDateUtc",
    headerName: "Activation Date (UTC)",
    valueGetter: getFormattedActivationDateUtc,
    flex: 1,
  },
  {
    field: "tagValue",
    valueGetter: getHexTagValue,
    headerName: "Tag Value",
    flex: 1,
  },
];
