import { AbstractAPI as AbstractApi } from "../abstractApi";
import { ResourceUnit, ResourceUnitInput } from "./ResourceUnitInterface";
export class ResourceUnitApi extends AbstractApi<
  ResourceUnit,
  ResourceUnitInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "resourceUnit");
  }
}
