import axios, { AxiosError, AxiosResponse } from "axios";
import {
  ApiArrayResponseSuccess,
  ApiObjectResponseSuccess,
  ApiResponseFailure,
} from "../types/apiResponse";

export async function axiosGetAllRequest<outputType>(
  baseUrl: string,
  endpoint: string
): Promise<ApiArrayResponseSuccess<outputType> | ApiResponseFailure> {
  return await axios
    .get(`${baseUrl}/${endpoint}`)
    .then((response: AxiosResponse) => {
      const responseSuccess: ApiArrayResponseSuccess<outputType> = {
        data: response.data as outputType[],
        success: true,
      };
      return responseSuccess;
    })
    .catch((error: AxiosError) => {
      const responseFailure: ApiResponseFailure = {
        error: error.message,
        success: false,
      };
      return responseFailure;
    });
}

export async function axiosGetOneRequest<outputType>(
  baseUrl: string,
  endpoint: string,
  id: number
): Promise<ApiObjectResponseSuccess<outputType> | ApiResponseFailure> {
  return await axios
    .get(`${baseUrl}/${endpoint}/${id.toString()}`)
    .then((response: AxiosResponse) => {
      const responseSuccess: ApiObjectResponseSuccess<outputType> = {
        data: response.data as outputType,
        success: true,
      };
      return responseSuccess;
    })
    .catch((error: AxiosError) => {
      const responseFailure: ApiResponseFailure = {
        error: error.message,
        success: false,
      };
      return responseFailure;
    });
}

export async function axiosUpdateRequest<
  outputType extends { id: number },
  inputType extends { id?: number }
>(
  baseUrl: string,
  endpoint: string,
  record: inputType
): Promise<ApiObjectResponseSuccess<outputType> | ApiResponseFailure> {
  return await axios
    .post(`${baseUrl}/${endpoint}/${record.id!.toString()}`, record)
    .then((response: AxiosResponse) => {
      const responseSuccess: ApiObjectResponseSuccess<outputType> = {
        data: response.data as outputType,
        success: true,
      };
      return responseSuccess;
    })
    .catch((error: AxiosError) => {
      const responseFailure: ApiResponseFailure = {
        error: error.response!.data,
        success: false,
      };
      return responseFailure;
    });
}

export async function axiosInsertRequest<outputType, inputType>(
  baseUrl: string,
  endpoint: string,
  record: inputType
): Promise<ApiObjectResponseSuccess<outputType> | ApiResponseFailure> {
  return await axios
    .post(`${baseUrl}/${endpoint}`, record)
    .then((response: AxiosResponse) => {
      const responseSuccess: ApiObjectResponseSuccess<outputType> = {
        data: response.data as outputType,
        success: true,
      };
      return responseSuccess;
    })
    .catch((error: AxiosError) => {
      const responseFailure: ApiResponseFailure = {
        error: error.response!.data,
        success: false,
      };
      return responseFailure;
    });
}

export async function axiosDeleteRequest<outputType>(
  baseUrl: string,
  endpoint: string,
  id: number
): Promise<ApiObjectResponseSuccess<outputType> | ApiResponseFailure> {
  return await axios
    .delete(`${baseUrl}/${endpoint}/${id.toString()}`)
    .then((response: AxiosResponse) => {
      const responseSuccess: ApiObjectResponseSuccess<outputType> = {
        data: response.data as outputType,
        success: true,
      };
      return responseSuccess;
    })
    .catch((error: AxiosError) => {
      const responseFailure: ApiResponseFailure = {
        error: error.response!.data,
        success: false,
      };
      return responseFailure;
    });
}

export async function axiosGetByForeignKeyIdRequest<outputType>(
  baseUrl: string,
  endpoint: string,
  foreignKey: string,
  id: number
): Promise<ApiArrayResponseSuccess<outputType> | ApiResponseFailure> {
  return await axios
    .get(`${baseUrl}/${endpoint}/${foreignKey}/${id}`)
    .then((response: AxiosResponse) => {
      const responseSuccess: ApiArrayResponseSuccess<outputType> = {
        data: response.data as outputType[],
        success: true,
      };
      return responseSuccess;
    })
    .catch((error: AxiosError) => {
      const responseFailure: ApiResponseFailure = {
        error: error.message,
        success: false,
      };
      return responseFailure;
    });
}
