import { Room, SystemRoomStatus } from "../../api/Room/RoomInterface";
import { defaultBuilding } from "../Building/DefaultBuilding";
import { defaultRoomStatus } from "../RoomStatus/DefaultRoomStatus";
import { defaultRoomType } from "../RoomType/DefaultRoomType";

export const defaultRoom: Room = {
  id: 0,
  name: "",
  building: defaultBuilding,
  roomType: defaultRoomType,
  systemRoomStatus: SystemRoomStatus.AVAILABLE,
  roomStatus: defaultRoomStatus,
  roomUuid: "",
  floor: 0,
  number: 0,
  loft: false,
  paraplegic: false,
};
