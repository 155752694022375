import { object, SchemaOf, string } from "yup";
import { deviceFunctionApi } from "../../../../api/api";
import { DeviceFunctionSchema } from "../../../../api/DeviceFunction/DeviceFunctionInterface";
import { occurrencesInStringArray } from "../../../../utils/occurrencesInArray";
import { PageType } from "../../types";

export function generateSchema(type: PageType) {
  const schema: SchemaOf<DeviceFunctionSchema> = object().shape({
    name: string()
      .required()
      .test(
        "checkNameUnique",
        "The device function name already exists!",
        async (value: unknown): Promise<boolean> => {
          if (typeof value !== "string") {
            return false;
          }
          const deviceFunctionList = await deviceFunctionApi.getAll();
          if ("error" in deviceFunctionList) {
            return false;
          }
          if (!deviceFunctionList.data.length) {
            return true;
          }
          const deviceFunctions = deviceFunctionList.data.map(
            (deviceFunction) => deviceFunction.name
          );
          const occurrences = occurrencesInStringArray(value, deviceFunctions);
          switch (type) {
            case PageType.New:
            case PageType.NewDuplicate:
              return occurrences < 1;
            case PageType.Existing:
            case PageType.Inserted:
              return occurrences < 2;
          }
          return false;
        }
      ),
  });
  return schema;
}
