import { FormControl, TextField } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import React, { FC } from "react";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";
import TextInputField from "../TextInputField/TextInputField";

type Input = {
  inputValue?: string;
  displayValue: string;
};

type ComboAutocompleteFieldProps = {
  options: Input[] | null;
  name: string;
  displayName: string;
  errors: DeepMap<any, FieldError>;
  control: Control<any>;
  readOnly?: boolean;
};

const filter = createFilterOptions<Input>();

const ComboAutocompleteField: FC<ComboAutocompleteFieldProps> = ({
  options,
  name,
  displayName,
  errors,
  control,
  readOnly,
}) => {
  if (options) {
    return (
      <FormControl fullWidth>
        <Controller
          name={name}
          control={control}
          render={(props) => (
            <Autocomplete
              {...props.field}
              options={options}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.displayValue;
              }}
              onChange={(_, data) => {
                if (!data) {
                  return;
                }
                if (data.inputValue) {
                  props.field.onChange(data.inputValue);
                  return;
                }
                if (data.displayValue) {
                  props.field.onChange(data.displayValue);
                  return;
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    displayValue: `Add "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              handleHomeEndKeys
              id={displayName}
              renderOption={(option) => option.displayValue}
              freeSolo
              renderInput={(params) => (
                <TextField
                  error={errors[name] ? true : false}
                  {...params}
                  name={name}
                  label={displayName}
                  placeholder={displayName}
                  helperText={errors[name] && errors[name].message}
                  variant={readOnly ? "standard" : "outlined"}
                />
              )}
            />
          )}
        />
      </FormControl>
    );
  } else {
    return (
      <TextInputField
        name={name}
        displayName={displayName}
        errors={errors}
        control={control}
        readOnly={readOnly}
      />
    );
  }
};
export default ComboAutocompleteField;
