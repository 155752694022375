import {
  AreaStatus,
  AreaStatusInput,
} from "../../api/AreaStatus/AreaStatusInterface";
import { areaStatusApi } from "../../api/api";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultAreaStatus } from "./DefaultAreaStatus";

export class AreaStatusStore extends MobXStore<AreaStatus, AreaStatusInput> {
  constructor(root: RootStore) {
    super(
      root,
      areaStatusApi,
      "areaStatus",
      "Area Status",
      "Area Statuses",
      defaultAreaStatus
    );
  }
}
