import { PlatformSpecification } from "../../api/PlatformSpecification/PlatformSpecificationInterface";

export const defaultPlatformSpecification: PlatformSpecification = {
  id: 0,
  platform: "",
  mcu: "",
  cores: 0,
  flashInternal: 0,
  flashExternal: 0,
  sramInternal: 0,
  sramExternal: 0,
  clockDefault: 0,
  clockMinimum: 0,
  clockMaximum: 0,
  wifiAInd: false,
  wifiBInd: false,
  wifiGInd: false,
  wifiNInd: false,
  supplyVoltage: 0,
  versionNumber: 0,
};
