import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  ApiArrayResponseSuccess,
  ApiResponseFailure,
} from "../types/apiResponse";
import { axiosGetByForeignKeyIdRequest } from "../util/axiosRequest";
import {
  ActuatorFunctionLine,
  ActuatorFunctionLineInput,
} from "./ActuatorFunctionLineInterface";

export class ActuatorFunctionLineApi extends AbstractApi<
  ActuatorFunctionLine,
  ActuatorFunctionLineInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "actuatorFunctionLine");
  }

  public async getByForeignKeyId(
    id: number
  ): Promise<
    ApiArrayResponseSuccess<ActuatorFunctionLine> | ApiResponseFailure
  > {
    return axiosGetByForeignKeyIdRequest(
      this.baseUrl,
      this.endpoint,
      "deviceFunction",
      id
    );
  }
}
