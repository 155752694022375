import { Grid } from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect } from "react";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import Heading from "../../../Form/Heading/Heading";
import SubmitButton from "../../../Form/SubmitButton/SubmitButton";
import { PageType } from "../../types";
import ActuatorSpecificationDialogForm from "../ActuatorSpecification/DeviceSpecificationActuatorSpecificationDialogForm";
import ActuatorSpecificationTable from "../ActuatorSpecification/DeviceSpecificationActuatorSpecificationTable";
import SensorSpecificationDialogForm from "../SensorSpecification/DeviceSpecificationSensorSpecificationDialogForm";
import SensorSpecificationTable from "../SensorSpecification/DeviceSpecificationSensorSpecificationTable";
import DeviceSpecificationForm from "./DeviceSpecificationForm";

const DeviceSpecificationPage: FC = () => {
  const {
    deviceSpecificationStore,
    deviceSpecificationActuatorSpecificationStore,
    deviceSpecificationSensorSpecificationStore,
    platformSpecificationStore,
    sensorSpecificationStore,
    actuatorSpecificationStore,
    userInterfaceStore,
  } = useRootStore();
  const router = useRouter();

  useEffect(() => {
    autorun(async () => {
      await platformSpecificationStore.getItemListAsync();
      await sensorSpecificationStore.getItemListAsync();
      await actuatorSpecificationStore.getItemListAsync();
      switch (userInterfaceStore.pageType) {
        case PageType.View:
        case PageType.Existing:
          await deviceSpecificationStore.getItemAsync(Number(router.query.id));
          await deviceSpecificationActuatorSpecificationStore.getItemListByForeignKeyAsync(
            Number(router.query.id),
            "deviceSpecification"
          );
          await deviceSpecificationSensorSpecificationStore.getItemListByForeignKeyAsync(
            Number(router.query.id),
            "deviceSpecification"
          );
          break;
        case PageType.New:
          deviceSpecificationStore.resetItem();
          deviceSpecificationSensorSpecificationStore.resetItemList();
          deviceSpecificationActuatorSpecificationStore.resetItemList();
          break;
        case PageType.Inserted:
      }
    });
  }, [
    actuatorSpecificationStore,
    deviceSpecificationActuatorSpecificationStore,
    deviceSpecificationSensorSpecificationStore,
    deviceSpecificationStore,
    platformSpecificationStore,
    router.query.id,
    sensorSpecificationStore,
    userInterfaceStore.pageType,
  ]);

  const formId = "deviceSpecificationForm";
  const sensorSpecificationFormId = "sensorSpecificationForm";
  const actuatorSpecificationFormId = "actuatorSpecificationForm";

  return (
    <Fragment>
      <Heading
        pageType={userInterfaceStore.pageType!}
        heading={deviceSpecificationStore.singularDisplayName}
      />
      <DeviceSpecificationForm id={formId} />
      <SensorSpecificationDialogForm id={sensorSpecificationFormId} />
      <ActuatorSpecificationDialogForm id={actuatorSpecificationFormId} />
      <Grid container spacing={10}>
        <SensorSpecificationTable />
        <ActuatorSpecificationTable />
      </Grid>
      {userInterfaceStore.pageType !== PageType.View && (
        <SubmitButton form={formId} pageType={userInterfaceStore.pageType!} />
      )}
    </Fragment>
  );
};

export default observer(DeviceSpecificationPage);
