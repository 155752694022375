import { GridColumns } from "@material-ui/data-grid";
import { DeviceSpecification } from "../../../../api/DeviceSpecification/DeviceSpecificationInterface";

function getPlatformSpecification(params: any) {
  const deviceSpecification: DeviceSpecification = params.row;
  if (deviceSpecification.platformSpecification) {
    return deviceSpecification.platformSpecification.platform;
  }
}

function getSensorList(params: any) {
  const deviceSpecification: DeviceSpecification = params.row;
  if (deviceSpecification.sensorList) {
    const sensorSpecifications = deviceSpecification.sensorList.map((val) => {
      return `${val.sensorSpecification.manufacturer}-${val.sensorSpecification.model}-${val.sensorSpecification.type} (${val.quantity})`;
    });
    return sensorSpecifications.join(", ");
  }
}
function getActuatorList(params: any) {
  const deviceSpecification: DeviceSpecification = params.row;
  if (deviceSpecification.actuatorList) {
    const actuatorSpecifications = deviceSpecification.actuatorList.map(
      (val) => {
        return `${val.actuatorSpecification.manufacturer}-${val.actuatorSpecification.model}-${val.actuatorSpecification.type} (${val.quantity})`;
      }
    );
    return actuatorSpecifications.join(", ");
  }
}

export const deviceSpecificationListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "platformSpecification",
    headerName: "Platform Specification",
    valueGetter: getPlatformSpecification,
    flex: 1,
  },
  {
    field: "manufacturer",
    headerName: "Manufacturer",
    flex: 1,
  },
  {
    field: "enclosure",
    headerName: "Enclosure",
    hide: true,
  },
  {
    field: "model",
    headerName: "Model",
    flex: 1,
  },
  {
    field: "sensorList",
    headerName: "Sensor List",
    valueGetter: getSensorList,
    flex: 1,
  },
  {
    field: "actuatorList",
    headerName: "Actuator List",
    valueGetter: getActuatorList,
    flex: 1,
  },
  {
    field: "supplyVoltage",
    headerName: "Supply Voltage",
    type: "number",
    hide: true,
  },
  {
    field: "token",
    headerName: "Token",
    hide: true,
  },
];
