import { action, makeObservable, observable, runInAction } from "mobx";
import { sensorFunctionInstanceChannelApi } from "../../api/api";
import { DeviceFunctionInstanceApi } from "../../api/DeviceFunctionInstance/DeviceFunctionInstance";
import { DeviceFunctionInstance } from "../../api/DeviceFunctionInstance/DeviceFunctionInstanceInterface";
import {
  SensorFunctionInstanceChannel,
  SensorFunctionInstanceChannelInput,
} from "../../api/SensorFunctionInstanceChannel/SensorFunctionInstanceChannelInterface";
import { SensorFunctionLineApi } from "../../api/SensorFunctionLine/SensorFunctionLine";
import { ActionType } from "../../components/pages/types";
import { defaultDeviceFunctionInstance } from "../DeviceFunctionInstance/DefaultDeviceFunctionInstance";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultSensorFunctionInstanceChannel } from "./DefaultSensorFunctionInstanceChannel";

export class SensorFunctionInstanceChannelStore extends MobXStore<
  SensorFunctionInstanceChannel,
  SensorFunctionInstanceChannelInput
> {
  localIdCounter: number = 0;
  actionType?: ActionType = undefined;
  dialogOpen: boolean = false;
  deviceFunctionInstanceMap: Map<number, SensorFunctionInstanceChannel[]> =
    new Map<number, SensorFunctionInstanceChannel[]>();
  deviceFunctionInstanceApi: DeviceFunctionInstanceApi;
  sensorFunctionLineApi: SensorFunctionLineApi;

  constructor(
    root: RootStore,
    sensorFunctionLineApi: SensorFunctionLineApi,
    deviceFunctionInstanceApi: DeviceFunctionInstanceApi
  ) {
    super(
      root,
      sensorFunctionInstanceChannelApi,
      "sensorFunctionInstanceChannel",
      "Sensor Function Instance Channel",
      "Sensor Function Instance Channels",
      defaultSensorFunctionInstanceChannel
    );
    makeObservable(this, {
      dialogOpen: observable,
      deviceFunctionInstanceMap: observable,
      setDialogOpen: action,
      setDeviceFunctionInstanceMap: action,
    });
    this.sensorFunctionLineApi = sensorFunctionLineApi;
    this.deviceFunctionInstanceApi = deviceFunctionInstanceApi;
  }

  incrementLocalIdCounter() {
    this.localIdCounter++;
  }

  setActionType(actionType: ActionType) {
    this.actionType = actionType;
  }

  setDialogOpen(dialogOpen: boolean) {
    this.dialogOpen = dialogOpen;
  }

  async setDeviceFunctionInstanceMap(
    deviceFunctionInstances: DeviceFunctionInstance[]
  ): Promise<void> {
    deviceFunctionInstances.forEach(async (deviceFunctionInstance) => {
      const sensorFunctionInstanceChannels = await this.api.getByForeignKeyId!(
        deviceFunctionInstance.id,
        "deviceFunctionInstance"
      );
      if ("error" in sensorFunctionInstanceChannels) {
        this.setAndShowMessage(
          JSON.stringify(sensorFunctionInstanceChannels.error)
        );
        this.setMessageSeverity("error");
        return;
      }
      if (sensorFunctionInstanceChannels.data.length) {
        sensorFunctionInstanceChannels.data.forEach(
          (sensorFunctionInstanceChannel) => {
            sensorFunctionInstanceChannel.localId = this.localIdCounter;
            this.incrementLocalIdCounter();
          }
        );
      }
      this.deviceFunctionInstanceMap.set(
        deviceFunctionInstance.localId!,
        sensorFunctionInstanceChannels.data
      );
    });
  }

  resetDeviceFunctionInstanceMap() {
    this.deviceFunctionInstanceMap.clear();
  }

  setItemListFromDeviceFunctionInstanceMap(
    localDeviceFunctionInstanceId: number
  ) {
    const itemList = this.deviceFunctionInstanceMap.get(
      localDeviceFunctionInstanceId
    );
    if (itemList === undefined) {
      return;
    }
    this.setItemList(itemList);
  }

  async handleDeviceFunctionChange(
    deviceFunctionId: number,
    deviceFunctionInstance: DeviceFunctionInstance
  ): Promise<void> {
    const sensorFunctionLines =
      await this.sensorFunctionLineApi.getByForeignKeyId(deviceFunctionId);
    if ("error" in sensorFunctionLines) {
      this.setAndShowMessage(JSON.stringify(sensorFunctionLines.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: SensorFunctionInstanceChannel[] = [];
    if (sensorFunctionLines.data.length) {
      sensorFunctionLines.data.forEach((sensorFunctionLine) => {
        var channel = 0;
        for (var i = 0; i < sensorFunctionLine.quantity; i++) {
          let item: SensorFunctionInstanceChannel = {
            id: 0,
            localId: this.localIdCounter,
            action: ActionType.New,
            deviceFunctionInstance: defaultDeviceFunctionInstance,
            sensorFunctionLine,
            channel,
            versionNumber: 0,
          };
          channel++;
          this.incrementLocalIdCounter();
          enrichedResults.push(item);
        }
      });
    }
    if (
      this.deviceFunctionInstanceMap.get(deviceFunctionInstance.localId!)
        ?.length
    ) {
      this.deviceFunctionInstanceMap
        .get(deviceFunctionInstance.localId!)
        ?.forEach((sensor) => {
          switch (sensor.action) {
            case undefined:
            case ActionType.Edit:
              enrichedResults.push({ ...sensor, action: ActionType.Delete });
          }
        });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
    this.deviceFunctionInstanceMap.set(
      deviceFunctionInstance.localId!,
      enrichedResults
    );
  }

  handleChangeDeviceSpecification(
    deviceFunctionInstanceList: DeviceFunctionInstance[]
  ) {
    deviceFunctionInstanceList.forEach((deviceFunctionInstance) => {
      let sficList: SensorFunctionInstanceChannel[] = [];
      if (
        this.deviceFunctionInstanceMap.get(deviceFunctionInstance.localId!)
          ?.length
      ) {
        this.deviceFunctionInstanceMap
          .get(deviceFunctionInstance.localId!)
          ?.forEach((sfic) => {
            switch (sfic.action) {
              case undefined:
              case ActionType.Edit:
                sfic.action = ActionType.Delete;
                sficList.push(sfic);
            }
          });
        runInAction(() => {
          this.deviceFunctionInstanceMap.set(
            deviceFunctionInstance.localId!,
            sficList
          );
        });
      }
    });
  }

  handleUpdateDeviceFunctionInstance(
    deviceFunctionInstance: DeviceFunctionInstance
  ) {
    if (
      this.deviceFunctionInstanceMap.get(deviceFunctionInstance.localId!)
        ?.length
    ) {
      let sficList = this.deviceFunctionInstanceMap.get(
        deviceFunctionInstance.localId!
      )!;
      sficList = sficList!.map((sfic, i) => {
        runInAction(() => {
          sfic.channel = deviceFunctionInstance.sensors[i].channel;
          sfic.action = ActionType.Edit;
        });
        return sfic;
      });
      runInAction(() => {
        this.deviceFunctionInstanceMap.set(
          deviceFunctionInstance.localId!,
          sficList
        );
      });
    }
  }
  handleDeleteDeviceFunctionInstance(
    deviceFunctionInstance: DeviceFunctionInstance
  ) {
    if (
      this.deviceFunctionInstanceMap.get(deviceFunctionInstance.localId!)
        ?.length
    ) {
      let sficList: SensorFunctionInstanceChannel[] = [];
      this.deviceFunctionInstanceMap
        .get(deviceFunctionInstance.localId!)
        ?.forEach((sfic) => {
          switch (sfic.action) {
            case undefined:
            case ActionType.Edit:
              sfic.action = ActionType.Delete;
          }
          sficList.push(sfic);
        });
      runInAction(() => {
        this.deviceFunctionInstanceMap.set(
          deviceFunctionInstance.localId!,
          sficList
        );
      });
      this.resetItemList();
    }
  }
}
