import { number, boolean, object, SchemaOf, string, ref } from "yup";
import { platformSpecificationApi } from "../../../../api/api";
import { PlatformSpecificationSchema } from "../../../../api/PlatformSpecification/PlatformSpecificationInterface";
import { occurrencesInStringArray } from "../../../../utils/occurrencesInArray";
import { PageType } from "../../types";

export function generateSchema(type: PageType) {
  const schema: SchemaOf<PlatformSpecificationSchema> = object().shape({
    platform: string()
      .required()
      .test(
        "checkPlatformUnique",
        "The platform already exists!",
        async (value: unknown): Promise<boolean> => {
          if (typeof value !== "string") {
            return false;
          }
          const platformSpecificationList =
            await platformSpecificationApi.getAll();
          if ("error" in platformSpecificationList) {
            return false;
          }
          if (!platformSpecificationList.data.length) {
            return true;
          }
          const platforms = platformSpecificationList.data.map(
            (platformSpecification) => platformSpecification.platform
          );
          const occurrences = occurrencesInStringArray(value, platforms);
          switch (type) {
            case PageType.New:
            case PageType.NewDuplicate:
              return occurrences < 1;
            case PageType.Existing:
              return occurrences < 2;
          }
          return false;
        }
      ),
    mcu: string().required(),
    cores: number().required().positive().integer(),
    flashInternal: number()
      .required()
      .moreThan(-1, "Flash Internal must be 0 or higher")
      .integer(),
    flashExternal: number()
      .required()
      .moreThan(-1, "Flash External must be 0 or higher")
      .integer()
      .test(
        "checkSumOfFlashGt0",
        "Sum of Flash internal and external is 0",
        (value: unknown, context): boolean => {
          if (Number(value) + Number(context.parent.flashInternal) === 0) {
            return false;
          }
          return true;
        }
      ),
    sramInternal: number()
      .required()
      .moreThan(-1, "SRAM Internal must be 0 or higher")
      .integer(),
    sramExternal: number()
      .required()
      .moreThan(-1, "SRAM External must be 0 or higher")
      .integer()
      .test(
        "checkSumOfSRAMGt0",
        "Sum of SRAM internal and external is 0",
        (value: unknown, context): boolean => {
          if (Number(value) + Number(context.parent.sramInternal) === 0) {
            return false;
          }
          return true;
        }
      ),
    clockDefault: number()
      .required()
      .positive()
      .integer()
      .max(ref("clockMaximum"))
      .min(ref("clockMinimum")),
    clockMinimum: number()
      .required()
      .positive()
      .integer()
      .max(ref("clockMaximum")),
    clockMaximum: number()
      .required()
      .positive()
      .integer()
      .min(ref("clockMinimum")),
    wifiAInd: boolean().required(),
    wifiBInd: boolean().required(),
    wifiGInd: boolean().required(),
    wifiNInd: boolean().required(),
    supplyVoltage: number().required().positive(),
  });
  return schema;
}
