import { DeviceFunctionInstance } from "../../api/DeviceFunctionInstance/DeviceFunctionInstanceInterface";
import { defaultDeviceFunction } from "../DeviceFunction/DefaultDeviceFunction";
import { defaultDeviceRole } from "../DeviceRole/DefaultDeviceRole";

export const defaultDeviceFunctionInstance: DeviceFunctionInstance = {
  id: 0,
  deviceFunction: defaultDeviceFunction,
  deviceRole: defaultDeviceRole,
  name: "",
  sensors: [],
  actuators: [],
  versionNumber: 0,
};
