import { ActuatorFunctionInstanceChannel } from "../../api/ActuatorFunctionInstanceChannel/ActuatorFunctionInstanceChannelInterface";
import { defaultDeviceFunctionInstance } from "../DeviceFunctionInstance/DefaultDeviceFunctionInstance";
import { defaultActuatorFunctionLine } from "../ActuatorFunctionLine/DefaultActuatorFunctionLine";
export const defaultActuatorFunctionInstanceChannel: ActuatorFunctionInstanceChannel =
  {
    id: 0,
    localId: 0,
    action: undefined,
    deviceFunctionInstance: defaultDeviceFunctionInstance,
    actuatorFunctionLine: defaultActuatorFunctionLine,
    channel: 0,
    versionNumber: 0,
  };
