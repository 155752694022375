import { Area, AreaInput } from "../../api/Area/AreaInterface";
import { areaApi } from "../../api/api";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultArea } from "./DefaultArea";

export class AreaStore extends MobXStore<Area, AreaInput> {
  constructor(root: RootStore) {
    super(root, areaApi, "area", "Area", "Areas", defaultArea);
  }
}
