import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect } from "react";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import Heading from "../../../Form/Heading/Heading";
import SubmitButton from "../../../Form/SubmitButton/SubmitButton";
import { PageType } from "../../types";
import DeviceConfigForm from "./DeviceConfigForm";

const DeviceConfigPage: FC = () => {
  const {
    userInterfaceStore,
    deviceConfigStore,
    deviceStore,
    deviceRoleStore,
    deviceSpecificationStore,
    deviceModeStore,
  } = useRootStore();
  const router = useRouter();

  useEffect(() => {
    autorun(async () => {
      const endpoint = router.query.endpoint;
      const id = router.query.id;
      switch (endpoint) {
        case "deviceMode":
          await deviceConfigStore.getDeviceModeItemAsync(Number(id));
          break;
        case "deviceRoleMode":
          await deviceConfigStore.getDeviceRoleModeItemAsync(Number(id));
          break;
        case "deviceSpecificationMode":
          await deviceConfigStore.getDeviceSpecificationModeItemAsync(
            Number(id)
          );
          break;
        case "deviceSense":
          await deviceConfigStore.getDeviceSenseItemAsync(Number(id));
          break;
        case "deviceRoleSense":
          await deviceConfigStore.getDeviceRoleSenseItemAsync(Number(id));
          break;
        case "deviceSpecificationSense":
          await deviceConfigStore.getDeviceSpecificationSenseItemAsync(
            Number(id)
          );
          break;
        case "deviceRule":
          await deviceConfigStore.getDeviceRuleItemAsync(Number(id));
          break;
        case "deviceRoleRule":
          await deviceConfigStore.getDeviceRoleRuleItemAsync(Number(id));
          break;
        case "deviceSpecificationRule":
          await deviceConfigStore.getDeviceSpecificationRuleItemAsync(
            Number(id)
          );
          break;
      }
      await deviceStore.getItemListAsync();
      await deviceRoleStore.getItemListAsync();
      await deviceSpecificationStore.getItemListAsync();
      switch (userInterfaceStore.pageType) {
        case PageType.View:
        case PageType.Existing:
          await deviceConfigStore.getItemAsync(Number(router.query.id));
          await deviceConfigRoleStore.getItemAsync(
            Number(deviceConfigStore.item.deviceConfigRole.id)
          );
          await deviceConfigFunctionInstanceDeviceStore.getItemListByForeignKeyAsync(
            Number(deviceConfigStore.item.id),
            "deviceConfig"
          );
          break;
        case PageType.New:
          deviceConfigStore.resetItem();
          break;
        case PageType.Inserted:
      }
    });
  }, [
    userInterfaceStore.pageType,
    deviceConfigStore,
    router.query.id,
    deviceStore,
    deviceRoleStore,
    deviceSpecificationStore,
    router.query.endpoint,
  ]);

  const formId = "deviceConfigForm";
  return (
    <Fragment>
      <Heading
        pageType={userInterfaceStore.pageType!}
        heading={deviceConfigStore.singularDisplayName}
      />
      <DeviceConfigForm id={formId} />

      {userInterfaceStore.pageType !== PageType.View && (
        <SubmitButton form={formId} pageType={userInterfaceStore.pageType!} />
      )}
    </Fragment>
  );
};

export default observer(DeviceConfigPage);
