import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { ActuatorFunctionLine } from "../../../../api/ActuatorFunctionLine/ActuatorFunctionLineInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import NumberInputField from "../../../Form/NumberInputField/NumberInputField";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import { FormProps } from "../../../Form/types";
import { ActionType } from "../../types";
import { generateSchema } from "./ActuatorFunctionLineValidation";

const ActuatorFunctionLineDialogForm: FC<FormProps> = ({ id }: FormProps) => {
  const {
    actuatorFunctionLineStore,
    actuatorSpecificationStore,
  } = useRootStore();

  const handleClose = () => {
    actuatorFunctionLineStore.setDialogOpen(false);
  };

  const handleDelete = () => {
    actuatorFunctionLineStore.handleDelete();
    handleClose();
  };

  const onSubmit = (values: ActuatorFunctionLine) => {
    const actuatorSpecification = actuatorSpecificationStore.getItemFromList(
      values.actuatorSpecification.id
    );
    switch (actuatorFunctionLineStore.actionType) {
      case ActionType.Edit:
        actuatorFunctionLineStore.handleEdit(values, actuatorSpecification);
        break;
      case ActionType.New:
        actuatorFunctionLineStore.handleInsert(values, actuatorSpecification);
        break;
    }
    handleClose();
  };

  const deviceFunctionActuatorSchema = useMemo(
    () =>
      generateSchema(
        actuatorFunctionLineStore.itemList,
        actuatorFunctionLineStore.item
      ),
    [actuatorFunctionLineStore.itemList, actuatorFunctionLineStore.item]
  );

  const resolver = useYupValidationResolver(deviceFunctionActuatorSchema);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: actuatorFunctionLineStore.defaultItem,
    resolver,
  });

  useEffect(() => {
    autorun(async () => {
      reset(actuatorFunctionLineStore.item);
    });
  }, [reset, actuatorFunctionLineStore.item]);

  const actuatorSpecifications = actuatorSpecificationStore.itemList;
  let actuatorOptions: { id: number; value: string }[] = [];
  if (actuatorSpecifications.length) {
    actuatorOptions = actuatorSpecifications.map((actuatorSpecification) => {
      return {
        id: actuatorSpecification.id,
        value: `${actuatorSpecification.manufacturer}-${actuatorSpecification.type}-${actuatorSpecification.model}`,
      };
    });
  }

  return (
    <Dialog
      open={actuatorFunctionLineStore.dialogOpen}
      onClose={handleClose}
      aria-labelledby="actuator-function-line-form-dialog-title"
    >
      <DialogTitle id="actuator-function-line-form-dialog-title">
        {actuatorFunctionLineStore.actionType === ActionType.Edit && "Modify "}
        {actuatorFunctionLineStore.actionType === ActionType.New &&
          "Add a new "}
        Actuator
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {actuatorFunctionLineStore.actionType === ActionType.Edit &&
            "Modify existing actuator "}
          {actuatorFunctionLineStore.actionType === ActionType.New &&
            "Add a new actuator "}
          for the device function
        </DialogContentText>
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
          <SelectInputField
            name="actuatorSpecification.id"
            options={actuatorOptions}
            displayName="Actuator"
            errors={errors}
            control={control}
          />
          <NumberInputField
            name="quantity"
            displayName="Quantity"
            errors={errors}
            control={control}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {actuatorFunctionLineStore.actionType === ActionType.Edit && (
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        )}
        <Button form={id} type="submit" color="primary">
          {actuatorFunctionLineStore.actionType === ActionType.Edit && "Update"}
          {actuatorFunctionLineStore.actionType === ActionType.New && "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(ActuatorFunctionLineDialogForm);
