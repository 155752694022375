import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { DeviceRoleDatabaseDefinition } from "../../../../api/DeviceRoleDatabaseDefinition/DeviceRoleDatabaseDefinitionInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { ActionType } from "../../types";

const DeviceRoleDatabaseDefinitionTable: FC = () => {
  const { deviceRoleDatabaseDefinitionStore } = useRootStore();

  const handleNewDeviceRoleDatabaseDefinitionButton = () => {
    deviceRoleDatabaseDefinitionStore.resetItem();
    deviceRoleDatabaseDefinitionStore.setActionType(ActionType.New);
    deviceRoleDatabaseDefinitionStore.setDialogOpen(true);
  };

  const handleRowClick = (row: DeviceRoleDatabaseDefinition) => {
    deviceRoleDatabaseDefinitionStore.setItemFromList(row.localId!);
    deviceRoleDatabaseDefinitionStore.setActionType(ActionType.Edit);
    deviceRoleDatabaseDefinitionStore.setDialogOpen(true);
  };

  function renderTableRow(row: DeviceRoleDatabaseDefinition) {
    if (row.action === ActionType.Delete) {
      return;
    }

    return (
      <TableRow key={row.localId} onClick={() => handleRowClick(row)}>
        <TableCell>{row.deviceDatabaseColumnName.displayColumnName}</TableCell>
        <TableCell>{row.deviceDatabaseColumnType.displayColumnType}</TableCell>
        <TableCell>{row.size}</TableCell>
        <TableCell>{row.resource?.name}</TableCell>
      </TableRow>
    );
  }

  return (
    <Grid item>
      <Table aria-label="device role database definition">
        <TableHead>
          <TableRow>
            <TableCell>Device Database Column Name</TableCell>
            <TableCell>Device Database Column Type</TableCell>
            <TableCell>Size (Bytes)</TableCell>
            <TableCell>Resource</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deviceRoleDatabaseDefinitionStore.itemList.length
            ? deviceRoleDatabaseDefinitionStore.itemList.map(
                (row: DeviceRoleDatabaseDefinition) => {
                  return renderTableRow(row);
                }
              )
            : null}
        </TableBody>
      </Table>
      <Button
        variant="text"
        color="primary"
        type="submit"
        onClick={handleNewDeviceRoleDatabaseDefinitionButton}
      >
        New Device Role Database Definition
      </Button>
    </Grid>
  );
};

export default observer(DeviceRoleDatabaseDefinitionTable);
