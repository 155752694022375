import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  ApiArrayResponseSuccess,
  ApiResponseFailure,
} from "../types/apiResponse";
import { axiosGetByForeignKeyIdRequest } from "../util/axiosRequest";
import {
  DeviceRoleDatabaseDefinition,
  DeviceRoleDatabaseDefinitionInput,
} from "./DeviceRoleDatabaseDefinitionInterface";

export class DeviceRoleDatabaseDefinitionApi extends AbstractApi<
  DeviceRoleDatabaseDefinition,
  DeviceRoleDatabaseDefinitionInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "deviceRoleDatabaseDefinition");
  }

  public async getByForeignKeyId(
    id: number
  ): Promise<
    ApiArrayResponseSuccess<DeviceRoleDatabaseDefinition> | ApiResponseFailure
  > {
    return axiosGetByForeignKeyIdRequest(
      this.baseUrl,
      this.endpoint,
      "deviceRole",
      id
    );
  }
}
