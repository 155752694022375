import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import React, { FC } from "react";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";

type CheckboxInputFieldProps = {
  name: string;
  displayName: string;
  errors: DeepMap<any, FieldError>;
  control: Control<any>;
  readOnly?: boolean;
  maximum?: number;
  minimum?: number;
  step?: number;
};

const CheckboxInputField: FC<CheckboxInputFieldProps> = ({
  name,
  displayName,
  errors,
  control,
}: CheckboxInputFieldProps) => {
  return (
    <FormControl>
      <FormControlLabel
        control={
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={field.value}
                name={name}
                placeholder={displayName}
              />
            )}
          />
        }
        label={displayName}
      />
      {errors[name] && (
        <FormHelperText error>{errors[name].message}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CheckboxInputField;
