import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { PersonArea } from "../../../../api/PersonArea/PersonAreaInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { ActionType } from "../../types";

const PersonAreaTable: FC = () => {
  const { personAreaStore } = useRootStore();

  const handleNewPersonAreaButton = () => {
    personAreaStore.resetItem();
    personAreaStore.setActionType(ActionType.New);
    personAreaStore.setDialogOpen(true);
  };

  const handleRowClick = (row: PersonArea) => {
    personAreaStore.setItemFromList(row.id);
    personAreaStore.setActionType(ActionType.Edit);
    personAreaStore.setDialogOpen(true);
  };

  function renderTableRow(row: PersonArea) {
    if (row.action === ActionType.Delete) {
      return;
    }

    return (
      <TableRow key={row.localId} onClick={() => handleRowClick(row)}>
        <TableCell>{row.area.name}</TableCell>
        <TableCell>{row.area.areaStatus.status}</TableCell>
      </TableRow>
    );
  }

  return (
    <Grid item>
      <Table aria-label="person area">
        <TableHead>
          <TableRow>
            <TableCell>Area Name</TableCell>
            <TableCell>Area Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {personAreaStore.itemList.length
            ? personAreaStore.itemList.map((row: PersonArea) => {
                return renderTableRow(row);
              })
            : null}
        </TableBody>
      </Table>
      <Button
        variant="text"
        color="primary"
        type="submit"
        onClick={handleNewPersonAreaButton}
      >
        Add New Area
      </Button>
    </Grid>
  );
};

export default observer(PersonAreaTable);
