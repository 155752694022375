import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect } from "react";
import { tbCustomerApi } from "../../../../api/api";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import Heading from "../../../Form/Heading/Heading";
import SubmitButton from "../../../Form/SubmitButton/SubmitButton";
import { PageType } from "../../types";
import DeviceFunctionInstanceDeviceDialogForm from "../DeviceFunctionInstanceDevice/DeviceFunctionInstanceDeviceDialogForm";
import DeviceFunctionInstanceDeviceTable from "../DeviceFunctionInstanceDevice/DeviceFunctionInstanceDeviceTable";
import DeviceForm from "./DeviceForm";

const DevicePage: FC = () => {
  const {
    deviceStore,
    deviceFunctionInstanceDeviceStore,
    deviceRoleStore,
    tbAssetStore,
    customerStore,
    buildingStore,
    userInterfaceStore,
  } = useRootStore();
  const router = useRouter();

  useEffect(() => {
    autorun(async () => {
      await deviceRoleStore.getItemListAsync();
      await customerStore.getItemListAsync();
      await buildingStore.getItemListAsync();
      switch (userInterfaceStore.pageType) {
        case PageType.View:
        case PageType.Existing:
          await deviceStore.getItemAsync(Number(router.query.id));
          await deviceRoleStore.getItemAsync(
            Number(deviceStore.item.deviceRole.id)
          );
          await deviceFunctionInstanceDeviceStore.getItemListByForeignKeyAsync(
            Number(deviceStore.item.id),
            "device"
          );
          break;
        case PageType.New:
          deviceStore.resetItem();
          deviceFunctionInstanceDeviceStore.resetItemList();
          break;
        case PageType.Inserted:
      }
    });
  }, [
    userInterfaceStore.pageType,
    deviceStore,
    router.query.id,
    deviceRoleStore,
    deviceFunctionInstanceDeviceStore,
    customerStore,
    tbAssetStore,
  ]);

  const formId = "deviceForm";
  const deviceFunctionInstanceDeviceFormId = "deviceFunctionInstanceDeviceForm";
  return (
    <Fragment>
      <Heading
        pageType={userInterfaceStore.pageType!}
        heading={deviceStore.singularDisplayName}
      />
      <DeviceForm id={formId} />
      <DeviceFunctionInstanceDeviceDialogForm
        id={deviceFunctionInstanceDeviceFormId}
      />
      <DeviceFunctionInstanceDeviceTable />
      {userInterfaceStore.pageType !== PageType.View && (
        <SubmitButton form={formId} pageType={userInterfaceStore.pageType!} />
      )}
    </Fragment>
  );
};

export default observer(DevicePage);
