export interface TbEntityRelation {
  id: {
    entityType: string;
    id: string;
  };
  createdTime: number;
  additionalInfo: any;
  tenantId: {
    entityType: string;
    id: string;
  };
  customerId: {
    entityType: string;
    id: string;
  };
  name: string;
  type: string;
  label: string;
}
export interface TbEntityRelationRequestBody {
  from: {
    entityType: TbEntityType;
    id: string;
  };
  to: {
    entityType: TbEntityType;
    id: string;
  };
  type: string;
  typeGroup: string;
}

export enum TbDirection {
  TO = "to",
  FROM = "from",
}

export enum TbEntityType {
  TENANT = "TENANT",
  DEVICE = "DEVICE",
  CUSTOMER = "CUSTOMER",
  RULE_NODE = "RULE_NODE",
  ASSET = "ASSET",
  RULE_CHAIN = "RULE_CHAIN",
}
