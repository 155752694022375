import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { observer } from "mobx-react";
import React, { FC } from "react";
import {
  ActuatorListItem,
  SensorListItem,
} from "../../../../api/DeviceFunction/DeviceFunctionInterface";
import { DeviceFunctionInstance } from "../../../../api/DeviceFunctionInstance/DeviceFunctionInstanceInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { ActionType } from "../../types";

const DeviceFunctionInstanceTable: FC = () => {
  const {
    deviceFunctionInstanceStore,
    sensorFunctionInstanceChannelStore,
    actuatorFunctionInstanceChannelStore,
  } = useRootStore();

  const handleNewDeviceFunctionInstanceButton = () => {
    deviceFunctionInstanceStore.handleInitialSetup();
    sensorFunctionInstanceChannelStore.resetItemList();
    actuatorFunctionInstanceChannelStore.resetItemList();
    deviceFunctionInstanceStore.setActionType(ActionType.New);
    deviceFunctionInstanceStore.setDialogOpen(true);
  };

  const handleRowClick = (row: DeviceFunctionInstance) => {
    deviceFunctionInstanceStore.setItemFromList(row.localId!);
    deviceFunctionInstanceStore.setActionType(ActionType.Edit);
    deviceFunctionInstanceStore.setDialogOpen(true);
    sensorFunctionInstanceChannelStore.setItemListFromDeviceFunctionInstanceMap(
      row.localId!
    );
    actuatorFunctionInstanceChannelStore.setItemListFromDeviceFunctionInstanceMap(
      row.localId!
    );
  };

  function renderTableRow(row: DeviceFunctionInstance) {
    if (row.action === ActionType.Delete) {
      return;
    }

    return (
      <TableRow key={row.localId} onClick={() => handleRowClick(row)}>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.deviceFunction.name}</TableCell>
        <TableCell>{getSensorList(row.deviceFunction.sensorList)}</TableCell>
        <TableCell>
          {getActuatorList(row.deviceFunction.actuatorList)}
        </TableCell>
      </TableRow>
    );
  }

  function getSensorList(sensorList: SensorListItem[]) {
    if (sensorList) {
      const sensorListItems = sensorList.map((sensor) => {
        return `${sensor.sensorSpecification.manufacturer}-${sensor.sensorSpecification.model}-${sensor.sensorSpecification.type} (${sensor.quantity})`;
      });
      return sensorListItems.join(", ");
    }
  }

  function getActuatorList(actuatorList: ActuatorListItem[]) {
    if (actuatorList) {
      const actuatorListItems = actuatorList.map((actuator) => {
        return `${actuator.actuatorSpecification.manufacturer}-${actuator.actuatorSpecification.model}-${actuator.actuatorSpecification.type} (${actuator.quantity})`;
      });
      return actuatorListItems.join(", ");
    }
  }

  return (
    <Grid item>
      <Table aria-label="device function instance">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Device Function</TableCell>
            <TableCell>Sensors</TableCell>
            <TableCell>Actuators</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deviceFunctionInstanceStore.itemList.length
            ? deviceFunctionInstanceStore.itemList.map(
                (row: DeviceFunctionInstance) => {
                  return renderTableRow(row);
                }
              )
            : null}
        </TableBody>
      </Table>
      <Button
        variant="text"
        color="primary"
        type="submit"
        onClick={handleNewDeviceFunctionInstanceButton}
      >
        New Device Function Instance
      </Button>
    </Grid>
  );
};

export default observer(DeviceFunctionInstanceTable);
