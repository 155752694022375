import { customerApi } from "../../api/api";
import { Customer, CustomerInput } from "../../api/Customer/CustomerInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultCustomer } from "./DefaultCustomer";

export class CustomerStore extends MobXStore<Customer, CustomerInput> {
  constructor(root: RootStore) {
    super(
      root,
      customerApi,
      "customer",
      "Customer",
      "Customers",
      defaultCustomer
    );
  }

  async getItemListByForeignKeyAsync(
    id: number | string,
    foreignKey: string
  ): Promise<void> {
    const result = await this.api.getByForeignKeyId!(id, foreignKey);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.resetApiResponse();
    this.setItemList(result.data);
  }
}
