import { Device } from "../../api/Device/DeviceInterface";
import { defaultBuilding } from "../Building/DefaultBuilding";
import { defaultDeviceRole } from "../DeviceRole/DefaultDeviceRole";
import { defaultTbAsset } from "../TbAsset/DefaultTbAsset";
import { defaultTbCustomer } from "../TbCustomer/DefaultTbCustomer";

export const defaultDevice: Device = {
  id: 0,
  deviceRole: defaultDeviceRole,
  customer: defaultTbCustomer,
  building: defaultBuilding,
  macAddress: "",
  accessToken: "",
  deviceUuid: "",
  deviceName: "",
};
