import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  ApiArrayResponseSuccess,
  ApiResponseFailure,
} from "../types/apiResponse";
import { axiosGetByForeignKeyIdRequest } from "../util/axiosRequest";
import { PersonArea, PersonAreaInput } from "./PersonAreaInterface";
export class PersonAreaApi extends AbstractApi<PersonArea, PersonAreaInput> {
  constructor(baseUrl: string) {
    super(baseUrl, "personArea");
  }

  public async getByForeignKeyId(
    id: number
  ): Promise<ApiArrayResponseSuccess<PersonArea> | ApiResponseFailure> {
    return axiosGetByForeignKeyIdRequest(
      this.baseUrl,
      this.endpoint,
      "person",
      id
    );
  }
}
