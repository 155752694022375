import { ActuatorFunctionLine } from "../../api/ActuatorFunctionLine/ActuatorFunctionLineInterface";
import { defaultActuatorSpecification } from "../ActuatorSpecification/DefaultActuatorSpecification";
import { defaultDeviceFunction } from "../DeviceFunction/DefaultDeviceFunction";
export const defaultActuatorFunctionLine: ActuatorFunctionLine = {
  id: 0,
  localId: 0,
  action: undefined,
  actuatorSpecification: defaultActuatorSpecification,
  deviceFunction: defaultDeviceFunction,
  quantity: 1,
  versionNumber: 0,
};
