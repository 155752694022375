import { DeviceRoleDatabaseDefinition } from "../../api/DeviceRoleDatabaseDefinition/DeviceRoleDatabaseDefinitionInterface";

export const defaultDeviceRoleDatabaseDefinition: DeviceRoleDatabaseDefinition =
  {
    id: 0,
    deviceRole: {
      id: 0,
      deviceSpecificationId: 0,
      name: "",
      versionNumber: 0,
    },
    deviceDatabaseColumnName: {
      id: 0,
      firmwareColumnName: "",
      displayColumnName: "",
      value: 0,
    },
    deviceDatabaseColumnType: {
      id: 0,
      firmwareColumnType: "",
      displayColumnType: "",
      value: 0,
    },
    size: 0,
    versionNumber: 0,
  };
