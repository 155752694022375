import { deviceDatabaseColumnTypeApi } from "../../api/api";
import { DeviceDatabaseColumnType } from "../../api/DeviceDatabaseColumnType/DeviceDatabaseColumnTypeInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDeviceDatabaseColumnType } from "./DefaultDeviceDatabaseColumnType";

export class DeviceDatabaseColumnTypeStore extends MobXStore<
  DeviceDatabaseColumnType,
  DeviceDatabaseColumnType
> {
  constructor(root: RootStore) {
    super(
      root,
      deviceDatabaseColumnTypeApi,
      "deviceDatabaseColumnType",
      "Device Database Column Type",
      "Device Database Column Types",
      defaultDeviceDatabaseColumnType
    );
  }
}
