import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import {
  RoomTypeInput,
  RoomTypeSchema,
} from "../../../../api/RoomType/RoomTypeInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import calculateReadOnly from "../../../../utils/calculateReadOnly";
import deduplicateArray from "../../../../utils/deduplicateArray";
import CheckboxInputField from "../../../Form/CheckboxInputField/CheckboxInputField";
import NumberInputField from "../../../Form/NumberInputField/NumberInputField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./RoomTypeValidation";

const RoomTypeForm: FC<FormProps> = ({ id }: FormProps) => {
  const router = useRouter();
  const { roomTypeStore, userInterfaceStore } = useRootStore();

  const roomTypeSchema = useMemo(
    () => generateSchema(userInterfaceStore.pageType!),
    [userInterfaceStore.pageType]
  );

  const resolver = useYupValidationResolver(roomTypeSchema);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    reset,
  } = useForm({
    defaultValues: roomTypeStore.defaultItem,
    resolver,
  });

  const onSubmit = async (values: RoomTypeSchema) => {
    let roomType: RoomTypeInput = {
      ...values,
    };
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
        roomType = {
          ...values,
          id: Number(router.query.id),
        };
        await roomTypeStore.updateItemAsync(roomType);
        break;
      case PageType.New:
      case PageType.NewDuplicate:
        await roomTypeStore.insertItemAsync(roomType);
        userInterfaceStore.setPageType(PageType.Inserted);
        router.history.push(
          `/${roomTypeStore.endpoint}/edit/${roomTypeStore.item.id}`
        );
        break;
    }
  };

  useEffect(() => {
    autorun(async () => {
      reset(roomTypeStore.item);
    });
  }, [roomTypeStore, reset]);
  const readOnly = calculateReadOnly(userInterfaceStore.pageType);

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={roomTypeStore} />
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <TextInputField
          name="type"
          displayName="Type"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <NumberInputField
          name="tier"
          displayName="Tier"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <NumberInputField
          name="maxOccupancy"
          displayName="Max Occupancy"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <CheckboxInputField
          name="bedroom"
          displayName="Is Bedroom?"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
      </form>
    </Fragment>
  );
};

export default observer(RoomTypeForm);
