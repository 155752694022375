import { GridColumns } from "@material-ui/data-grid";
import { Person } from "../../../../api/Person/PersonInterface";

function getPersonTag(params: any) {
  const person: Person = params.row;
  if (person.tag) {
    return person.tag.tagInput;
  }
}
function getPersonType(params: any) {
  const person: Person = params.row;
  if (person.personType) {
    return person.personType.type;
  }
}
function getPersonStatus(params: any) {
  const person: Person = params.row;
  if (person.personStatus) {
    return person.personStatus.status;
  }
}
export const personListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "firstName",
    headerName: "First Name",
    flex: 1,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    flex: 1,
  },
  {
    field: "tag",
    headerName: "Tag",
    valueGetter: getPersonTag,
    flex: 1,
  },
  {
    field: "type",
    headerName: "Person Type",
    valueGetter: getPersonType,
    flex: 1,
  },
  {
    field: "status",
    headerName: "Person Status",
    valueGetter: getPersonStatus,
    flex: 1,
  },
  {
    field: "addressId",
    headerName: "Address ID",
    type: "number",
    flex: 1,
  },
  {
    field: "dateOfBirth",
    headerName: "DOB",
    flex: 1,
  },
  {
    field: "emailAddress",
    headerName: "Email Address",
    flex: 1,
  },
  {
    field: "employer",
    headerName: "Employer",
    flex: 1,
  },
  {
    field: "startDate",
    headerName: "Start Date",
    flex: 1,
  },
  {
    field: "endDate",
    headerName: "End Date",
    flex: 1,
  },
];
