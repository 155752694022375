import { boolean, number, object, SchemaOf } from "yup";
import {
  PersonServicePackage,
  PersonServicePackageSchema,
} from "../../../../api/PersonServicePackage/PersonServicePackageInterface";
import { occurrencesInNumberArray } from "../../../../utils/occurrencesInArray";

export function generateSchema(
  personServicePackageList: PersonServicePackage[]
) {
  const schema: SchemaOf<PersonServicePackageSchema> = object().shape({
    servicePackage: object().shape({
      id: number()
        .required()
        .positive()
        .test(
          "checkOneOfEachServicePackage",
          "Please select a valid service package",
          (value: unknown): boolean => {
            if (typeof value !== "number") {
              return false;
            }
            if (value === 0) {
              return false;
            }
            if (!personServicePackageList.length) {
              return true;
            }
            const servicePackageIdList = personServicePackageList.map(
              (personServicePackageItem) =>
                Number(personServicePackageItem.servicePackage.id)
            );
            const occurrences = occurrencesInNumberArray(
              value,
              servicePackageIdList
            );

            return occurrences < 1;
          }
        ),
    }),
  });
  return schema;
}
