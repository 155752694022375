import { deviceSpecificationModeApi } from "../../api/api";
import {
  DeviceMode,
  DeviceSpecificationModeInput,
} from "../../api/DeviceMode/DeviceModeInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDeviceMode } from "../DeviceConfig/DefaultDeviceMode";

export class DeviceSpecificationModeStore extends MobXStore<
  DeviceMode,
  DeviceSpecificationModeInput
> {
  constructor(root: RootStore) {
    super(
      root,
      deviceSpecificationModeApi,
      "deviceSpecificationMode",
      "Device Specification Mode",
      "Device Specification Mode",
      defaultDeviceMode
    );
  }
}
