import { personApi } from "../../api/api";
import { Person, PersonInput } from "../../api/Person/PersonInterface";
import calculateUtcFromLocal from "../../utils/calculateUtcFromLocal";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultPerson } from "./DefaultPerson";

export class PersonStore extends MobXStore<Person, PersonInput> {
  constructor(root: RootStore) {
    super(root, personApi, "person", "Person", "People", defaultPerson);
  }
  async getItemAsync(id: number): Promise<void> {
    const result = await this.api.getOne(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.resetApiResponse();
    const utcStartDate = calculateUtcFromLocal(result.data.startDate!);
    result.data.startDateUtc = utcStartDate;
    const utcEndDate = calculateUtcFromLocal(result.data.endDate!);
    result.data.endDateUtc = utcEndDate;
    this.setItem(result.data);
  }

  async updateItemAsync(item: PersonInput): Promise<void> {
    const result = await this.api.update(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully updated!`
    );
    this.setMessageSeverity("success");
    const utcStartDate = calculateUtcFromLocal(result.data.startDate!);
    result.data.startDateUtc = utcStartDate;
    const utcEndDate = calculateUtcFromLocal(result.data.endDate!);
    result.data.endDateUtc = utcEndDate;
    this.setItem(result.data);
  }
}
