import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import {
  DataGrid,
  GridColumns,
  GridRowParams,
  GridRowsProp,
  GridToolbar,
} from "@material-ui/data-grid";
import * as React from "react";
import { FC, Fragment } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dataTable: {
      height: 1000,
      width: "100%",
      display: "flex",
      marginTop: 20,
      marginBottom: 20,
    },
  })
);

type DataTableProps = {
  rows: GridRowsProp;
  columns: GridColumns;
  pageSize: number;
  handleRowClick(
    param: GridRowParams,
    event: React.MouseEvent<Element, MouseEvent>
  ): void;
};

const DataTable: FC<DataTableProps> = ({
  rows,
  columns,
  pageSize,
  handleRowClick,
}: DataTableProps) => {
  const classes = useStyles();

  return (
    <Fragment>
      {rows ? (
        <div className={classes.dataTable}>
          <DataGrid
            rows={rows}
            columns={columns}
            components={{
              Toolbar: GridToolbar,
            }}
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 20]}
            onRowClick={handleRowClick}
          />
        </div>
      ) : (
        <Typography>Nothing to show yet</Typography>
      )}
    </Fragment>
  );
};

export default DataTable;
