import { GridColumns } from "@material-ui/data-grid";
import { TbAsset } from "../../../../api/TbAsset/TbAssetInterface";

function getFormattedTimeFromUtc(params: any) {
  const tbAsset: TbAsset = params.row;
  if (tbAsset.createdTime) {
    return new Date(tbAsset.createdTime);
  }
}
export const buildingListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "siteName",
    headerName: "Site Name",
    flex: 1,
  },
  {
    field: "customerName",
    headerName: "Customer",
    flex: 1,
  },
  {
    field: "createdTime",
    headerName: "Created Time",
    valueGetter: getFormattedTimeFromUtc,
    flex: 1,
  },
  {
    field: "latitude",
    headerName: "Latitude",
    flex: 1,
  },
  {
    field: "longitude",
    headerName: "Longitude",
    flex: 1,
  },
];
