import { deviceModeApi } from "../../api/api";
import {
  DeviceMode,
  DeviceModeInput,
} from "../../api/DeviceMode/DeviceModeInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDeviceMode } from "../DeviceConfig/DefaultDeviceMode";

export class DeviceModeStore extends MobXStore<DeviceMode, DeviceModeInput> {
  constructor(root: RootStore) {
    super(
      root,
      deviceModeApi,
      "deviceMode",
      "Device Mode",
      "Device Mode",
      defaultDeviceMode
    );
  }
}
