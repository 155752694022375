import { object, string } from "yup";
import { TbCustomer } from "../../../../api/TbCustomer/TbCustomerInterface";
import { occurrencesInStringArray } from "../../../../utils/occurrencesInArray";
import { PageType } from "../../types";

export function generateSchema(type: PageType, customerList: TbCustomer[]) {
  const schema = object().shape({
    title: string()
      .required()
      .test(
        "checkUniqueCustomerName",
        "The customer name already exists!",
        async (value: unknown): Promise<boolean> => {
          if (!customerList) {
            return true;
          }
          const customerTitles = customerList.map((customer) => customer.title);
          const occurrences = occurrencesInStringArray(
            String(value),
            customerTitles
          );
          console.log(occurrences);
          switch (type) {
            case PageType.New:
            case PageType.NewDuplicate:
              return occurrences < 1;
            case PageType.Existing:
            case PageType.Inserted:
              return occurrences < 2;
          }
          return false;
        }
      ),
    country: string().required(),
    state: string().required(),
    city: string().required(),
    address: string().required(),
    address2: string().required(),
    zip: string().required(),
    phone: string().required(),
    email: string().required().email(),
    description: string(),
  });
  return schema;
}
