import { deviceSpecificationRuleApi } from "../../api/api";
import {
  DeviceRule,
  DeviceSpecificationRuleInput,
} from "../../api/DeviceRule/DeviceRuleInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDeviceRule } from "./DefaultDeviceRule";

export class DeviceSpecificationRuleStore extends MobXStore<
  DeviceRule,
  DeviceSpecificationRuleInput
> {
  constructor(root: RootStore) {
    super(
      root,
      deviceSpecificationRuleApi,
      "deviceSpecificationRule",
      "Device Specification Rule",
      "Device Specification Rule",
      defaultDeviceRule
    );
  }
}
