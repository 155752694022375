import { array, boolean, number, object, SchemaOf, string } from "yup";
import { ActuatorFunctionLine } from "../../../../api/ActuatorFunctionLine/ActuatorFunctionLineInterface";
import {
  deviceFunctionApi,
  deviceSpecificationSensorSpecificationApi,
} from "../../../../api/api";
import {
  DeviceFunctionInstance,
  DeviceFunctionInstanceSchema,
} from "../../../../api/DeviceFunctionInstance/DeviceFunctionInstanceInterface";
import { DeviceSpecificationActuatorSpecification } from "../../../../api/DeviceSpecificationActuatorSpecification/DeviceSpecificationActuatorSpecificationInterface";
import {
  DeviceSpecificationSensorSpecification,
  DeviceSpecificationSensorSpecificationInput,
} from "../../../../api/DeviceSpecificationSensorSpecification/DeviceSpecificationSensorSpecificationInterface";
import { SensorFunctionLine } from "../../../../api/SensorFunctionLine/SensorFunctionLineInterface";
import { occurrencesInStringArray } from "../../../../utils/occurrencesInArray";
import { ActionType } from "../../types";

export function generateSchema(
  deviceFunctionInstanceList: DeviceFunctionInstance[],
  deviceFunctionInstance: DeviceFunctionInstance,
  deviceSpecificationSensorSpecificationList: DeviceSpecificationSensorSpecification[],
  deviceSpecificationActuatorSpecificationList: DeviceSpecificationActuatorSpecification[]
) {
  const schema: SchemaOf<DeviceFunctionInstanceSchema> = object().shape({
    deviceFunction: object().shape({
      id: number()
        .required()
        .positive()
        .test(
          "checkDeviceFunctionSensors",
          "The device function cannot be added due to sensor quantity limit",
          async (value: unknown): Promise<boolean> => {
            if (typeof value !== "number") {
              return false;
            }
            const deviceFunction = await deviceFunctionApi.getOne(value);
            if ("error" in deviceFunction) {
              return false;
            }
            if (!deviceFunction.data) {
              return true;
            }
            if (!deviceFunction.data.sensorList.length) {
              return true;
            }
            let deviceSpecificationSensorSpecificationMap = new Map<
              number,
              number
            >();
            if (deviceSpecificationSensorSpecificationList.length) {
              deviceSpecificationSensorSpecificationList.forEach((val) => {
                deviceSpecificationSensorSpecificationMap.set(
                  val.sensorSpecification.id,
                  val.quantity
                );
              });
            }

            let validDeviceFunction = true;
            deviceFunction.data.sensorList.forEach((sensor) => {
              if (
                !deviceSpecificationSensorSpecificationMap.has(
                  sensor.sensorSpecification.id
                )
              ) {
                validDeviceFunction = false;
              } else {
                deviceSpecificationSensorSpecificationMap.set(
                  sensor.sensorSpecification.id,
                  deviceSpecificationSensorSpecificationMap.get(
                    sensor.sensorSpecification.id
                  )! - sensor.quantity
                );
              }
            });
            deviceFunctionInstanceList.forEach((val) => {
              if (val.deviceFunction.sensorList.length) {
                val.deviceFunction.sensorList.forEach((sensor) => {
                  deviceSpecificationSensorSpecificationMap.set(
                    sensor.sensorSpecification.id,
                    deviceSpecificationSensorSpecificationMap.get(
                      sensor.sensorSpecification.id
                    )! - sensor.quantity
                  );
                });
              }
            });

            deviceSpecificationSensorSpecificationMap.forEach((quantity) => {
              if (quantity < 0) {
                validDeviceFunction = false;
              }
            });
            return validDeviceFunction;
          }
        )
        .test(
          "checkDeviceFunctionActuators",
          "The device function cannot be added due to actuator quantity limit",
          async (value: unknown): Promise<boolean> => {
            if (typeof value !== "number") {
              return false;
            }
            const deviceFunction = await deviceFunctionApi.getOne(value);
            if ("error" in deviceFunction) {
              return false;
            }
            if (!deviceFunction.data) {
              return true;
            }
            if (!deviceFunction.data.actuatorList.length) {
              return true;
            }
            let deviceSpecificationActuatorSpecificationMap = new Map<
              number,
              number
            >();
            if (deviceSpecificationActuatorSpecificationList.length) {
              deviceSpecificationActuatorSpecificationList.forEach((val) => {
                deviceSpecificationActuatorSpecificationMap.set(
                  val.actuatorSpecification.id,
                  val.quantity
                );
              });
            }

            let validDeviceFunction = true;
            deviceFunction.data.actuatorList.forEach((actuator) => {
              if (
                !deviceSpecificationActuatorSpecificationMap.has(
                  actuator.actuatorSpecification.id
                )
              ) {
                validDeviceFunction = false;
              } else {
                deviceSpecificationActuatorSpecificationMap.set(
                  actuator.actuatorSpecification.id,
                  deviceSpecificationActuatorSpecificationMap.get(
                    actuator.actuatorSpecification.id
                  )! - actuator.quantity
                );
              }
            });
            deviceFunctionInstanceList.forEach((val) => {
              if (val.deviceFunction.actuatorList.length) {
                val.deviceFunction.actuatorList.forEach((actuator) => {
                  deviceSpecificationActuatorSpecificationMap.set(
                    actuator.actuatorSpecification.id,
                    deviceSpecificationActuatorSpecificationMap.get(
                      actuator.actuatorSpecification.id
                    )! - actuator.quantity
                  );
                });
              }
            });
            deviceSpecificationActuatorSpecificationMap.forEach((quantity) => {
              if (quantity < 0) {
                validDeviceFunction = false;
              }
            });
            return validDeviceFunction;
          }
        ),
    }),
    name: string()
      .required()
      .test(
        "checkNameUnique",
        "The device function instance name exists!",
        (value: unknown): boolean => {
          if (typeof value !== "string") {
            return false;
          }
          if (!deviceFunctionInstanceList.length) {
            return true;
          }
          if (deviceFunctionInstance.name === value) {
            return true;
          }
          const filteredDeviceFunctionInstanceList =
            deviceFunctionInstanceList.filter((deviceFunctionInstanceItem) => {
              return deviceFunctionInstanceItem.action !== ActionType.Delete;
            });
          const deviceFunctionInstanceNameList =
            filteredDeviceFunctionInstanceList.map(
              (deviceFunctionInstanceItem) => {
                return deviceFunctionInstanceItem.name;
              }
            );
          const occurrences = occurrencesInStringArray(
            value,
            deviceFunctionInstanceNameList
          );
          return occurrences < 1;
        }
      ),
    sensors: array().of(
      object().shape({
        channel: number()
          .required()
          .moreThan(-1, "Channel must be 0 or higher"),
      })
    ),
    actuators: array().of(
      object().shape({
        channel: number()
          .required()
          .moreThan(-1, "Channel must be 0 or higher"),
      })
    ),
  });
  return schema;
}
