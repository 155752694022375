import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  PlatformSpecification,
  PlatformSpecificationInput,
  PlatformSpecificationSchema,
} from "../../../../api/PlatformSpecification/PlatformSpecificationInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import calculateReadOnly from "../../../../utils/calculateReadOnly";
import CheckboxInputField from "../../../Form/CheckboxInputField/CheckboxInputField";
import NumberInputField from "../../../Form/NumberInputField/NumberInputField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./PlatformSpecificationValidation";
import { Prompt } from "react-router-dom";

const PlatformSpecificationForm: FC = () => {
  const router = useRouter();
  const { platformSpecificationStore, userInterfaceStore } = useRootStore();

  const platformSpecificationSchema = useMemo(
    () => generateSchema(userInterfaceStore.pageType!),
    [userInterfaceStore.pageType]
  );

  const resolver = useYupValidationResolver(platformSpecificationSchema);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    reset,
  } = useForm({
    defaultValues: platformSpecificationStore.defaultItem,
    resolver,
  });

  const onSubmit = async (values: PlatformSpecificationSchema) => {
    let platformSpecification: PlatformSpecificationInput = {
      ...values,
    };
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
        platformSpecification = {
          ...values,
          id: Number(router.query.id),
        };
        await platformSpecificationStore.updateItemAsync(platformSpecification);
        break;
      case PageType.New:
      case PageType.NewDuplicate:
        await platformSpecificationStore.insertItemAsync(platformSpecification);
        userInterfaceStore.setPageType(PageType.Inserted);
        router.history.push(
          `/${platformSpecificationStore.endpoint}/edit/${platformSpecificationStore.item.id}`
        );
        break;
    }
  };

  useEffect(() => {
    autorun(async () => {
      reset(platformSpecificationStore.item);
    });
  }, [reset, platformSpecificationStore]);

  const readOnly = calculateReadOnly(userInterfaceStore.pageType);

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={platformSpecificationStore} />
      <form id="platformSpecificationForm" onSubmit={handleSubmit(onSubmit)}>
        <TextInputField
          name="platform"
          displayName="Platform"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <TextInputField
          name="mcu"
          displayName="MCU"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <NumberInputField
          name="cores"
          displayName="Cores"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <NumberInputField
          name="flashInternal"
          displayName="Flash Internal"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <NumberInputField
          name="flashExternal"
          displayName="Flash External"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <NumberInputField
          name="sramInternal"
          displayName="SRAM Internal"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <NumberInputField
          name="sramExternal"
          displayName="SRAM External"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <NumberInputField
          name="clockDefault"
          displayName="Clock Default"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <NumberInputField
          name="clockMinimum"
          displayName="Clock Minimum"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <NumberInputField
          name="clockMaximum"
          displayName="Clock Maximum"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <CheckboxInputField
          name="wifiAInd"
          displayName="WiFi A"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <CheckboxInputField
          name="wifiBInd"
          displayName="WiFi B"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <CheckboxInputField
          name="wifiGInd"
          displayName="WiFi G"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <CheckboxInputField
          name="wifiNInd"
          displayName="WiFi N"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <NumberInputField
          name="supplyVoltage"
          displayName="Supply Voltage (V)"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
      </form>
    </Fragment>
  );
};

export default observer(PlatformSpecificationForm);
