import axios, { AxiosResponse } from "axios";
import {
  TbCreateDeviceRequestBody,
  TbDevice,
  TbUpdateDeviceRequestBody,
} from "./TbDeviceInterface";

export class TbDeviceApi {
  protected readonly baseUrl: string;
  protected readonly endpoint: string;

  constructor(baseUrl: string, endpoint: string) {
    this.baseUrl = baseUrl;
    this.endpoint = endpoint;
  }

  public async getOne(deviceId: string): Promise<TbDevice> {
    return await axios
      .get(`${this.baseUrl}/tb/${this.endpoint}/${deviceId}`)
      .then((response: AxiosResponse) => {
        return response.data as unknown as TbDevice;
      });
  }

  async insert(
    deviceToInsert: TbCreateDeviceRequestBody | TbUpdateDeviceRequestBody,
    accessToken: string
  ): Promise<TbDevice> {
    let url: string = `${this.baseUrl}/tb/device/${encodeURIComponent(
      accessToken
    )}`;
    return await axios
      .post(url, deviceToInsert)
      .then((response: AxiosResponse) => {
        return response.data as unknown as TbDevice;
      });
  }
}
