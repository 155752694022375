import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { ServiceAllocation } from "../../../../api/ServiceAllocation/ServiceAllocationInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { ActionType } from "../../types";

const ServiceAllocationTable: FC = () => {
  const { serviceAllocationStore } = useRootStore();

  const handleNewServiceAllocation = () => {
    serviceAllocationStore.resetItem();
    serviceAllocationStore.setActionType(ActionType.New);
    serviceAllocationStore.setDialogOpen(true);
  };

  const handleRowClick = (row: ServiceAllocation) => {
    serviceAllocationStore.setItemFromList(row.id);
    serviceAllocationStore.setActionType(ActionType.Edit);
    serviceAllocationStore.setDialogOpen(true);
  };

  function renderTableRow(row: ServiceAllocation) {
    if (row.action === ActionType.Delete) {
      return;
    }

    return (
      <TableRow key={row.localId} onClick={() => handleRowClick(row)}>
        <TableCell>
          {row.resource.name} ({row.resource.resourceUnit.unit})
        </TableCell>
        <TableCell align="right">{row.period.name}</TableCell>
        <TableCell>{row.allocation}</TableCell>
        <TableCell>{row.carryOver ? "True" : "False"}</TableCell>
      </TableRow>
    );
  }

  return (
    <Table aria-label="device function instance device">
      <TableHead>
        <TableRow>
          <TableCell>Resource</TableCell>
          <TableCell align="right">Period</TableCell>
          <TableCell>Allocation</TableCell>
          <TableCell>Carry Over?</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {serviceAllocationStore.itemList.length
          ? serviceAllocationStore.itemList.map((row: ServiceAllocation) => {
              return renderTableRow(row);
            })
          : null}
      </TableBody>
      <Button
        variant="text"
        color="primary"
        type="submit"
        onClick={handleNewServiceAllocation}
      >
        New Service Allocation
      </Button>
    </Table>
  );
};

export default observer(ServiceAllocationTable);
