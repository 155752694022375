import { Grid } from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect } from "react";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import Heading from "../../../Form/Heading/Heading";
import SubmitButton from "../../../Form/SubmitButton/SubmitButton";
import { PageType } from "../../types";
import ActuatorFunctionLineDialogForm from "../ActuatorFunctionLine/ActuatorFunctionLineDialogForm";
import ActuatorFunctionLineTable from "../ActuatorFunctionLine/ActuatorFunctionLineTable";
import SensorFunctionLineDialogForm from "../SensorFunctionLine/SensorFunctionLineDialogForm";
import SensorFunctionLineTable from "../SensorFunctionLine/SensorFunctionLineTable";
import DeviceFunctionForm from "./DeviceFunctionForm";

const DeviceFunctionPage: FC = () => {
  const {
    deviceFunctionStore,
    sensorSpecificationStore,
    userInterfaceStore,
    sensorFunctionLineStore,
    actuatorFunctionLineStore,
    actuatorSpecificationStore,
  } = useRootStore();
  const router = useRouter();

  useEffect(() => {
    autorun(async () => {
      await sensorSpecificationStore.getItemListAsync();
      await actuatorSpecificationStore.getItemListAsync();
      switch (userInterfaceStore.pageType) {
        case PageType.View:
        case PageType.Existing:
          await deviceFunctionStore.getItemAsync(Number(router.query.id));

          await sensorFunctionLineStore.getItemListByForeignKeyAsync(
            Number(router.query.id),
            "deviceFunction"
          );
          await actuatorFunctionLineStore.getItemListByForeignKeyAsync(
            Number(router.query.id),
            "deviceFunction"
          );
          break;
        case PageType.New:
          deviceFunctionStore.resetItem();
          sensorFunctionLineStore.resetItemList();
          actuatorFunctionLineStore.resetItemList();
          break;
        case PageType.Inserted:
      }
    });
  }, [
    userInterfaceStore.pageType,
    deviceFunctionStore,
    router.query.id,
    sensorFunctionLineStore,
    sensorSpecificationStore,
    actuatorFunctionLineStore,
    actuatorSpecificationStore,
  ]);

  const formId = "deviceFunctionForm";
  const sensorFunctionLineFormId = "sensorFunctionLineForm";
  const actuatorFunctionLineFormId = "actuatorFunctionLineForm";

  return (
    <Fragment>
      <Heading
        pageType={userInterfaceStore.pageType!}
        heading={deviceFunctionStore.singularDisplayName}
      />
      <DeviceFunctionForm id={formId} />
      <SensorFunctionLineDialogForm id={sensorFunctionLineFormId} />
      <ActuatorFunctionLineDialogForm id={actuatorFunctionLineFormId} />
      <Grid container spacing={10}>
        <SensorFunctionLineTable />
        <ActuatorFunctionLineTable />
      </Grid>
      {userInterfaceStore.pageType !== PageType.View && (
        <SubmitButton form={formId} pageType={userInterfaceStore.pageType!} />
      )}
    </Fragment>
  );
};

export default observer(DeviceFunctionPage);
