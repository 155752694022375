import { AreaDeviceFunctionInstanceDevice } from "../../api/AreaDeviceFunctionInstanceDevice/AreaDeviceFunctionInstanceDeviceInterface";
import { defaultArea } from "../Area/DefaultArea";
import { defaultDeviceFunctionInstanceDevice } from "../DeviceFunctionInstanceDevice/DefaultDeviceFunctionInstanceDevice";

export const defaultAreaDeviceFunctionInstanceDevice: AreaDeviceFunctionInstanceDevice =
  {
    id: 0,
    area: defaultArea,
    deviceFunctionInstanceDevice: defaultDeviceFunctionInstanceDevice,
    versionNumber: 0,
  };
