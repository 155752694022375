export function occurrencesInStringArray(
  value: string,
  array: string[]
): number {
  let number = 0;
  for (let i of array) {
    if (i === value) {
      number++;
    }
  }
  return number;
}

export function occurrencesInNumberArray(
  value: number,
  array: number[]
): number {
  let number = 0;
  for (let i of array) {
    if (i === value) {
      number++;
    }
  }
  return number;
}
