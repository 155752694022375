import { platformSpecificationApi } from "../../api/api";
import {
  PlatformSpecification,
  PlatformSpecificationInput,
} from "../../api/PlatformSpecification/PlatformSpecificationInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultPlatformSpecification } from "./DefaultPlatformSpecification";

export class PlatformSpecificationStore extends MobXStore<
  PlatformSpecification,
  PlatformSpecificationInput
> {
  constructor(root: RootStore) {
    super(
      root,
      platformSpecificationApi,
      "platformSpecification",
      "Platform Specification",
      "Platform Specifications",
      defaultPlatformSpecification
    );
  }
}
