import { Menu, MenuItem } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { FC, Fragment } from "react";
import { useRootStore } from "../../hooks/useRootStore";
import { useRouter } from "../../hooks/useRouter";
import { MobXStore } from "../../state/types";
import DataTableDelete from "../DataTableDelete/DataTableDelete";
import { PageType } from "../pages/types";

type DataTableMenuProps = {
  store: MobXStore<any, any>;
  disableView?: boolean;
  disableEdit?: boolean;
  disableDuplicate?: boolean;
  disableDelete?: boolean;
};

const DataTableMenu: FC<DataTableMenuProps> = ({
  store,
  disableView,
  disableEdit,
  disableDuplicate,
  disableDelete,
}: DataTableMenuProps) => {
  const { userInterfaceStore } = useRootStore();
  const router = useRouter();
  let history = router.history;

  const handleClose = () => {
    userInterfaceStore.resetMouseXMouseY();
  };

  const handleView = () => {
    handleClose();
    userInterfaceStore.setPageType(PageType.View);
    history.push(`/${store.endpoint}/view/${userInterfaceStore.id}`);
  };

  const handleEdit = () => {
    handleClose();
    userInterfaceStore.setPageType(PageType.Existing);
    history.push(`/${store.endpoint}/edit/${userInterfaceStore.id}`);
  };

  const handleDuplicate = () => {
    handleClose();
    userInterfaceStore.setPageType(PageType.NewDuplicate);
    store.getItemAsync(Number(userInterfaceStore.id!));
    history.push(`/${store.endpoint}/new`);
  };

  const handleDelete = () => {
    handleClose();
    userInterfaceStore.setDialogOpen(true);
  };

  return (
    <Fragment>
      <DataTableDelete store={store} />
      <Menu
        keepMounted
        open={userInterfaceStore.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          userInterfaceStore.mouseY !== null &&
          userInterfaceStore.mouseX !== null
            ? {
                top: userInterfaceStore.mouseY,
                left: userInterfaceStore.mouseX,
              }
            : undefined
        }
      >
        {!disableView && <MenuItem onClick={handleView}>View</MenuItem>}
        {!disableEdit && <MenuItem onClick={handleEdit}>Edit</MenuItem>}
        {!disableDelete && <MenuItem onClick={handleDelete}>Delete</MenuItem>}
        {!disableDuplicate && (
          <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
        )}
      </Menu>
    </Fragment>
  );
};

const DataTableMenuObserver = observer(DataTableMenu);
export default DataTableMenuObserver;
