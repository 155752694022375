import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import {
  DeviceFunctionInput,
  DeviceFunctionSchema,
} from "../../../../api/DeviceFunction/DeviceFunctionInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./DeviceFunctionValidation";

const DeviceFunctionForm: FC<FormProps> = ({ id }: FormProps) => {
  const router = useRouter();
  const {
    deviceFunctionStore,
    sensorFunctionLineStore,
    actuatorFunctionLineStore,
    userInterfaceStore,
  } = useRootStore();

  const deviceFunctionSchema = useMemo(
    () => generateSchema(userInterfaceStore.pageType!),
    [userInterfaceStore.pageType]
  );

  const resolver = useYupValidationResolver(deviceFunctionSchema);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    defaultValues: deviceFunctionStore.defaultItem,
    resolver,
  });

  useEffect(() => {
    autorun(async () => {
      reset(deviceFunctionStore.item);
    });
  }, [deviceFunctionStore.item, reset]);

  const onSubmit = async (values: DeviceFunctionSchema) => {
    let deviceFunction: DeviceFunctionInput = {
      ...values,
    };
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
      case PageType.Inserted:
        deviceFunction = {
          ...values,
          id: Number(router.query.id),
        };
        await deviceFunctionStore.updateItemAsync(deviceFunction);
        break;
      case PageType.New:
      case PageType.NewDuplicate:
        await deviceFunctionStore.insertItemAsync(deviceFunction);
        userInterfaceStore.setPageType(PageType.Inserted);
        router.history.push(
          `/${deviceFunctionStore.endpoint}/edit/${deviceFunctionStore.item.id}`
        );
        break;
    }
    await sensorFunctionLineStore.handleUpdateDeviceFunction(
      deviceFunctionStore.item.id
    );
    await actuatorFunctionLineStore.handleUpdateDeviceFunction(
      deviceFunctionStore.item.id
    );
  };
  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={deviceFunctionStore} />
      <SnackbarAlert store={sensorFunctionLineStore} />
      <SnackbarAlert store={actuatorFunctionLineStore} />
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <TextInputField
          name="name"
          displayName="Name"
          errors={errors}
          control={control}
          readOnly={false}
        />
      </form>
    </Fragment>
  );
};

export default observer(DeviceFunctionForm);
