import { number, object, SchemaOf, string } from "yup";
import { AreaSchema } from "../../../../api/Area/AreaInterface";
import { PageType } from "../../types";

export function generateSchema(type: PageType) {
  const schema: SchemaOf<AreaSchema> = object().shape({
    name: string().required(),
    areaStatus: object().shape({
      id: number().required().positive().integer(),
    }),
  });
  return schema;
}
