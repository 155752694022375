import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import {
  SensorSpecification,
  SensorSpecificationInput,
  SensorSpecificationSchema,
} from "../../../../api/SensorSpecification/SensorSpecificationInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import calculateReadOnly from "../../../../utils/calculateReadOnly";
import deduplicateArray from "../../../../utils/deduplicateArray";
import ComboAutocompleteField from "../../../Form/ComboAutocompleteField/ComboAutocompleteField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./SensorSpecificationValidation";

const SensorSpecificationForm: FC<FormProps> = ({ id }: FormProps) => {
  const router = useRouter();
  const { sensorSpecificationStore, userInterfaceStore } = useRootStore();

  const sensorSpecificationSchema = useMemo(
    () => generateSchema(userInterfaceStore.pageType!),
    [userInterfaceStore.pageType]
  );

  const resolver = useYupValidationResolver(sensorSpecificationSchema);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    reset,
  } = useForm({
    defaultValues: sensorSpecificationStore.defaultItem,
    resolver,
  });

  const onSubmit = async (values: SensorSpecificationSchema) => {
    let sensorSpecification: SensorSpecificationInput = {
      ...values,
    };
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
        sensorSpecification = {
          ...values,
          id: Number(router.query.id),
        };
        await sensorSpecificationStore.updateItemAsync(sensorSpecification);
        break;
      case PageType.New:
      case PageType.NewDuplicate:
        await sensorSpecificationStore.insertItemAsync(sensorSpecification);
        userInterfaceStore.setPageType(PageType.Inserted);
        router.history.push(
          `/${sensorSpecificationStore.endpoint}/edit/${sensorSpecificationStore.item.id}`
        );
        break;
    }
  };

  useEffect(() => {
    autorun(async () => {
      reset(sensorSpecificationStore.item);
    });
  }, [sensorSpecificationStore, reset]);

  let manufacturerOptions = null,
    typeOptions = null;

  if (sensorSpecificationStore.itemList) {
    const rawManufacturerValues = sensorSpecificationStore.itemList.map(
      (val) => {
        return val.manufacturer;
      }
    );
    manufacturerOptions = deduplicateArray(rawManufacturerValues).map((val) => {
      return { displayValue: val };
    });

    const rawTypeValues = sensorSpecificationStore.itemList.map((val) => {
      return val.type;
    });

    typeOptions = deduplicateArray(rawTypeValues).map((val) => {
      return { displayValue: val };
    });
  }

  const readOnly = calculateReadOnly(userInterfaceStore.pageType);

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={sensorSpecificationStore} />
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        {readOnly ? (
          <Fragment>
            <TextInputField
              name="manufacturer"
              displayName="Manufacturer"
              errors={errors}
              control={control}
              readOnly={readOnly}
            />
            <TextInputField
              name="type"
              displayName="Type"
              errors={errors}
              control={control}
              readOnly={readOnly}
            />
          </Fragment>
        ) : (
          <Fragment>
            <ComboAutocompleteField
              options={manufacturerOptions}
              name="manufacturer"
              displayName="Manufacturer"
              errors={errors}
              control={control}
              readOnly={readOnly}
            />
            <ComboAutocompleteField
              options={typeOptions}
              name="type"
              displayName="Type"
              errors={errors}
              control={control}
              readOnly={readOnly}
            />
          </Fragment>
        )}
        <TextInputField
          name="model"
          displayName="Model"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
      </form>
    </Fragment>
  );
};

export default observer(SensorSpecificationForm);
