import { DeviceSpecificationSensorSpecification } from "../../api/DeviceSpecificationSensorSpecification/DeviceSpecificationSensorSpecificationInterface";
import { defaultDeviceSpecification } from "../DeviceSpecification/DefaultDeviceSpecification";
import { defaultSensorSpecification } from "../SensorSpecification/DefaultSensorSpecification";
export const defaultDeviceSpecificationSensorSpecification: DeviceSpecificationSensorSpecification =
  {
    id: 0,
    localId: 0,
    action: undefined,
    sensorSpecification: defaultSensorSpecification,
    deviceSpecification: defaultDeviceSpecification,
    quantity: 0,
  };
