import { object, SchemaOf, string } from "yup";
import { RoomStatusSchema } from "../../../../api/RoomStatus/RoomStatusInterface";
import { PageType } from "../../types";

export function generateSchema(type: PageType) {
  const schema: SchemaOf<RoomStatusSchema> = object().shape({
    status: string().required(),
    description: string().required(),
  });
  return schema;
}
