import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { DeviceRoleDatabaseDefinition } from "../../../../api/DeviceRoleDatabaseDefinition/DeviceRoleDatabaseDefinitionInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import NumberInputField from "../../../Form/NumberInputField/NumberInputField";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import { FormProps } from "../../../Form/types";
import { ActionType } from "../../types";
import { generateSchema } from "./DeviceRoleDatabaseDefinitionValidation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogHeading: {
      marginBottom: 10,
    },
  })
);

const DeviceRoleDatabaseDefinitionDialogForm: FC<FormProps> = ({
  id,
}: FormProps) => {
  const {
    deviceDatabaseColumnNameStore,
    deviceDatabaseColumnTypeStore,
    deviceRoleDatabaseDefinitionStore,
    resourceStore,
    deviceRoleStore,
  } = useRootStore();

  const handleClose = () => {
    deviceRoleDatabaseDefinitionStore.setDialogOpen(false);
  };

  const handleDelete = () => {
    deviceRoleDatabaseDefinitionStore.handleDelete();
    handleClose();
  };

  const onSubmit = (values: DeviceRoleDatabaseDefinition) => {
    const deviceDatabaseColumnName =
      deviceDatabaseColumnNameStore.getItemFromList(
        values.deviceDatabaseColumnName.id
      );
    const deviceDatabaseColumnType =
      deviceDatabaseColumnTypeStore.getItemFromList(
        values.deviceDatabaseColumnType.id
      );
    const resource = resourceStore.getItemFromList(values.resource!.id);
    const deviceRole = deviceRoleStore.item;
    switch (deviceRoleDatabaseDefinitionStore.actionType) {
      case ActionType.Edit:
        deviceRoleDatabaseDefinitionStore.handleEdit(
          values,
          deviceDatabaseColumnName,
          deviceDatabaseColumnType,
          resource
        );
        break;
      case ActionType.New:
        deviceRoleDatabaseDefinitionStore.handleInsert(
          values,
          deviceDatabaseColumnName,
          deviceDatabaseColumnType,
          resource
        );
        break;
    }
    handleClose();
  };

  const deviceRoleDatabaseDefinitionSchema = useMemo(
    () => generateSchema(),
    []
  );

  const resolver = useYupValidationResolver(deviceRoleDatabaseDefinitionSchema);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    setFocus,
  } = useForm({
    defaultValues: deviceRoleDatabaseDefinitionStore.defaultItem,
    resolver,
  });

  useEffect(() => {
    autorun(async () => {
      reset(deviceRoleDatabaseDefinitionStore.item);
    });
  }, [reset, deviceRoleDatabaseDefinitionStore.item]);

  const classes = useStyles();

  const deviceDatabaseColumnNameList = deviceDatabaseColumnNameStore.itemList;
  let deviceDatabaseColumnNameOptions: { id: number; value: string }[] = [];
  if (deviceDatabaseColumnNameList.length) {
    deviceDatabaseColumnNameOptions = deviceDatabaseColumnNameList.map(
      (ddcn) => {
        return {
          id: ddcn.id,
          value: `${ddcn.displayColumnName}`,
        };
      }
    );
  }
  const deviceDatabaseColumnTypeList = deviceDatabaseColumnTypeStore.itemList;
  let deviceDatabaseColumnTypeOptions: { id: number; value: string }[] = [];
  if (deviceDatabaseColumnTypeList.length) {
    deviceDatabaseColumnTypeOptions = deviceDatabaseColumnTypeList.map(
      (ddcn) => {
        return {
          id: ddcn.id,
          value: `${ddcn.displayColumnType}`,
        };
      }
    );
  }
  const resourceList = resourceStore.itemList;
  let resourceOptions: { id: number; value: string }[] = [];
  if (resourceList.length) {
    resourceOptions = resourceList.map((resource) => {
      return {
        id: resource.id,
        value: `${resource.name} (${resource.resourceUnit.unit})`,
      };
    });
  }

  return (
    <Dialog
      open={deviceRoleDatabaseDefinitionStore.dialogOpen}
      onClose={handleClose}
      aria-labelledby="device-role-database-definition-form-dialog-title"
    >
      <DialogTitle id="device-role-database-definition-form-dialog-title">
        {deviceRoleDatabaseDefinitionStore.actionType === ActionType.Edit &&
          "Modify "}
        {deviceRoleDatabaseDefinitionStore.actionType === ActionType.New &&
          "Add a new "}
        Device Role Database Definition
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {deviceRoleDatabaseDefinitionStore.actionType === ActionType.Edit &&
            "Modify device role database definition "}
          {deviceRoleDatabaseDefinitionStore.actionType === ActionType.New &&
            "Add device role database definition "}
          for the device role
        </DialogContentText>
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
          <SelectInputField
            name="deviceDatabaseColumnName.id"
            displayName="Device Database Column Name"
            options={deviceDatabaseColumnNameOptions}
            errors={errors}
            control={control}
          />
          <SelectInputField
            name="deviceDatabaseColumnType.id"
            displayName="Device Database Column Type"
            options={deviceDatabaseColumnTypeOptions}
            errors={errors}
            control={control}
          />
          <NumberInputField
            name="size"
            displayName="Size (Bytes)"
            errors={errors}
            control={control}
          />
          <SelectInputField
            name="resource.id"
            displayName="Resource"
            optional
            options={resourceOptions}
            errors={errors}
            control={control}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {deviceRoleDatabaseDefinitionStore.actionType === ActionType.Edit && (
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        )}
        <Button form={id} type="submit" color="primary">
          {deviceRoleDatabaseDefinitionStore.actionType === ActionType.Edit &&
            "Update"}
          {deviceRoleDatabaseDefinitionStore.actionType === ActionType.New &&
            "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(DeviceRoleDatabaseDefinitionDialogForm);
