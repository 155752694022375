import { GridColumns } from "@material-ui/data-grid";
import { TagModel } from "../../../../api/TagModel/TagModelInterface";

export const tagModelListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "modelNumber",
    headerName: "Model Number",
    flex: 1,
  },
  {
    field: "manufacturer",
    headerName: "Manufacturer",
    flex: 1,
  },
  {
    field: "versionNumber",
    headerName: "Version Number",
    hide: true,
  },
];
