import { FormControl, TextField } from "@material-ui/core";
import React, { FC } from "react";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";

type TextInputFieldProps = {
  name: string;
  displayName: string;
  errors: DeepMap<any, FieldError>;
  control: Control<any>;
  numberOfLines?: number;
  readOnly?: boolean;
};

const TextInputField: FC<TextInputFieldProps> = ({
  name,
  displayName,
  errors,
  control,
  numberOfLines,
  readOnly,
}: TextInputFieldProps) => {
  if (numberOfLines) {
    return (
      <FormControl fullWidth>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <TextField
              error={errors[name] ? true : false}
              {...field}
              name={name}
              multiline
              rows={numberOfLines}
              InputProps={{
                readOnly: readOnly,
              }}
              label={displayName}
              placeholder={displayName}
              helperText={errors[name] && errors[name].message}
              variant={readOnly ? "standard" : "outlined"}
            />
          )}
        />
      </FormControl>
    );
  } else {
    return (
      <FormControl fullWidth>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <TextField
              error={errors[name] ? true : false}
              {...field}
              name={name}
              InputProps={{
                readOnly: readOnly,
              }}
              label={displayName}
              placeholder={displayName}
              helperText={errors[name] && errors[name].message}
              variant={readOnly ? "standard" : "outlined"}
            />
          )}
        />
      </FormControl>
    );
  }
};

export default TextInputField;
