import { AbstractAPI as AbstractApi } from "../abstractApi";
import { DeviceDatabaseColumnType } from "./DeviceDatabaseColumnTypeInterface";
export class DeviceDatabaseColumnTypeApi extends AbstractApi<
  DeviceDatabaseColumnType,
  DeviceDatabaseColumnType
> {
  constructor(baseUrl: string) {
    super(baseUrl, "deviceDatabaseColumnType");
  }
}
