import { number, object, SchemaOf, string } from "yup";
import { ActuatorSpecificationSchema } from "../../../../api/ActuatorSpecification/ActuatorSpecificationInterface";
import { PageType } from "../../types";

export function generateSchema(type: PageType) {
  const schema: SchemaOf<ActuatorSpecificationSchema> = object().shape({
    manufacturer: string().required(),
    type: string().required(),
    model: string().required(),
    voltage: number().required().positive(),
    current: number().required().positive(),
    levels: number().required().positive().integer(),
    phases: number().required().positive().integer(),
  });
  return schema;
}
