import { AbstractAPI as AbstractApi } from "../abstractApi";
import { PersonStatus, PersonStatusInput } from "./PersonStatusInterface";

export class PersonStatusApi extends AbstractApi<
  PersonStatus,
  PersonStatusInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "personStatus");
  }
}
