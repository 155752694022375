import { tagStatusApi } from "../../api/api";
import { TagStatus } from "../../api/TagStatus/TagStatusInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultTagStatus } from "./DefaultTagStatus";

export class TagStatusStore extends MobXStore<TagStatus, TagStatus> {
  constructor(root: RootStore) {
    super(
      root,
      tagStatusApi,
      "tagStatus",
      "Tag Status",
      "Tag Statuses",
      defaultTagStatus
    );
  }
}
