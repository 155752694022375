import { GridColumns } from "@material-ui/data-grid";
import { Room } from "../../../../api/Room/RoomInterface";
import { TbAsset } from "../../../../api/TbAsset/TbAssetInterface";

function getRoomType(params: any) {
  const room: Room = params.row;
  if (room.roomType) {
    return room.roomType.type;
  }
}

function getRoomStatus(params: any) {
  const room: Room = params.row;
  if (room.roomStatus) {
    return room.roomStatus.description;
  }
}

function getFormattedTimeFromUtc(params: any) {
  const tbAsset: TbAsset = params.row;
  if (tbAsset.createdTime) {
    return new Date(tbAsset.createdTime);
  }
}

export const roomListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "name",
    headerName: "Name",
    type: "number",
    flex: 1,
  },
  {
    field: "number",
    headerName: "Number",
    type: "number",
    flex: 1,
  },
  {
    field: "floor",
    headerName: "Floor",
    type: "number",
    flex: 1,
  },
  {
    field: "number",
    headerName: "Number",
    type: "number",
    flex: 1,
  },
  {
    field: "type",
    headerName: "Room Type",
    valueGetter: getRoomType,
    flex: 1,
  },
  {
    field: "systemRoomStatus",
    headerName: "System Room Status",
    type: "number",
    flex: 1,
  },
  {
    field: "customerName",
    headerName: "Customer",
    flex: 1,
  },
  {
    field: "createdTime",
    headerName: "Created Time",
    valueGetter: getFormattedTimeFromUtc,
    flex: 1,
  },
  {
    field: "status",
    headerName: "Room Status",
    valueGetter: getRoomStatus,
    flex: 1,
  },
  {
    field: "loft",
    headerName: "Has Loft?",
    flex: 1,
  },
  {
    field: "paraplegic",
    headerName: "Is Paraplegic?",
    flex: 1,
  },
];
