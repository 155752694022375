import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { SensorFunctionLine } from "../../../../api/SensorFunctionLine/SensorFunctionLineInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import NumberInputField from "../../../Form/NumberInputField/NumberInputField";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import { FormProps } from "../../../Form/types";
import { ActionType } from "../../types";
import { generateSchema } from "./SensorFunctionLineValidation";

const SensorFunctionLineDialogForm: FC<FormProps> = ({ id }: FormProps) => {
  const { sensorFunctionLineStore, sensorSpecificationStore } = useRootStore();

  const handleClose = () => {
    sensorFunctionLineStore.setDialogOpen(false);
  };

  const handleDelete = () => {
    sensorFunctionLineStore.handleDelete();
    handleClose();
  };

  const onSubmit = (values: SensorFunctionLine) => {
    const sensorSpecification = sensorSpecificationStore.getItemFromList(
      values.sensorSpecification.id
    );
    switch (sensorFunctionLineStore.actionType) {
      case ActionType.Edit:
        sensorFunctionLineStore.handleEdit(values, sensorSpecification);
        break;
      case ActionType.New:
        sensorFunctionLineStore.handleInsert(values, sensorSpecification);
        break;
    }
    handleClose();
  };

  const deviceFunctionSensorSchema = useMemo(
    () =>
      generateSchema(
        sensorFunctionLineStore.itemList,
        sensorFunctionLineStore.item
      ),
    [sensorFunctionLineStore.itemList, sensorFunctionLineStore.item]
  );

  const resolver = useYupValidationResolver(deviceFunctionSensorSchema);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: sensorFunctionLineStore.defaultItem,
    resolver,
  });

  useEffect(() => {
    autorun(async () => {
      reset(sensorFunctionLineStore.item);
    });
  }, [reset, sensorFunctionLineStore.item]);

  const sensorSpecifications = sensorSpecificationStore.itemList;
  let sensorOptions: { id: number; value: string }[] = [];
  if (sensorSpecifications.length) {
    sensorOptions = sensorSpecifications.map((sensorSpecification) => {
      return {
        id: sensorSpecification.id,
        value: `${sensorSpecification.manufacturer}-${sensorSpecification.type}-${sensorSpecification.model}`,
      };
    });
  }

  return (
    <Dialog
      open={sensorFunctionLineStore.dialogOpen}
      onClose={handleClose}
      aria-labelledby="sensor-function-line-form-dialog-title"
    >
      <DialogTitle id="sensor-function-line-form-dialog-title">
        {sensorFunctionLineStore.actionType === ActionType.Edit && "Modify "}
        {sensorFunctionLineStore.actionType === ActionType.New && "Add a new "}
        Sensor
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {sensorFunctionLineStore.actionType === ActionType.Edit &&
            "Modify existing sensor "}
          {sensorFunctionLineStore.actionType === ActionType.New &&
            "Add a new sensor "}
          for the device function
        </DialogContentText>
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
          <SelectInputField
            name="sensorSpecification.id"
            options={sensorOptions}
            displayName="Sensor"
            errors={errors}
            control={control}
          />
          <NumberInputField
            name="quantity"
            displayName="Quantity"
            errors={errors}
            control={control}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {sensorFunctionLineStore.actionType === ActionType.Edit && (
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        )}
        <Button form={id} type="submit" color="primary">
          {sensorFunctionLineStore.actionType === ActionType.Edit && "Update"}
          {sensorFunctionLineStore.actionType === ActionType.New && "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(SensorFunctionLineDialogForm);
