import { GridColumns } from "@material-ui/data-grid";
import { DeviceFunction } from "../../../../api/DeviceFunction/DeviceFunctionInterface";

function getSensorList(params: any) {
  const deviceFunction: DeviceFunction = params.row;
  if (deviceFunction.sensorList) {
    const sensorSpecifications = deviceFunction.sensorList.map((val) => {
      return `${val.sensorSpecification.manufacturer}-${val.sensorSpecification.model}-${val.sensorSpecification.type} (${val.quantity})`;
    });
    return sensorSpecifications.join(", ");
  }
}
function getActuatorList(params: any) {
  const deviceFunction: DeviceFunction = params.row;
  if (deviceFunction.actuatorList) {
    const actuatorSpecifications = deviceFunction.actuatorList.map((val) => {
      return `${val.actuatorSpecification.manufacturer}-${val.actuatorSpecification.model}-${val.actuatorSpecification.type} (${val.quantity})`;
    });
    return actuatorSpecifications.join(", ");
  }
}

export const deviceFunctionListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "sensorList",
    headerName: "Sensor List",
    valueGetter: getSensorList,
    flex: 1,
  },
  {
    field: "actuatorList",
    headerName: "Actuator List",
    valueGetter: getActuatorList,
    flex: 1,
  },
];
