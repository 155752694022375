import { boolean, number, object, SchemaOf, string } from "yup";
import { deviceRoleApi } from "../../../../api/api";
import { DeviceRoleSchema } from "../../../../api/DeviceRole/DeviceRoleInterface";
import { occurrencesInStringArray } from "../../../../utils/occurrencesInArray";
import { PageType } from "../../types";

export function generateSchema(type: PageType) {
  const schema: SchemaOf<DeviceRoleSchema> = object().shape({
    name: string()
      .required()
      .test(
        "checkNameUnique",
        "The device function name already exists!",
        async (value: unknown): Promise<boolean> => {
          if (typeof value !== "string") {
            return false;
          }
          const deviceRoleList = await deviceRoleApi.getAll();
          if ("error" in deviceRoleList) {
            return false;
          }
          if (!deviceRoleList.data.length) {
            return true;
          }
          const deviceRoles = deviceRoleList.data.map(
            (deviceRole) => deviceRole.name
          );
          const occurrences = occurrencesInStringArray(value, deviceRoles);
          switch (type) {
            case PageType.New:
            case PageType.NewDuplicate:
              return occurrences < 1;
            case PageType.Existing:
            case PageType.Inserted:
              return occurrences < 2;
          }
          return false;
        }
      ),
    deviceSpecification: object().shape({
      id: number().required().positive().integer(),
    }),
  });
  return schema;
}
