import { AbstractAPI as AbstractApi } from "../abstractApi";
import { DeviceRule, DeviceRoleRuleInput } from "./DeviceRuleInterface";

export class DeviceRoleRuleApi extends AbstractApi<
  DeviceRule,
  DeviceRoleRuleInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "deviceRoleRule");
  }
}
