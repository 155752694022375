import { GridColumns } from "@material-ui/data-grid";
import { ServicePackage } from "../../../../api/ServicePackage/ServicePackageInterface";

function getRoomType(params: any) {
  const servicePackage: ServicePackage = params.row;
  if (servicePackage.roomType) {
    return servicePackage.roomType.type;
  }
}
export const servicePackageListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "name",
    headerName: "Service Package Name",
    flex: 1,
  },
  {
    field: "description",
    headerName: "Service Package Description",
    flex: 1,
  },
  {
    field: "roomType",
    headerName: "Room Type",
    valueGetter: getRoomType,
    flex: 1,
  },
];
