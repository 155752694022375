import { FormControl, TextField } from "@material-ui/core";
import React, { FC } from "react";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";

type NumberInputFieldProps = {
  name: string;
  displayName: string;
  errors: DeepMap<any, FieldError>;
  control: Control<any>;
  readOnly?: boolean;
  maximum?: number;
  minimum?: number;
  step?: number;
};

const NumberInputField: FC<NumberInputFieldProps> = ({
  name,
  displayName,
  errors,
  control,
  readOnly,
  maximum,
  minimum,
  step,
}: NumberInputFieldProps) => {
  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            error={errors[name] ? true : false}
            {...field}
            name={name}
            type="number"
            InputProps={{
              readOnly: readOnly,
              inputProps: {
                max: maximum,
                min: minimum,
                step: step,
              },
            }}
            label={displayName}
            placeholder={displayName}
            helperText={errors[name] && errors[name].message}
            variant={readOnly ? "standard" : "outlined"}
          />
        )}
      />
    </FormControl>
  );
};

export default NumberInputField;
