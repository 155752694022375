import {
  ActuatorSpecification,
  ActuatorSpecificationInput,
} from "../../api/ActuatorSpecification/ActuatorSpecificationInterface";
import { actuatorSpecificationApi } from "../../api/api";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultActuatorSpecification } from "./DefaultActuatorSpecification";

export class ActuatorSpecificationStore extends MobXStore<
  ActuatorSpecification,
  ActuatorSpecificationInput
> {
  constructor(root: RootStore) {
    super(
      root,
      actuatorSpecificationApi,
      "actuatorSpecification",
      "Actuator Specification",
      "Actuator Specifications",
      defaultActuatorSpecification
    );
  }
}
