import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect } from "react";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import Heading from "../../../Form/Heading/Heading";
import SubmitButton from "../../../Form/SubmitButton/SubmitButton";
import { PageType } from "../../types";
import ResourceUnitForm from "./ResourceUnitForm";

const ResourceUnitPage: FC = () => {
  const router = useRouter();
  const { resourceUnitStore, userInterfaceStore } = useRootStore();

  useEffect(() => {
    autorun(async () => {
      switch (userInterfaceStore.pageType) {
        case PageType.View:
        case PageType.Existing:
          await resourceUnitStore.getItemAsync(Number(router.query.id));
          break;
        case PageType.New:
          resourceUnitStore.resetItem();
          break;
      }
    });
  }, [userInterfaceStore.pageType, resourceUnitStore, router.query.id]);

  const formId = "resourceUnitForm";

  return (
    <Fragment>
      <Heading
        pageType={userInterfaceStore.pageType!}
        heading={resourceUnitStore.singularDisplayName}
      />
      <ResourceUnitForm id={formId} />
      {userInterfaceStore.pageType !== PageType.View && (
        <SubmitButton form={formId} pageType={userInterfaceStore.pageType!} />
      )}
    </Fragment>
  );
};

export default observer(ResourceUnitPage);
