import { GridColumns } from "@material-ui/data-grid";
import { DeviceRole } from "../../../../api/DeviceRole/DeviceRoleInterface";

function getDeviceSpecification(params: any) {
  const deviceRole: DeviceRole = params.row;
  if (deviceRole.deviceSpecification) {
    return deviceRole.deviceSpecification.name;
  }
}

function getDeviceFunctionInstances(params: any) {
  const deviceRole: DeviceRole = params.row;
  if (deviceRole.deviceFunctionList) {
    const deviceFunctionInstances = deviceRole.deviceFunctionList.map((val) => {
      return `${val.name}`;
    });
    return deviceFunctionInstances.join(", ");
  }
}

export const deviceRoleListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "deviceSpecification",
    headerName: "Device Specification",
    valueGetter: getDeviceSpecification,
    flex: 1,
  },
  {
    field: "deviceFunctionInstances",
    headerName: "Device Function Instances",
    valueGetter: getDeviceFunctionInstances,
    flex: 1,
  },
];
