import { roomStatusApi } from "../../api/api";
import {
  RoomStatus,
  RoomStatusInput,
} from "../../api/RoomStatus/RoomStatusInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultRoomStatus } from "./DefaultRoomStatus";

export class RoomStatusStore extends MobXStore<RoomStatus, RoomStatusInput> {
  constructor(root: RootStore) {
    super(
      root,
      roomStatusApi,
      "roomStatus",
      "Room Status",
      "Room Statuses",
      defaultRoomStatus
    );
  }
}
