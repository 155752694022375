export enum PageType {
  View = "VIEW",
  New = "NEW",
  NewDuplicate = "NEW_DUPLICATE",
  Existing = "EXISTING",
  Inserted = "INSERTED",
}

export enum ActionType {
  Edit = "EDIT",
  New = "NEW",
  Delete = "DELETE",
}
