import { ThemeProvider } from "@material-ui/core";
import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Navigation from "./components/Navigation/Navigation";
import ActuatorSpecification from "./components/pages/ActuatorSpecification/ActuatorSpecification/ActuatorSpecification";
import ActuatorSpecificationList from "./components/pages/ActuatorSpecification/ActuatorSpecificationList/ActuatorSpecificationList";
import Area from "./components/pages/Area/Area/Area";
import AreaList from "./components/pages/Area/AreaList/AreaList";
import Building from "./components/pages/Building/Building/Building";
import BuildingList from "./components/pages/Building/BuildingList/BuildingList";
import Customer from "./components/pages/Customer/Customer/Customer";
import CustomerList from "./components/pages/Customer/CustomerList/CustomerList";
import Device from "./components/pages/Device/Device/Device";
import DeviceList from "./components/pages/Device/DeviceList/DeviceList";
import DeviceConfig from "./components/pages/DeviceConfig/DeviceConfig/DeviceConfig";
import DeviceConfigList from "./components/pages/DeviceConfig/DeviceConfigList/DeviceConfigList";
import DeviceFunction from "./components/pages/DeviceFunction/DeviceFunction/DeviceFunction";
import DeviceFunctionList from "./components/pages/DeviceFunction/DeviceFunctionList/DeviceFunctionList";
import DeviceRole from "./components/pages/DeviceRole/DeviceRole/DeviceRole";
import DeviceRoleList from "./components/pages/DeviceRole/DeviceRoleList/DeviceRoleList";
import DeviceSpecification from "./components/pages/DeviceSpecification/DeviceSpecification/DeviceSpecification";
import DeviceSpecificationList from "./components/pages/DeviceSpecification/DeviceSpecificationList/DeviceSpecificationList";
import HomePage from "./components/pages/Homepage/Homepage";
import Period from "./components/pages/Period/Period/Period";
import PeriodList from "./components/pages/Period/PeriodList/PeriodList";
import Person from "./components/pages/Person/Person/Person";
import PersonList from "./components/pages/Person/PersonList/PersonList";
import PlatformSpecification from "./components/pages/PlatformSpecification/PlatformSpecification/PlatformSpecification";
import PlatformSpecificationList from "./components/pages/PlatformSpecification/PlatformSpecificationList/PlatformSpecificationList";
import Resource from "./components/pages/Resource/Resource/Resource";
import ResourceList from "./components/pages/Resource/ResourceList/ResourceList";
import ResourceUnit from "./components/pages/ResourceUnit/ResourceUnit/ResourceUnit";
import ResourceUnitList from "./components/pages/ResourceUnit/ResourceUnitList/ResourceUnitList";
import Room from "./components/pages/Room/Room/Room";
import RoomList from "./components/pages/Room/RoomList/RoomList";
import RoomStatus from "./components/pages/RoomStatus/RoomStatus/RoomStatus";
import RoomStatusList from "./components/pages/RoomStatus/RoomStatusList/RoomStatusList";
import RoomType from "./components/pages/RoomType/RoomType/RoomType";
import RoomTypeList from "./components/pages/RoomType/RoomTypeList/RoomTypeList";
import SensorSpecification from "./components/pages/SensorSpecification/SensorSpecification/SensorSpecification";
import SensorSpecificationList from "./components/pages/SensorSpecification/SensorSpecificationList/SensorSpecificationList";
import ServicePackage from "./components/pages/ServicePackage/ServicePackage/ServicePackage";
import ServicePackageList from "./components/pages/ServicePackage/ServicePackageList/ServicePackageList";
import Tag from "./components/pages/Tag/Tag/Tag";
import TagList from "./components/pages/Tag/TagList/TagList";
import TagModel from "./components/pages/TagModel/TagModel/TagModel";
import TagModelList from "./components/pages/TagModel/TagModelList/TagModelList";
import muiTheme from "./theme/muiTheme";
export const App = () => (
  <ThemeProvider theme={muiTheme}>
    <BrowserRouter basename="/">
      <Navigation>
        <Layout>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route path="/platformSpecificationList">
              <PlatformSpecificationList />
            </Route>
            <Route path="/platformSpecification/view/:id">
              <PlatformSpecification />
            </Route>
            <Route path="/platformSpecification/edit/:id">
              <PlatformSpecification />
            </Route>
            <Route path="/platformSpecification/new">
              <PlatformSpecification />
            </Route>
            <Route path="/sensorSpecificationList">
              <SensorSpecificationList />
            </Route>
            <Route path="/sensorSpecification/view/:id">
              <SensorSpecification />
            </Route>
            <Route path="/sensorSpecification/edit/:id">
              <SensorSpecification />
            </Route>
            <Route path="/sensorSpecification/new">
              <SensorSpecification />
            </Route>
            <Route path="/actuatorSpecificationList">
              <ActuatorSpecificationList />
            </Route>
            <Route path="/actuatorSpecification/view/:id">
              <ActuatorSpecification />
            </Route>
            <Route path="/actuatorSpecification/edit/:id">
              <ActuatorSpecification />
            </Route>
            <Route path="/actuatorSpecification/new">
              <ActuatorSpecification />
            </Route>
            <Route path="/deviceFunctionList">
              <DeviceFunctionList />
            </Route>
            <Route path="/deviceFunction/view/:id">
              <DeviceFunction />
            </Route>
            <Route path="/deviceFunction/edit/:id">
              <DeviceFunction />
            </Route>
            <Route path="/deviceFunction/new">
              <DeviceFunction />
            </Route>
            <Route path="/deviceSpecificationList">
              <DeviceSpecificationList />
            </Route>
            <Route path="/deviceSpecification/view/:id">
              <DeviceSpecification />
            </Route>
            <Route path="/deviceSpecification/edit/:id">
              <DeviceSpecification />
            </Route>
            <Route path="/deviceSpecification/new">
              <DeviceSpecification />
            </Route>
            <Route path="/deviceRoleList">
              <DeviceRoleList />
            </Route>
            <Route path="/deviceRole/view/:id">
              <DeviceRole />
            </Route>
            <Route path="/deviceRole/edit/:id">
              <DeviceRole />
            </Route>
            <Route path="/deviceRole/new">
              <DeviceRole />
            </Route>
            <Route path="/deviceList">
              <DeviceList />
            </Route>
            <Route path="/device/view/:id">
              <Device />
            </Route>
            <Route path="/device/edit/:id">
              <Device />
            </Route>
            <Route path="/device/new">
              <Device />
            </Route>
            <Route path="/deviceConfigList">
              <DeviceConfigList />
            </Route>
            <Route path="/deviceConfig/view/:endpoint/:id">
              <DeviceConfig />
            </Route>
            <Route path="/deviceConfig/edit/:endpoint/:id">
              <DeviceConfig />
            </Route>
            <Route path="/deviceConfig/new">
              <DeviceConfig />
            </Route>
            <Route path="/roomTypeList">
              <RoomTypeList />
            </Route>
            <Route path="/roomType/view/:id">
              <RoomType />
            </Route>
            <Route path="/roomType/edit/:id">
              <RoomType />
            </Route>
            <Route path="/roomType/new">
              <RoomType />
            </Route>
            <Route path="/roomStatusList">
              <RoomStatusList />
            </Route>
            <Route path="/roomStatus/view/:id">
              <RoomStatus />
            </Route>
            <Route path="/roomStatus/edit/:id">
              <RoomStatus />
            </Route>
            <Route path="/roomStatus/new">
              <RoomStatus />
            </Route>
            <Route path="/roomList">
              <RoomList />
            </Route>
            <Route path="/room/view/:id">
              <Room />
            </Route>
            <Route path="/room/edit/:id">
              <Room />
            </Route>
            <Route path="/room/new">
              <Room />
            </Route>
            <Route path="/tagModelList">
              <TagModelList />
            </Route>
            <Route path="/tagModel/view/:id">
              <TagModel />
            </Route>
            <Route path="/tagModel/edit/:id">
              <TagModel />
            </Route>
            <Route path="/tagModel/new">
              <TagModel />
            </Route>
            <Route path="/tagList">
              <TagList />
            </Route>
            <Route path="/tag/view/:id">
              <Tag />
            </Route>
            <Route path="/tag/edit/:id">
              <Tag />
            </Route>
            <Route path="/tag/new">
              <Tag />
            </Route>
            <Route path="/personList">
              <PersonList />
            </Route>
            <Route path="/person/view/:id">
              <Person />
            </Route>
            <Route path="/person/edit/:id">
              <Person />
            </Route>
            <Route path="/person/new">
              <Person />
            </Route>
            <Route path="/buildingList">
              <BuildingList />
            </Route>
            <Route path="/building/view/:id">
              <Building />
            </Route>
            <Route path="/building/edit/:id">
              <Building />
            </Route>
            <Route path="/building/new">
              <Building />
            </Route>
            <Route path="/customerList">
              <CustomerList />
            </Route>
            <Route path="/customer/view/:id">
              <Customer />
            </Route>
            <Route path="/customer/edit/:id">
              <Customer />
            </Route>
            <Route path="/customer/new">
              <Customer />
            </Route>
            <Route path="/areaList">
              <AreaList />
            </Route>
            <Route path="/area/view/:id">
              <Area />
            </Route>
            <Route path="/area/edit/:id">
              <Area />
            </Route>
            <Route path="/area/new">
              <Area />
            </Route>
            <Route path="/resourceUnitList">
              <ResourceUnitList />
            </Route>
            <Route path="/resourceUnit/view/:id">
              <ResourceUnit />
            </Route>
            <Route path="/resourceUnit/edit/:id">
              <ResourceUnit />
            </Route>
            <Route path="/resourceUnit/new">
              <ResourceUnit />
            </Route>
            <Route path="/resourceList">
              <ResourceList />
            </Route>
            <Route path="/resource/view/:id">
              <Resource />
            </Route>
            <Route path="/resource/edit/:id">
              <Resource />
            </Route>
            <Route path="/resource/new">
              <Resource />
            </Route>
            <Route path="/periodList">
              <PeriodList />
            </Route>
            <Route path="/period/view/:id">
              <Period />
            </Route>
            <Route path="/period/edit/:id">
              <Period />
            </Route>
            <Route path="/period/new">
              <Period />
            </Route>
            <Route path="/servicePackageList">
              <ServicePackageList />
            </Route>
            <Route path="/servicePackage/view/:id">
              <ServicePackage />
            </Route>
            <Route path="/servicePackage/edit/:id">
              <ServicePackage />
            </Route>
            <Route path="/servicePackage/new">
              <ServicePackage />
            </Route>
          </Switch>
        </Layout>
      </Navigation>
    </BrowserRouter>
  </ThemeProvider>
);
