import { GridColumns } from "@material-ui/data-grid";
import { RoomType } from "../../../../api/RoomType/RoomTypeInterface";

function getBedroom(params: any) {
  const roomType: RoomType = params.row;
  return roomType.bedroom ? "True" : "False";
}

export const roomTypeListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "type",
    headerName: "Room Type",
    flex: 1,
  },
  {
    field: "tier",
    headerName: "Tier",
    type: "number",
    flex: 1,
  },
  {
    field: "maxOccupancy",
    headerName: "Max Occupancy",
    type: "number",
    flex: 1,
  },
  {
    field: "bedroom",
    headerName: "Is Bedroom?",
    valueGetter: getBedroom,
    flex: 1,
  },
];
