import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect } from "react";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import Heading from "../../../Form/Heading/Heading";
import SubmitButton from "../../../Form/SubmitButton/SubmitButton";
import { PageType } from "../../types";
import PersonAreaDialogForm from "../PersonArea/PersonAreaDialogForm";
import PersonAreaTable from "../PersonArea/PersonAreaTable";
import PersonRoomDialogForm from "../PersonRoom/PersonRoomDialogForm";
import PersonRoomTable from "../PersonRoom/PersonRoomTable";
import PersonServiceAllocationTable from "../PersonServiceAllocation/PersonServiceAllocationTable";
import PersonServicePackageDialogForm from "../PersonServicePackage/PersonServicePackageDialogForm";
import PersonServicePackageTable from "../PersonServicePackage/PersonServicePackageTable";
import PersonForm from "./PersonForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subHeading: {
      marginTop: 25,
    },
  })
);

const PersonPage: FC = () => {
  const router = useRouter();
  const {
    personStore,
    tagStore,
    servicePackageStore,
    personServicePackageStore,
    personServiceAllocationStore,
    personTypeStore,
    personStatusStore,
    areaStore,
    roomStore,
    personRoomStore,
    personAreaStore,
    userInterfaceStore,
  } = useRootStore();

  const classes = useStyles();

  useEffect(() => {
    autorun(async () => {
      await tagStore.getItemListAsync();
      await personTypeStore.getItemListAsync();
      await personStatusStore.getItemListAsync();
      await servicePackageStore.getItemListAsync();
      await areaStore.getItemListAsync();
      await roomStore.getItemListAsync();
      switch (userInterfaceStore.pageType) {
        case PageType.View:
        case PageType.Existing:
          await personStore.getItemAsync(Number(router.query.id));
          await personServicePackageStore.getItemListByForeignKeyAsync(
            Number(personStore.item.id),
            "person"
          );
          await personServiceAllocationStore.getItemListByForeignKeyAsync(
            Number(personStore.item.id),
            "person"
          );
          await personAreaStore.getItemListByForeignKeyAsync(
            Number(personStore.item.id),
            "person"
          );
          await personRoomStore.getItemListByForeignKeyAsync(
            Number(personStore.item.id),
            "person"
          );
          break;
        case PageType.New:
          personStore.resetItem();
          personServicePackageStore.resetItemList();
          personServiceAllocationStore.resetItemList();
          personAreaStore.resetItemList();
          personRoomStore.resetItemList();
          break;
      }
    });
  }, [
    userInterfaceStore.pageType,
    personStore,
    router.query.id,
    personStatusStore,
    personTypeStore,
    tagStore,
    servicePackageStore,
    personServicePackageStore,
    personServiceAllocationStore,
    areaStore,
    roomStore,
    personAreaStore,
    personRoomStore,
  ]);

  const formId = "personForm";
  const personServicePackageFormId = "personServicePackageForm";
  const personAreaFormId = "personAreaFormId";
  const personRoomFormId = "personRoomFormId";

  return (
    <Fragment>
      <Heading
        pageType={userInterfaceStore.pageType!}
        heading={personStore.singularDisplayName}
      />
      <PersonForm id={formId} />
      <Typography className={classes.subHeading} variant="h5" component="h5">
        Service Package
      </Typography>
      <PersonServicePackageDialogForm id={personServicePackageFormId} />
      <PersonServicePackageTable />
      <Typography className={classes.subHeading} variant="h5" component="h5">
        Service Allocations
      </Typography>
      <PersonServiceAllocationTable />
      <Typography className={classes.subHeading} variant="h5" component="h5">
        Areas
      </Typography>
      <PersonAreaDialogForm id={personAreaFormId} />
      <PersonAreaTable />
      <Typography className={classes.subHeading} variant="h5" component="h5">
        Rooms
      </Typography>
      <PersonRoomDialogForm id={personRoomFormId} />
      <PersonRoomTable />
      {userInterfaceStore.pageType !== PageType.View && (
        <SubmitButton form={formId} pageType={userInterfaceStore.pageType!} />
      )}
    </Fragment>
  );
};

export default observer(PersonPage);
