import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { DeviceSpecificationActuatorSpecification } from "../../../../api/DeviceSpecificationActuatorSpecification/DeviceSpecificationActuatorSpecificationInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import NumberInputField from "../../../Form/NumberInputField/NumberInputField";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import { FormProps } from "../../../Form/types";
import { ActionType } from "../../types";
import { generateSchema } from "./DeviceSpecificationActuatorSpecificationValidation";

const DeviceSpecificationActuatorSpecificationDialogForm: FC<FormProps> = ({
  id,
}: FormProps) => {
  const {
    deviceSpecificationActuatorSpecificationStore,
    actuatorSpecificationStore,
  } = useRootStore();

  const handleClose = () => {
    deviceSpecificationActuatorSpecificationStore.setDialogOpen(false);
  };

  const handleDelete = () => {
    deviceSpecificationActuatorSpecificationStore.handleDelete();
    handleClose();
  };

  const onSubmit = (values: DeviceSpecificationActuatorSpecification) => {
    const actuatorSpecification = actuatorSpecificationStore.getItemFromList(
      values.actuatorSpecification.id
    );
    switch (deviceSpecificationActuatorSpecificationStore.actionType) {
      case ActionType.Edit:
        deviceSpecificationActuatorSpecificationStore.handleEdit(
          values,
          actuatorSpecification
        );
        break;
      case ActionType.New:
        deviceSpecificationActuatorSpecificationStore.handleInsert(
          values,
          actuatorSpecification
        );
        break;
    }
    handleClose();
  };

  const deviceSpecificationActuatorSchema = useMemo(
    () =>
      generateSchema(
        deviceSpecificationActuatorSpecificationStore.itemList,
        deviceSpecificationActuatorSpecificationStore.item
      ),
    [
      deviceSpecificationActuatorSpecificationStore.itemList,
      deviceSpecificationActuatorSpecificationStore.item,
    ]
  );

  const resolver = useYupValidationResolver(deviceSpecificationActuatorSchema);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: deviceSpecificationActuatorSpecificationStore.defaultItem,
    resolver,
  });

  useEffect(() => {
    autorun(async () => {
      reset(deviceSpecificationActuatorSpecificationStore.item);
    });
  }, [reset, deviceSpecificationActuatorSpecificationStore.item]);

  let actuatorOptions = null;
  if (actuatorSpecificationStore.itemList) {
    const actuatorSpecifications = actuatorSpecificationStore.itemList;
    actuatorOptions = actuatorSpecifications.map((actuatorSpecification) => {
      return {
        id: actuatorSpecification.id,
        value: `${actuatorSpecification.manufacturer}-${actuatorSpecification.type}-${actuatorSpecification.model}`,
      };
    });
  }

  return (
    <Dialog
      open={deviceSpecificationActuatorSpecificationStore.dialogOpen}
      onClose={handleClose}
      aria-labelledby="actuator-specification-form-dialog-title"
    >
      <DialogTitle id="actuator-specification-form-dialog-title">
        {deviceSpecificationActuatorSpecificationStore.actionType ===
          ActionType.Edit && "Modify "}
        {deviceSpecificationActuatorSpecificationStore.actionType ===
          ActionType.New && "Add a new "}
        Actuator
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {deviceSpecificationActuatorSpecificationStore.actionType ===
            ActionType.Edit && "Modify existing actuator "}
          {deviceSpecificationActuatorSpecificationStore.actionType ===
            ActionType.New && "Add a new actuator "}
          for the device specification
        </DialogContentText>
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
          <SelectInputField
            name="actuatorSpecification.id"
            options={actuatorOptions}
            displayName="Actuator"
            errors={errors}
            control={control}
          />
          <NumberInputField
            name="quantity"
            displayName="Quantity"
            errors={errors}
            control={control}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {deviceSpecificationActuatorSpecificationStore.actionType ===
          ActionType.Edit && (
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        )}
        <Button form={id} type="submit" color="primary">
          {deviceSpecificationActuatorSpecificationStore.actionType ===
            ActionType.Edit && "Update"}
          {deviceSpecificationActuatorSpecificationStore.actionType ===
            ActionType.New && "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(DeviceSpecificationActuatorSpecificationDialogForm);
