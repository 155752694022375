import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  ApiArrayResponseSuccess,
  ApiResponseFailure,
} from "../types/apiResponse";
import { axiosGetByForeignKeyIdRequest } from "../util/axiosRequest";
import {
  RoomDeviceFunctionInstanceDevice,
  RoomDeviceFunctionInstanceDeviceInput,
} from "./RoomDeviceFunctionInstanceDeviceInterface";
export class RoomDeviceFunctionInstanceDeviceApi extends AbstractApi<
  RoomDeviceFunctionInstanceDevice,
  RoomDeviceFunctionInstanceDeviceInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "roomDeviceFunctionInstanceDevice");
  }

  public async getByForeignKeyId(
    id: number
  ): Promise<
    | ApiArrayResponseSuccess<RoomDeviceFunctionInstanceDevice>
    | ApiResponseFailure
  > {
    return axiosGetByForeignKeyIdRequest(
      this.baseUrl,
      this.endpoint,
      "room",
      id
    );
  }
}
