import { number, object, string } from "yup";
import { DeviceConfigLevel } from "../../../../api/DeviceConfig/DeviceConfigInterface";

export function generateSchema() {
  const schema = object().shape({
    type: string().required(),
    level: string().required(),
    deviceId: number().test(
      "checkIsPresentIfRequired",
      "Please select a device",
      (value: unknown, context): boolean => {
        if (
          context.parent.level === DeviceConfigLevel.DEVICE &&
          typeof value !== "number"
        ) {
          return false;
        }
        return true;
      }
    ),
    deviceRoleId: number().test(
      "checkIsPresentIfRequired",
      "Please select a device role",
      (value: unknown, context): boolean => {
        if (
          context.parent.level === DeviceConfigLevel.DEVICE_ROLE &&
          typeof value !== "number"
        ) {
          return false;
        }
        return true;
      }
    ),
    deviceSpecificationId: number().test(
      "checkIsPresentIfRequired",
      "Please select a device specification",
      (value: unknown, context): boolean => {
        if (
          context.parent.level === DeviceConfigLevel.DEVICE_SPECIFICATION &&
          typeof value !== "number"
        ) {
          return false;
        }
        return true;
      }
    ),
    command: string().required(),
  });
  return schema;
}
