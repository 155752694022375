import { AbstractAPI as AbstractApi } from "../abstractApi";
import { DeviceMode, DeviceRoleModeInput } from "./DeviceModeInterface";

export class DeviceRoleModeApi extends AbstractApi<
  DeviceMode,
  DeviceRoleModeInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "deviceRoleMode");
  }
}
