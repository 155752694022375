import { action, makeObservable, observable, runInAction } from "mobx";
import { ActuatorFunctionInstanceChannelApi } from "../../api/ActuatorFunctionInstanceChannel/ActuatorFunctionInstanceChannel";
import {
  ActuatorFunctionInstanceChannel,
  ActuatorFunctionInstanceChannelInput,
} from "../../api/ActuatorFunctionInstanceChannel/ActuatorFunctionInstanceChannelInterface";
import { deviceFunctionInstanceApi } from "../../api/api";
import { DeviceFunction } from "../../api/DeviceFunction/DeviceFunctionInterface";
import {
  DeviceFunctionInstance,
  DeviceFunctionInstanceInput,
} from "../../api/DeviceFunctionInstance/DeviceFunctionInstanceInterface";
import { SensorFunctionInstanceChannelApi } from "../../api/SensorFunctionInstanceChannel/SensorFunctionInstanceChannel";
import {
  SensorFunctionInstanceChannel,
  SensorFunctionInstanceChannelInput,
} from "../../api/SensorFunctionInstanceChannel/SensorFunctionInstanceChannelInterface";
import { ActionType } from "../../components/pages/types";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDeviceFunctionInstance } from "./DefaultDeviceFunctionInstance";

export class DeviceFunctionInstanceStore extends MobXStore<
  DeviceFunctionInstance,
  DeviceFunctionInstanceInput
> {
  localIdCounter: number = 0;
  actionType?: ActionType = undefined;
  dialogOpen: boolean = false;
  sensorFunctionInstanceChannelApi: SensorFunctionInstanceChannelApi;
  actuatorFunctionInstanceChannelApi: ActuatorFunctionInstanceChannelApi;

  constructor(
    root: RootStore,
    sensorFunctionInstanceChannelApi: SensorFunctionInstanceChannelApi,
    actuatorFunctionInstanceChannelApi: ActuatorFunctionInstanceChannelApi
  ) {
    super(
      root,
      deviceFunctionInstanceApi,
      "deviceFunctionInstance",
      "Device Function Instance",
      "Device Function Instances",
      defaultDeviceFunctionInstance
    );
    makeObservable(this, { dialogOpen: observable, setDialogOpen: action });
    this.sensorFunctionInstanceChannelApi = sensorFunctionInstanceChannelApi;
    this.actuatorFunctionInstanceChannelApi =
      actuatorFunctionInstanceChannelApi;
  }

  incrementLocalIdCounter() {
    this.localIdCounter++;
  }

  setActionType(actionType: ActionType) {
    this.actionType = actionType;
  }

  setDialogOpen(dialogOpen: boolean) {
    this.dialogOpen = dialogOpen;
  }

  setItemFromList(localId: number) {
    const item = this.itemList.find((element) => localId === element.localId);
    if (item === undefined) {
      return;
    }
    this.setItem(item);
  }

  async getItemListAsync(): Promise<void> {
    const result = await this.api.getAll();
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: DeviceFunctionInstance[] = [];
    if (result.data.length) {
      enrichedResults = result.data.map((item) => {
        item.localId = this.localIdCounter;
        this.incrementLocalIdCounter();
        return item;
      });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
  }

  async getItemListByForeignKeyAsync(
    id: number,
    foreignKey: string
  ): Promise<void> {
    const result = await this.api.getByForeignKeyId!(id, foreignKey);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let promises: Promise<DeviceFunctionInstance>[];
    if (result.data.length) {
      promises = result.data.map(async (item) => {
        const sensors =
          await this.sensorFunctionInstanceChannelApi.getByForeignKeyId(
            item.id
          );
        if ("error" in sensors) {
          return item;
        }
        if (sensors.data.length) {
          item.sensors = sensors.data.map((sensor) => {
            return {
              channel: sensor.channel,
            };
          });
        }
        const actuators =
          await this.actuatorFunctionInstanceChannelApi.getByForeignKeyId(
            item.id
          );
        if ("error" in actuators) {
          return item;
        }
        if (actuators.data.length) {
          item.actuators = actuators.data.map((actuator) => {
            return {
              channel: actuator.channel,
            };
          });
        }
        item.localId = this.localIdCounter;
        this.incrementLocalIdCounter();
        return item;
      });
      const enrichedResults = await Promise.all(promises);
      this.resetApiResponse();
      this.setItemList(enrichedResults);
    }
  }

  handleChangeDeviceSpecification() {
    this.itemList.forEach((item, index) => {
      switch (item.action) {
        case undefined:
        case ActionType.Edit:
          runInAction(() => {
            this.itemList[index].action = ActionType.Delete;
          });
          break;
        case ActionType.New:
          runInAction(() => {
            this.itemList.splice(index, 1);
          });
          break;
      }
    });
  }

  handleInitialSetup() {
    this.resetItem();
    runInAction(() => {
      this.item.localId = this.localIdCounter;
    });
    this.incrementLocalIdCounter();
  }

  handleInsert(
    deviceFunctionInstance: DeviceFunctionInstance,
    deviceFunction: DeviceFunction
  ) {
    console.log(deviceFunctionInstance);
    deviceFunctionInstance.deviceFunction = deviceFunction;
    deviceFunctionInstance.localId = this.item.localId;
    deviceFunctionInstance.action = ActionType.New;
    let newItemList = [deviceFunctionInstance];
    if (this.itemList.length) {
      newItemList = [...this.itemList, deviceFunctionInstance];
    }
    this.setItemList(newItemList);
    this.setItem(deviceFunctionInstance);
  }

  handleEdit(
    deviceFunctionInstance: DeviceFunctionInstance,
    deviceFunction: DeviceFunction
  ) {
    const newItemList = this.itemList.map((item) => {
      if (deviceFunctionInstance.localId !== item.localId) {
        return item;
      }

      deviceFunctionInstance.deviceFunction = deviceFunction;
      if (deviceFunctionInstance.action !== ActionType.New) {
        deviceFunctionInstance.action = ActionType.Edit;
      }
      return deviceFunctionInstance;
    });
    this.setItemList(newItemList);
    this.setItem(deviceFunctionInstance);
  }

  handleDelete() {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach((itemInList, index) => {
      if (this.item.localId !== itemInList.localId) {
        return;
      }
      switch (itemInList.action) {
        case undefined:
        case ActionType.Edit:
          runInAction(() => {
            this.itemList[index].action = ActionType.Delete;
          });
          break;
        case ActionType.New:
          runInAction(() => {
            this.itemList.splice(index, 1);
          });
          break;
      }
    });
    this.resetItem();
  }

  async handleUpdateDeviceRole(
    deviceRoleId: number,
    deviceFunctionInstanceSensorMap: Map<
      number,
      SensorFunctionInstanceChannel[]
    >,
    deviceFunctionInstanceActuatorMap: Map<
      number,
      ActuatorFunctionInstanceChannel[]
    >
  ) {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach(async (item) => {
      const deviceFunctionInstanceInput: DeviceFunctionInstanceInput = {
        id: item.id,
        deviceFunctionId: item.deviceFunction.id,
        deviceRoleId: deviceRoleId,
        name: item.name,
        versionNumber: 0,
      };
      switch (item.action) {
        case ActionType.New: {
          let result = await deviceFunctionInstanceApi.insert(
            deviceFunctionInstanceInput
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          const deviceFunctionInstanceId = result.data.id;
          runInAction(() => {
            item.action = undefined;
          });
          if (deviceFunctionInstanceSensorMap.get(item.localId!)?.length) {
            deviceFunctionInstanceSensorMap
              .get(item.localId!)
              ?.forEach(async (sfic) => {
                const sficInput: SensorFunctionInstanceChannelInput = {
                  id: 0,
                  deviceFunctionInstanceId,
                  sensorFunctionLineId: sfic.sensorFunctionLine.id,
                  channel: sfic.channel,
                  versionNumber: 0,
                };
                let result = await this.sensorFunctionInstanceChannelApi.insert(
                  sficInput
                );
              });
          }
          if (deviceFunctionInstanceActuatorMap.get(item.localId!)?.length) {
            deviceFunctionInstanceActuatorMap
              .get(item.localId!)
              ?.forEach(async (afic) => {
                const aficInput: ActuatorFunctionInstanceChannelInput = {
                  id: 0,
                  deviceFunctionInstanceId,
                  actuatorFunctionLineId: afic.actuatorFunctionLine.id,
                  channel: afic.channel,
                  versionNumber: 0,
                };
                let result =
                  await this.actuatorFunctionInstanceChannelApi.insert(
                    aficInput
                  );
              });
          }
          break;
        }
        case ActionType.Edit: {
          let result = await deviceFunctionInstanceApi.update(
            deviceFunctionInstanceInput
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          if (deviceFunctionInstanceSensorMap.get(item.localId!)?.length) {
            deviceFunctionInstanceSensorMap
              .get(item.localId!)
              ?.forEach(async (sfic) => {
                const sficInput: SensorFunctionInstanceChannelInput = {
                  id: sfic.id,
                  deviceFunctionInstanceId: deviceFunctionInstanceInput.id,
                  sensorFunctionLineId: sfic.sensorFunctionLine.id,
                  channel: sfic.channel,
                  versionNumber: 0,
                };
                switch (sfic.action) {
                  case ActionType.Edit:
                    let result =
                      await this.sensorFunctionInstanceChannelApi.update(
                        sficInput
                      );
                    break;
                  case ActionType.Delete:
                    result = await this.sensorFunctionInstanceChannelApi.delete(
                      sficInput.id
                    );
                    break;
                  case ActionType.New:
                    result = await this.sensorFunctionInstanceChannelApi.insert(
                      sficInput
                    );
                    break;
                }
              });
          }
          if (deviceFunctionInstanceActuatorMap.get(item.localId!)?.length) {
            deviceFunctionInstanceActuatorMap
              .get(item.localId!)
              ?.forEach(async (afic) => {
                const aficInput: ActuatorFunctionInstanceChannelInput = {
                  id: afic.id,
                  deviceFunctionInstanceId: deviceFunctionInstanceInput.id,
                  actuatorFunctionLineId: afic.actuatorFunctionLine.id,
                  channel: afic.channel,
                  versionNumber: 0,
                };
                switch (afic.action) {
                  case ActionType.Edit:
                    let result =
                      await this.actuatorFunctionInstanceChannelApi.update(
                        aficInput
                      );
                    break;
                  case ActionType.Delete:
                    result =
                      await this.actuatorFunctionInstanceChannelApi.delete(
                        aficInput.id
                      );
                    break;
                  case ActionType.New:
                    result =
                      await this.actuatorFunctionInstanceChannelApi.insert(
                        aficInput
                      );
                    break;
                }
              });
          }
          break;
        }
        case ActionType.Delete: {
          const sficsToDelete =
            await this.sensorFunctionInstanceChannelApi.getByForeignKeyId(
              deviceFunctionInstanceInput.id
            );
          if ("error" in sficsToDelete) {
            this.setAndShowMessage(JSON.stringify(sficsToDelete.error));
            this.setMessageSeverity("error");
            return;
          }
          if (sficsToDelete.data.length) {
            sficsToDelete.data.forEach(async (sfic) => {
              await this.sensorFunctionInstanceChannelApi.delete(sfic.id);
            });
          }
          const aficsToDelete =
            await this.actuatorFunctionInstanceChannelApi.getByForeignKeyId(
              deviceFunctionInstanceInput.id
            );
          if ("error" in aficsToDelete) {
            this.setAndShowMessage(JSON.stringify(aficsToDelete.error));
            this.setMessageSeverity("error");
            return;
          }
          if (aficsToDelete.data.length) {
            aficsToDelete.data.forEach(async (afic) => {
              await this.actuatorFunctionInstanceChannelApi.delete(afic.id);
            });
          }
          let result = await deviceFunctionInstanceApi.delete(
            deviceFunctionInstanceInput.id
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            runInAction(() => {
              item.action = undefined;
            });
            break;
          }
        }
      }
    });
  }
}
