import { Period, PeriodInput } from "../../api/Period/PeriodInterface";
import { periodApi } from "../../api/api";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultPeriod } from "./DefaultPeriod";

export class PeriodStore extends MobXStore<Period, PeriodInput> {
  constructor(root: RootStore) {
    super(root, periodApi, "period", "Period", "Periods", defaultPeriod);
  }
}
