import { SensorFunctionInstanceChannel } from "../../api/SensorFunctionInstanceChannel/SensorFunctionInstanceChannelInterface";
import { defaultDeviceFunctionInstance } from "../DeviceFunctionInstance/DefaultDeviceFunctionInstance";
import { defaultSensorFunctionLine } from "../SensorFunctionLine/DefaultSensorFunctionLine";
export const defaultSensorFunctionInstanceChannel: SensorFunctionInstanceChannel =
  {
    id: 0,
    localId: 0,
    action: undefined,
    deviceFunctionInstance: defaultDeviceFunctionInstance,
    sensorFunctionLine: defaultSensorFunctionLine,
    channel: 0,
    versionNumber: 0,
  };
