import {
  ApiResponseFailure,
  ApiArrayResponseSuccess,
  ApiObjectResponseSuccess,
} from "./types/apiResponse";
import { Repository } from "./types/repository";
import {
  axiosDeleteRequest,
  axiosGetAllRequest,
  axiosGetOneRequest,
  axiosInsertRequest,
  axiosUpdateRequest,
} from "./util/axiosRequest";

// outputType represents the 'full' type with expanded foreign key data
// inputType is the input type I.E. the fields which are required to create / update a record
export abstract class AbstractAPI<
  outputType extends { id: number },
  inputType extends { id?: number }
> implements Repository<outputType, inputType>
{
  protected readonly baseUrl: string;
  protected readonly endpoint: string;

  constructor(baseUrl: string, endpoint: string) {
    this.baseUrl = baseUrl;
    this.endpoint = endpoint;
  }
  async getAll(): Promise<
    ApiArrayResponseSuccess<outputType> | ApiResponseFailure
  > {
    return axiosGetAllRequest(this.baseUrl, this.endpoint);
  }
  async getOne(
    id: number
  ): Promise<ApiObjectResponseSuccess<outputType> | ApiResponseFailure> {
    return axiosGetOneRequest(this.baseUrl, this.endpoint, id);
  }
  async update(
    record: inputType
  ): Promise<ApiObjectResponseSuccess<outputType> | ApiResponseFailure> {
    return axiosUpdateRequest<outputType, inputType>(
      this.baseUrl,
      this.endpoint,
      record
    );
  }
  async insert(
    record: inputType
  ): Promise<ApiObjectResponseSuccess<outputType> | ApiResponseFailure> {
    return axiosInsertRequest<outputType, inputType>(
      this.baseUrl,
      this.endpoint,
      record
    );
  }
  async delete(
    id: number
  ): Promise<ApiObjectResponseSuccess<outputType> | ApiResponseFailure> {
    return axiosDeleteRequest(this.baseUrl, this.endpoint, id);
  }
}
