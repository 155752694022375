import axios, { AxiosResponse } from "axios";

export class AccessTokenApi {
  private readonly baseUrl: string;
  private readonly endpoint: string;
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.endpoint = "accessToken";
  }

  public async generateAccessToken(): Promise<string> {
    return await axios
      .get(`${this.baseUrl}/${this.endpoint}/generate`)
      .then((response: AxiosResponse) => {
        return response.data;
      });
  }
}
