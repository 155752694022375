import { DeviceFunctionInstanceDevice } from "../../api/DeviceFunctionInstanceDevice/DeviceFunctionInstanceDeviceInterface";
import { defaultDevice } from "../Device/DefaultDevice";
import { defaultDeviceFunctionInstance } from "../DeviceFunctionInstance/DefaultDeviceFunctionInstance";

export const defaultDeviceFunctionInstanceDevice: DeviceFunctionInstanceDevice =
  {
    id: 0,
    localId: 0,
    action: undefined,
    device: defaultDevice,
    deviceFunctionInstance: defaultDeviceFunctionInstance,
    roomDeviceFunctionInstanceDeviceList: [],
    areaDeviceFunctionInstanceDeviceList: [],
    isInUse: true,
  };
