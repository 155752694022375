import { roomApi } from "../../api/api";
import { Room, RoomInput } from "../../api/Room/RoomInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultRoom } from "./DefaultRoom";

export class RoomStore extends MobXStore<Room, RoomInput> {
  constructor(root: RootStore) {
    super(root, roomApi, "room", "Room", "Rooms", defaultRoom);
  }
}
