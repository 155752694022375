export enum DeviceConfigType {
  MODE = "MODE",
  RULE = "RULE",
  SENSE = "SENSE",
}

export enum DeviceConfigLevel {
  DEVICE = "DEVICE",
  DEVICE_ROLE = "DEVICE_ROLE",
  DEVICE_SPECIFICATION = "DEVICE_SPECIFICATION",
}

export interface DeviceConfigRecord {
  id: string;
  type: DeviceConfigType;
  level: DeviceConfigLevel;
  value: number;
  formattedValue: string;
  record: string;
}

export interface DeviceConfigSchema {
  type: DeviceConfigType;
  level: DeviceConfigLevel;
  deviceId?: number;
  deviceRoleId?: number;
  deviceSpecificationId?: number;
  command: string;
}

export interface DeviceConfigInput {
  id?: number;
  type: DeviceConfigType;
  level: DeviceConfigLevel;
  deviceId?: number;
  deviceRoleId?: number;
  deviceSpecificationId?: number;
  mode?: string;
  sense?: string;
  rule?: string;
}
