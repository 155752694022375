import { makeAutoObservable } from "mobx";
import { TbAssetApi } from "../../api/TbAsset/TbAsset";
import {
  TbAsset,
  TbCreateAssetRequestBody,
  TbUpdateAssetRequestBody,
} from "../../api/TbAsset/TbAssetInterface";
import { RootStore } from "../Root/RootStore";

export class TbAssetStore {
  public root: RootStore;
  public api: TbAssetApi;
  public defaultItem: TbAsset;
  public item: TbAsset;
  public itemList: TbAsset[];

  constructor(root: RootStore, api: TbAssetApi, defaultItem: TbAsset) {
    this.root = root;
    this.api = api;
    this.defaultItem = defaultItem;
    this.item = defaultItem;
    this.itemList = [defaultItem];
    makeAutoObservable(this);
  }

  setItem(item: TbAsset) {
    this.item = item;
  }
  resetItem() {
    this.item = this.defaultItem;
  }
  async getItemListAsync(customerId: string, assetType: string): Promise<void> {
    const result = await this.api.getAssetsByCustomer(customerId, assetType);
    this.itemList = result;
  }

  async insertItemAsync(
    item: TbCreateAssetRequestBody | TbUpdateAssetRequestBody
  ): Promise<void> {
    const result = await this.api.insert(item);
    this.setItem(result);
  }
}
