import { Building } from "../Building/BuildingInterface";
import { RoomStatus } from "../RoomStatus/RoomStatusInterface";
import { RoomType } from "../RoomType/RoomTypeInterface";

export interface RoomSchema {
  name?: string;
  building: {
    id: number;
  };
  roomType: {
    id: number;
  };
  roomStatus: {
    id: number;
  };
  floor: number;
  number: number;
  loft: boolean;
  roomUuid?: string;
  paraplegic: boolean;
}

export interface Room {
  id: number;
  name?: string;
  building: Building;
  roomType: RoomType;
  systemRoomStatus: SystemRoomStatus;
  roomStatus: RoomStatus;
  roomUuid: string;
  floor: number;
  number: number;
  loft: boolean;
  paraplegic: boolean;
}
export type RoomInput = {
  id?: number;
  name?: string;
  roomTypeId: number;
  buildingId?: number;
  roomStatusId: number;
  floor: number;
  number: number;
  loft: boolean;
  paraplegic: boolean;
  roomUuid: string;
};

export enum SystemRoomStatus {
  AVAILABLE = "AVL",
  ALLOCATED = "ALC",
  PART = "PART",
  ERROR = "ERR",
}
