import { Typography } from "@material-ui/core";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect } from "react";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import Heading from "../../../Form/Heading/Heading";
import SubmitButton from "../../../Form/SubmitButton/SubmitButton";
import { PageType } from "../../types";
import DeviceFunctionInstanceDialogForm from "../DeviceFunctionInstance/DeviceFunctionInstanceDialogForm";
import DeviceFunctionInstanceTable from "../DeviceFunctionInstance/DeviceFunctionInstanceTable";
import DeviceRoleDatabaseDefinitionDialogForm from "../DeviceRoleDatabaseDefinition/DeviceRoleDatabaseDefinitionDialogForm";
import DeviceRoleDatabaseDefinitionTable from "../DeviceRoleDatabaseDefinition/DeviceRoleDatabaseDefinitionTable";
import DeviceSpecificationActuatorSpecification from "../DeviceSpecificationActuatorSpecification/DeviceSpecificationActuatorSpecification";
import DeviceSpecificationSensorSpecification from "../DeviceSpecificationSensorSpecification/DeviceSpecificationSensorSpecification";
import DeviceRoleForm from "./DeviceRoleForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subHeading: {
      marginTop: 25,
    },
  })
);

const DeviceRolePage: FC = () => {
  const {
    deviceRoleStore,
    deviceSpecificationStore,
    deviceSpecificationActuatorSpecificationStore,
    deviceSpecificationSensorSpecificationStore,
    deviceFunctionInstanceStore,
    deviceDatabaseColumnNameStore,
    deviceDatabaseColumnTypeStore,
    resourceStore,
    deviceRoleDatabaseDefinitionStore,
    deviceFunctionStore,
    sensorFunctionInstanceChannelStore,
    actuatorFunctionInstanceChannelStore,
    userInterfaceStore,
  } = useRootStore();
  const router = useRouter();

  const classes = useStyles();

  useEffect(() => {
    autorun(async () => {
      await deviceSpecificationStore.getItemListAsync();
      await deviceFunctionStore.getItemListAsync();
      await deviceDatabaseColumnNameStore.getItemListAsync();
      await deviceDatabaseColumnTypeStore.getItemListAsync();
      await resourceStore.getItemListAsync();
      switch (userInterfaceStore.pageType) {
        case PageType.View:
        case PageType.Existing:
          await deviceRoleStore.getItemAsync(Number(router.query.id));
          await deviceRoleDatabaseDefinitionStore.getItemListByForeignKeyAsync(
            Number(router.query.id),
            "deviceRole"
          );
          await deviceSpecificationSensorSpecificationStore.getItemListByForeignKeyAsync(
            Number(deviceRoleStore.item.deviceSpecification.id),
            "deviceSpecification"
          );
          await deviceSpecificationActuatorSpecificationStore.getItemListByForeignKeyAsync(
            Number(deviceRoleStore.item.deviceSpecification.id),
            "deviceSpecification"
          );
          await deviceFunctionInstanceStore.getItemListByForeignKeyAsync(
            Number(router.query.id),
            "deviceRole"
          );
          await sensorFunctionInstanceChannelStore.setDeviceFunctionInstanceMap(
            deviceFunctionInstanceStore.itemList
          );
          await actuatorFunctionInstanceChannelStore.setDeviceFunctionInstanceMap(
            deviceFunctionInstanceStore.itemList
          );
          break;
        case PageType.New:
          deviceRoleStore.resetItem();
          deviceFunctionInstanceStore.resetItemList();
          deviceRoleDatabaseDefinitionStore.resetItemList();
          deviceSpecificationActuatorSpecificationStore.resetItemList();
          deviceSpecificationSensorSpecificationStore.resetItemList();
          sensorFunctionInstanceChannelStore.resetItemList();
          sensorFunctionInstanceChannelStore.resetDeviceFunctionInstanceMap();
          actuatorFunctionInstanceChannelStore.resetItemList();
          actuatorFunctionInstanceChannelStore.resetDeviceFunctionInstanceMap();
          break;
        case PageType.Inserted:
      }
    });
  }, [
    userInterfaceStore.pageType,
    deviceRoleStore,
    router.query.id,
    deviceSpecificationStore,
    deviceFunctionInstanceStore,
    deviceFunctionStore,
    deviceSpecificationActuatorSpecificationStore,
    deviceSpecificationSensorSpecificationStore,
    sensorFunctionInstanceChannelStore,
    actuatorFunctionInstanceChannelStore,
    deviceRoleDatabaseDefinitionStore,
    deviceDatabaseColumnNameStore,
    deviceDatabaseColumnTypeStore,
  ]);

  const formId = "deviceRoleForm";
  const deviceFunctionInstanceFormId = "deviceFunctionInstanceForm";
  const deviceRoleDatabaseDefinitionFormId = "deviceRoleDatabaseDefinitionForm";

  return (
    <Fragment>
      <Heading
        pageType={userInterfaceStore.pageType!}
        heading={deviceRoleStore.singularDisplayName}
      />
      <DeviceRoleForm id={formId} />
      <Grid container spacing={10}>
        <DeviceSpecificationSensorSpecification />
        <DeviceSpecificationActuatorSpecification />
      </Grid>

      <DeviceFunctionInstanceDialogForm id={deviceFunctionInstanceFormId} />
      <DeviceFunctionInstanceTable />
      <Typography className={classes.subHeading} variant="h5" component="h5">
        Device Role Database Definition
      </Typography>
      <DeviceRoleDatabaseDefinitionDialogForm
        id={deviceRoleDatabaseDefinitionFormId}
      />
      <DeviceRoleDatabaseDefinitionTable />
      {userInterfaceStore.pageType !== PageType.View && (
        <SubmitButton form={formId} pageType={userInterfaceStore.pageType!} />
      )}
    </Fragment>
  );
};

export default observer(DeviceRolePage);
