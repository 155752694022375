import { action, makeObservable, observable, runInAction } from "mobx";
import { deviceFunctionInstanceDeviceApi } from "../../api/api";
import { DeviceFunctionInstanceApi } from "../../api/DeviceFunctionInstance/DeviceFunctionInstance";
import {
  DeviceFunctionInstanceDevice,
  DeviceFunctionInstanceDeviceInput,
} from "../../api/DeviceFunctionInstanceDevice/DeviceFunctionInstanceDeviceInterface";
import { ActionType } from "../../components/pages/types";
import { defaultDevice } from "../Device/DefaultDevice";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDeviceFunctionInstanceDevice } from "./DefaultDeviceFunctionInstanceDevice";

export class DeviceFunctionInstanceDeviceStore extends MobXStore<
  DeviceFunctionInstanceDevice,
  DeviceFunctionInstanceDeviceInput
> {
  localIdCounter: number = 0;
  actionType?: ActionType = undefined;
  dialogOpen: boolean = false;
  deviceFunctionInstanceApi: DeviceFunctionInstanceApi;

  constructor(
    root: RootStore,
    deviceFunctionInstanceApi: DeviceFunctionInstanceApi
  ) {
    super(
      root,
      deviceFunctionInstanceDeviceApi,
      "deviceFunctionInstanceDevice",
      "Device Function Instance Device",
      "Device Function Instance Devices",
      defaultDeviceFunctionInstanceDevice
    );
    makeObservable(this, { dialogOpen: observable, setDialogOpen: action });
    this.deviceFunctionInstanceApi = deviceFunctionInstanceApi;
  }

  incrementLocalIdCounter() {
    this.localIdCounter++;
  }

  setActionType(actionType: ActionType) {
    this.actionType = actionType;
  }

  setDialogOpen(dialogOpen: boolean) {
    this.dialogOpen = dialogOpen;
  }

  setItemFromList(id: number) {
    const item = this.itemList.find((element) => id === element.id);
    this.setItem(item!);
  }

  async getItemListByForeignKeyAsync(
    id: number,
    foreignKey: string
  ): Promise<void> {
    const result = await this.api.getByForeignKeyId!(id, foreignKey);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: DeviceFunctionInstanceDevice[] = [];
    if (result.data.length) {
      enrichedResults = result.data.map(
        (item: DeviceFunctionInstanceDevice) => {
          item.localId = this.localIdCounter;
          this.incrementLocalIdCounter();
          return item;
        }
      );
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
  }

  async handleChangeDeviceRole(deviceRoleId: number) {
    let newItemList: DeviceFunctionInstanceDevice[] = [];
    this.itemList.forEach((itemInList) => {
      switch (itemInList.action) {
        case undefined:
        case ActionType.Edit:
          runInAction(() => {
            itemInList.action = ActionType.Delete;
          });
          newItemList.push(itemInList);
          break;
        case ActionType.New:
          break;
      }
    });
    const result = await this.deviceFunctionInstanceApi.getByForeignKeyId(
      deviceRoleId
    );
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    if (result.data.length) {
      result.data.forEach((deviceFunctionInstance) => {
        const deviceFunctionInstanceDevice: DeviceFunctionInstanceDevice = {
          id: 0,
          localId: this.localIdCounter,
          action: ActionType.New,
          deviceFunctionInstance: deviceFunctionInstance,
          device: defaultDevice,
          roomDeviceFunctionInstanceDeviceList: [],
          areaDeviceFunctionInstanceDeviceList: [],
          isInUse: true,
        };
        this.incrementLocalIdCounter();
        newItemList.push(deviceFunctionInstanceDevice);
      });
    }
    this.setItemList(newItemList);
  }

  handleEdit(deviceFunctionInstanceDevice: DeviceFunctionInstanceDevice) {
    this.itemList.map((item) => {
      if (deviceFunctionInstanceDevice.localId !== item.localId) {
        return item;
      }
      if (deviceFunctionInstanceDevice.action !== ActionType.New) {
        runInAction(() => {
          item.action = ActionType.Edit;
        });
      }
      runInAction(() => {
        item.isInUse = deviceFunctionInstanceDevice.isInUse;
      });
      return item;
    });
  }

  handleDelete() {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach((itemInList, index) => {
      if (this.item.localId !== itemInList.localId) {
        return;
      }
      switch (itemInList.action) {
        case undefined:
        case ActionType.Edit:
          runInAction(() => {
            this.itemList[index].action = ActionType.Delete;
          });
          break;
        case ActionType.New:
          runInAction(() => {
            this.itemList.splice(index, 1);
          });
          break;
      }
    });
  }

  async handleUpdateDevice(deviceId: number) {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach(async (item) => {
      const deviceFunctionInstanceDeviceInput: DeviceFunctionInstanceDeviceInput =
        {
          id: item.id,
          deviceId: deviceId,
          deviceFunctionInstanceId: item.deviceFunctionInstance.id,
          isInUse: item.isInUse ? true : false,
        };
      switch (item.action) {
        case ActionType.New: {
          let result = await deviceFunctionInstanceDeviceApi.insert(
            deviceFunctionInstanceDeviceInput
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Edit: {
          let result = await deviceFunctionInstanceDeviceApi.update(
            deviceFunctionInstanceDeviceInput
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Delete: {
          let result = await deviceFunctionInstanceDeviceApi.delete(
            deviceFunctionInstanceDeviceInput.id
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            runInAction(() => {
              item.action = undefined;
            });
            break;
          }
        }
      }
    });
  }
}
