import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  ApiArrayResponseSuccess,
  ApiResponseFailure,
} from "../types/apiResponse";
import { axiosGetByForeignKeyIdRequest } from "../util/axiosRequest";
import {
  AreaDeviceFunctionInstanceDevice,
  AreaDeviceFunctionInstanceDeviceInput,
} from "./AreaDeviceFunctionInstanceDeviceInterface";
export class AreaDeviceFunctionInstanceDeviceApi extends AbstractApi<
  AreaDeviceFunctionInstanceDevice,
  AreaDeviceFunctionInstanceDeviceInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "areaDeviceFunctionInstanceDevice");
  }

  public async getByForeignKeyId(
    id: number
  ): Promise<
    | ApiArrayResponseSuccess<AreaDeviceFunctionInstanceDevice>
    | ApiResponseFailure
  > {
    return axiosGetByForeignKeyIdRequest(
      this.baseUrl,
      this.endpoint,
      "area",
      id
    );
  }
}
