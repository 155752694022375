import { action, makeObservable, observable, runInAction } from "mobx";
import { ActuatorSpecification } from "../../api/ActuatorSpecification/ActuatorSpecificationInterface";
import { deviceSpecificationActuatorSpecificationApi } from "../../api/api";
import {
  DeviceSpecificationActuatorSpecification,
  DeviceSpecificationActuatorSpecificationInput,
} from "../../api/DeviceSpecificationActuatorSpecification/DeviceSpecificationActuatorSpecificationInterface";
import { ActionType } from "../../components/pages/types";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDeviceSpecificationActuatorSpecification } from "./DefaultDeviceSpecificationActuatorSpecification";

export class DeviceSpecificationActuatorSpecificationStore extends MobXStore<
  DeviceSpecificationActuatorSpecification,
  DeviceSpecificationActuatorSpecificationInput
> {
  localIdCounter: number = 0;
  actionType?: ActionType = undefined;
  dialogOpen: boolean = false;

  constructor(root: RootStore) {
    super(
      root,
      deviceSpecificationActuatorSpecificationApi,
      "deviceSpecificationActuatorSpecification",
      "Device Specification Actuator Specification",
      "Device Specification Actuator Specifications",
      defaultDeviceSpecificationActuatorSpecification
    );
    makeObservable(this, { dialogOpen: observable, setDialogOpen: action });
  }

  incrementLocalIdCounter() {
    this.localIdCounter++;
  }

  setActionType(actionType: ActionType) {
    this.actionType = actionType;
  }

  setDialogOpen(dialogOpen: boolean) {
    this.dialogOpen = dialogOpen;
  }

  setItemFromList(id: number) {
    const item = this.itemList.find(
      (element) => id === element.actuatorSpecification.id
    );
    this.setItem(item!);
  }

  async getItemListAsync(): Promise<void> {
    const result = await this.api.getAll();
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: DeviceSpecificationActuatorSpecification[] = [];
    if (result.data.length) {
      enrichedResults = result.data.map((item) => {
        item.localId = this.localIdCounter;
        this.incrementLocalIdCounter();
        return item;
      });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
  }

  async getItemListByForeignKeyAsync(
    id: number,
    foreignKey: string
  ): Promise<void> {
    const result = await this.api.getByForeignKeyId!(id, foreignKey);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: DeviceSpecificationActuatorSpecification[] = [];
    if (result.data.length) {
      enrichedResults = result.data.map((item) => {
        item.localId = this.localIdCounter;
        this.incrementLocalIdCounter();
        return item;
      });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
  }

  handleInsert(
    deviceSpecificationActuatorSpecification: DeviceSpecificationActuatorSpecification,
    actuatorSpecification: ActuatorSpecification
  ) {
    deviceSpecificationActuatorSpecification.actuatorSpecification =
      actuatorSpecification;
    deviceSpecificationActuatorSpecification.localId = this.localIdCounter;
    this.incrementLocalIdCounter();
    deviceSpecificationActuatorSpecification.action = ActionType.New;
    let newItemList = [deviceSpecificationActuatorSpecification];
    if (this.itemList.length) {
      newItemList = [
        ...this.itemList,
        deviceSpecificationActuatorSpecification,
      ];
    }
    this.setItemList(newItemList);
  }

  handleEdit(
    deviceSpecificationActuatorSpecification: DeviceSpecificationActuatorSpecification,
    actuatorSpecification: ActuatorSpecification
  ) {
    const newItemList = this.itemList.map((item) => {
      if (deviceSpecificationActuatorSpecification.localId !== item.localId) {
        return item;
      }

      deviceSpecificationActuatorSpecification.actuatorSpecification =
        actuatorSpecification;
      if (deviceSpecificationActuatorSpecification.action !== ActionType.New) {
        deviceSpecificationActuatorSpecification.action = ActionType.Edit;
      }
      return deviceSpecificationActuatorSpecification;
    });
    this.setItemList(newItemList);
  }

  handleDelete() {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach((itemInList, index) => {
      if (this.item.localId !== itemInList.localId) {
        return;
      }
      switch (itemInList.action) {
        case undefined:
        case ActionType.Edit:
          runInAction(() => {
            this.itemList[index].action = ActionType.Delete;
          });
          break;
        case ActionType.New:
          runInAction(() => {
            this.itemList.splice(index, 1);
          });
          break;
      }
    });
  }

  async handleUpdateDeviceSpecification(deviceSpecificationId: number) {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach(async (item) => {
      const deviceSpecificationActuatorSpecificationInput: DeviceSpecificationActuatorSpecificationInput =
        {
          id: item.id,
          actuatorSpecificationId: item.actuatorSpecification.id,
          deviceSpecificationId: deviceSpecificationId,
          quantity: item.quantity,
        };
      switch (item.action) {
        case ActionType.New: {
          let result = await deviceSpecificationActuatorSpecificationApi.insert(
            deviceSpecificationActuatorSpecificationInput
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Edit: {
          let result = await deviceSpecificationActuatorSpecificationApi.update(
            deviceSpecificationActuatorSpecificationInput
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Delete: {
          let result = await deviceSpecificationActuatorSpecificationApi.delete(
            deviceSpecificationActuatorSpecificationInput.id
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            runInAction(() => {
              item.action = undefined;
            });
            break;
          }
          break;
        }
      }
    });
  }
}
