import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { DeviceConfigStore } from "../../state/DeviceConfig/DeviceConfigStore";
import { MobXStore } from "../../state/types";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

type SnackbarAlertProps = {
  store: any;
};

const SnackbarAlert: FC<SnackbarAlertProps> = ({
  store,
}: SnackbarAlertProps) => {
  const classes = useStyles();

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    store.setShowMessage(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={store.showMessage}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={store.messageSeverity}>
          {store.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default observer(SnackbarAlert);
