import { Person } from "../../api/Person/PersonInterface";
import { PersonType } from "../../api/PersonType/PersonTypeInterface";
import { defaultPersonStatus } from "../PersonStatus/DefaultPersonStatus";
import { defaultPersonType } from "../PersonType/DefaultPersonType";
import { defaultTag } from "../Tag/DefaultTag";

export const defaultPerson: Person = {
  id: 0,
  tag: defaultTag,
  personType: defaultPersonType,
  personStatus: defaultPersonStatus,
  addressId: 0,
  firstName: "",
  lastName: "",
  dateOfBirth: null,
  emailAddress: "",
  employer: "",
  startDate: null,
  startDateUtc: null,
  endDate: null,
  endDateUtc: null,
};
