import { deviceRoleModeApi } from "../../api/api";
import {
  DeviceMode,
  DeviceRoleModeInput,
} from "../../api/DeviceMode/DeviceModeInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDeviceMode } from "../DeviceConfig/DefaultDeviceMode";

export class DeviceRoleModeStore extends MobXStore<
  DeviceMode,
  DeviceRoleModeInput
> {
  constructor(root: RootStore) {
    super(
      root,
      deviceRoleModeApi,
      "deviceRoleMode",
      "Device Role Mode",
      "Device Role Mode",
      defaultDeviceMode
    );
  }
}
