import { number, object, SchemaOf } from "yup";
import {
  AreaDeviceFunctionInstanceDevice,
  AreaDeviceFunctionInstanceDeviceSchema,
} from "../../../../api/AreaDeviceFunctionInstanceDevice/AreaDeviceFunctionInstanceDeviceInterface";
import { occurrencesInNumberArray } from "../../../../utils/occurrencesInArray";

export function generateSchema(
  areaDeviceFunctionInstanceDeviceList: AreaDeviceFunctionInstanceDevice[]
) {
  const schema: SchemaOf<AreaDeviceFunctionInstanceDeviceSchema> =
    object().shape({
      deviceFunctionInstanceDevice: object().shape({
        id: number()
          .required()
          .positive()
          .test(
            "checkOneOfEachDeviceFunctionInstanceDevice",
            "Please select a valid device function instance device",
            (value: unknown): boolean => {
              if (typeof value !== "number") {
                return false;
              }
              if (value === 0) {
                return false;
              }
              if (!areaDeviceFunctionInstanceDeviceList.length) {
                return true;
              }
              const deviceFunctionInstanceDeviceIdList =
                areaDeviceFunctionInstanceDeviceList.map(
                  (areaDeviceFunctionInstanceDevice) =>
                    Number(
                      areaDeviceFunctionInstanceDevice
                        .deviceFunctionInstanceDevice.id
                    )
                );
              const occurrences = occurrencesInNumberArray(
                value,
                deviceFunctionInstanceDeviceIdList
              );

              return occurrences < 1;
            }
          ),
      }),
    });
  return schema;
}
