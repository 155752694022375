import { boolean, number, object, SchemaOf, string } from "yup";
import { RoomTypeSchema } from "../../../../api/RoomType/RoomTypeInterface";
import { PageType } from "../../types";

export function generateSchema(type: PageType) {
  const schema: SchemaOf<RoomTypeSchema> = object().shape({
    type: string().required(),
    tier: number().required().positive(),
    maxOccupancy: number().required().positive(),
    bedroom: boolean().required(),
  });
  return schema;
}
