import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  ApiArrayResponseSuccess,
  ApiResponseFailure,
} from "../types/apiResponse";
import { axiosGetByForeignKeyIdRequest } from "../util/axiosRequest";
import {
  ActuatorFunctionInstanceChannel,
  ActuatorFunctionInstanceChannelInput,
} from "./ActuatorFunctionInstanceChannelInterface";

export class ActuatorFunctionInstanceChannelApi extends AbstractApi<
  ActuatorFunctionInstanceChannel,
  ActuatorFunctionInstanceChannelInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "actuatorFunctionInstanceChannel");
  }

  public async getByForeignKeyId(
    id: number
  ): Promise<
    | ApiArrayResponseSuccess<ActuatorFunctionInstanceChannel>
    | ApiResponseFailure
  > {
    return axiosGetByForeignKeyIdRequest(
      this.baseUrl,
      this.endpoint,
      "deviceFunctionInstance",
      id
    );
  }
}
