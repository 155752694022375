import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { RoomDeviceFunctionInstanceDevice } from "../../../../api/RoomDeviceFunctionInstanceDevice/RoomDeviceFunctionInstanceDeviceInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import { ActionType } from "../../types";
import { generateSchema } from "./RoomDeviceFunctionInstanceDeviceValidation";

const RoomDeviceFunctionInstanceDialogForm: FC<FormProps> = ({
  id,
}: FormProps) => {
  const {
    roomDeviceFunctionInstanceDeviceStore,
    deviceFunctionInstanceDeviceStore,
  } = useRootStore();

  const handleClose = () => {
    roomDeviceFunctionInstanceDeviceStore.setDialogOpen(false);
  };

  const handleDelete = () => {
    roomDeviceFunctionInstanceDeviceStore.handleDelete();
    handleClose();
  };

  const onSubmit = (values: RoomDeviceFunctionInstanceDevice) => {
    const deviceFunctionInstanceDevice =
      deviceFunctionInstanceDeviceStore.getItemFromList(
        values.deviceFunctionInstanceDevice.id
      );
    switch (roomDeviceFunctionInstanceDeviceStore.actionType) {
      case ActionType.Edit:
        roomDeviceFunctionInstanceDeviceStore.handleEdit(
          values,
          deviceFunctionInstanceDevice
        );
        break;
      case ActionType.New:
        roomDeviceFunctionInstanceDeviceStore.handleInsert(
          values,
          deviceFunctionInstanceDevice
        );
        break;
    }
    handleClose();
  };

  const roomDeviceFunctionInstanceDeviceSchema = useMemo(
    () => generateSchema(roomDeviceFunctionInstanceDeviceStore.itemList),
    [roomDeviceFunctionInstanceDeviceStore.itemList]
  );

  const resolver = useYupValidationResolver(
    roomDeviceFunctionInstanceDeviceSchema
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: roomDeviceFunctionInstanceDeviceStore.defaultItem,
    resolver,
  });

  useEffect(() => {
    autorun(async () => {
      reset(roomDeviceFunctionInstanceDeviceStore.item);
    });
  }, [reset, roomDeviceFunctionInstanceDeviceStore.item]);

  let deviceFunctionInstanceDeviceOptions = null;
  if (deviceFunctionInstanceDeviceStore.itemList) {
    const deviceFunctionInstanceDevices =
      deviceFunctionInstanceDeviceStore.itemList;
    deviceFunctionInstanceDeviceOptions = deviceFunctionInstanceDevices.map(
      (deviceFunctionInstanceDevice) => {
        return {
          id: deviceFunctionInstanceDevice.id,
          value: `${deviceFunctionInstanceDevice.device.deviceRole.name} (${deviceFunctionInstanceDevice.device.macAddress}) - ${deviceFunctionInstanceDevice.deviceFunctionInstance.name}`,
        };
      }
    );
  }

  return (
    <Dialog
      open={roomDeviceFunctionInstanceDeviceStore.dialogOpen}
      onClose={handleClose}
      aria-labelledby="room-device-function-instance-form-dialog-title"
    >
      <DialogTitle id="room-device-function-instance-form-dialog-title">
        {roomDeviceFunctionInstanceDeviceStore.actionType === ActionType.Edit &&
          "Modify "}
        {roomDeviceFunctionInstanceDeviceStore.actionType === ActionType.New &&
          "Add a new "}
        Device Function Instance Device
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {roomDeviceFunctionInstanceDeviceStore.actionType ===
            ActionType.Edit && "Modify device function instance "}
          {roomDeviceFunctionInstanceDeviceStore.actionType ===
            ActionType.New && "Add a new device function instance "}
          to the room
        </DialogContentText>
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
          <SelectInputField
            name="deviceFunctionInstanceDevice.id"
            options={deviceFunctionInstanceDeviceOptions}
            displayName="Device Function Instance Device"
            errors={errors}
            control={control}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {roomDeviceFunctionInstanceDeviceStore.actionType ===
          ActionType.Edit && (
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        )}
        <Button form={id} type="submit" color="primary">
          {roomDeviceFunctionInstanceDeviceStore.actionType ===
            ActionType.Edit && "Update"}
          {roomDeviceFunctionInstanceDeviceStore.actionType ===
            ActionType.New && "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(RoomDeviceFunctionInstanceDialogForm);
