import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { PersonServicePackage } from "../../../../api/PersonServicePackage/PersonServicePackageInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { ActionType } from "../../types";

const PersonServicePackageTable: FC = () => {
  const { personServicePackageStore } = useRootStore();

  const handleNewPersonServicePackage = () => {
    personServicePackageStore.resetItem();
    personServicePackageStore.setActionType(ActionType.New);
    personServicePackageStore.setDialogOpen(true);
  };

  const handleRowClick = (row: PersonServicePackage) => {
    personServicePackageStore.setItemFromList(row.id);
    personServicePackageStore.setActionType(ActionType.Edit);
    personServicePackageStore.setDialogOpen(true);
  };

  function renderTableRow(row: PersonServicePackage) {
    if (row.action === ActionType.Delete) {
      return;
    }

    return (
      <TableRow key={row.localId} onClick={() => handleRowClick(row)}>
        <TableCell>
          {row.servicePackage.name} ({row.servicePackage.description})
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Table aria-label="device function instance device">
      <TableHead>
        <TableRow>
          <TableCell>Service Package</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {personServicePackageStore.itemList.length
          ? personServicePackageStore.itemList.map(
              (row: PersonServicePackage) => {
                return renderTableRow(row);
              }
            )
          : null}
      </TableBody>
      <Button
        variant="text"
        color="primary"
        type="submit"
        onClick={handleNewPersonServicePackage}
      >
        New Service Package
      </Button>
    </Table>
  );
};

export default observer(PersonServicePackageTable);
