import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  ApiArrayResponseSuccess,
  ApiObjectResponseSuccess,
  ApiResponseFailure,
} from "../types/apiResponse";
import {
  axiosGetByForeignKeyIdRequest,
  axiosInsertRequest,
  axiosUpdateRequest,
} from "../util/axiosRequest";
import {
  DeviceSpecificationSensorSpecification,
  DeviceSpecificationSensorSpecificationInput,
} from "./DeviceSpecificationSensorSpecificationInterface";

export class DeviceSpecificationSensorSpecificationApi extends AbstractApi<
  DeviceSpecificationSensorSpecification,
  DeviceSpecificationSensorSpecificationInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "deviceSpecificationSensorSpecification");
  }

  public async update(
    record: DeviceSpecificationSensorSpecificationInput
  ): Promise<
    | ApiObjectResponseSuccess<DeviceSpecificationSensorSpecification>
    | ApiResponseFailure
  > {
    return axiosUpdateRequest<
      DeviceSpecificationSensorSpecification,
      DeviceSpecificationSensorSpecificationInput
    >(this.baseUrl, this.endpoint, record);
  }

  public async insert(
    record: DeviceSpecificationSensorSpecificationInput
  ): Promise<
    | ApiObjectResponseSuccess<DeviceSpecificationSensorSpecification>
    | ApiResponseFailure
  > {
    return axiosInsertRequest<
      DeviceSpecificationSensorSpecification,
      DeviceSpecificationSensorSpecificationInput
    >(this.baseUrl, this.endpoint, record);
  }

  public async getByForeignKeyId(
    id: number
  ): Promise<
    | ApiArrayResponseSuccess<DeviceSpecificationSensorSpecification>
    | ApiResponseFailure
  > {
    return axiosGetByForeignKeyIdRequest(
      this.baseUrl,
      this.endpoint,
      "deviceSpecification",
      id
    );
  }
}
