import { boolean, object, SchemaOf } from "yup";
import {
  DeviceFunctionInstanceDeviceSchema,
  RoomDeviceFunctionInstanceDeviceListItem,
} from "../../../../api/DeviceFunctionInstanceDevice/DeviceFunctionInstanceDeviceInterface";

export function generateSchema(
  roomList: RoomDeviceFunctionInstanceDeviceListItem[]
) {
  const schema: SchemaOf<DeviceFunctionInstanceDeviceSchema> = object().shape({
    isInUse: boolean()
      .required()
      .test(
        "checkAllowedToChangeIsInUse",
        "Please unassign the room before changing the status",
        (value: unknown): boolean => {
          if (typeof value !== "boolean") {
            return false;
          }

          if (value === true) {
            return true;
          }

          if (roomList.length) {
            return false;
          }
          return true;
        }
      ),
  });
  return schema;
}
