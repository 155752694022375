import axios, { AxiosResponse } from "axios";
import {
  TbEntityRelation,
  TbEntityRelationRequestBody,
} from "./TbEntityRelationInterface";

export class TbEntityRelationApi {
  protected readonly baseUrl: string;
  protected readonly endpoint: string;

  constructor(baseUrl: string, endpoint: string) {
    this.baseUrl = baseUrl;
    this.endpoint = endpoint;
  }

  async getDeviceRelationTo(deviceId: string): Promise<TbEntityRelation[]> {
    return await axios
      .get(`${this.baseUrl}/tb/${this.endpoint}/to/device/${deviceId}`)
      .then((response: AxiosResponse) => {
        return response.data as unknown as TbEntityRelation[];
      });
  }

  async insert(relationToInsert: TbEntityRelationRequestBody): Promise<void> {
    let url: string = `${this.baseUrl}/tb/${this.endpoint}`;
    return await axios
      .post(url, relationToInsert)
      .then((response: AxiosResponse) => {
        return response.data as unknown as void;
      });
  }
}
