import InboxIcon from "@material-ui/icons/MoveToInbox";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode";
import RemoveFromQueueIcon from "@material-ui/icons/RemoveFromQueue";
import ApartmentIcon from "@material-ui/icons/Apartment";
import BookIcon from "@material-ui/icons/Book";
export const navigationItems = [
  {
    displayName: "Platform Specifications",
    link: "/platformSpecificationList",
    Icon: ChromeReaderModeIcon,
  },
  {
    displayName: "Sensor Specifications",
    link: "/sensorSpecificationList",
    Icon: RemoveFromQueueIcon,
  },
  {
    displayName: "Actuator Specifications",
    link: "/actuatorSpecificationList",
    Icon: ApartmentIcon,
  },
  {
    displayName: "Device Specifications",
    link: "/deviceSpecificationList",
    Icon: InboxIcon,
  },
  {
    displayName: "Device Functions",
    link: "/deviceFunctionList",
    Icon: BookIcon,
  },
  {
    displayName: "Device Roles",
    link: "/deviceRoleList",
    Icon: BookIcon,
  },
  {
    displayName: "Devices",
    link: "/deviceList",
    Icon: BookIcon,
  },
  {
    displayName: "Device Config",
    link: "/deviceConfigList",
    Icon: BookIcon,
  },
  {
    displayName: "Room Types",
    link: "/roomTypeList",
    Icon: BookIcon,
  },
  {
    displayName: "Room Statuses",
    link: "/roomStatusList",
    Icon: BookIcon,
  },
  {
    displayName: "Rooms",
    link: "/roomList",
    Icon: BookIcon,
  },
  {
    displayName: "Tag Models",
    link: "/tagModelList",
    Icon: BookIcon,
  },
  {
    displayName: "Tags",
    link: "/tagList",
    Icon: BookIcon,
  },
  {
    displayName: "People",
    link: "/personList",
    Icon: BookIcon,
  },
  {
    displayName: "Customers",
    link: "/customerList",
    Icon: BookIcon,
  },
  {
    displayName: "Buildings",
    link: "/buildingList",
    Icon: BookIcon,
  },
  {
    displayName: "Areas",
    link: "/areaList",
    Icon: BookIcon,
  },
  {
    displayName: "Resource Units",
    link: "/resourceUnitList",
    Icon: BookIcon,
  },
  {
    displayName: "Resources",
    link: "/resourceList",
    Icon: BookIcon,
  },
  {
    displayName: "Periods",
    link: "/periodList",
    Icon: BookIcon,
  },
  {
    displayName: "Service Packages",
    link: "/servicePackageList",
    Icon: BookIcon,
  },
];
