import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect } from "react";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import Heading from "../../../Form/Heading/Heading";
import SubmitButton from "../../../Form/SubmitButton/SubmitButton";
import { PageType } from "../../types";
import PlatformSpecificationForm from "./PlatformSpecificationForm";

const PlatformSpecificationPage: FC = () => {
  const router = useRouter();
  const { platformSpecificationStore, userInterfaceStore } = useRootStore();

  useEffect(() => {
    autorun(async () => {
      switch (userInterfaceStore.pageType) {
        case PageType.View:
        case PageType.Existing:
          await platformSpecificationStore.getItemAsync(
            Number(router.query.id)
          );
          break;
        case PageType.New:
          platformSpecificationStore.resetItem();
          break;
      }
    });
  }, [
    userInterfaceStore.pageType,
    platformSpecificationStore,
    router.query.id,
  ]);

  return (
    <Fragment>
      <Heading
        pageType={userInterfaceStore.pageType!}
        heading={platformSpecificationStore.singularDisplayName}
      />

      <PlatformSpecificationForm />
      {userInterfaceStore.pageType !== PageType.View && (
        <SubmitButton
          form="platformSpecificationForm"
          pageType={userInterfaceStore.pageType!}
        />
      )}
    </Fragment>
  );
};

export default observer(PlatformSpecificationPage);
