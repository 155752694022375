import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { PersonRoom } from "../../../../api/PersonRoom/PersonRoomInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { ActionType } from "../../types";

const PersonRoomTable: FC = () => {
  const { personRoomStore } = useRootStore();

  const handleNewPersonRoomButton = () => {
    personRoomStore.resetItem();
    personRoomStore.setActionType(ActionType.New);
    personRoomStore.setDialogOpen(true);
  };

  const handleRowClick = (row: PersonRoom) => {
    personRoomStore.setItemFromList(row.id);
    personRoomStore.setActionType(ActionType.Edit);
    personRoomStore.setDialogOpen(true);
  };

  function renderTableRow(row: PersonRoom) {
    if (row.action === ActionType.Delete) {
      return;
    }

    return (
      <TableRow key={row.localId} onClick={() => handleRowClick(row)}>
        <TableCell>{row.room.floor}</TableCell>
        <TableCell>{row.room.number}</TableCell>
        <TableCell>{row.room.roomType.type}</TableCell>
        <TableCell>{row.room.systemRoomStatus}</TableCell>
        <TableCell>{row.room.roomStatus.status}</TableCell>
      </TableRow>
    );
  }

  return (
    <Grid item>
      <Table aria-label="person room">
        <TableHead>
          <TableRow>
            <TableCell>Room Floor</TableCell>
            <TableCell>Room Number</TableCell>
            <TableCell>Room Type</TableCell>
            <TableCell>System Room Status</TableCell>
            <TableCell>Room Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {personRoomStore.itemList.length
            ? personRoomStore.itemList.map((row: PersonRoom) => {
                return renderTableRow(row);
              })
            : null}
        </TableBody>
      </Table>
      <Button
        variant="text"
        color="primary"
        type="submit"
        onClick={handleNewPersonRoomButton}
      >
        Add New Room
      </Button>
    </Grid>
  );
};

export default observer(PersonRoomTable);
