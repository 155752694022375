import { FormControl, TextField } from "@material-ui/core";
import React, { FC } from "react";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";

type TextInputFieldProps = {
  name: string;
  displayName: string;
  errors: DeepMap<any, FieldError>;
  control: Control<any>;
  handleChange: (
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) => void;
  readOnly?: boolean;
};

const TextInputField: FC<TextInputFieldProps> = ({
  name,
  displayName,
  errors,
  control,
  handleChange,
  readOnly,
}: TextInputFieldProps) => {
  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            error={errors[name] ? true : false}
            {...field}
            name={name}
            InputProps={{
              readOnly: readOnly,
              onChange: handleChange,
            }}
            label={displayName}
            placeholder={displayName}
            helperText={errors[name] && errors[name].message}
            variant={readOnly ? "standard" : "outlined"}
          />
        )}
      />
    </FormControl>
  );
};

export default TextInputField;
