import { AbstractAPI as AbstractApi } from "../abstractApi";
import { DeviceFunction, DeviceFunctionInput } from "./DeviceFunctionInterface";

export class DeviceFunctionApi extends AbstractApi<
  DeviceFunction,
  DeviceFunctionInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "deviceFunction");
  }
}
