import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import { AreaInput, AreaSchema } from "../../../../api/Area/AreaInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import calculateReadOnly from "../../../../utils/calculateReadOnly";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./AreaValidation";

const AreaForm: FC<FormProps> = ({ id }: FormProps) => {
  const router = useRouter();
  const {
    areaStore,
    areaStatusStore,
    areaDeviceFunctionInstanceDeviceStore,
    userInterfaceStore,
  } = useRootStore();

  const areaSchema = useMemo(
    () => generateSchema(userInterfaceStore.pageType!),
    [userInterfaceStore.pageType]
  );

  const resolver = useYupValidationResolver(areaSchema);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    reset,
  } = useForm({
    defaultValues: areaStore.defaultItem,
    resolver,
  });

  const onSubmit = async (values: AreaSchema) => {
    let area: AreaInput = {
      areaStatusId: values.areaStatus.id,
      name: values.name,
      versionNumber: 0,
    };
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
        area.id = Number(router.query.id);
        let updateResult = await areaStore.updateItemAsync(area);
        break;
      case PageType.New:
      case PageType.NewDuplicate:
        await areaStore.insertItemAsync(area);
        userInterfaceStore.setPageType(PageType.Inserted);
        router.history.push(`/${areaStore.endpoint}/edit/${areaStore.item.id}`);
        break;
    }
    areaDeviceFunctionInstanceDeviceStore.handleUpdateArea(areaStore.item.id);
  };

  useEffect(() => {
    autorun(async () => {
      reset(areaStore.item);
    });
  }, [areaStore, reset]);
  const readOnly = calculateReadOnly(userInterfaceStore.pageType);

  const areaStatuses = areaStatusStore.itemList;
  let areaStatusOptions: { id: number; value: string }[] = [];
  if (areaStatuses.length) {
    areaStatusOptions = areaStatuses.map((personStatus) => {
      return {
        id: personStatus.id,
        value: personStatus.status,
      };
    });
  }

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={areaStore} />
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <TextInputField
          name="name"
          displayName="Area Name"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <SelectInputField
          name="areaStatus.id"
          options={areaStatusOptions}
          displayName="Area Status"
          errors={errors}
          control={control}
        />
      </form>
    </Fragment>
  );
};

export default observer(AreaForm);
