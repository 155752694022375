import {
  ServicePackage,
  ServicePackageInput,
} from "../../api/ServicePackage/ServicePackageInterface";
import { servicePackageApi } from "../../api/api";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultServicePackage } from "./DefaultServicePackage";

export class ServicePackageStore extends MobXStore<
  ServicePackage,
  ServicePackageInput
> {
  constructor(root: RootStore) {
    super(
      root,
      servicePackageApi,
      "servicePackage",
      "Service Package",
      "Service Packages",
      defaultServicePackage
    );
  }
}
