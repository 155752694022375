import { RoomDeviceFunctionInstanceDevice } from "../../api/RoomDeviceFunctionInstanceDevice/RoomDeviceFunctionInstanceDeviceInterface";
import { defaultDeviceFunctionInstanceDevice } from "../DeviceFunctionInstanceDevice/DefaultDeviceFunctionInstanceDevice";
import { defaultRoom } from "../Room/DefaultRoom";

export const defaultRoomDeviceFunctionInstanceDevice: RoomDeviceFunctionInstanceDevice =
  {
    id: 0,
    room: defaultRoom,
    deviceFunctionInstanceDevice: defaultDeviceFunctionInstanceDevice,
  };
