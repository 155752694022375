import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  ApiArrayResponseSuccess,
  ApiResponseFailure,
} from "../types/apiResponse";
import { axiosGetByForeignKeyIdRequest } from "../util/axiosRequest";
import {
  ServiceAllocation,
  ServiceAllocationInput,
} from "./ServiceAllocationInterface";
export class ServiceAllocationApi extends AbstractApi<
  ServiceAllocation,
  ServiceAllocationInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "serviceAllocation");
  }

  public async getByForeignKeyId(
    id: number
  ): Promise<ApiArrayResponseSuccess<ServiceAllocation> | ApiResponseFailure> {
    return axiosGetByForeignKeyIdRequest(
      this.baseUrl,
      this.endpoint,
      "servicePackage",
      id
    );
  }
}
