import { AccessTokenApi } from "./AccessToken/AccessToken";
import { ActuatorFunctionInstanceChannelApi } from "./ActuatorFunctionInstanceChannel/ActuatorFunctionInstanceChannel";
import { ActuatorFunctionLineApi } from "./ActuatorFunctionLine/ActuatorFunctionLine";
import { ActuatorSpecificationApi } from "./ActuatorSpecification/ActuatorSpecification";
import { AreaApi } from "./Area/Area";
import { AreaDeviceFunctionInstanceDeviceApi } from "./AreaDeviceFunctionInstanceDevice/AreaDeviceFunctionInstanceDevice";
import { AreaStatusApi } from "./AreaStatus/AreaStatus";
import { BuildingApi } from "./Building/Building";
import { CustomerApi } from "./Customer/Customer";
import { DeviceApi } from "./Device/Device";
import { DeviceConfigApi } from "./DeviceConfig/DeviceConfig";
import { DeviceDatabaseColumnNameApi } from "./DeviceDatabaseColumnName/DeviceDatabaseColumnName";
import { DeviceDatabaseColumnTypeApi } from "./DeviceDatabaseColumnType/DeviceDatabaseColumnType";
import { DeviceFunctionApi } from "./DeviceFunction/DeviceFunction";
import { DeviceFunctionInstanceApi } from "./DeviceFunctionInstance/DeviceFunctionInstance";
import { DeviceFunctionInstanceDeviceApi } from "./DeviceFunctionInstanceDevice/DeviceFunctionInstanceDevice";
import { DeviceModeApi } from "./DeviceMode/DeviceMode";
import { DeviceRoleModeApi } from "./DeviceMode/DeviceRoleMode";
import { DeviceSpecificationModeApi } from "./DeviceMode/DeviceSpecificationMode";
import { DeviceRoleApi } from "./DeviceRole/DeviceRole";
import { DeviceRoleDatabaseDefinitionApi } from "./DeviceRoleDatabaseDefinition/DeviceRoleDatabaseDefinition";
import { DeviceRoleRuleApi } from "./DeviceRule/DeviceRoleRule";
import { DeviceRuleApi } from "./DeviceRule/DeviceRule";
import { DeviceSpecificationRuleApi } from "./DeviceRule/DeviceSpecificationRule";
import { DeviceRoleSenseApi } from "./DeviceSense/DeviceRoleSense";
import { DeviceSenseApi } from "./DeviceSense/DeviceSense";
import { DeviceSpecificationSenseApi } from "./DeviceSense/DeviceSpecificationSense";
import { DeviceSpecificationApi } from "./DeviceSpecification/DeviceSpecification";
import { DeviceSpecificationActuatorSpecificationApi } from "./DeviceSpecificationActuatorSpecification/DeviceSpecificationActuatorSpecification";
import { DeviceSpecificationSensorSpecificationApi } from "./DeviceSpecificationSensorSpecification/DeviceSpecificationSensorSpecification";
import { PeriodApi } from "./Period/Period";
import { PersonApi } from "./Person/Person";
import { PersonAreaApi } from "./PersonArea/PersonArea";
import { PersonRoomApi } from "./PersonRoom/PersonRoom";
import { PersonServiceAllocationApi } from "./PersonServiceAllocation/PersonServiceAllocation";
import { PersonServicePackageApi } from "./PersonServicePackage/PersonServicePackage";
import { PersonStatusApi } from "./PersonStatus/PersonStatus";
import { PersonTypeApi } from "./PersonType/PersonType";
import { PlatformSpecificationApi } from "./PlatformSpecification/PlatformSpecification";
import { ResourceApi } from "./Resource/Resource";
import { ResourceUnitApi } from "./ResourceUnit/ResourceUnit";
import { RoomApi } from "./Room/Room";
import { RoomDeviceFunctionInstanceDeviceApi } from "./RoomDeviceFunctionInstanceDevice/RoomDeviceFunctionInstanceDevice";
import { RoomStatusApi } from "./RoomStatus/RoomStatus";
import { RoomTypeApi } from "./RoomType/RoomType";
import { SensorFunctionInstanceChannelApi } from "./SensorFunctionInstanceChannel/SensorFunctionInstanceChannel";
import { SensorFunctionLineApi } from "./SensorFunctionLine/SensorFunctionLine";
import { SensorSpecificationApi } from "./SensorSpecification/SensorSpecification";
import { ServiceAllocationApi } from "./ServiceAllocation/ServiceAllocation";
import { ServicePackageApi } from "./ServicePackage/ServicePackage";
import { TagApi } from "./Tag/Tag";
import { TagModelApi } from "./TagModel/TagModel";
import { TagStatusApi } from "./TagStatus/TagStatus";
import { TbAssetApi } from "./TbAsset/TbAsset";
import { TbCustomerApi } from "./TbCustomer/TbCustomer";
import { TbDeviceApi } from "./TbDevice/TbDevice";
import { TbEntityRelationApi } from "./TbEntityRelation/TbEntityRelation";

const baseUrl = process.env.REACT_APP_API_URL || "http://tbdev.maree.us:6001";

const accessTokenApi = new AccessTokenApi(baseUrl);
const areaApi = new AreaApi(baseUrl);
const areaStatusApi = new AreaStatusApi(baseUrl);
const areaDeviceFunctionInstanceDeviceApi =
  new AreaDeviceFunctionInstanceDeviceApi(baseUrl);
const deviceApi = new DeviceApi(baseUrl);
const deviceConfigApi = new DeviceConfigApi(baseUrl);
const buildingApi = new BuildingApi(baseUrl);
const customerApi = new CustomerApi(baseUrl);
const platformSpecificationApi = new PlatformSpecificationApi(baseUrl);
const sensorSpecificationApi = new SensorSpecificationApi(baseUrl);
const sensorFunctionLineApi = new SensorFunctionLineApi(baseUrl);
const sensorFunctionInstanceChannelApi = new SensorFunctionInstanceChannelApi(
  baseUrl
);
const actuatorSpecificationApi = new ActuatorSpecificationApi(baseUrl);
const actuatorFunctionLineApi = new ActuatorFunctionLineApi(baseUrl);
const actuatorFunctionInstanceChannelApi =
  new ActuatorFunctionInstanceChannelApi(baseUrl);
const deviceFunctionApi = new DeviceFunctionApi(baseUrl);
const deviceSpecificationApi = new DeviceSpecificationApi(baseUrl);
const deviceSpecificationActuatorSpecificationApi =
  new DeviceSpecificationActuatorSpecificationApi(baseUrl);
const deviceSpecificationSensorSpecificationApi =
  new DeviceSpecificationSensorSpecificationApi(baseUrl);
const deviceRoleApi = new DeviceRoleApi(baseUrl);
const deviceDatabaseColumnNameApi = new DeviceDatabaseColumnNameApi(baseUrl);
const deviceDatabaseColumnTypeApi = new DeviceDatabaseColumnTypeApi(baseUrl);
const deviceRoleDatabaseDefinitionApi = new DeviceRoleDatabaseDefinitionApi(
  baseUrl
);
const deviceModeApi = new DeviceModeApi(baseUrl);
const deviceRoleModeApi = new DeviceRoleModeApi(baseUrl);
const deviceSpecificationModeApi = new DeviceSpecificationModeApi(baseUrl);
const deviceSenseApi = new DeviceSenseApi(baseUrl);
const deviceRoleSenseApi = new DeviceRoleSenseApi(baseUrl);
const deviceSpecificationSenseApi = new DeviceSpecificationSenseApi(baseUrl);
const deviceRuleApi = new DeviceRuleApi(baseUrl);
const deviceRoleRuleApi = new DeviceRoleRuleApi(baseUrl);
const deviceSpecificationRuleApi = new DeviceSpecificationRuleApi(baseUrl);
const deviceFunctionInstanceApi = new DeviceFunctionInstanceApi(baseUrl);
const deviceFunctionInstanceDeviceApi = new DeviceFunctionInstanceDeviceApi(
  baseUrl
);
const roomTypeApi = new RoomTypeApi(baseUrl);
const roomApi = new RoomApi(baseUrl);
const roomStatusApi = new RoomStatusApi(baseUrl);
const periodApi = new PeriodApi(baseUrl);
const personStatusApi = new PersonStatusApi(baseUrl);
const personApi = new PersonApi(baseUrl);
const personAreaApi = new PersonAreaApi(baseUrl);
const personTypeApi = new PersonTypeApi(baseUrl);
const personRoomApi = new PersonRoomApi(baseUrl);
const personServiceAllocationApi = new PersonServiceAllocationApi(baseUrl);
const personServicePackageApi = new PersonServicePackageApi(baseUrl);
const tagApi = new TagApi(baseUrl);
const tagModelApi = new TagModelApi(baseUrl);
const tagStatusApi = new TagStatusApi(baseUrl);
const resourceApi = new ResourceApi(baseUrl);
const resourceUnitApi = new ResourceUnitApi(baseUrl);
const roomDeviceFunctionInstanceDeviceApi =
  new RoomDeviceFunctionInstanceDeviceApi(baseUrl);
const serviceAllocationApi = new ServiceAllocationApi(baseUrl);
const servicePackageApi = new ServicePackageApi(baseUrl);
const tbAssetApi = new TbAssetApi(baseUrl, "asset");
const tbDeviceApi = new TbDeviceApi(baseUrl, "device");
const tbCustomerApi = new TbCustomerApi(baseUrl, "customer");
const tbEntityRelationApi = new TbEntityRelationApi(baseUrl, "entityRelation");

export {
  accessTokenApi,
  areaApi,
  areaStatusApi,
  areaDeviceFunctionInstanceDeviceApi,
  deviceApi,
  deviceModeApi,
  deviceRoleModeApi,
  deviceSpecificationModeApi,
  deviceSenseApi,
  deviceRoleSenseApi,
  deviceSpecificationSenseApi,
  deviceRuleApi,
  deviceRoleRuleApi,
  deviceSpecificationRuleApi,
  deviceConfigApi,
  buildingApi,
  customerApi,
  platformSpecificationApi,
  sensorSpecificationApi,
  sensorFunctionLineApi,
  sensorFunctionInstanceChannelApi,
  actuatorSpecificationApi,
  actuatorFunctionLineApi,
  actuatorFunctionInstanceChannelApi,
  deviceFunctionApi,
  deviceRoleApi,
  deviceDatabaseColumnNameApi,
  deviceDatabaseColumnTypeApi,
  deviceRoleDatabaseDefinitionApi,
  deviceSpecificationApi,
  deviceSpecificationActuatorSpecificationApi,
  deviceSpecificationSensorSpecificationApi,
  deviceFunctionInstanceApi,
  deviceFunctionInstanceDeviceApi,
  roomTypeApi,
  roomApi,
  roomStatusApi,
  personApi,
  personStatusApi,
  personTypeApi,
  tagApi,
  periodApi,
  personAreaApi,
  personRoomApi,
  personServiceAllocationApi,
  personServicePackageApi,
  resourceApi,
  resourceUnitApi,
  roomDeviceFunctionInstanceDeviceApi,
  serviceAllocationApi,
  servicePackageApi,
  tagModelApi,
  tagStatusApi,
  tbAssetApi,
  tbDeviceApi,
  tbCustomerApi,
  tbEntityRelationApi,
};
