import classes from "*.module.css";
import {
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Fragment } from "react";
import { FC } from "react";
import { useRootStore } from "../../../../hooks/useRootStore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",

      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  })
);
const DeviceSpecificationSensorSpecification: FC = () => {
  const { deviceSpecificationSensorSpecificationStore } = useRootStore();
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6}>
      <Typography variant="subtitle1" component="h5">
        Sensors from Device Specification
      </Typography>

      <div className={classes.root}>
        {deviceSpecificationSensorSpecificationStore.itemList.map((val) => {
          return (
            <Chip
              key={val.id}
              label={`${val.sensorSpecification.manufacturer}-${val.sensorSpecification.model}-${val.sensorSpecification.type} (${val.quantity})`}
            />
          );
        })}
      </div>
    </Grid>
  );
};

export default observer(DeviceSpecificationSensorSpecification);
