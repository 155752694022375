import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  DeviceRule,
  DeviceSpecificationRuleInput,
} from "./DeviceRuleInterface";

export class DeviceSpecificationRuleApi extends AbstractApi<
  DeviceRule,
  DeviceSpecificationRuleInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "deviceSpecificationRule");
  }
}
