import { action, makeObservable, observable, runInAction } from "mobx";
import { actuatorFunctionInstanceChannelApi } from "../../api/api";
import { DeviceFunctionInstanceApi } from "../../api/DeviceFunctionInstance/DeviceFunctionInstance";
import { DeviceFunctionInstance } from "../../api/DeviceFunctionInstance/DeviceFunctionInstanceInterface";
import {
  ActuatorFunctionInstanceChannel,
  ActuatorFunctionInstanceChannelInput,
} from "../../api/ActuatorFunctionInstanceChannel/ActuatorFunctionInstanceChannelInterface";
import { ActuatorFunctionLineApi } from "../../api/ActuatorFunctionLine/ActuatorFunctionLine";
import { ActionType } from "../../components/pages/types";
import { defaultDeviceFunctionInstance } from "../DeviceFunctionInstance/DefaultDeviceFunctionInstance";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultActuatorFunctionInstanceChannel } from "./DefaultActuatorFunctionInstanceChannel";

export class ActuatorFunctionInstanceChannelStore extends MobXStore<
  ActuatorFunctionInstanceChannel,
  ActuatorFunctionInstanceChannelInput
> {
  localIdCounter: number = 0;
  actionType?: ActionType = undefined;
  dialogOpen: boolean = false;
  deviceFunctionInstanceMap: Map<number, ActuatorFunctionInstanceChannel[]> =
    new Map<number, ActuatorFunctionInstanceChannel[]>();
  deviceFunctionInstanceApi: DeviceFunctionInstanceApi;
  actuatorFunctionLineApi: ActuatorFunctionLineApi;

  constructor(
    root: RootStore,
    actuatorFunctionLineApi: ActuatorFunctionLineApi,
    deviceFunctionInstanceApi: DeviceFunctionInstanceApi
  ) {
    super(
      root,
      actuatorFunctionInstanceChannelApi,
      "actuatorFunctionInstanceChannel",
      "Actuator Function Line",
      "Actuator Function Lines",
      defaultActuatorFunctionInstanceChannel
    );
    makeObservable(this, {
      dialogOpen: observable,
      deviceFunctionInstanceMap: observable,
      setDialogOpen: action,
      setDeviceFunctionInstanceMap: action,
    });
    this.actuatorFunctionLineApi = actuatorFunctionLineApi;
    this.deviceFunctionInstanceApi = deviceFunctionInstanceApi;
  }

  incrementLocalIdCounter() {
    this.localIdCounter++;
  }

  setActionType(actionType: ActionType) {
    this.actionType = actionType;
  }

  setDialogOpen(dialogOpen: boolean) {
    this.dialogOpen = dialogOpen;
  }

  async setDeviceFunctionInstanceMap(
    deviceFunctionInstances: DeviceFunctionInstance[]
  ): Promise<void> {
    deviceFunctionInstances.forEach(async (deviceFunctionInstance) => {
      const actuatorFunctionInstanceChannels = await this.api
        .getByForeignKeyId!(
        deviceFunctionInstance.id,
        "deviceFunctionInstance"
      );
      if ("error" in actuatorFunctionInstanceChannels) {
        this.setAndShowMessage(
          JSON.stringify(actuatorFunctionInstanceChannels.error)
        );
        this.setMessageSeverity("error");
        return;
      }
      if (actuatorFunctionInstanceChannels.data.length) {
        actuatorFunctionInstanceChannels.data.forEach(
          (actuatorFunctionInstanceChannel) => {
            actuatorFunctionInstanceChannel.localId = this.localIdCounter;
            this.incrementLocalIdCounter();
          }
        );
      }
      this.deviceFunctionInstanceMap.set(
        deviceFunctionInstance.localId!,
        actuatorFunctionInstanceChannels.data
      );
    });
  }

  resetDeviceFunctionInstanceMap() {
    this.deviceFunctionInstanceMap.clear();
  }

  setItemListFromDeviceFunctionInstanceMap(
    localDeviceFunctionInstanceId: number
  ) {
    const itemList = this.deviceFunctionInstanceMap.get(
      localDeviceFunctionInstanceId
    );
    if (itemList === undefined) {
      return;
    }
    this.setItemList(itemList);
  }

  async handleDeviceFunctionChange(
    deviceFunctionId: number,
    deviceFunctionInstance: DeviceFunctionInstance
  ): Promise<void> {
    const actuatorFunctionLines =
      await this.actuatorFunctionLineApi.getByForeignKeyId(deviceFunctionId);
    if ("error" in actuatorFunctionLines) {
      this.setAndShowMessage(JSON.stringify(actuatorFunctionLines.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: ActuatorFunctionInstanceChannel[] = [];
    if (actuatorFunctionLines.data.length) {
      var channel = 0;
      actuatorFunctionLines.data.forEach((actuatorFunctionLine) => {
        for (var j = 1; j <= actuatorFunctionLine.quantity; j++) {
          let item: ActuatorFunctionInstanceChannel = {
            id: 0,
            localId: this.localIdCounter,
            action: ActionType.New,
            deviceFunctionInstance: defaultDeviceFunctionInstance,
            actuatorFunctionLine,
            channel,
            versionNumber: 0,
          };
          channel++;
          this.incrementLocalIdCounter();
          enrichedResults.push(item);
        }
      });
    }
    if (
      this.deviceFunctionInstanceMap.get(deviceFunctionInstance.localId!)
        ?.length
    ) {
      this.deviceFunctionInstanceMap
        .get(deviceFunctionInstance.localId!)
        ?.forEach((actuator) => {
          switch (actuator.action) {
            case undefined:
            case ActionType.Edit:
              enrichedResults.push({ ...actuator, action: ActionType.Delete });
          }
        });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
    this.deviceFunctionInstanceMap.set(
      deviceFunctionInstance.localId!,
      enrichedResults
    );
  }

  handleChangeDeviceSpecification(
    deviceFunctionInstanceList: DeviceFunctionInstance[]
  ) {
    deviceFunctionInstanceList.forEach((deviceFunctionInstance) => {
      let sficList: ActuatorFunctionInstanceChannel[] = [];
      if (
        this.deviceFunctionInstanceMap.get(deviceFunctionInstance.localId!)
          ?.length
      ) {
        this.deviceFunctionInstanceMap
          .get(deviceFunctionInstance.localId!)
          ?.forEach((sfic) => {
            switch (sfic.action) {
              case undefined:
              case ActionType.Edit:
                sfic.action = ActionType.Delete;
                sficList.push(sfic);
            }
          });
        runInAction(() => {
          this.deviceFunctionInstanceMap.set(
            deviceFunctionInstance.localId!,
            sficList
          );
        });
      }
    });
  }

  handleUpdateDeviceFunctionInstance(
    deviceFunctionInstance: DeviceFunctionInstance
  ) {
    if (
      this.deviceFunctionInstanceMap.get(deviceFunctionInstance.localId!)
        ?.length
    ) {
      let sficList = this.deviceFunctionInstanceMap.get(
        deviceFunctionInstance.localId!
      )!;
      sficList = sficList!.map((sfic, i) => {
        runInAction(() => {
          sfic.channel = deviceFunctionInstance.actuators[i].channel;
          sfic.action = ActionType.Edit;
        });
        return sfic;
      });
      runInAction(() => {
        this.deviceFunctionInstanceMap.set(
          deviceFunctionInstance.localId!,
          sficList
        );
      });
    }
  }
  handleDeleteDeviceFunctionInstance(
    deviceFunctionInstance: DeviceFunctionInstance
  ) {
    if (
      this.deviceFunctionInstanceMap.get(deviceFunctionInstance.localId!)
        ?.length
    ) {
      let sficList: ActuatorFunctionInstanceChannel[] = [];
      this.deviceFunctionInstanceMap
        .get(deviceFunctionInstance.localId!)
        ?.forEach((sfic) => {
          switch (sfic.action) {
            case undefined:
            case ActionType.Edit:
              sfic.action = ActionType.Delete;
          }
          sficList.push(sfic);
        });
      runInAction(() => {
        this.deviceFunctionInstanceMap.set(
          deviceFunctionInstance.localId!,
          sficList
        );
      });
      this.resetItemList();
    }
  }
}
