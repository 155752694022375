import { action, makeObservable, observable, runInAction } from "mobx";
import { sensorFunctionLineApi } from "../../api/api";
import {
  SensorFunctionLine,
  SensorFunctionLineInput,
} from "../../api/SensorFunctionLine/SensorFunctionLineInterface";
import { SensorSpecification } from "../../api/SensorSpecification/SensorSpecificationInterface";
import { ActionType } from "../../components/pages/types";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultSensorFunctionLine } from "./DefaultSensorFunctionLine";

export class SensorFunctionLineStore extends MobXStore<
  SensorFunctionLine,
  SensorFunctionLineInput
> {
  localIdCounter: number = 0;
  actionType?: ActionType = undefined;
  dialogOpen: boolean = false;

  constructor(root: RootStore) {
    super(
      root,
      sensorFunctionLineApi,
      "sensorFunctionLine",
      "Sensor Function Line",
      "Sensor Function Lines",
      defaultSensorFunctionLine
    );
    makeObservable(this, { dialogOpen: observable, setDialogOpen: action });
  }

  incrementLocalIdCounter() {
    this.localIdCounter++;
  }

  setActionType(actionType: ActionType) {
    this.actionType = actionType;
  }

  setDialogOpen(dialogOpen: boolean) {
    this.dialogOpen = dialogOpen;
  }

  setItemFromList(id: number) {
    const item = this.itemList.find(
      (element) => id === element.sensorSpecification.id
    );
    this.setItem(item!);
  }

  async getItemListAsync(): Promise<void> {
    const result = await this.api.getAll();
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: SensorFunctionLine[] = [];
    if (result.data.length) {
      enrichedResults = result.data.map((item) => {
        item.localId = this.localIdCounter;
        this.incrementLocalIdCounter();
        return item;
      });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
  }

  async getItemListByForeignKeyAsync(
    id: number,
    foreignKey: string
  ): Promise<void> {
    const result = await this.api.getByForeignKeyId!(id, foreignKey);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: SensorFunctionLine[] = [];
    if (result.data.length) {
      enrichedResults = result.data.map((item) => {
        item.localId = this.localIdCounter;
        this.incrementLocalIdCounter();
        return item;
      });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
  }

  handleInsert(
    sensorFunctionLine: SensorFunctionLine,
    sensorSpecification: SensorSpecification
  ) {
    sensorFunctionLine.sensorSpecification = sensorSpecification;
    sensorFunctionLine.localId = this.localIdCounter;
    this.incrementLocalIdCounter();
    sensorFunctionLine.action = ActionType.New;
    let newItemList = [sensorFunctionLine];
    if (this.itemList.length) {
      newItemList = [...this.itemList, sensorFunctionLine];
    }
    this.setItemList(newItemList);
  }

  handleEdit(
    sensorFunctionLine: SensorFunctionLine,
    sensorSpecification: SensorSpecification
  ) {
    const newItemList = this.itemList.map((item) => {
      if (sensorFunctionLine.localId !== item.localId) {
        return item;
      }

      sensorFunctionLine.sensorSpecification = sensorSpecification;
      sensorFunctionLine.action = ActionType.Edit;
      return sensorFunctionLine;
    });
    this.setItemList(newItemList);
  }

  handleDelete() {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach((itemInList, index) => {
      if (this.item.localId !== itemInList.localId) {
        return;
      }
      switch (itemInList.action) {
        case undefined:
        case ActionType.Edit:
          runInAction(() => {
            this.itemList[index].action = ActionType.Delete;
          });
          break;
        case ActionType.New:
          runInAction(() => {
            this.itemList.splice(index, 1);
          });
          break;
      }
    });
  }

  async handleUpdateDeviceFunction(deviceFunctionId: number) {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach(async (item) => {
      const sensorFunctionLineInput: SensorFunctionLineInput = {
        id: item.id,
        sensorSpecificationId: item.sensorSpecification.id,
        deviceFunctionId: deviceFunctionId,
        versionNumber: 0,
        quantity: item.quantity,
      };
      switch (item.action) {
        case ActionType.New: {
          let result = await sensorFunctionLineApi.insert(
            sensorFunctionLineInput
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Edit: {
          let result = await sensorFunctionLineApi.update(
            sensorFunctionLineInput
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Delete: {
          let result = await sensorFunctionLineApi.delete(
            sensorFunctionLineInput.id
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            runInAction(() => {
              item.action = undefined;
            });
            break;
          }
          break;
        }
      }
    });
  }
}
