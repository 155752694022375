import { GridColumns } from "@material-ui/data-grid";
import { Device } from "../../../../api/Device/DeviceInterface";
import { TbDevice } from "../../../../api/TbDevice/TbDeviceInterface";

function getDeviceRole(params: any) {
  const device: Device = params.row;
  if (device.deviceRole) {
    return device.deviceRole.name;
  }
}

function getFormattedTimeFromUtc(params: any) {
  const tbDevice: TbDevice = params.row;
  if (tbDevice.createdTime) {
    return new Date(tbDevice.createdTime);
  }
}

export const DeviceConfigListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    hide: true,
  },
  {
    field: "type",
    headerName: "Command Type",
    flex: 1,
  },
  {
    field: "level",
    headerName: "Command Level",
    flex: 1,
  },
  {
    field: "formattedValue",
    headerName: "Value",
    flex: 3,
  },
  {
    field: "value",
    headerName: "Raw Value",
    hide: true,
  },
  {
    field: "record",
    headerName: "Record",
    flex: 6,
  },
];
