import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  ApiArrayResponseSuccess,
  ApiResponseFailure,
} from "../types/apiResponse";
import { axiosGetByForeignKeyIdRequest } from "../util/axiosRequest";
import {
  SensorFunctionInstanceChannel,
  SensorFunctionInstanceChannelInput,
} from "./SensorFunctionInstanceChannelInterface";

export class SensorFunctionInstanceChannelApi extends AbstractApi<
  SensorFunctionInstanceChannel,
  SensorFunctionInstanceChannelInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "sensorFunctionInstanceChannel");
  }

  public async getByForeignKeyId(
    id: number
  ): Promise<
    ApiArrayResponseSuccess<SensorFunctionInstanceChannel> | ApiResponseFailure
  > {
    return axiosGetByForeignKeyIdRequest(
      this.baseUrl,
      this.endpoint,
      "deviceFunctionInstance",
      id
    );
  }
}
