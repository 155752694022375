import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  ApiArrayResponseSuccess,
  ApiObjectResponseSuccess,
  ApiResponseFailure,
} from "../types/apiResponse";
import {
  axiosGetByForeignKeyIdRequest,
  axiosInsertRequest,
  axiosUpdateRequest,
} from "../util/axiosRequest";
import {
  SensorFunctionLine,
  SensorFunctionLineInput,
} from "./SensorFunctionLineInterface";

export class SensorFunctionLineApi extends AbstractApi<
  SensorFunctionLine,
  SensorFunctionLineInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "sensorFunctionLine");
  }

  public async update(
    record: SensorFunctionLineInput
  ): Promise<
    ApiObjectResponseSuccess<SensorFunctionLine> | ApiResponseFailure
  > {
    return axiosUpdateRequest<SensorFunctionLine, SensorFunctionLineInput>(
      this.baseUrl,
      this.endpoint,
      record
    );
  }

  public async insert(
    record: SensorFunctionLineInput
  ): Promise<
    ApiObjectResponseSuccess<SensorFunctionLine> | ApiResponseFailure
  > {
    return axiosInsertRequest<SensorFunctionLine, SensorFunctionLineInput>(
      this.baseUrl,
      this.endpoint,
      record
    );
  }

  public async getByForeignKeyId(
    id: number
  ): Promise<ApiArrayResponseSuccess<SensorFunctionLine> | ApiResponseFailure> {
    return axiosGetByForeignKeyIdRequest(
      this.baseUrl,
      this.endpoint,
      "deviceFunction",
      id
    );
  }
}
