import { AbstractAPI as AbstractApi } from "../abstractApi";
import { ServiceAllocation } from "../ServiceAllocation/ServiceAllocationInterface";
import {
  ApiArrayResponseSuccess,
  ApiResponseFailure,
} from "../types/apiResponse";
import { axiosGetByForeignKeyIdRequest } from "../util/axiosRequest";
import {
  PersonServicePackage,
  PersonServicePackageInput,
} from "./PersonServicePackageInterface";
export class PersonServicePackageApi extends AbstractApi<
  PersonServicePackage,
  PersonServicePackageInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "personServicePackage");
  }
  public async getByForeignKeyId(
    id: number
  ): Promise<
    ApiArrayResponseSuccess<PersonServicePackage> | ApiResponseFailure
  > {
    return axiosGetByForeignKeyIdRequest(
      this.baseUrl,
      this.endpoint,
      "person",
      id
    );
  }
}
