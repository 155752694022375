import { action, makeObservable, observable, runInAction } from "mobx";
import { personServiceAllocationApi } from "../../api/api";
import {
  PersonServiceAllocation,
  PersonServiceAllocationInput,
} from "../../api/PersonServiceAllocation/PersonServiceAllocationInterface";
import { PersonServicePackage } from "../../api/PersonServicePackage/PersonServicePackageInterface";
import { ServiceAllocationApi } from "../../api/ServiceAllocation/ServiceAllocation";
import { ServicePackage } from "../../api/ServicePackage/ServicePackageInterface";
import { ActionType } from "../../components/pages/types";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultPersonServiceAllocation } from "./DefaultPersonServiceAllocation";

export class PersonServiceAllocationStore extends MobXStore<
  PersonServiceAllocation,
  PersonServiceAllocationInput
> {
  localIdCounter: number = 0;
  actionType?: ActionType = undefined;
  dialogOpen: boolean = false;
  serviceAllocationApi: ServiceAllocationApi;
  changedPersonServicePackage?: PersonServicePackage = undefined;

  constructor(root: RootStore, serviceAllocationApi: ServiceAllocationApi) {
    super(
      root,
      personServiceAllocationApi,
      "personServiceAllocation",
      "Person Service Allocation",
      "Person Service Allocations",
      defaultPersonServiceAllocation
    );
    makeObservable(this, {
      dialogOpen: observable,
      changedPersonServicePackage: observable,
      setDialogOpen: action,
      setChangedPersonServicePackage: action,
    });
    this.serviceAllocationApi = serviceAllocationApi;
  }
  incrementLocalIdCounter() {
    this.localIdCounter++;
  }

  setActionType(actionType: ActionType) {
    this.actionType = actionType;
  }

  setDialogOpen(dialogOpen: boolean) {
    this.dialogOpen = dialogOpen;
  }

  setChangedPersonServicePackage(personServicePackage: PersonServicePackage) {
    this.changedPersonServicePackage = personServicePackage;
  }

  setItemFromList(id: number) {
    const item = this.itemList.find((element) => id === element.id);
    this.setItem(item!);
  }

  async getItemListByForeignKeyAsync(
    id: number,
    foreignKey: string
  ): Promise<void> {
    const result = await this.api.getByForeignKeyId!(id, foreignKey);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: PersonServiceAllocation[] = [];
    if (result.data.length) {
      enrichedResults = result.data.map((item: PersonServiceAllocation) => {
        item.localId = this.localIdCounter;
        this.incrementLocalIdCounter();
        return item;
      });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
  }

  async handleInsert(servicePackage: ServicePackage, date: Date) {
    let newItemList = [];
    let serviceAllocations = await this.serviceAllocationApi.getByForeignKeyId(
      Number(servicePackage.id)
    );
    if ("error" in serviceAllocations) {
      this.setAndShowMessage(JSON.stringify(serviceAllocations.error));
      this.setMessageSeverity("error");
      return;
    }
    if (serviceAllocations.data.length) {
      serviceAllocations.data.forEach((serviceAllocation) => {
        let lastUpdateTime = date;
        if (lastUpdateTime === null) {
          lastUpdateTime = new Date();
        }
        const personServiceAllocation: PersonServiceAllocation = {
          id: 0,
          serviceAllocation: serviceAllocation,
          balance: serviceAllocation.allocation,
          localId: this.localIdCounter,
          lastUpdateTime: lastUpdateTime,
          nextUpdateTime: null,
          action: ActionType.New,
        };
        this.incrementLocalIdCounter();
        newItemList = [personServiceAllocation];
        if (this.itemList.length) {
          newItemList = [...this.itemList, personServiceAllocation];
        }
        this.setItemList(newItemList);
      });
    }
  }

  async handleEdit(servicePackage: ServicePackage) {
    let newItemList: PersonServiceAllocation[] = [];

    //handle rows that need to be removed
    if (this.changedPersonServicePackage!.id === servicePackage.id) {
      return;
    }
    this.itemList.forEach((item) => {
      if (
        Number(this.changedPersonServicePackage!.servicePackage.id) !==
        Number(item.serviceAllocation.servicePackage.id)
      ) {
        newItemList.push(item);
      } else if (item.action !== ActionType.New) {
        item.action = ActionType.Delete;
        newItemList.push(item);
      }
    });

    // handle rows that need to be added
    let serviceAllocations = await this.serviceAllocationApi.getByForeignKeyId(
      Number(servicePackage.id)
    );
    if ("error" in serviceAllocations) {
      this.setAndShowMessage(JSON.stringify(serviceAllocations.error));
      this.setMessageSeverity("error");
      return;
    }
    if (!serviceAllocations.data.length) {
      this.setItemList(newItemList);
      return;
    }
    serviceAllocations.data.forEach((serviceAllocation) => {
      const personServiceAllocation: PersonServiceAllocation = {
        id: 0,
        serviceAllocation: serviceAllocation,
        balance: serviceAllocation.allocation,
        localId: this.localIdCounter,
        lastUpdateTime: new Date(),
        nextUpdateTime: null,
        action: ActionType.New,
      };
      this.incrementLocalIdCounter();
      newItemList.push(personServiceAllocation);
    });
    this.setItemList(newItemList);
  }

  handleDelete() {
    let newItemList: PersonServiceAllocation[] = [];
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach((item) => {
      if (
        Number(this.changedPersonServicePackage!.servicePackage.id) !==
        Number(item.serviceAllocation.servicePackage.id)
      ) {
        newItemList.push(item);
      } else if (item.action !== ActionType.New) {
        item.action = ActionType.Delete;
        newItemList.push(item);
      }
    });
    this.setItemList(newItemList);
  }

  async handleUpdatePerson(personId: number) {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach(async (item) => {
      const personServiceAllocationInput: PersonServiceAllocationInput = {
        id: item.id,
        personId: personId,
        serviceAllocationId: item.serviceAllocation.id,
        balance: item.balance,
        lastUpdateTime: item.lastUpdateTime,
        nextUpdateTime: item.nextUpdateTime,
        versionNumber: 0,
      };
      switch (item.action) {
        case ActionType.New: {
          let result = await this.api.insert(personServiceAllocationInput);
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Edit: {
          let result = await this.api.update(personServiceAllocationInput);
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Delete: {
          let result = await this.api.delete(personServiceAllocationInput.id!);
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            runInAction(() => {
              item.action = undefined;
            });
            break;
          }
        }
      }
    });
  }
}
