import { Button, Typography } from "@material-ui/core";
import { GridRowParams } from "@material-ui/data-grid";
import AddIcon from "@material-ui/icons/Add";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useRootStore } from "../../../../hooks/useRootStore";
import DataTable from "../../../DataTable/DataTable";
import DeviceConfigDataTableMenu from "../../../DeviceConfigDataTableMenu/DeviceConfigDataTableMenu";
import { PageType } from "../../types";
import { DeviceConfigListTableColumns } from "./TableColumnDefinition";

const DeviceConfigListPage: FC = () => {
  const { deviceConfigStore, userInterfaceStore } = useRootStore();

  const handleRowClick = (
    param: GridRowParams,
    event: React.MouseEvent<Element, globalThis.MouseEvent>
  ) => {
    event.preventDefault();
    userInterfaceStore.setMouseXMouseY(event.clientX - 2, event.clientY - 4);
    userInterfaceStore.setId(param.id);
  };

  const handleButtonClick = () => {
    userInterfaceStore.setPageType(PageType.New);
  };

  useEffect(() => {
    autorun(async () => {
      await deviceConfigStore.getItemListAsync();
    });
  }, [deviceConfigStore]);

  return (
    <Fragment>
      <DeviceConfigDataTableMenu store={deviceConfigStore} disableDuplicate />
      <Typography variant="h3" component="h3">
        {deviceConfigStore.pluralDisplayName}
      </Typography>
      <Button
        component={NavLink}
        to={`/${deviceConfigStore.endpoint}/new`}
        variant="contained"
        color="primary"
        type="submit"
        onClick={handleButtonClick}
        startIcon={<AddIcon />}
      >
        New {deviceConfigStore.singularDisplayName}
      </Button>
      <DataTable
        rows={deviceConfigStore.itemList}
        columns={DeviceConfigListTableColumns}
        pageSize={20}
        handleRowClick={handleRowClick}
      ></DataTable>
      <Button
        component={NavLink}
        to={`/${deviceConfigStore.endpoint}/new`}
        variant="contained"
        color="primary"
        type="submit"
        onClick={handleButtonClick}
        startIcon={<AddIcon />}
      >
        New {deviceConfigStore.singularDisplayName}
      </Button>
    </Fragment>
  );
};

export default observer(DeviceConfigListPage);
