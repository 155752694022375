import {
  ResourceUnit,
  ResourceUnitInput,
} from "../../api/ResourceUnit/ResourceUnitInterface";
import { resourceUnitApi } from "../../api/api";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultResourceUnit } from "./DefaultResourceUnit";

export class ResourceUnitStore extends MobXStore<
  ResourceUnit,
  ResourceUnitInput
> {
  constructor(root: RootStore) {
    super(
      root,
      resourceUnitApi,
      "resourceUnit",
      "Resource Unit",
      "Resource Units",
      defaultResourceUnit
    );
  }
}
