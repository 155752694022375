import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { DeviceFunctionInstanceDevice } from "../../../../api/DeviceFunctionInstanceDevice/DeviceFunctionInstanceDeviceInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { ActionType } from "../../types";

const DeviceFunctionInstanceDeviceTable: FC = () => {
  const { deviceFunctionInstanceDeviceStore } = useRootStore();

  const handleRowClick = (row: DeviceFunctionInstanceDevice) => {
    deviceFunctionInstanceDeviceStore.setItemFromList(row.id);
    deviceFunctionInstanceDeviceStore.setActionType(ActionType.Edit);
    deviceFunctionInstanceDeviceStore.setDialogOpen(true);
  };

  function renderTableRow(row: DeviceFunctionInstanceDevice) {
    if (row.action === ActionType.Delete) {
      return;
    }

    return (
      <TableRow key={row.localId} onClick={() => handleRowClick(row)}>
        <TableCell>{row.deviceFunctionInstance.name}</TableCell>
        <TableCell>
          {row.roomDeviceFunctionInstanceDeviceList.length
            ? row.roomDeviceFunctionInstanceDeviceList.map((item) => {
                return `${item.room.name},`;
              })
            : "None"}
        </TableCell>
        <TableCell>
          {row.areaDeviceFunctionInstanceDeviceList.length
            ? row.areaDeviceFunctionInstanceDeviceList.map((item) => {
                return `${item.area.name},`;
              })
            : "None"}
        </TableCell>
        <TableCell>{row.isInUse ? "True" : "False"}</TableCell>
      </TableRow>
    );
  }

  return (
    <Table aria-label="device function instance device">
      <TableHead>
        <TableRow>
          <TableCell>Device Function Instance</TableCell>
          <TableCell>Assigned Rooms</TableCell>
          <TableCell>Assigned Areas</TableCell>
          <TableCell>Functional?</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {deviceFunctionInstanceDeviceStore.itemList.length
          ? deviceFunctionInstanceDeviceStore.itemList.map(
              (row: DeviceFunctionInstanceDevice) => {
                return renderTableRow(row);
              }
            )
          : null}
      </TableBody>
    </Table>
  );
};

export default observer(DeviceFunctionInstanceDeviceTable);
