import { number, object, string } from "yup";
import { buildingApi } from "../../../../api/api";
import { occurrencesInStringArray } from "../../../../utils/occurrencesInArray";
import { PageType } from "../../types";

export function generateSchema(type: PageType) {
  const schema = object().shape({
    siteName: string()
      .required()
      .test(
        "checkSiteNameUnique",
        "The site name already exists!",
        async (value: unknown): Promise<boolean> => {
          if (typeof value !== "string") {
            return false;
          }
          const buildingList = await buildingApi.getAll();
          if ("error" in buildingList) {
            return false;
          }
          if (!buildingList.data.length) {
            return true;
          }
          const buildingSiteNames = buildingList.data.map(
            (building) => building.siteName
          );
          const occurrences = occurrencesInStringArray(
            value,
            buildingSiteNames
          );
          switch (type) {
            case PageType.New:
            case PageType.NewDuplicate:
              return occurrences < 1;
            case PageType.Existing:
            case PageType.Inserted:
              return occurrences < 2;
          }
          return false;
        }
      ),
    customer: object().shape({
      id: number().required().positive(),
    }),
    latitude: number().required(),
    longitude: number().required(),
  });
  return schema;
}
