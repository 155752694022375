import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { DeviceFunctionInstanceDevice } from "../../../../api/DeviceFunctionInstanceDevice/DeviceFunctionInstanceDeviceInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import CheckboxInputField from "../../../Form/CheckboxInputField/CheckboxInputField";
import NumberInputField from "../../../Form/NumberInputField/NumberInputField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import { ActionType } from "../../types";
import { generateSchema } from "./DeviceFunctionInstanceDeviceValidation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subHeading: {
      marginTop: 10,
    },
  })
);

const DeviceFunctionInstanceDeviceDialogForm: FC<FormProps> = ({
  id,
}: FormProps) => {
  const classes = useStyles();
  const { deviceFunctionInstanceDeviceStore } = useRootStore();

  const handleClose = () => {
    deviceFunctionInstanceDeviceStore.setDialogOpen(false);
  };

  const handleDelete = () => {
    deviceFunctionInstanceDeviceStore.handleDelete();
    handleClose();
  };

  const onSubmit = (values: DeviceFunctionInstanceDevice) => {
    switch (deviceFunctionInstanceDeviceStore.actionType) {
      case ActionType.Edit:
        deviceFunctionInstanceDeviceStore.handleEdit(values);
        break;
    }
    handleClose();
  };

  const deviceFunctionActuatorSchema = useMemo(
    () =>
      generateSchema(
        deviceFunctionInstanceDeviceStore.item
          .roomDeviceFunctionInstanceDeviceList
      ),
    [
      deviceFunctionInstanceDeviceStore.item
        .roomDeviceFunctionInstanceDeviceList,
    ]
  );

  const resolver = useYupValidationResolver(deviceFunctionActuatorSchema);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: deviceFunctionInstanceDeviceStore.defaultItem,
    resolver,
  });

  useEffect(() => {
    autorun(async () => {
      reset(deviceFunctionInstanceDeviceStore.item);
    });
  }, [reset, deviceFunctionInstanceDeviceStore.item]);

  return (
    <Dialog
      open={deviceFunctionInstanceDeviceStore.dialogOpen}
      onClose={handleClose}
      aria-labelledby="actuator-function-line-form-dialog-title"
    >
      <DialogTitle id="actuator-function-line-form-dialog-title">
        {deviceFunctionInstanceDeviceStore.actionType === ActionType.Edit &&
          "Modify "}
        Device Function Instance
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {deviceFunctionInstanceDeviceStore.actionType === ActionType.Edit &&
            "Modify existing device function instance "}
          {deviceFunctionInstanceDeviceStore.actionType === ActionType.New &&
            "Add a new actuator "}
          for the device
        </DialogContentText>
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
          <TextInputField
            name="deviceFunctionInstance.name"
            displayName="Device Function Instance"
            errors={errors}
            control={control}
            readOnly={true}
          />
          {deviceFunctionInstanceDeviceStore.item
            .areaDeviceFunctionInstanceDeviceList.length ? (
            <Typography
              className={classes.subHeading}
              variant="h6"
              component="h6"
            >
              Assigned Areas
            </Typography>
          ) : (
            <Fragment />
          )}
          {deviceFunctionInstanceDeviceStore.item.areaDeviceFunctionInstanceDeviceList.map(
            (item) => {
              return <Typography>{item.area.name}</Typography>;
            }
          )}
          {deviceFunctionInstanceDeviceStore.item
            .roomDeviceFunctionInstanceDeviceList.length ? (
            <Typography
              className={classes.subHeading}
              variant="h6"
              component="h6"
            >
              Assigned Rooms
            </Typography>
          ) : (
            <Fragment />
          )}
          {deviceFunctionInstanceDeviceStore.item.roomDeviceFunctionInstanceDeviceList.map(
            (item) => {
              return (
                <Typography>{`${item.room.name} (Floor ${item.room.floor}, Room ${item.room.number})`}</Typography>
              );
            }
          )}
          <CheckboxInputField
            name="isInUse"
            displayName="Functional?"
            errors={errors}
            control={control}
          ></CheckboxInputField>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {deviceFunctionInstanceDeviceStore.actionType === ActionType.Edit && (
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        )}
        <Button form={id} type="submit" color="primary">
          {deviceFunctionInstanceDeviceStore.actionType === ActionType.Edit &&
            "Update"}
          {deviceFunctionInstanceDeviceStore.actionType === ActionType.New &&
            "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(DeviceFunctionInstanceDeviceDialogForm);
