import DateFnsUtils from "@date-io/date-fns";
import { FormControl, FormHelperText } from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { FC } from "react";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";
type DateTimeFieldProps = {
  name: string;
  displayName: string;
  errors: DeepMap<any, FieldError>;
  control: Control<any>;
  handleChange: (date: MaterialUiPickersDate) => void;
  readOnly?: boolean;
};
const DateTimeField: FC<DateTimeFieldProps> = ({
  name,
  displayName,
  errors,
  control,
  handleChange,
  readOnly,
}) => {
  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              {...rest}
              {...fieldState}
              label={displayName}
              views={["year", "month", "date", "hours", "minutes", "seconds"]}
              format="dd/MM/yyyy HH:mm:ss"
              inputVariant={readOnly ? "standard" : "outlined"}
              error={errors[name] ? true : false}
              clearable
              onChange={handleChange}
              readOnly={readOnly}
              ampm={false}
            />
          </MuiPickersUtilsProvider>
        )}
      />
      {errors[name] && (
        <FormHelperText error>{errors[name].message}</FormHelperText>
      )}
    </FormControl>
  );
};
export default DateTimeField;
