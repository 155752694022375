import axios, { AxiosResponse } from "axios";
import { TbAsset } from "../TbAsset/TbAssetInterface";
import {
  TbCreateCustomerRequestBody,
  TbCustomer,
  TbUpdateCustomerRequestBody,
} from "./TbCustomerInterface";

export class TbCustomerApi {
  protected readonly baseUrl: string;
  protected readonly endpoint: string;

  constructor(baseUrl: string, endpoint: string) {
    this.baseUrl = baseUrl;
    this.endpoint = endpoint;
  }

  async getAll(): Promise<TbCustomer[]> {
    return await axios
      .get(`${this.baseUrl}/tb/${this.endpoint}`)
      .then((response: AxiosResponse) => {
        return response.data as unknown as TbCustomer[];
      });
  }

  public async assignAssetToCustomer(
    customerUuid: string,
    assetId: string
  ): Promise<TbAsset> {
    return await axios
      .post(
        `${this.baseUrl}/tb/${this.endpoint}/${customerUuid}/asset/${assetId}`
      )
      .then((response: AxiosResponse) => {
        return response.data as unknown as TbAsset;
      });
  }

  public async getOne(customerId: number): Promise<TbCustomer> {
    return await axios
      .get(`${this.baseUrl}/tb/${this.endpoint}/${customerId}`)
      .then((response: AxiosResponse) => {
        return response.data as unknown as TbCustomer;
      });
  }

  public async insert(
    customerToInsert: TbCreateCustomerRequestBody
  ): Promise<TbCustomer> {
    return await axios
      .post(`${this.baseUrl}/tb/${this.endpoint}`, customerToInsert)
      .then((response: AxiosResponse) => {
        return response.data as unknown as TbCustomer;
      });
  }
  public async update(
    customerToUpdate: TbUpdateCustomerRequestBody,
    customerId: number
  ): Promise<TbCustomer> {
    return await axios
      .post(
        `${this.baseUrl}/tb/${this.endpoint}/${customerId}`,
        customerToUpdate
      )
      .then((response: AxiosResponse) => {
        return response.data as unknown as TbCustomer;
      });
  }
  public async delete(customerId: number): Promise<void> {
    return await axios
      .delete(`${this.baseUrl}/tb/${this.endpoint}/${customerId}`)
      .then((response: AxiosResponse) => {
        return response.data as unknown as void;
      });
  }
}
