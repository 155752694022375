import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { PersonServicePackage } from "../../../../api/PersonServicePackage/PersonServicePackageInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import { FormProps } from "../../../Form/types";
import { ActionType } from "../../types";
import { generateSchema } from "./PersonServicePackageValidation";

const PersonServicePackageDialogForm: FC<FormProps> = ({ id }: FormProps) => {
  const {
    personServicePackageStore,
    personServiceAllocationStore,
    servicePackageStore,
    personStore,
  } = useRootStore();

  const handleClose = () => {
    personServicePackageStore.setDialogOpen(false);
  };

  const handleDelete = () => {
    personServiceAllocationStore.setChangedPersonServicePackage(
      personServicePackageStore.item
    );
    personServicePackageStore.handleDelete();
    personServiceAllocationStore.handleDelete();
    handleClose();
  };

  const onSubmit = (values: PersonServicePackage) => {
    personServiceAllocationStore.setChangedPersonServicePackage(
      personServicePackageStore.item
    );
    const servicePackage = servicePackageStore.getItemFromList(
      Number(values.servicePackage.id)
    );
    switch (personServicePackageStore.actionType) {
      case ActionType.Edit:
        personServicePackageStore.handleEdit(values, servicePackage);
        personServiceAllocationStore.handleEdit(servicePackage);
        break;
      case ActionType.New:
        personServicePackageStore.handleInsert(values, servicePackage);
        personServiceAllocationStore.handleInsert(
          servicePackage,
          personStore.item.startDate!
        );
    }
    handleClose();
  };

  const personServicePackageSchema = useMemo(
    () => generateSchema(personServicePackageStore.itemList),
    [personServicePackageStore.itemList]
  );

  const resolver = useYupValidationResolver(personServicePackageSchema);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: personServicePackageStore.defaultItem,
    resolver,
  });

  useEffect(() => {
    autorun(async () => {
      reset(personServicePackageStore.item);
    });
  }, [reset, personServicePackageStore.item]);

  const servicePackages = servicePackageStore.itemList;
  let servicePackageOptions: { id: number; value: string }[] = [];
  if (servicePackages.length) {
    servicePackageOptions = servicePackages.map((servicePackage) => {
      return {
        id: servicePackage.id,
        value: `${servicePackage.name} (${servicePackage.description})`,
      };
    });
  }

  return (
    <Dialog
      open={personServicePackageStore.dialogOpen}
      onClose={handleClose}
      aria-labelledby="person-service-package-form-dialog-title"
    >
      <DialogTitle id="person-service-package-form-dialog-title">
        {personServicePackageStore.actionType === ActionType.Edit && "Modify "}
        Person Service Packages
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {personServicePackageStore.actionType === ActionType.Edit &&
            "Modify existing "}
          {personServicePackageStore.actionType === ActionType.New &&
            "Add a new "}
          person service package
        </DialogContentText>
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
          <SelectInputField
            name="servicePackage.id"
            displayName="Service Package"
            options={servicePackageOptions}
            errors={errors}
            control={control}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {personServicePackageStore.actionType === ActionType.Edit && (
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        )}
        <Button form={id} type="submit" color="primary">
          {personServicePackageStore.actionType === ActionType.Edit && "Update"}
          {personServicePackageStore.actionType === ActionType.New && "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(PersonServicePackageDialogForm);
