import axios, { AxiosResponse } from "axios";
import { TbEntityType } from "../TbEntityRelation/TbEntityRelationInterface";
import {
  TbAsset,
  TbCreateAssetRequestBody,
  TbUpdateAssetRequestBody,
} from "./TbAssetInterface";

export class TbAssetApi {
  protected readonly baseUrl: string;
  protected readonly endpoint: string;

  constructor(baseUrl: string, endpoint: string) {
    this.baseUrl = baseUrl;
    this.endpoint = endpoint;
  }

  async getAssetTypes(): Promise<TbEntityType[]> {
    return await axios
      .get(`${this.baseUrl}/tb/${this.endpoint}/types`)
      .then((response: AxiosResponse) => {
        return response.data as unknown as TbEntityType[];
      });
  }

  async getAssetsByCustomer(
    customerId: string,
    assetType: string
  ): Promise<TbAsset[]> {
    return await axios
      .get(
        `${this.baseUrl}/tb/${this.endpoint}/${assetType}/customer/${customerId}`
      )
      .then((response: AxiosResponse) => {
        return response.data as unknown as TbAsset[];
      });
  }

  public async insert(
    assetToInsert: TbCreateAssetRequestBody | TbUpdateAssetRequestBody
  ): Promise<TbAsset> {
    return await axios
      .post(`${this.baseUrl}/tb/${this.endpoint}`, assetToInsert)
      .then((response: AxiosResponse) => {
        return response.data as unknown as TbAsset;
      });
  }
}
