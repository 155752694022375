import { DeviceSpecificationActuatorSpecification } from "../../api/DeviceSpecificationActuatorSpecification/DeviceSpecificationActuatorSpecificationInterface";
import { defaultActuatorSpecification } from "../ActuatorSpecification/DefaultActuatorSpecification";
import { defaultDeviceSpecification } from "../DeviceSpecification/DefaultDeviceSpecification";
export const defaultDeviceSpecificationActuatorSpecification: DeviceSpecificationActuatorSpecification =
  {
    id: 0,
    localId: 0,
    action: undefined,
    actuatorSpecification: defaultActuatorSpecification,
    deviceSpecification: defaultDeviceSpecification,
    quantity: 0,
  };
