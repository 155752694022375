import { personTypeApi } from "../../api/api";
import {
  PersonType,
  PersonTypeInput,
} from "../../api/PersonType/PersonTypeInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultPersonType } from "./DefaultPersonType";

export class PersonTypeStore extends MobXStore<PersonType, PersonTypeInput> {
  constructor(root: RootStore) {
    super(
      root,
      personTypeApi,
      "personType",
      "Person Type",
      "Person Types",
      defaultPersonType
    );
  }
}
