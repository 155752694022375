import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { ServiceAllocation } from "../../../../api/ServiceAllocation/ServiceAllocationInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import CheckboxInputField from "../../../Form/CheckboxInputField/CheckboxInputField";
import NumberInputField from "../../../Form/NumberInputField/NumberInputField";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import { FormProps } from "../../../Form/types";
import { ActionType } from "../../types";
import { generateSchema } from "./ServiceAllocationValidation";

const ServiceAllocationDialogForm: FC<FormProps> = ({ id }: FormProps) => {
  const { serviceAllocationStore, resourceStore, periodStore } = useRootStore();

  const handleClose = () => {
    serviceAllocationStore.setDialogOpen(false);
  };

  const handleDelete = () => {
    serviceAllocationStore.handleDelete();
    handleClose();
  };

  const onSubmit = (values: ServiceAllocation) => {
    const resource = resourceStore.getItemFromList(values.resource.id);
    const period = periodStore.getItemFromList(values.period.id);
    switch (serviceAllocationStore.actionType) {
      case ActionType.Edit:
        serviceAllocationStore.handleEdit(values, resource, period);
        break;
      case ActionType.New:
        serviceAllocationStore.handleInsert(values, resource, period);
    }
    handleClose();
  };

  const serviceAllocationSchema = useMemo(() => generateSchema(), []);

  const resolver = useYupValidationResolver(serviceAllocationSchema);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: serviceAllocationStore.defaultItem,
    resolver,
  });

  useEffect(() => {
    autorun(async () => {
      reset(serviceAllocationStore.item);
    });
  }, [reset, serviceAllocationStore.item]);

  const resources = resourceStore.itemList;
  let resourceOptions: { id: number; value: string }[] = [];
  if (resources.length) {
    resourceOptions = resources.map((resource) => {
      return {
        id: resource.id,
        value: `${resource.name} (${resource.resourceUnit.unit})`,
      };
    });
  }
  const periods = periodStore.itemList;
  let periodOptions: { id: number; value: string }[] = [];
  if (periods.length) {
    periodOptions = periods.map((period) => {
      return {
        id: period.id,
        value: period.name,
      };
    });
  }

  return (
    <Dialog
      open={serviceAllocationStore.dialogOpen}
      onClose={handleClose}
      aria-labelledby="service-allocation-form-dialog-title"
    >
      <DialogTitle id="service-allocation-form-dialog-title">
        {serviceAllocationStore.actionType === ActionType.Edit && "Modify "}
        Service Allocation
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {serviceAllocationStore.actionType === ActionType.Edit &&
            "Modify existing "}
          {serviceAllocationStore.actionType === ActionType.New && "Add a new "}
          service allocation
        </DialogContentText>
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
          <SelectInputField
            name="resource.id"
            displayName="Resource"
            options={resourceOptions}
            errors={errors}
            control={control}
          />
          <SelectInputField
            name="period.id"
            displayName="Period"
            options={periodOptions}
            errors={errors}
            control={control}
          />
          <NumberInputField
            name="allocation"
            displayName="Allocation"
            errors={errors}
            control={control}
          />
          <CheckboxInputField
            name="carryOver"
            displayName="Carry Over?"
            errors={errors}
            control={control}
          ></CheckboxInputField>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {serviceAllocationStore.actionType === ActionType.Edit && (
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        )}
        <Button form={id} type="submit" color="primary">
          {serviceAllocationStore.actionType === ActionType.Edit && "Update"}
          {serviceAllocationStore.actionType === ActionType.New && "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(ServiceAllocationDialogForm);
