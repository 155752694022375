import { roomTypeApi } from "../../api/api";
import { RoomType, RoomTypeInput } from "../../api/RoomType/RoomTypeInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultRoomType } from "./DefaultRoomType";

export class RoomTypeStore extends MobXStore<RoomType, RoomTypeInput> {
  constructor(root: RootStore) {
    super(
      root,
      roomTypeApi,
      "roomType",
      "Room Type",
      "Room Types",
      defaultRoomType
    );
  }
}
