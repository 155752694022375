import { sensorSpecificationApi } from "../../api/api";
import {
  SensorSpecification,
  SensorSpecificationInput,
} from "../../api/SensorSpecification/SensorSpecificationInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultSensorSpecification } from "./DefaultSensorSpecification";

export class SensorSpecificationStore extends MobXStore<
  SensorSpecification,
  SensorSpecificationInput
> {
  constructor(root: RootStore) {
    super(
      root,
      sensorSpecificationApi,
      "sensorSpecification",
      "Sensor Specification",
      "Sensor Specifications",
      defaultSensorSpecification
    );
  }
}
