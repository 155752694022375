import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { DeviceSpecificationSensorSpecification } from "../../../../api/DeviceSpecificationSensorSpecification/DeviceSpecificationSensorSpecificationInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import NumberInputField from "../../../Form/NumberInputField/NumberInputField";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import { FormProps } from "../../../Form/types";
import { ActionType } from "../../types";
import { generateSchema } from "./DeviceSpecificationSensorSpecificationValidation";

const DeviceSpecificationSensorSpecificationDialogForm: FC<FormProps> = ({
  id,
}: FormProps) => {
  const {
    deviceSpecificationSensorSpecificationStore,
    sensorSpecificationStore,
  } = useRootStore();

  const handleClose = () => {
    deviceSpecificationSensorSpecificationStore.setDialogOpen(false);
  };

  const handleDelete = () => {
    deviceSpecificationSensorSpecificationStore.handleDelete();
    handleClose();
  };

  const onSubmit = (values: DeviceSpecificationSensorSpecification) => {
    const sensorSpecification = sensorSpecificationStore.getItemFromList(
      values.sensorSpecification.id
    );
    switch (deviceSpecificationSensorSpecificationStore.actionType) {
      case ActionType.Edit:
        deviceSpecificationSensorSpecificationStore.handleEdit(
          values,
          sensorSpecification
        );
        break;
      case ActionType.New:
        deviceSpecificationSensorSpecificationStore.handleInsert(
          values,
          sensorSpecification
        );
        break;
    }
    handleClose();
  };

  const deviceSpecificationSensorSchema = useMemo(
    () =>
      generateSchema(
        deviceSpecificationSensorSpecificationStore.itemList,
        deviceSpecificationSensorSpecificationStore.item
      ),
    [
      deviceSpecificationSensorSpecificationStore.itemList,
      deviceSpecificationSensorSpecificationStore.item,
    ]
  );

  const resolver = useYupValidationResolver(deviceSpecificationSensorSchema);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: deviceSpecificationSensorSpecificationStore.defaultItem,
    resolver,
  });

  useEffect(() => {
    autorun(async () => {
      reset(deviceSpecificationSensorSpecificationStore.item);
    });
  }, [reset, deviceSpecificationSensorSpecificationStore.item]);

  let sensorOptions = null;
  if (sensorSpecificationStore.itemList) {
    const sensorSpecifications = sensorSpecificationStore.itemList;
    sensorOptions = sensorSpecifications.map((sensorSpecification) => {
      return {
        id: sensorSpecification.id,
        value: `${sensorSpecification.manufacturer}-${sensorSpecification.type}-${sensorSpecification.model}`,
      };
    });
  }

  return (
    <Dialog
      open={deviceSpecificationSensorSpecificationStore.dialogOpen}
      onClose={handleClose}
      aria-labelledby="sensor-specification-form-dialog-title"
    >
      <DialogTitle id="sensor-specification-form-dialog-title">
        {deviceSpecificationSensorSpecificationStore.actionType ===
          ActionType.Edit && "Modify "}
        {deviceSpecificationSensorSpecificationStore.actionType ===
          ActionType.New && "Add a new "}
        Sensor
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {deviceSpecificationSensorSpecificationStore.actionType ===
            ActionType.Edit && "Modify existing sensor "}
          {deviceSpecificationSensorSpecificationStore.actionType ===
            ActionType.New && "Add a new sensor "}
          for the device specification
        </DialogContentText>
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
          <SelectInputField
            name="sensorSpecification.id"
            options={sensorOptions}
            displayName="Sensor"
            errors={errors}
            control={control}
          />
          <NumberInputField
            name="quantity"
            displayName="Quantity"
            errors={errors}
            control={control}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {deviceSpecificationSensorSpecificationStore.actionType ===
          ActionType.Edit && (
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        )}
        <Button form={id} type="submit" color="primary">
          {deviceSpecificationSensorSpecificationStore.actionType ===
            ActionType.Edit && "Update"}
          {deviceSpecificationSensorSpecificationStore.actionType ===
            ActionType.New && "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(DeviceSpecificationSensorSpecificationDialogForm);
