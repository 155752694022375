import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import {
  ResourceInput,
  ResourceSchema,
} from "../../../../api/Resource/ResourceInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import calculateReadOnly from "../../../../utils/calculateReadOnly";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./ResourceValidation";

const ResourceForm: FC<FormProps> = ({ id }: FormProps) => {
  const router = useRouter();
  const { resourceStore, resourceUnitStore, userInterfaceStore } =
    useRootStore();

  const resourceSchema = useMemo(
    () => generateSchema(userInterfaceStore.pageType!),
    [userInterfaceStore.pageType]
  );

  const resolver = useYupValidationResolver(resourceSchema);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    reset,
  } = useForm({
    defaultValues: resourceStore.defaultItem,
    resolver,
  });

  const onSubmit = async (values: ResourceSchema) => {
    let resource: ResourceInput = {
      name: values.name,
      resourceUnitId: values.resourceUnit.id,
    };
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
        resource.id = Number(router.query.id);
        await resourceStore.updateItemAsync(resource);
        break;
      case PageType.New:
      case PageType.NewDuplicate:
        await resourceStore.insertItemAsync(resource);
        userInterfaceStore.setPageType(PageType.Inserted);
        router.history.push(
          `/${resourceStore.endpoint}/edit/${resourceStore.item.id}`
        );
        break;
    }
  };

  useEffect(() => {
    autorun(async () => {
      reset(resourceStore.item);
    });
  }, [resourceStore, reset]);
  const readOnly = calculateReadOnly(userInterfaceStore.pageType);

  const resourceUnits = resourceUnitStore.itemList;
  let resourceUnitOptions: { id: number; value: string }[] = [];
  if (resourceUnits.length) {
    resourceUnitOptions = resourceUnits.map((resourceUnit) => {
      return {
        id: resourceUnit.id,
        value: resourceUnit.unit,
      };
    });
  }

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={resourceStore} />
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <TextInputField
          name="name"
          displayName="Resource Name"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <SelectInputField
          name="resourceUnit.id"
          options={resourceUnitOptions}
          displayName="Resource Unit"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
      </form>
    </Fragment>
  );
};

export default observer(ResourceForm);
