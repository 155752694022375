import { GridColumns } from "@material-ui/data-grid";
import { Area } from "../../../../api/Area/AreaInterface";

function getAreaStatus(params: any) {
  const area: Area = params.row;
  if (area.areaStatus) {
    return area.areaStatus.status;
  }
}

export const areaListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "name",
    headerName: "Area Name",
    flex: 1,
  },
  {
    field: "areaStatus",
    headerName: "Area Status",
    valueGetter: getAreaStatus,
    flex: 1,
  },
];
