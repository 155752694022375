import { GridColumns } from "@material-ui/data-grid";

export const roomStatusListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
  },
];
