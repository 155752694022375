import { SensorFunctionLine } from "../../api/SensorFunctionLine/SensorFunctionLineInterface";
import { defaultDeviceFunction } from "../DeviceFunction/DefaultDeviceFunction";
import { defaultSensorSpecification } from "../SensorSpecification/DefaultSensorSpecification";
export const defaultSensorFunctionLine: SensorFunctionLine = {
  id: 0,
  localId: 0,
  action: undefined,
  sensorSpecification: defaultSensorSpecification,
  deviceFunction: defaultDeviceFunction,
  quantity: 1,
  versionNumber: 0,
};
