import { number, object, SchemaOf } from "yup";
import {
  DeviceSpecificationActuatorSpecification,
  DeviceSpecificationActuatorSpecificationSchema,
} from "../../../../api/DeviceSpecificationActuatorSpecification/DeviceSpecificationActuatorSpecificationInterface";
import { occurrencesInNumberArray } from "../../../../utils/occurrencesInArray";

export function generateSchema(
  actuatorList: DeviceSpecificationActuatorSpecification[],
  actuator: DeviceSpecificationActuatorSpecification
) {
  const schema: SchemaOf<DeviceSpecificationActuatorSpecificationSchema> =
    object().shape({
      actuatorSpecification: object().shape({
        id: number()
          .required()
          .test(
            "checkOnlyOneOfEachActuator",
            "Please select a valid actuator",
            (value: unknown): boolean => {
              if (typeof value !== "number") {
                return false;
              }
              if (value === 0) {
                return false;
              }
              if (!actuatorList.length) {
                return true;
              }
              if (actuator.actuatorSpecification.id === value) {
                return true;
              }
              const actuatorSpecificationIdList = actuatorList.map(
                (actuatorItem) => actuatorItem.actuatorSpecification.id
              );
              const occurrences = occurrencesInNumberArray(
                value,
                actuatorSpecificationIdList
              );

              return occurrences < 1;
            }
          ),
      }),
      quantity: number().positive().integer().required(),
    });
  return schema;
}
