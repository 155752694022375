import { Button, Typography } from "@material-ui/core";
import { GridRowParams } from "@material-ui/data-grid";
import AddIcon from "@material-ui/icons/Add";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useRootStore } from "../../../../hooks/useRootStore";
import DataTable from "../../../DataTable/DataTable";
import DataTableMenu from "../../../DataTableMenu/DataTableMenu";
import { PageType } from "../../types";
import { roomListTableColumns } from "./TableColumnDefinition";

const RoomListPage: FC = () => {
  const { roomStore, userInterfaceStore } = useRootStore();

  const handleRowClick = (
    param: GridRowParams,
    event: React.MouseEvent<Element, globalThis.MouseEvent>
  ) => {
    event.preventDefault();
    userInterfaceStore.setMouseXMouseY(event.clientX - 2, event.clientY - 4);
    userInterfaceStore.setId(Number(param.id));
  };

  const handleButtonClick = () => {
    userInterfaceStore.setPageType(PageType.New);
  };

  useEffect(() => {
    autorun(async () => {
      await roomStore.getItemListAsync();
    });
  }, [roomStore]);

  return (
    <Fragment>
      <DataTableMenu store={roomStore} />
      <Typography variant="h3" component="h3">
        {roomStore.pluralDisplayName}
      </Typography>
      <Button
        component={NavLink}
        to={`/${roomStore.endpoint}/new`}
        variant="contained"
        color="primary"
        type="submit"
        onClick={handleButtonClick}
        startIcon={<AddIcon />}
      >
        New {roomStore.singularDisplayName}
      </Button>
      <DataTable
        rows={roomStore.itemList}
        columns={roomListTableColumns}
        pageSize={20}
        handleRowClick={handleRowClick}
      ></DataTable>
      <Button
        component={NavLink}
        to={`/${roomStore.endpoint}/new`}
        variant="contained"
        color="primary"
        type="submit"
        onClick={handleButtonClick}
        startIcon={<AddIcon />}
      >
        New {roomStore.singularDisplayName}
      </Button>
    </Fragment>
  );
};

export default observer(RoomListPage);
