import { tagModelApi } from "../../api/api";
import { TagModel, TagModelInput } from "../../api/TagModel/TagModelInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultTagModel } from "./DefaultTagModel";

export class TagModelStore extends MobXStore<TagModel, TagModelInput> {
  constructor(root: RootStore) {
    super(
      root,
      tagModelApi,
      "tagModel",
      "Tag Model",
      "Tag Models",
      defaultTagModel
    );
  }
}
