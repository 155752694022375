import * as React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { RootStoreProvider } from "./hooks/useRootStore";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <RootStoreProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </RootStoreProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
