import { object, SchemaOf, string } from "yup";
import { SensorSpecificationSchema } from "../../../../api/SensorSpecification/SensorSpecificationInterface";
import { PageType } from "../../types";

export function generateSchema(type: PageType) {
  const schema: SchemaOf<SensorSpecificationSchema> = object().shape({
    manufacturer: string().required(),
    type: string().required(),
    model: string().required(),
  });
  return schema;
}
