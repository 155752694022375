import { object, SchemaOf, string } from "yup";
import { ResourceUnitSchema } from "../../../../api/ResourceUnit/ResourceUnitInterface";
import { PageType } from "../../types";

export function generateSchema(type: PageType) {
  const schema: SchemaOf<ResourceUnitSchema> = object().shape({
    unit: string().required(),
    description: string().required(),
  });
  return schema;
}
