import { TbEntityRelation } from "../../api/TbEntityRelation/TbEntityRelationInterface";

export const defaultTbEntityRelation: TbEntityRelation = {
  id: {
    entityType: "DEVICE",
    id: "",
  },
  createdTime: 0,
  additionalInfo: {},
  tenantId: {
    entityType: "",
    id: "",
  },
  customerId: {
    entityType: "",
    id: "",
  },
  name: "",
  type: "",
  label: "",
};
