import { number, object, SchemaOf } from "yup";
import {
  DeviceSpecificationSensorSpecification,
  DeviceSpecificationSensorSpecificationSchema,
} from "../../../../api/DeviceSpecificationSensorSpecification/DeviceSpecificationSensorSpecificationInterface";
import { occurrencesInNumberArray } from "../../../../utils/occurrencesInArray";

export function generateSchema(
  sensorList: DeviceSpecificationSensorSpecification[],
  sensor: DeviceSpecificationSensorSpecification
) {
  const schema: SchemaOf<DeviceSpecificationSensorSpecificationSchema> =
    object().shape({
      sensorSpecification: object().shape({
        id: number()
          .required()
          .test(
            "checkOnlyOneOfEachSensor",
            "Please select a valid sensor",
            (value: unknown): boolean => {
              if (typeof value !== "number") {
                return false;
              }
              if (value === 0) {
                return false;
              }
              if (!sensorList.length) {
                return true;
              }
              if (sensor.sensorSpecification.id === value) {
                return true;
              }
              const sensorSpecificationIdList = sensorList.map(
                (sensorItem) => sensorItem.sensorSpecification.id
              );
              const occurrences = occurrencesInNumberArray(
                value,
                sensorSpecificationIdList
              );

              return occurrences < 1;
            }
          ),
      }),
      quantity: number().positive().integer().required(),
    });
  return schema;
}
