import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { DeviceSpecificationSensorSpecification } from "../../../../api/DeviceSpecificationSensorSpecification/DeviceSpecificationSensorSpecificationInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { ActionType } from "../../types";

const DeviceSpecificationSensorSpecificationTable: FC = () => {
  const { deviceSpecificationSensorSpecificationStore } = useRootStore();

  const handleNewSensorButton = () => {
    deviceSpecificationSensorSpecificationStore.resetItem();
    deviceSpecificationSensorSpecificationStore.setActionType(ActionType.New);
    deviceSpecificationSensorSpecificationStore.setDialogOpen(true);
  };

  const handleRowClick = (row: DeviceSpecificationSensorSpecification) => {
    deviceSpecificationSensorSpecificationStore.setItemFromList(
      row.sensorSpecification.id
    );
    deviceSpecificationSensorSpecificationStore.setActionType(ActionType.Edit);
    deviceSpecificationSensorSpecificationStore.setDialogOpen(true);
  };

  function renderTableRow(row: DeviceSpecificationSensorSpecification) {
    if (row.action === ActionType.Delete) {
      return;
    }

    return (
      <TableRow
        key={row.sensorSpecification.id}
        onClick={() => handleRowClick(row)}
      >
        <TableCell>
          {`${row.sensorSpecification.manufacturer}-${row.sensorSpecification.type}-${row.sensorSpecification.model}`}
        </TableCell>
        <TableCell align="right">{row.quantity}</TableCell>
      </TableRow>
    );
  }

  return (
    <Grid item xs={12} sm={6}>
      <Table aria-label="sensor specification">
        <TableHead>
          <TableRow>
            <TableCell>Sensor</TableCell>
            <TableCell align="right">Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deviceSpecificationSensorSpecificationStore.itemList.length
            ? deviceSpecificationSensorSpecificationStore.itemList.map(
                (row: DeviceSpecificationSensorSpecification) => {
                  return renderTableRow(row);
                }
              )
            : null}
        </TableBody>
      </Table>
      <Button
        variant="text"
        color="primary"
        type="submit"
        onClick={handleNewSensorButton}
      >
        New Sensor
      </Button>
    </Grid>
  );
};

export default observer(DeviceSpecificationSensorSpecificationTable);
