import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { RoomDeviceFunctionInstanceDevice } from "../../../../api/RoomDeviceFunctionInstanceDevice/RoomDeviceFunctionInstanceDeviceInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { ActionType } from "../../types";

const RoomDeviceFunctionInstanceDeviceTable: FC = () => {
  const { roomDeviceFunctionInstanceDeviceStore } = useRootStore();

  const handleNewRoomDeviceFunctionInstanceDeviceButton = () => {
    roomDeviceFunctionInstanceDeviceStore.resetItem();
    roomDeviceFunctionInstanceDeviceStore.setActionType(ActionType.New);
    roomDeviceFunctionInstanceDeviceStore.setDialogOpen(true);
  };

  const handleRowClick = (row: RoomDeviceFunctionInstanceDevice) => {
    roomDeviceFunctionInstanceDeviceStore.setItemFromList(row.id);
    roomDeviceFunctionInstanceDeviceStore.setActionType(ActionType.Edit);
    roomDeviceFunctionInstanceDeviceStore.setDialogOpen(true);
  };

  function renderTableRow(row: RoomDeviceFunctionInstanceDevice) {
    if (row.action === ActionType.Delete) {
      return;
    }
    return (
      <TableRow key={row.localId} onClick={() => handleRowClick(row)}>
        <TableCell>
          {row.deviceFunctionInstanceDevice.device.deviceRole.name}
        </TableCell>
        <TableCell>
          {row.deviceFunctionInstanceDevice.device.macAddress}
        </TableCell>
        <TableCell>
          {row.deviceFunctionInstanceDevice.deviceFunctionInstance.name}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Grid item>
      <Table aria-label="device function instance">
        <TableHead>
          <TableRow>
            <TableCell>Device Role</TableCell>
            <TableCell>MAC Address</TableCell>
            <TableCell>Device Function Instance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {roomDeviceFunctionInstanceDeviceStore.itemList.length
            ? roomDeviceFunctionInstanceDeviceStore.itemList.map(
                (row: RoomDeviceFunctionInstanceDevice) => {
                  return renderTableRow(row);
                }
              )
            : null}
        </TableBody>
      </Table>
      <Button
        variant="text"
        color="primary"
        type="submit"
        onClick={handleNewRoomDeviceFunctionInstanceDeviceButton}
      >
        Add New Device Function Instance Device
      </Button>
    </Grid>
  );
};

export default observer(RoomDeviceFunctionInstanceDeviceTable);
