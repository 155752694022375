import { action, makeObservable, observable, runInAction } from "mobx";
import {
  ActuatorFunctionLine,
  ActuatorFunctionLineInput,
} from "../../api/ActuatorFunctionLine/ActuatorFunctionLineInterface";
import { ActuatorSpecification } from "../../api/ActuatorSpecification/ActuatorSpecificationInterface";
import { actuatorFunctionLineApi } from "../../api/api";
import { ActionType } from "../../components/pages/types";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultActuatorFunctionLine } from "./DefaultActuatorFunctionLine";

export class ActuatorFunctionLineStore extends MobXStore<
  ActuatorFunctionLine,
  ActuatorFunctionLineInput
> {
  localIdCounter: number = 0;
  actionType?: ActionType = undefined;
  dialogOpen: boolean = false;

  constructor(root: RootStore) {
    super(
      root,
      actuatorFunctionLineApi,
      "actuatorFunctionLine",
      "Actuator Function Line",
      "Actuator Function Lines",
      defaultActuatorFunctionLine
    );
    makeObservable(this, { dialogOpen: observable, setDialogOpen: action });
  }

  incrementLocalIdCounter() {
    this.localIdCounter++;
  }

  setActionType(actionType: ActionType) {
    this.actionType = actionType;
  }

  setDialogOpen(dialogOpen: boolean) {
    this.dialogOpen = dialogOpen;
  }

  setItemFromList(id: number) {
    const item = this.itemList.find(
      (element) => id === element.actuatorSpecification.id
    );
    this.setItem(item!);
  }

  async getItemListAsync(): Promise<void> {
    const result = await this.api.getAll();
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: ActuatorFunctionLine[] = [];
    if (result.data.length) {
      enrichedResults = result.data.map((item) => {
        item.localId = this.localIdCounter;
        this.incrementLocalIdCounter();
        return item;
      });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
  }

  async getItemListByForeignKeyAsync(
    id: number,
    foreignKey: string
  ): Promise<void> {
    const result = await this.api.getByForeignKeyId!(id, foreignKey);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: ActuatorFunctionLine[] = [];
    if (result.data.length) {
      enrichedResults = result.data.map((item) => {
        item.localId = this.localIdCounter;
        this.incrementLocalIdCounter();
        return item;
      });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
  }

  handleInsert(
    actuatorFunctionLine: ActuatorFunctionLine,
    actuatorSpecification: ActuatorSpecification
  ) {
    actuatorFunctionLine.actuatorSpecification = actuatorSpecification;
    actuatorFunctionLine.localId = this.localIdCounter;
    this.incrementLocalIdCounter();
    actuatorFunctionLine.action = ActionType.New;
    let newItemList = [actuatorFunctionLine];
    if (this.itemList.length) {
      newItemList = [...this.itemList, actuatorFunctionLine];
    }
    this.setItemList(newItemList);
  }

  handleEdit(
    actuatorFunctionLine: ActuatorFunctionLine,
    actuatorSpecification: ActuatorSpecification
  ) {
    const newItemList = this.itemList.map((item) => {
      if (actuatorFunctionLine.localId !== item.localId) {
        return item;
      }

      actuatorFunctionLine.actuatorSpecification = actuatorSpecification;
      actuatorFunctionLine.action = ActionType.Edit;
      return actuatorFunctionLine;
    });
    this.setItemList(newItemList);
  }

  handleDelete() {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach((itemInList, index) => {
      if (this.item.localId !== itemInList.localId) {
        return;
      }
      switch (itemInList.action) {
        case undefined:
        case ActionType.Edit:
          runInAction(() => {
            this.itemList[index].action = ActionType.Delete;
          });
          break;
        case ActionType.New:
          runInAction(() => {
            this.itemList.splice(index, 1);
          });
          break;
      }
    });
  }

  async handleUpdateDeviceFunction(deviceFunctionId: number) {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach(async (item) => {
      const actuatorFunctionLineInput: ActuatorFunctionLineInput = {
        id: item.id,
        actuatorSpecificationId: item.actuatorSpecification.id,
        deviceFunctionId: deviceFunctionId,
        versionNumber: 0,
        quantity: item.quantity,
      };
      switch (item.action) {
        case ActionType.New: {
          let result = await actuatorFunctionLineApi.insert(
            actuatorFunctionLineInput
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Edit: {
          let result = await actuatorFunctionLineApi.update(
            actuatorFunctionLineInput
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Delete: {
          let result = await actuatorFunctionLineApi.delete(
            actuatorFunctionLineInput.id
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            runInAction(() => {
              item.action = undefined;
            });
            break;
          }
          break;
        }
      }
    });
  }
}
