import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect } from "react";
import { RoomDeviceFunctionInstanceDeviceApi } from "../../../../api/RoomDeviceFunctionInstanceDevice/RoomDeviceFunctionInstanceDevice";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import Heading from "../../../Form/Heading/Heading";
import SubmitButton from "../../../Form/SubmitButton/SubmitButton";
import { PageType } from "../../types";
import RoomDeviceFunctionInstanceDeviceDialogForm from "../RoomDeviceFunctionInstanceDevice/RoomDeviceFunctionInstanceDeviceDialogForm";
import RoomDeviceFunctionInstanceDeviceTable from "../RoomDeviceFunctionInstanceDevice/RoomDeviceFunctionInstanceDeviceTable";
import RoomForm from "./RoomForm";

const RoomPage: FC = () => {
  const router = useRouter();
  const {
    buildingStore,
    roomStore,
    roomTypeStore,
    roomDeviceFunctionInstanceDeviceStore,
    deviceFunctionInstanceDeviceStore,
    roomStatusStore,
    userInterfaceStore,
  } = useRootStore();

  useEffect(() => {
    autorun(async () => {
      await roomStore.getItemListAsync();
      await buildingStore.getItemListAsync();
      await roomTypeStore.getItemListAsync();
      await roomStatusStore.getItemListAsync();
      await deviceFunctionInstanceDeviceStore.getItemListAsync();
      switch (userInterfaceStore.pageType) {
        case PageType.View:
        case PageType.Existing:
          await roomStore.getItemAsync(Number(router.query.id));
          await roomDeviceFunctionInstanceDeviceStore.getItemListByForeignKeyAsync(
            roomStore.item.id,
            "room"
          );
          break;
        case PageType.New:
          roomStore.resetItem();
          roomDeviceFunctionInstanceDeviceStore.resetItemList();
          break;
      }
    });
  }, [
    userInterfaceStore.pageType,
    roomStore,
    router.query.id,
    roomStatusStore,
    roomTypeStore,
    deviceFunctionInstanceDeviceStore,
    roomDeviceFunctionInstanceDeviceStore,
    buildingStore,
  ]);

  const formId = "roomForm";
  const roomDeviceFunctionInstanceDeviceFormId =
    "roomDeviceFunctionInstanceDeviceForm";

  return (
    <Fragment>
      <Heading
        pageType={userInterfaceStore.pageType!}
        heading={roomStore.singularDisplayName}
      />
      <RoomForm id={formId} />
      <RoomDeviceFunctionInstanceDeviceDialogForm
        id={roomDeviceFunctionInstanceDeviceFormId}
      />
      <RoomDeviceFunctionInstanceDeviceTable />
      {userInterfaceStore.pageType !== PageType.View && (
        <SubmitButton form={formId} pageType={userInterfaceStore.pageType!} />
      )}
    </Fragment>
  );
};

export default observer(RoomPage);
