import {
  DeviceConfigLevel,
  DeviceConfigType,
} from "../../api/DeviceConfig/DeviceConfigInterface";
import { DeviceMode } from "../../api/DeviceMode/DeviceModeInterface";

export const defaultDeviceMode: DeviceMode = {
  id: 0,
  level: DeviceConfigLevel.DEVICE,
  type: DeviceConfigType.MODE,
  command: "",
};
