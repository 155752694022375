import { action, makeObservable, observable, runInAction } from "mobx";
import { deviceRoleDatabaseDefinitionApi } from "../../api/api";
import { DeviceDatabaseColumnName } from "../../api/DeviceDatabaseColumnName/DeviceDatabaseColumnNameInterface";
import { DeviceDatabaseColumnType } from "../../api/DeviceDatabaseColumnType/DeviceDatabaseColumnTypeInterface";
import { DeviceRole } from "../../api/DeviceRole/DeviceRoleInterface";
import {
  DeviceRoleDatabaseDefinition,
  DeviceRoleDatabaseDefinitionInput,
} from "../../api/DeviceRoleDatabaseDefinition/DeviceRoleDatabaseDefinitionInterface";
import { Resource } from "../../api/Resource/ResourceInterface";
import { ActionType } from "../../components/pages/types";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDeviceRoleDatabaseDefinition } from "./DefaultDeviceRoleDatabaseDefinition";

export class DeviceRoleDatabaseDefinitionStore extends MobXStore<
  DeviceRoleDatabaseDefinition,
  DeviceRoleDatabaseDefinitionInput
> {
  localIdCounter: number = 0;
  actionType?: ActionType = undefined;
  dialogOpen: boolean = false;

  constructor(root: RootStore) {
    super(
      root,
      deviceRoleDatabaseDefinitionApi,
      "deviceRoleDatabaseDefinition",
      "Device Role Database Definition",
      "Device Role Database Definitions",
      defaultDeviceRoleDatabaseDefinition
    );
    makeObservable(this, { dialogOpen: observable, setDialogOpen: action });
  }

  incrementLocalIdCounter() {
    this.localIdCounter++;
  }

  setActionType(actionType: ActionType) {
    this.actionType = actionType;
  }

  setDialogOpen(dialogOpen: boolean) {
    this.dialogOpen = dialogOpen;
  }

  setItemFromList(localId: number) {
    const item = this.itemList.find((element) => localId === element.localId);
    if (item === undefined) {
      return;
    }
    this.setItem(item);
  }

  async getItemListAsync(): Promise<void> {
    const result = await this.api.getAll();
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: DeviceRoleDatabaseDefinition[] = [];
    if (result.data.length) {
      enrichedResults = result.data.map((item) => {
        item.localId = this.localIdCounter;
        this.incrementLocalIdCounter();
        return item;
      });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
  }

  async getItemListByForeignKeyAsync(
    id: number,
    foreignKey: string
  ): Promise<void> {
    const result = await this.api.getByForeignKeyId!(id, foreignKey);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: DeviceRoleDatabaseDefinition[] = [];
    if (result.data.length) {
      enrichedResults = result.data.map((item) => {
        item.localId = this.localIdCounter;
        this.incrementLocalIdCounter();
        return item;
      });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
  }

  handleInsert(
    deviceRoleDatabaseDefinition: DeviceRoleDatabaseDefinition,
    deviceDatabaseColumnName: DeviceDatabaseColumnName,
    deviceDatabaseColumnType: DeviceDatabaseColumnType,
    resource?: Resource
  ) {
    deviceRoleDatabaseDefinition.deviceDatabaseColumnName =
      deviceDatabaseColumnName;
    deviceRoleDatabaseDefinition.deviceDatabaseColumnType =
      deviceDatabaseColumnType;
    deviceRoleDatabaseDefinition.resource = resource;
    deviceRoleDatabaseDefinition.localId = this.localIdCounter;
    this.incrementLocalIdCounter();
    deviceRoleDatabaseDefinition.action = ActionType.New;
    let newItemList = [deviceRoleDatabaseDefinition];
    if (this.itemList.length) {
      newItemList = [...this.itemList, deviceRoleDatabaseDefinition];
    }
    this.setItemList(newItemList);
  }

  handleEdit(
    deviceRoleDatabaseDefinition: DeviceRoleDatabaseDefinition,
    deviceDatabaseColumnName: DeviceDatabaseColumnName,
    deviceDatabaseColumnType: DeviceDatabaseColumnType,
    resource?: Resource
  ) {
    const newItemList = this.itemList.map((item) => {
      if (deviceRoleDatabaseDefinition.localId !== item.localId) {
        return item;
      }

      deviceRoleDatabaseDefinition.deviceDatabaseColumnName =
        deviceDatabaseColumnName;
      deviceRoleDatabaseDefinition.deviceDatabaseColumnType =
        deviceDatabaseColumnType;
      deviceRoleDatabaseDefinition.resource = resource;
      deviceRoleDatabaseDefinition.action = ActionType.Edit;
      return deviceRoleDatabaseDefinition;
    });
    this.setItemList(newItemList);
  }

  handleDelete() {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach((itemInList, index) => {
      if (this.item.localId !== itemInList.localId) {
        return;
      }
      switch (itemInList.action) {
        case undefined:
        case ActionType.Edit:
          runInAction(() => {
            this.itemList[index].action = ActionType.Delete;
          });
          break;
        case ActionType.New:
          runInAction(() => {
            this.itemList.splice(index, 1);
          });
          break;
      }
    });
  }

  async handleUpdateDeviceRole(deviceRoleId: number) {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach(async (item) => {
      const deviceRoleDatabaseDefinitionInput: DeviceRoleDatabaseDefinitionInput =
        {
          id: item.id,
          deviceRoleId: deviceRoleId,
          deviceDatabaseColumnNameId: item.deviceDatabaseColumnName.id,
          deviceDatabaseColumnTypeId: item.deviceDatabaseColumnType.id,
          resourceId: item.resource?.id,
          size: item.size,
          versionNumber: 0,
        };
      switch (item.action) {
        case ActionType.New: {
          let result = await this.api.insert(deviceRoleDatabaseDefinitionInput);
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Edit: {
          let result = await this.api.update(deviceRoleDatabaseDefinitionInput);
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Delete: {
          let result = await this.api.delete(
            deviceRoleDatabaseDefinitionInput.id!
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            runInAction(() => {
              item.action = undefined;
            });
            break;
          }
          break;
        }
      }
    });
  }
}
