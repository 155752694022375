import { GridColumns } from "@material-ui/data-grid";
import { Device } from "../../../../api/Device/DeviceInterface";
import { TbDevice } from "../../../../api/TbDevice/TbDeviceInterface";

function getDeviceRole(params: any) {
  const device: Device = params.row;
  if (device.deviceRole) {
    return device.deviceRole.name;
  }
}

function getFormattedTimeFromUtc(params: any) {
  const tbDevice: TbDevice = params.row;
  if (tbDevice.createdTime) {
    return new Date(tbDevice.createdTime);
  }
}

export const deviceListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "deviceName",
    headerName: "Device Name",
    flex: 1,
  },
  {
    field: "macAddress",
    headerName: "MAC Address",
    flex: 1,
  },
  {
    field: "deviceRole",
    headerName: "Device Role",
    valueGetter: getDeviceRole,
    flex: 1,
  },
  {
    field: "createdTime",
    headerName: "Created Time",
    valueGetter: getFormattedTimeFromUtc,
    flex: 1,
  },
  {
    field: "accessToken",
    headerName: "Access Token (TB)",
    flex: 1,
  },
  {
    field: "deviceUuid",
    headerName: "Device UUID (TB)",
    flex: 1,
  },
];
