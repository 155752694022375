import axios, { AxiosResponse } from "axios";
import { DeviceConfigRecord } from "./DeviceConfigInterface";

export class DeviceConfigApi {
  private readonly baseUrl: string;
  private readonly endpoint: string;
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.endpoint = "deviceConfig";
  }

  public async getAll(): Promise<DeviceConfigRecord[]> {
    return await axios
      .get(`${this.baseUrl}/${this.endpoint}`)
      .then((response: AxiosResponse) => {
        return response.data as DeviceConfigRecord[];
      });
  }
}
