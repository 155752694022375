import { deviceRoleApi } from "../../api/api";
import {
  DeviceRole,
  DeviceRoleInput,
} from "../../api/DeviceRole/DeviceRoleInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDeviceRole } from "./DefaultDeviceRole";

export class DeviceRoleStore extends MobXStore<DeviceRole, DeviceRoleInput> {
  constructor(root: RootStore) {
    super(
      root,
      deviceRoleApi,
      "deviceRole",
      "Device Role",
      "Device Roles",
      defaultDeviceRole
    );
  }
}
