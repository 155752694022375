import { DeviceFunction } from "../../api/DeviceFunction/DeviceFunctionInterface";
import { defaultActuatorSpecification } from "../ActuatorSpecification/DefaultActuatorSpecification";
import { defaultSensorSpecification } from "../SensorSpecification/DefaultSensorSpecification";

export const defaultDeviceFunction: DeviceFunction = {
  id: 0,
  actuatorList: [
    {
      id: 0,
      actuatorSpecification: defaultActuatorSpecification,
      quantity: 1,
      versionNumber: 0,
    },
  ],
  sensorList: [
    {
      id: 0,
      sensorSpecification: defaultSensorSpecification,
      quantity: 1,
      versionNumber: 0,
    },
  ],
  name: "",
  versionNumber: 0,
};
