import { ActuatorSpecification } from "../../api/ActuatorSpecification/ActuatorSpecificationInterface";

export const defaultActuatorSpecification: ActuatorSpecification = {
  id: 0,
  manufacturer: "",
  type: "",
  model: "",
  voltage: 0,
  current: 0,
  levels: 0,
  phases: 0,
};
