import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import {
  RoomStatusInput,
  RoomStatusSchema,
} from "../../../../api/RoomStatus/RoomStatusInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import calculateReadOnly from "../../../../utils/calculateReadOnly";
import CheckboxInputField from "../../../Form/CheckboxInputField/CheckboxInputField";
import NumberInputField from "../../../Form/NumberInputField/NumberInputField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./RoomStatusValidation";

const RoomStatusForm: FC<FormProps> = ({ id }: FormProps) => {
  const router = useRouter();
  const { roomStatusStore, userInterfaceStore } = useRootStore();

  const roomStatusSchema = useMemo(
    () => generateSchema(userInterfaceStore.pageType!),
    [userInterfaceStore.pageType]
  );

  const resolver = useYupValidationResolver(roomStatusSchema);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    reset,
  } = useForm({
    defaultValues: roomStatusStore.defaultItem,
    resolver,
  });

  const onSubmit = async (values: RoomStatusSchema) => {
    let roomStatus: RoomStatusInput = {
      status: values.status,
      description: values.description,
    };
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
        roomStatus.id = Number(router.query.id);
        await roomStatusStore.updateItemAsync(roomStatus);
        break;
      case PageType.New:
      case PageType.NewDuplicate:
        await roomStatusStore.insertItemAsync(roomStatus);
        userInterfaceStore.setPageType(PageType.Inserted);
        router.history.push(
          `/${roomStatusStore.endpoint}/edit/${roomStatusStore.item.id}`
        );
        break;
    }
  };

  useEffect(() => {
    autorun(async () => {
      reset(roomStatusStore.item);
    });
  }, [roomStatusStore, reset]);
  const readOnly = calculateReadOnly(userInterfaceStore.pageType);

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={roomStatusStore} />
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <TextInputField
          name="status"
          displayName="Status"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <TextInputField
          name="description"
          displayName="Description"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
      </form>
    </Fragment>
  );
};

export default observer(RoomStatusForm);
