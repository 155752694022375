import { Resource, ResourceInput } from "../../api/Resource/ResourceInterface";
import { resourceApi } from "../../api/api";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultResource } from "./DefaultResource";

export class ResourceStore extends MobXStore<Resource, ResourceInput> {
  constructor(root: RootStore) {
    super(
      root,
      resourceApi,
      "resource",
      "Resource",
      "Resources",
      defaultResource
    );
  }
}
