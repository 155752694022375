import { boolean, number, object, SchemaOf, string } from "yup";
import {
  Room,
  RoomSchema,
  SystemRoomStatus,
} from "../../../../api/Room/RoomInterface";
import { occurrencesInStringArray } from "../../../../utils/occurrencesInArray";
import { PageType } from "../../types";

export function generateSchema(
  type: PageType,
  roomList: Room[],
  systemRoomStatus: SystemRoomStatus
) {
  const schema: SchemaOf<RoomSchema> = object().shape({
    name: string().test(
      "checkUniqueRoomName",
      "Please enter a unique room name!",
      (value: unknown): boolean => {
        if (typeof value !== "string") {
          return false;
        }
        if (value === "") {
          return true;
        }
        if (!roomList.length) {
          return true;
        }
        const roomNames = roomList.map((roomItem) => roomItem.name!);
        const occurrences = occurrencesInStringArray(value, roomNames);
        if (type === PageType.New || type === PageType.NewDuplicate) {
          return occurrences < 1;
        }
        return occurrences <= 1;
      }
    ),
    building: object().shape({
      id: number().required().positive(),
    }),
    roomType: object().shape({
      id: number().required().positive(),
    }),
    roomUuid: string(),
    roomStatus: object().shape({
      id: number()
        .required()
        .positive()
        .test(
          "checkAllowedToChangeStatus",
          "Room is in use in the system, cannot change status",
          (): boolean => {
            if (type === PageType.New || type === PageType.NewDuplicate) {
              return true;
            }
            // if (systemRoomStatus === SystemRoomStatus.AVAILABLE) {
            //   return false;
            // }
            return true;
          }
        ),
    }),
    floor: number().required().moreThan(-1, "Floor must be 0 or greater"),
    number: number()
      .required()
      .moreThan(-1, "Room number must be 0 or greater")
      .test(
        "checkValidNumber",
        "Room already exists within building, please choose another one",
        (value: unknown, context): boolean => {
          if (!roomList) {
            return true;
          }
          var occurrences = 0;
          for (var room of roomList) {
            if (
              Number(room.building.id) === Number(context.parent.building.id) &&
              Number(room.floor) === Number(context.parent.floor) &&
              Number(room.number) === Number(value)
            ) {
              occurrences++;
            }
          }
          if (type === PageType.New || type === PageType.NewDuplicate) {
            return occurrences < 1;
          }
          return occurrences <= 1;
        }
      ),
    loft: boolean().required(),
    paraplegic: boolean().required(),
  });
  return schema;
}
