import { number, object, string } from "yup";
import { deviceApi } from "../../../../api/api";
import { occurrencesInStringArray } from "../../../../utils/occurrencesInArray";
import { PageType } from "../../types";

export function generateSchema(type: PageType) {
  const schema = object().shape({
    macAddress: string()
      .required()
      .matches(/\b[0-9A-F]+$\b/i, "MAC Address contains invalid characters")
      .length(12)
      .test(
        "checkMacAddressUnique",
        "The mac address already exists!",
        async (value: unknown): Promise<boolean> => {
          if (typeof value !== "string") {
            return false;
          }
          const deviceList = await deviceApi.getAll();
          if ("error" in deviceList) {
            return false;
          }
          if (!deviceList.data.length) {
            return true;
          }
          const deviceMacAddresses = deviceList.data.map(
            (device) => device.macAddress
          );
          const occurrences = occurrencesInStringArray(
            value,
            deviceMacAddresses
          );
          switch (type) {
            case PageType.New:
            case PageType.NewDuplicate:
              return occurrences < 1;
            case PageType.Existing:
            case PageType.Inserted:
              return occurrences < 2;
          }
          return false;
        }
      ),
    deviceName: string()
      .required()
      .test(
        "checkDeviceNameUnique",
        "The device name already exists!",
        async (value: unknown): Promise<boolean> => {
          if (typeof value !== "string") {
            return false;
          }
          const deviceList = await deviceApi.getAll();
          if ("error" in deviceList) {
            return false;
          }
          if (!deviceList.data.length) {
            return true;
          }
          const deviceNames = deviceList.data.map(
            (device) => device.deviceName
          );
          const occurrences = occurrencesInStringArray(value, deviceNames);
          switch (type) {
            case PageType.New:
            case PageType.NewDuplicate:
              return occurrences < 1;
            case PageType.Existing:
            case PageType.Inserted:
              return occurrences < 2;
          }
          return false;
        }
      ),
    deviceRole: object().shape({
      id: number().required().positive().integer(),
    }),
    customer: object().shape({
      id: number().required().positive().integer(),
    }),
    building: object().shape({
      id: number().required().positive().integer(),
    }),
  });
  return schema;
}
