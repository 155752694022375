import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  DeviceSpecification,
  DeviceSpecificationInput,
} from "./DeviceSpecificationInterface";

export class DeviceSpecificationApi extends AbstractApi<
  DeviceSpecification,
  DeviceSpecificationInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "deviceSpecification");
  }
}
