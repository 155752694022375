import { Building } from "../../api/Building/BuildingInterface";
import { defaultTbCustomer } from "../TbCustomer/DefaultTbCustomer";

export const defaultBuilding: Building = {
  id: 0,
  siteName: "",
  customer: {
    id: 0,
    name: "",
  },
  customerUuid: "",
  addressId: 0,
  buildingUuid: "",
  latitude: 0,
  longitude: 0,
};
