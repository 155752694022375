import DateFnsUtils from "@date-io/date-fns";
import { FormControl, FormHelperText } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { FC } from "react";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";
type DateFieldProps = {
  name: string;
  displayName: string;
  errors: DeepMap<any, FieldError>;
  control: Control<any>;
  readOnly?: boolean;
};
const DateField: FC<DateFieldProps> = ({
  name,
  displayName,
  errors,
  control,
  readOnly,
}) => {
  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        render={({ field: { ref, ...rest }, fieldState }) => (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              {...rest}
              {...fieldState}
              format="dd/MM/yyyy"
              label={displayName}
              inputVariant={readOnly ? "standard" : "outlined"}
              error={errors[name] ? true : false}
              clearable
              readOnly={readOnly}
            />
          </MuiPickersUtilsProvider>
        )}
      />
      {errors[name] && (
        <FormHelperText error>{errors[name].message}</FormHelperText>
      )}
    </FormControl>
  );
};
export default DateField;
