import { PersonServiceAllocation } from "../../api/PersonServiceAllocation/PersonServiceAllocationInterface";
import { defaultServiceAllocation } from "../ServiceAllocation/DefaultServiceAllocation";
import { defaultPerson } from "../Person/DefaultPerson";

export const defaultPersonServiceAllocation: PersonServiceAllocation = {
  id: 0,
  person: defaultPerson,
  serviceAllocation: defaultServiceAllocation,
  balance: 0,
};
