import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { PersonRoom } from "../../../../api/PersonRoom/PersonRoomInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import { ActionType } from "../../types";
import { generateSchema } from "./PersonRoomValidation";

const PersonRoomDialogForm: FC<FormProps> = ({ id }: FormProps) => {
  const { personRoomStore, roomStore } = useRootStore();

  const handleClose = () => {
    personRoomStore.setDialogOpen(false);
  };

  const handleDelete = () => {
    personRoomStore.handleDelete();
    handleClose();
  };

  const onSubmit = (values: PersonRoom) => {
    const deviceFunctionInstanceDevice = roomStore.getItemFromList(
      values.room.id
    );
    switch (personRoomStore.actionType) {
      case ActionType.Edit:
        personRoomStore.handleEdit(values, deviceFunctionInstanceDevice);
        break;
      case ActionType.New:
        personRoomStore.handleInsert(values, deviceFunctionInstanceDevice);
        break;
    }
    handleClose();
  };

  const personRoomSchema = useMemo(
    () => generateSchema(personRoomStore.itemList),
    [personRoomStore.itemList]
  );

  const resolver = useYupValidationResolver(personRoomSchema);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: personRoomStore.defaultItem,
    resolver,
  });

  useEffect(() => {
    autorun(async () => {
      reset(personRoomStore.item);
    });
  }, [reset, personRoomStore.item]);

  let roomOptions = null;
  if (roomStore.itemList) {
    const deviceFunctionInstanceDevices = roomStore.itemList;
    roomOptions = deviceFunctionInstanceDevices.map((room) => {
      return {
        id: room.id,
        value: `${room.roomType.type} (Floor ${room.floor}, Number ${room.number})`,
      };
    });
  }

  return (
    <Dialog
      open={personRoomStore.dialogOpen}
      onClose={handleClose}
      aria-labelledby="person-room-form-dialog-title"
    >
      <DialogTitle id="person-room-form-dialog-title">
        {personRoomStore.actionType === ActionType.Edit && "Modify "}
        {personRoomStore.actionType === ActionType.New && "Add a new "}
        room
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {personRoomStore.actionType === ActionType.Edit && "Modify room "}
          {personRoomStore.actionType === ActionType.New && "Add a new room "}
          for the person
        </DialogContentText>
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
          <SelectInputField
            name="room.id"
            options={roomOptions}
            displayName="Room"
            errors={errors}
            control={control}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {personRoomStore.actionType === ActionType.Edit && (
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        )}
        <Button form={id} type="submit" color="primary">
          {personRoomStore.actionType === ActionType.Edit && "Update"}
          {personRoomStore.actionType === ActionType.New && "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(PersonRoomDialogForm);
