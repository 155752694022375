import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  ApiArrayResponseSuccess,
  ApiResponseFailure,
} from "../types/apiResponse";
import { axiosGetByForeignKeyIdRequest } from "../util/axiosRequest";
import {
  PersonServiceAllocation,
  PersonServiceAllocationInput,
} from "./PersonServiceAllocationInterface";
export class PersonServiceAllocationApi extends AbstractApi<
  PersonServiceAllocation,
  PersonServiceAllocationInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "personServiceAllocation");
  }
  public async getByForeignKeyId(
    id: number
  ): Promise<
    ApiArrayResponseSuccess<PersonServiceAllocation> | ApiResponseFailure
  > {
    return axiosGetByForeignKeyIdRequest(
      this.baseUrl,
      this.endpoint,
      "person",
      id
    );
  }
}
