import { AbstractAPI as AbstractApi } from "../abstractApi";
import { ServicePackage, ServicePackageInput } from "./ServicePackageInterface";
export class ServicePackageApi extends AbstractApi<
  ServicePackage,
  ServicePackageInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "servicePackage");
  }
}
