import { action, makeObservable, observable, runInAction } from "mobx";
import { personRoomApi } from "../../api/api";
import {
  PersonRoom,
  PersonRoomInput,
} from "../../api/PersonRoom/PersonRoomInterface";
import { Room } from "../../api/Room/RoomInterface";
import { ActionType } from "../../components/pages/types";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultPersonRoom } from "./DefaultPersonRoom";

export class PersonRoomStore extends MobXStore<PersonRoom, PersonRoomInput> {
  localIdCounter: number = 0;
  actionType?: ActionType = undefined;
  dialogOpen: boolean = false;

  constructor(root: RootStore) {
    super(
      root,
      personRoomApi,
      "personRoom",
      "Person Room",
      "People Rooms",
      defaultPersonRoom
    );
    makeObservable(this, { dialogOpen: observable, setDialogOpen: action });
  }

  incrementLocalIdCounter() {
    this.localIdCounter++;
  }

  setActionType(actionType: ActionType) {
    this.actionType = actionType;
  }

  setDialogOpen(dialogOpen: boolean) {
    this.dialogOpen = dialogOpen;
  }

  setItemFromList(id: number) {
    const item = this.itemList.find((element) => id === element.id);
    this.setItem(item!);
  }

  async getItemListAsync(): Promise<void> {
    const result = await this.api.getAll();
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: PersonRoom[] = [];
    if (result.data.length) {
      enrichedResults = result.data.map((item) => {
        item.localId = this.localIdCounter;
        this.incrementLocalIdCounter();
        return item;
      });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
  }

  async getItemListByForeignKeyAsync(
    id: number,
    foreignKey: string
  ): Promise<void> {
    const result = await this.api.getByForeignKeyId!(id, foreignKey);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: PersonRoom[] = [];
    if (result.data.length) {
      enrichedResults = result.data.map((item) => {
        item.localId = this.localIdCounter;
        this.incrementLocalIdCounter();
        return item;
      });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
  }

  handleInsert(personRoom: PersonRoom, room: Room) {
    personRoom.room = room;
    personRoom.localId = this.localIdCounter;
    this.incrementLocalIdCounter();
    personRoom.action = ActionType.New;
    let newItemList = [personRoom];
    if (this.itemList.length) {
      newItemList = [...this.itemList, personRoom];
    }
    this.setItemList(newItemList);
  }

  handleEdit(personRoom: PersonRoom, room: Room) {
    const newItemList = this.itemList.map((item) => {
      if (personRoom.localId !== item.localId) {
        return item;
      }
      personRoom.room = room;
      if (personRoom.action !== ActionType.New) {
        personRoom.action = ActionType.Edit;
      }
      return personRoom;
    });
    this.setItemList(newItemList);
  }

  handleDelete() {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach((itemInList, index) => {
      if (this.item.localId !== itemInList.localId) {
        return;
      }
      switch (itemInList.action) {
        case undefined:
        case ActionType.Edit:
          runInAction(() => {
            this.itemList[index].action = ActionType.Delete;
          });
          break;
        case ActionType.New:
          runInAction(() => {
            this.itemList.splice(index, 1);
          });
          break;
      }
    });
  }

  async handleUpdatePerson(personId: number) {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach(async (item) => {
      const personRoomInput: PersonRoomInput = {
        id: item.id,
        personId,
        roomId: item.room.id,
      };
      switch (item.action) {
        case ActionType.New: {
          let result = await personRoomApi.insert(personRoomInput);
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Edit: {
          let result = await personRoomApi.update(personRoomInput);
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Delete: {
          let result = await personRoomApi.delete(personRoomInput.id!);
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            runInAction(() => {
              item.action = undefined;
            });
            break;
          }
        }
      }
    });
  }
}
