import { runInAction } from "mobx";
import { tagApi } from "../../api/api";
import { Tag, TagInput } from "../../api/Tag/TagInterface";
import calculateUtcFromLocal from "../../utils/calculateUtcFromLocal";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultTag } from "./DefaultTag";

export class TagStore extends MobXStore<Tag, TagInput> {
  constructor(root: RootStore) {
    super(root, tagApi, "tag", "Tag", "Tags", defaultTag);
  }
  async getItemAsync(id: number): Promise<void> {
    const result = await this.api.getOne(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.resetApiResponse();
    const utcDate = calculateUtcFromLocal(result.data.activationDate!);
    result.data.activationDateUtc = utcDate;
    this.setItem(result.data);
  }

  async updateItemAsync(item: TagInput): Promise<void> {
    const result = await this.api.update(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully updated!`
    );
    this.setMessageSeverity("success");
    const utcDate = calculateUtcFromLocal(result.data.activationDate!);
    result.data.activationDateUtc = utcDate;
    this.setItem(result.data);
  }
}
