import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { FC, Fragment } from "react";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";

type Input = {
  id: number | string;
  value: string;
};

type SelectInputFieldOnChangeProps = {
  options: Input[] | null;
  name: string;
  displayName: string;
  errors: DeepMap<any, FieldError>;
  control: Control<any>;
  handleChange: (
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) => void;
  readOnly?: boolean;
};

const SelectInputFieldOnChange: FC<SelectInputFieldOnChangeProps> = ({
  options,
  name,
  displayName,
  errors,
  control,
  handleChange,
  readOnly,
}) => {
  return (
    <FormControl variant={readOnly ? "standard" : "outlined"} fullWidth>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Fragment>
            <InputLabel>{displayName}</InputLabel>
            <Select
              {...field}
              label={displayName}
              error={errors[name] ? true : false}
              onChange={handleChange}
              readOnly={readOnly ? true : false}
            >
              {options?.map((option) => {
                return (
                  <MenuItem
                    key={option.id.toString()}
                    id={option.id.toString()}
                    value={option.id}
                  >
                    {option.value}
                  </MenuItem>
                );
              })}
            </Select>
            {errors[name] && (
              <FormHelperText error>{errors[name].message}</FormHelperText>
            )}
          </Fragment>
        )}
      />
    </FormControl>
  );
};

export default SelectInputFieldOnChange;
