import { action, makeObservable, observable, runInAction } from "mobx";
import { personServicePackageApi } from "../../api/api";
import {
  PersonServicePackage,
  PersonServicePackageInput,
} from "../../api/PersonServicePackage/PersonServicePackageInterface";
import { ServicePackage } from "../../api/ServicePackage/ServicePackageInterface";
import { ActionType } from "../../components/pages/types";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultPersonServicePackage } from "./DefaultPersonServicePackage";

export class PersonServicePackageStore extends MobXStore<
  PersonServicePackage,
  PersonServicePackageInput
> {
  localIdCounter: number = 0;
  actionType?: ActionType = undefined;
  dialogOpen: boolean = false;

  constructor(root: RootStore) {
    super(
      root,
      personServicePackageApi,
      "personServicePackage",
      "Person Service Package",
      "Person Service Packages",
      defaultPersonServicePackage
    );
    makeObservable(this, { dialogOpen: observable, setDialogOpen: action });
  }

  incrementLocalIdCounter() {
    this.localIdCounter++;
  }

  setActionType(actionType: ActionType) {
    this.actionType = actionType;
  }

  setDialogOpen(dialogOpen: boolean) {
    this.dialogOpen = dialogOpen;
  }

  setItemFromList(id: number) {
    const item = this.itemList.find((element) => id === element.id);
    this.setItem(item!);
  }

  async getItemListByForeignKeyAsync(
    id: number,
    foreignKey: string
  ): Promise<void> {
    const result = await this.api.getByForeignKeyId!(id, foreignKey);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: PersonServicePackage[] = [];
    if (result.data.length) {
      enrichedResults = result.data.map((item: PersonServicePackage) => {
        item.localId = this.localIdCounter;
        this.incrementLocalIdCounter();
        return item;
      });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
  }

  async handleInsert(
    personServicePackage: PersonServicePackage,
    servicePackage: ServicePackage
  ): Promise<number> {
    personServicePackage.servicePackage = servicePackage;
    personServicePackage.localId = this.localIdCounter;
    this.incrementLocalIdCounter();
    personServicePackage.action = ActionType.New;

    let newItemList = [personServicePackage];
    if (this.itemList.length) {
      newItemList = [...this.itemList, personServicePackage];
    }
    this.setItemList(newItemList);
    return personServicePackage.id;
  }

  async handleEdit(
    personServicePackage: PersonServicePackage,
    servicePackage: ServicePackage
  ) {
    personServicePackage.servicePackage = servicePackage;
    const newItemList = this.itemList.map((item) => {
      if (personServicePackage.localId !== item.localId) {
        return item;
      }
      if (personServicePackage.action !== ActionType.New) {
        personServicePackage.action = ActionType.Edit;
      }
      return personServicePackage;
    });
    this.setItemList(newItemList);
  }

  handleDelete() {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach((itemInList, index) => {
      if (this.item.localId !== itemInList.localId) {
        return;
      }
      switch (itemInList.action) {
        case undefined:
        case ActionType.Edit:
          runInAction(() => {
            this.itemList[index].action = ActionType.Delete;
          });
          break;
        case ActionType.New:
          runInAction(() => {
            this.itemList.splice(index, 1);
          });
          break;
      }
    });
  }

  async handleUpdatePerson(personId: number) {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach(async (item) => {
      const personServicePackageInput: PersonServicePackageInput = {
        id: item.id,
        personId: personId,
        servicePackageId: item.servicePackage.id,
      };
      switch (item.action) {
        case ActionType.New: {
          let result = await this.api.insert(personServicePackageInput);
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Edit: {
          let result = await this.api.update(personServicePackageInput);
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Delete: {
          let result = await this.api.delete(personServicePackageInput.id!);
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            runInAction(() => {
              item.action = undefined;
            });
            break;
          }
        }
      }
    });
  }
}
