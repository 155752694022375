import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import { RoomInput, RoomSchema } from "../../../../api/Room/RoomInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import calculateReadOnly from "../../../../utils/calculateReadOnly";
import CheckboxInputField from "../../../Form/CheckboxInputField/CheckboxInputField";
import NumberInputField from "../../../Form/NumberInputField/NumberInputField";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./RoomValidation";

const RoomForm: FC<FormProps> = ({ id }: FormProps) => {
  const router = useRouter();
  const {
    buildingStore,
    roomStore,
    roomTypeStore,
    roomDeviceFunctionInstanceDeviceStore,
    roomStatusStore,
    tbAssetStore,
    userInterfaceStore,
  } = useRootStore();

  const roomSchema = useMemo(
    () =>
      generateSchema(
        userInterfaceStore.pageType!,
        roomStore.itemList,
        roomStore.item.systemRoomStatus
      ),
    [
      roomStore.item.systemRoomStatus,
      roomStore.itemList,
      userInterfaceStore.pageType,
    ]
  );

  const resolver = useYupValidationResolver(roomSchema);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    reset,
  } = useForm({
    defaultValues: roomStore.defaultItem,
    resolver,
  });

  const onSubmit = async (values: RoomSchema) => {
    let room: RoomInput = {
      name: values.name,
      buildingId: values.building.id,
      roomTypeId: values.roomType.id,
      roomStatusId: values.roomStatus.id,
      floor: values.floor,
      number: values.number,
      loft: values.loft,
      roomUuid: values.roomUuid!,
      paraplegic: values.paraplegic,
    };
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
      case PageType.Inserted:
        room.id = Number(router.query.id);
        await roomStore.updateItemAsync(room);
        break;
      case PageType.New:
      case PageType.NewDuplicate:
        await roomStore.insertItemAsync(room);
        userInterfaceStore.setPageType(PageType.Inserted);
        router.history.push(`/${roomStore.endpoint}/edit/${roomStore.item.id}`);
        break;
    }
    roomDeviceFunctionInstanceDeviceStore.handleUpdateRoom(roomStore.item.id);
  };

  useEffect(() => {
    autorun(async () => {
      reset(roomStore.item);
    });
  }, [roomStore, reset]);
  const readOnly = calculateReadOnly(userInterfaceStore.pageType);

  const buildings = buildingStore.itemList;
  let buildingOptions: { id: number; value: string }[] = [];
  if (buildings.length) {
    buildingOptions = buildings.map((building) => {
      return {
        id: building.id,
        value: building.siteName,
      };
    });
  }
  const roomTypes = roomTypeStore.itemList;
  let roomTypeOptions: { id: number; value: string }[] = [];
  if (roomTypes.length) {
    roomTypeOptions = roomTypes.map((roomType) => {
      return {
        id: roomType.id,
        value: roomType.type,
      };
    });
  }

  const roomStatuses = roomStatusStore.itemList;
  let roomStatusOptions: { id: number; value: string }[] = [];
  if (roomStatuses.length) {
    roomStatusOptions = roomStatuses.map((roomStatus) => {
      return {
        id: roomStatus.id,
        value: roomStatus.description,
      };
    });
  }

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={roomStore} />
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <TextInputField
          name="name"
          displayName="Name"
          errors={errors}
          control={control}
        />
        <SelectInputField
          name="building.id"
          options={buildingOptions}
          displayName="Building"
          errors={errors}
          control={control}
        />
        <SelectInputField
          name="roomType.id"
          options={roomTypeOptions}
          displayName="Room Type"
          errors={errors}
          control={control}
        />
        {userInterfaceStore.pageType !== PageType.New &&
          userInterfaceStore.pageType !== PageType.NewDuplicate && (
            <Fragment>
              <TextInputField
                name="systemRoomStatus"
                displayName="System Room Status"
                errors={errors}
                control={control}
                readOnly={true}
              />
              <TextInputField
                name="roomUuid"
                displayName="Room UUID"
                errors={errors}
                control={control}
                readOnly={true}
              />
            </Fragment>
          )}
        <SelectInputField
          name="roomStatus.id"
          options={roomStatusOptions}
          displayName="Room Status"
          errors={errors}
          control={control}
        />
        <NumberInputField
          name="floor"
          displayName="Floor"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <NumberInputField
          name="number"
          displayName="Room Number"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <CheckboxInputField
          name="loft"
          displayName="Has Loft?"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <CheckboxInputField
          name="paraplegic"
          displayName="Is Paraplegic?"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
      </form>
    </Fragment>
  );
};

export default observer(RoomForm);
