import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import React, { FC } from "react";
import { PageType } from "../../pages/types";

type SubmitButtonProps = {
  pageType: PageType;
  form: string;
};

const SubmitButton: FC<SubmitButtonProps> = ({
  pageType,
  form,
}: SubmitButtonProps) => {
  return (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      form={form}
      startIcon={<SaveIcon />}
    >
      {pageType === PageType.New && "Insert"}
      {pageType === PageType.NewDuplicate && "Insert"}
      {pageType === PageType.Existing && "Update"}
      {pageType === PageType.Inserted && "Update"}
    </Button>
  );
};

export default SubmitButton;
