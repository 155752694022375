import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  ApiArrayResponseSuccess,
  ApiObjectResponseSuccess,
  ApiResponseFailure,
} from "../types/apiResponse";
import {
  axiosGetByForeignKeyIdRequest,
  axiosInsertRequest,
  axiosUpdateRequest,
} from "../util/axiosRequest";
import {
  DeviceFunctionInstanceDevice,
  DeviceFunctionInstanceDeviceInput,
} from "./DeviceFunctionInstanceDeviceInterface";

export class DeviceFunctionInstanceDeviceApi extends AbstractApi<
  DeviceFunctionInstanceDevice,
  DeviceFunctionInstanceDeviceInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "deviceFunctionInstanceDevice");
  }

  public async getByForeignKeyId(
    id: number
  ): Promise<
    ApiArrayResponseSuccess<DeviceFunctionInstanceDevice> | ApiResponseFailure
  > {
    return axiosGetByForeignKeyIdRequest(
      this.baseUrl,
      this.endpoint,
      "device",
      id
    );
  }
}
