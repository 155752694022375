import { deviceRoleSenseApi } from "../../api/api";
import {
  DeviceRoleSenseInput,
  DeviceSense,
} from "../../api/DeviceSense/DeviceSenseInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDeviceSense } from "./DefaultDeviceSense";

export class DeviceRoleSenseStore extends MobXStore<
  DeviceSense,
  DeviceRoleSenseInput
> {
  constructor(root: RootStore) {
    super(
      root,
      deviceRoleSenseApi,
      "deviceRoleSense",
      "Device Role Sense",
      "Device Role Sense",
      defaultDeviceSense
    );
  }
}
