import { TbAsset } from "../../api/TbAsset/TbAssetInterface";

export const defaultTbAsset: TbAsset = {
  id: {
    entityType: "ASSET",
    id: "",
  },
  createdTime: 0,
  additionalInfo: {},
  tenantId: {
    entityType: "",
    id: "",
  },
  customerId: {
    entityType: "",
    id: "",
  },
  name: "",
  type: "",
  label: "",
};
