import { action, makeObservable, observable, runInAction } from "mobx";
import { personAreaApi } from "../../api/api";
import { Area } from "../../api/Area/AreaInterface";
import {
  PersonArea,
  PersonAreaInput,
} from "../../api/PersonArea/PersonAreaInterface";
import { ActionType } from "../../components/pages/types";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultPersonArea } from "./DefaultPersonArea";

export class PersonAreaStore extends MobXStore<PersonArea, PersonAreaInput> {
  localIdCounter: number = 0;
  actionType?: ActionType = undefined;
  dialogOpen: boolean = false;

  constructor(root: RootStore) {
    super(
      root,
      personAreaApi,
      "personArea",
      "Person Area",
      "People Area",
      defaultPersonArea
    );
    makeObservable(this, { dialogOpen: observable, setDialogOpen: action });
  }

  incrementLocalIdCounter() {
    this.localIdCounter++;
  }

  setActionType(actionType: ActionType) {
    this.actionType = actionType;
  }

  setDialogOpen(dialogOpen: boolean) {
    this.dialogOpen = dialogOpen;
  }

  setItemFromList(id: number) {
    const item = this.itemList.find((element) => id === element.id);
    this.setItem(item!);
  }

  async getItemListAsync(): Promise<void> {
    const result = await this.api.getAll();
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: PersonArea[] = [];
    if (result.data.length) {
      enrichedResults = result.data.map((item) => {
        item.localId = this.localIdCounter;
        this.incrementLocalIdCounter();
        return item;
      });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
  }

  async getItemListByForeignKeyAsync(
    id: number,
    foreignKey: string
  ): Promise<void> {
    const result = await this.api.getByForeignKeyId!(id, foreignKey);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: PersonArea[] = [];
    if (result.data.length) {
      enrichedResults = result.data.map((item) => {
        item.localId = this.localIdCounter;
        this.incrementLocalIdCounter();
        return item;
      });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
  }

  handleInsert(personArea: PersonArea, area: Area) {
    personArea.area = area;
    personArea.localId = this.localIdCounter;
    this.incrementLocalIdCounter();
    personArea.action = ActionType.New;
    let newItemList = [personArea];
    if (this.itemList.length) {
      newItemList = [...this.itemList, personArea];
    }
    this.setItemList(newItemList);
  }

  handleEdit(personArea: PersonArea, area: Area) {
    const newItemList = this.itemList.map((item) => {
      if (personArea.localId !== item.localId) {
        return item;
      }
      personArea.area = area;
      if (personArea.action !== ActionType.New) {
        personArea.action = ActionType.Edit;
      }
      return personArea;
    });
    this.setItemList(newItemList);
  }

  handleDelete() {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach((itemInList, index) => {
      if (this.item.localId !== itemInList.localId) {
        return;
      }
      switch (itemInList.action) {
        case undefined:
        case ActionType.Edit:
          runInAction(() => {
            this.itemList[index].action = ActionType.Delete;
          });
          break;
        case ActionType.New:
          runInAction(() => {
            this.itemList.splice(index, 1);
          });
          break;
      }
    });
  }

  async handleUpdatePerson(personId: number) {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach(async (item) => {
      const personAreaInput: PersonAreaInput = {
        id: item.id,
        personId,
        areaId: item.area.id,
      };
      switch (item.action) {
        case ActionType.New: {
          let result = await personAreaApi.insert(personAreaInput);
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Edit: {
          let result = await personAreaApi.update(personAreaInput);
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Delete: {
          let result = await personAreaApi.delete(personAreaInput.id!);
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            runInAction(() => {
              item.action = undefined;
            });
            break;
          }
        }
      }
    });
  }
}
