import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import {
  ResourceUnitInput,
  ResourceUnitSchema,
} from "../../../../api/ResourceUnit/ResourceUnitInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import calculateReadOnly from "../../../../utils/calculateReadOnly";
import CheckboxInputField from "../../../Form/CheckboxInputField/CheckboxInputField";
import NumberInputField from "../../../Form/NumberInputField/NumberInputField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./ResourceUnitValidation";

const ResourceUnitForm: FC<FormProps> = ({ id }: FormProps) => {
  const router = useRouter();
  const { resourceUnitStore, userInterfaceStore } = useRootStore();

  const resourceUnitSchema = useMemo(
    () => generateSchema(userInterfaceStore.pageType!),
    [userInterfaceStore.pageType]
  );

  const resolver = useYupValidationResolver(resourceUnitSchema);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    reset,
  } = useForm({
    defaultValues: resourceUnitStore.defaultItem,
    resolver,
  });

  const onSubmit = async (values: ResourceUnitSchema) => {
    let resourceUnit: ResourceUnitInput = {
      unit: values.unit,
      description: values.description,
    };
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
        resourceUnit.id = Number(router.query.id);
        await resourceUnitStore.updateItemAsync(resourceUnit);
        break;
      case PageType.New:
      case PageType.NewDuplicate:
        await resourceUnitStore.insertItemAsync(resourceUnit);
        userInterfaceStore.setPageType(PageType.Inserted);
        router.history.push(
          `/${resourceUnitStore.endpoint}/edit/${resourceUnitStore.item.id}`
        );
        break;
    }
  };

  useEffect(() => {
    autorun(async () => {
      reset(resourceUnitStore.item);
    });
  }, [resourceUnitStore, reset]);
  const readOnly = calculateReadOnly(userInterfaceStore.pageType);

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={resourceUnitStore} />
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <TextInputField
          name="unit"
          displayName="Unit"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <TextInputField
          name="description"
          displayName="Description"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
      </form>
    </Fragment>
  );
};

export default observer(ResourceUnitForm);
