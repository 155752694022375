import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  PlatformSpecification,
  PlatformSpecificationInput,
} from "./PlatformSpecificationInterface";
export class PlatformSpecificationApi extends AbstractApi<
  PlatformSpecification,
  PlatformSpecificationInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "platformSpecification");
  }
}
