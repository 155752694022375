import { personStatusApi } from "../../api/api";
import {
  PersonStatus,
  PersonStatusInput,
} from "../../api/PersonStatus/PersonStatusInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultPersonStatus } from "./DefaultPersonStatus";

export class PersonStatusStore extends MobXStore<
  PersonStatus,
  PersonStatusInput
> {
  constructor(root: RootStore) {
    super(
      root,
      personStatusApi,
      "personStatus",
      "Person Status",
      "Person Statuses",
      defaultPersonStatus
    );
  }
}
