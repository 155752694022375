import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import {
  DeviceInput,
  DeviceSchema,
} from "../../../../api/Device/DeviceInterface";
import {
  TbCreateDeviceRequestBody,
  TbUpdateDeviceRequestBody,
} from "../../../../api/TbDevice/TbDeviceInterface";
import {
  TbEntityRelationRequestBody,
  TbEntityType,
} from "../../../../api/TbEntityRelation/TbEntityRelationInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import SelectInputFieldOnChange from "../../../Form/SelectInputFieldOnChange/SelectInputFieldOnChange";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./DeviceValidation";

const DeviceForm: FC<FormProps> = ({ id }: FormProps) => {
  const router = useRouter();
  const {
    deviceStore,
    deviceRoleStore,
    deviceFunctionInstanceDeviceStore,
    buildingStore,
    tbDeviceStore,
    customerStore,
    tbEntityRelationStore,
    userInterfaceStore,
  } = useRootStore();

  const deviceSchema = useMemo(
    () => generateSchema(userInterfaceStore.pageType!),
    [userInterfaceStore.pageType]
  );

  const resolver = useYupValidationResolver(deviceSchema);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    defaultValues: deviceStore.defaultItem,
    resolver,
  });

  useEffect(() => {
    autorun(async () => {
      reset(deviceStore.item);
    });
  }, [deviceStore.item, reset]);

  const onSubmit = async (values: DeviceSchema) => {
    let device: DeviceInput = {
      customerId: values.customer.id,
      deviceRoleId: values.deviceRole.id,
      buildingId: values.building.id,
      macAddress: values.macAddress,
      deviceName: values.deviceName,
      deviceUuid: values.deviceUuid,
      accessToken: values.accessToken,
    };
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
      case PageType.Inserted:
        device.id = Number(router.query.id);
        await deviceStore.updateItemAsync(device);
        break;
      case PageType.New:
      case PageType.NewDuplicate:
        await deviceStore.insertItemAsync(device);

        userInterfaceStore.setPageType(PageType.Inserted);
        router.history.push(
          `/${deviceStore.endpoint}/edit/${deviceStore.item.id}`
        );
        break;
    }
    deviceFunctionInstanceDeviceStore.handleUpdateDevice(deviceStore.item.id);
  };

  const deviceRoleList = deviceRoleStore.itemList;
  let deviceRoleOptions: { id: number; value: string }[] = [];
  if (deviceRoleList.length) {
    deviceRoleOptions = deviceRoleList.map((deviceRole) => {
      return {
        id: deviceRole.id,
        value: `${deviceRole.name}`,
      };
    });
  }
  const customerList = customerStore.itemList;
  let customerOptions: { id: number; value: string }[] = [];
  if (customerList.length) {
    customerOptions = customerList.map((customer) => {
      return {
        id: customer.id,
        value: `${customer.title}`,
      };
    });
  }
  const buildingList = buildingStore.itemList;
  let buildingOptions: { id: number; value: string }[] = [];
  if (buildingList.length) {
    buildingOptions = buildingList.map((building) => {
      return {
        id: building.id,
        value: `${building.siteName}`,
      };
    });
  }

  const handleDeviceRoleChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    deviceFunctionInstanceDeviceStore.handleChangeDeviceRole(
      Number(event.target.value)
    );
    await deviceRoleStore.getItemAsync(Number(event.target.value));
    setValue("deviceRole.id", event.target.value);
  };

  const handleCustomerChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    await buildingStore.getItemListByForeignKeyAsync(
      String(event.target.value),
      "building"
    );
    setValue("customer.id", event.target.value);
    setValue("building.id", 0);
  };
  const handleBuildingChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    buildingStore.item = await buildingStore.getItemFromList(
      Number(event.target.value)
    );

    setValue("building.id", event.target.value);
  };

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={deviceStore} />
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <TextInputField
          name="deviceName"
          displayName="Device Name"
          errors={errors}
          control={control}
          readOnly={false}
        />
        <TextInputField
          name="macAddress"
          displayName="MAC Address"
          errors={errors}
          control={control}
          readOnly={false}
        />
        <SelectInputFieldOnChange
          name="deviceRole.id"
          options={deviceRoleOptions}
          displayName="Device Role"
          errors={errors}
          control={control}
          handleChange={handleDeviceRoleChange}
        />
        <SelectInputFieldOnChange
          name="customer.id"
          options={customerOptions}
          displayName="Customer"
          errors={errors}
          control={control}
          handleChange={handleCustomerChange}
          readOnly={false}
        />
        <SelectInputFieldOnChange
          name="building.id"
          options={buildingOptions}
          displayName="Building"
          errors={errors}
          control={control}
          handleChange={handleBuildingChange}
          readOnly={false}
        />
        {userInterfaceStore.pageType !== PageType.New &&
          userInterfaceStore.pageType !== PageType.NewDuplicate && (
            <Fragment>
              <TextInputField
                name="accessToken"
                displayName="Access Token (TB)"
                errors={errors}
                control={control}
                readOnly={true}
              />
              <TextInputField
                name="deviceUuid"
                displayName="Device UUID (TB)"
                errors={errors}
                control={control}
                readOnly={true}
              />
            </Fragment>
          )}
      </form>
    </Fragment>
  );
};

export default observer(DeviceForm);
