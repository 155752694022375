import { date, number, object, string } from "yup";
import { tagStatusApi } from "../../../../api/api";
import { Person } from "../../../../api/Person/PersonInterface";
import { Tag } from "../../../../api/Tag/TagInterface";
import { occurrencesInNumberArray } from "../../../../utils/occurrencesInArray";
import { PageType } from "../../types";

export function generateSchema(type: PageType, tagList: Tag[], person: Person) {
  const schema = object().shape({
    tagModel: object().shape({
      id: number().required().positive(),
    }),
    tagStatus: object().shape({
      id: number()
        .required()
        .positive()
        .test(
          "checkAllowedToChangeStatus",
          "Please unassign the tag from a person before changing the status",
          async (value: unknown): Promise<boolean> => {
            const tagStatus = await tagStatusApi.getOne(Number(value));
            if ("error" in tagStatus) {
              return false;
            }
            if (tagStatus.data.status === "ACT") {
              return true;
            }
            if (typeof person === "undefined") {
              return true;
            }
            return false;
          }
        ),
    }),
    tagInput: string()
      .required()
      .matches(
        /\b(0?x[0-9a-fA-F]+|[x?0-9]+)\b/i,
        "Tag Value is not hexadecimal or numeric"
      ),
    tagValue: number()
      .required()
      .positive()
      .test(
        "checkUniqueTagNumber",
        "Please enter a unique tag number!",
        (value: unknown): boolean => {
          if (typeof value !== "number") {
            return false;
          }
          if (value === 0) {
            return false;
          }
          if (!tagList.length) {
            return true;
          }
          const tagValueList = tagList.map((tag) => Number(tag.tagValue));
          const occurrences = occurrencesInNumberArray(value, tagValueList);
          if (type === PageType.New || type === PageType.NewDuplicate) {
            return occurrences < 1;
          }
          return occurrences <= 1;
        }
      ),
    activationDateUtc: date().required(),
    activationDate: date().required(),
  });
  return schema;
}
