import { makeAutoObservable } from "mobx";
import { TbDeviceApi } from "../../api/TbDevice/TbDevice";
import {
  TbCreateDeviceRequestBody,
  TbDevice,
  TbUpdateDeviceRequestBody,
} from "../../api/TbDevice/TbDeviceInterface";
import { RootStore } from "../Root/RootStore";

export class TbDeviceStore {
  public root: RootStore;
  public api: TbDeviceApi;
  public defaultItem: TbDevice;
  public item: TbDevice;
  public itemList: TbDevice[];

  constructor(root: RootStore, api: TbDeviceApi, defaultItem: TbDevice) {
    this.root = root;
    this.api = api;
    this.defaultItem = defaultItem;
    this.item = defaultItem;
    this.itemList = [defaultItem];
    makeAutoObservable(this);
  }

  setItem(item: TbDevice) {
    this.item = item;
  }
  resetItem() {
    this.item = this.defaultItem;
  }

  async insertItemAsync(
    item: TbCreateDeviceRequestBody | TbUpdateDeviceRequestBody,
    accessToken: string
  ): Promise<void> {
    const result = await this.api.insert(item, accessToken);
    this.setItem(result);
  }
}
