import { Grid, Typography } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import { TagInput, TagSchema } from "../../../../api/Tag/TagInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import calculateLocalFromUtc from "../../../../utils/calculateLocalFromUtc";
import calculateReadOnly from "../../../../utils/calculateReadOnly";
import calculateUtcFromLocal from "../../../../utils/calculateUtcFromLocal";
import DateTimeField from "../../../Form/DateTimeField/DateTimeField";
import NumberInputField from "../../../Form/NumberInputField/NumberInputField";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import TextInputFieldOnChange from "../../../Form/TextInputFieldOnChange/TextInputFieldOnChange";
import { FormProps } from "../../../Form/types";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./TagValidation";

const TagForm: FC<FormProps> = ({ id }: FormProps) => {
  const router = useRouter();
  const { tagStore, tagModelStore, tagStatusStore, userInterfaceStore } =
    useRootStore();

  const tagSchema = useMemo(
    () =>
      generateSchema(
        userInterfaceStore.pageType!,
        tagStore.itemList,
        tagStore.item.person
      ),
    [tagStore.item.person, tagStore.itemList, userInterfaceStore.pageType]
  );

  const resolver = useYupValidationResolver(tagSchema);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors, isDirty },
    reset,
  } = useForm({
    defaultValues: tagStore.defaultItem,
    resolver,
  });

  const onSubmit = async (values: TagSchema) => {
    let tag: TagInput = {
      tagModelId: values.tagModel.id,
      tagStatusId: values.tagStatus.id,
      activationDate: values.activationDate,
      tagValue: values.tagValue,
      versionNumber: 0,
    };
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
        tag.id = Number(router.query.id);
        await tagStore.updateItemAsync(tag);
        break;
      case PageType.New:
      case PageType.NewDuplicate:
        await tagStore.insertItemAsync(tag);
        userInterfaceStore.setPageType(PageType.Inserted);
        router.history.push(`/${tagStore.endpoint}/edit/${tagStore.item.id}`);
        break;
    }
  };

  useEffect(() => {
    autorun(async () => {
      reset(tagStore.item);
    });
  }, [tagStore, reset]);
  const readOnly = calculateReadOnly(userInterfaceStore.pageType);

  const tagModels = tagModelStore.itemList;
  let tagModelOptions: { id: number; value: string }[] = [];
  if (tagModels.length) {
    tagModelOptions = tagModels.map((tagModel) => {
      return {
        id: tagModel.id,
        value: tagModel.name,
      };
    });
  }
  const tagStatuses = tagStatusStore.itemList;
  let tagStatusOptions: { id: number; value: string }[] = [];
  if (tagStatuses.length) {
    tagStatusOptions = tagStatuses.map((tagStatus) => {
      return {
        id: tagStatus.id,
        value: tagStatus.status,
      };
    });
  }

  const handleChangeDefault = (date: MaterialUiPickersDate): void => {
    const utcDate = calculateUtcFromLocal(date!);
    setValue("activationDate", date);
    setValue("activationDateUtc", utcDate);
  };
  const handleChangeUtc = (date: MaterialUiPickersDate): void => {
    const localDate = calculateLocalFromUtc(date!);
    setValue("activationDateUtc", date);
    setValue("activationDate", localDate);
  };

  const handleChangeTagInput = (
    event: React.ChangeEvent<{ value: unknown }>
  ): void => {
    const input = event.target.value;
    const numberValue = Number(input);
    const hexValue = parseInt(String(input));
    const inputWithLeading0 = "0" + input;
    const hexValueWith0 = parseInt(String(inputWithLeading0));
    let valid = false;
    if (numberValue) {
      valid = true;
      setValue("tagValue", numberValue);
    }
    if (hexValue) {
      valid = true;
      setValue("tagValue", hexValue);
    }
    if (hexValueWith0) {
      valid = true;
      setValue("tagValue", hexValueWith0);
    }
    if (!valid) {
      setValue("tagValue", 0);
    }
    setValue("tagInput", event.target.value);
  };

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={tagStore} />
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <SelectInputField
          name="tagModel.id"
          options={tagModelOptions}
          displayName="Tag Model"
          errors={errors}
          control={control}
        />
        <SelectInputField
          name="tagStatus.id"
          options={tagStatusOptions}
          displayName="Tag Status"
          errors={errors}
          control={control}
        />
        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <DateTimeField
              name="activationDateUtc"
              displayName="Activation Date (UTC)"
              errors={errors}
              control={control}
              handleChange={handleChangeUtc}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <DateTimeField
              name="activationDate"
              displayName="Activation Date (Local)"
              errors={errors}
              control={control}
              handleChange={handleChangeDefault}
              readOnly={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <TextInputFieldOnChange
              name="tagInput"
              displayName="Tag Value"
              errors={errors}
              control={control}
              handleChange={handleChangeTagInput}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <NumberInputField
              name="tagValue"
              displayName="Tag Value (Computed)"
              errors={errors}
              control={control}
              readOnly={true}
            />
          </Grid>
        </Grid>
        {userInterfaceStore.pageType !== PageType.New &&
          userInterfaceStore.pageType !== PageType.NewDuplicate && (
            <Fragment>
              <Typography variant="h5" component="h5">
                Assigned Person
              </Typography>
              {tagStore.item.person ? (
                <Typography>{`${tagStore.item.person.firstName} ${tagStore.item.person.lastName}`}</Typography>
              ) : (
                <Typography>None</Typography>
              )}
            </Fragment>
          )}
      </form>
    </Fragment>
  );
};

export default observer(TagForm);
