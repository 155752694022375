import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { AreaDeviceFunctionInstanceDevice } from "../../../../api/AreaDeviceFunctionInstanceDevice/AreaDeviceFunctionInstanceDeviceInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import { ActionType } from "../../types";
import { generateSchema } from "./AreaDeviceFunctionInstanceDeviceValidation";

const AreaDeviceFunctionInstanceDialogForm: FC<FormProps> = ({
  id,
}: FormProps) => {
  const {
    areaDeviceFunctionInstanceDeviceStore,
    deviceFunctionInstanceDeviceStore,
  } = useRootStore();

  const handleClose = () => {
    areaDeviceFunctionInstanceDeviceStore.setDialogOpen(false);
  };

  const handleDelete = () => {
    areaDeviceFunctionInstanceDeviceStore.handleDelete();
    handleClose();
  };

  const onSubmit = (values: AreaDeviceFunctionInstanceDevice) => {
    const deviceFunctionInstanceDevice =
      deviceFunctionInstanceDeviceStore.getItemFromList(
        values.deviceFunctionInstanceDevice.id
      );
    switch (areaDeviceFunctionInstanceDeviceStore.actionType) {
      case ActionType.Edit:
        areaDeviceFunctionInstanceDeviceStore.handleEdit(
          values,
          deviceFunctionInstanceDevice
        );
        break;
      case ActionType.New:
        areaDeviceFunctionInstanceDeviceStore.handleInsert(
          values,
          deviceFunctionInstanceDevice
        );
        break;
    }
    handleClose();
  };

  const areaDeviceFunctionInstanceSchema = useMemo(
    () => generateSchema(areaDeviceFunctionInstanceDeviceStore.itemList),
    [areaDeviceFunctionInstanceDeviceStore.itemList]
  );

  const resolver = useYupValidationResolver(areaDeviceFunctionInstanceSchema);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: areaDeviceFunctionInstanceDeviceStore.defaultItem,
    resolver,
  });

  useEffect(() => {
    autorun(async () => {
      reset(areaDeviceFunctionInstanceDeviceStore.item);
    });
  }, [reset, areaDeviceFunctionInstanceDeviceStore.item]);

  let deviceFunctionInstanceDeviceOptions = null;
  if (deviceFunctionInstanceDeviceStore.itemList) {
    const deviceFunctionInstanceDevices =
      deviceFunctionInstanceDeviceStore.itemList;
    deviceFunctionInstanceDeviceOptions = deviceFunctionInstanceDevices.map(
      (deviceFunctionInstanceDevice) => {
        return {
          id: deviceFunctionInstanceDevice.id,
          value: `${deviceFunctionInstanceDevice.device.deviceRole.name} (${deviceFunctionInstanceDevice.device.macAddress}) - ${deviceFunctionInstanceDevice.deviceFunctionInstance.name}`,
        };
      }
    );
  }

  return (
    <Dialog
      open={areaDeviceFunctionInstanceDeviceStore.dialogOpen}
      onClose={handleClose}
      aria-labelledby="area-device-function-instance-form-dialog-title"
    >
      <DialogTitle id="area-device-function-instance-form-dialog-title">
        {areaDeviceFunctionInstanceDeviceStore.actionType === ActionType.Edit &&
          "Modify "}
        {areaDeviceFunctionInstanceDeviceStore.actionType === ActionType.New &&
          "Add a new "}
        Device Function Instance Device
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {areaDeviceFunctionInstanceDeviceStore.actionType ===
            ActionType.Edit && "Modify device function instance "}
          {areaDeviceFunctionInstanceDeviceStore.actionType ===
            ActionType.New && "Add a new device function instance "}
          to the area
        </DialogContentText>
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
          <SelectInputField
            name="deviceFunctionInstanceDevice.id"
            options={deviceFunctionInstanceDeviceOptions}
            displayName="Device Function"
            errors={errors}
            control={control}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {areaDeviceFunctionInstanceDeviceStore.actionType ===
          ActionType.Edit && (
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        )}
        <Button form={id} type="submit" color="primary">
          {areaDeviceFunctionInstanceDeviceStore.actionType ===
            ActionType.Edit && "Update"}
          {areaDeviceFunctionInstanceDeviceStore.actionType ===
            ActionType.New && "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(AreaDeviceFunctionInstanceDialogForm);
