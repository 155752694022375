import { GridColumns } from "@material-ui/data-grid";
export const sensorSpecificationListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "manufacturer",
    headerName: "Manufacturer",
    flex: 1,
  },
  {
    field: "type",
    headerName: "Type",
    flex: 1,
  },
  {
    field: "model",
    headerName: "Model",
    flex: 1,
  },
  {
    field: "versionNumber",
    headerName: "Version Number",
    type: "number",
    hide: true,
  },
];
