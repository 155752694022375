import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { ActuatorFunctionInstanceChannel } from "../../../../api/ActuatorFunctionInstanceChannel/ActuatorFunctionInstanceChannelInterface";
import {
  DeviceFunctionInstance,
  DeviceFunctionInstanceSchema,
} from "../../../../api/DeviceFunctionInstance/DeviceFunctionInstanceInterface";
import { SensorFunctionInstanceChannel } from "../../../../api/SensorFunctionInstanceChannel/SensorFunctionInstanceChannelInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import NumberInputField from "../../../Form/NumberInputField/NumberInputField";
import SelectInputFieldOnChange from "../../../Form/SelectInputFieldOnChange/SelectInputFieldOnChange";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import { ActionType } from "../../types";
import { generateSchema } from "./DeviceFunctionInstanceValidation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogHeading: {
      marginBottom: 10,
    },
  })
);

const DeviceFunctionInstanceDialogForm: FC<FormProps> = ({ id }: FormProps) => {
  const {
    deviceFunctionInstanceStore,
    deviceFunctionStore,
    deviceSpecificationSensorSpecificationStore,
    deviceSpecificationActuatorSpecificationStore,
    actuatorFunctionInstanceChannelStore,
    sensorFunctionInstanceChannelStore,
  } = useRootStore();

  const handleClose = () => {
    deviceFunctionInstanceStore.setDialogOpen(false);
  };

  const handleDelete = () => {
    sensorFunctionInstanceChannelStore.handleDeleteDeviceFunctionInstance(
      deviceFunctionInstanceStore.item
    );
    actuatorFunctionInstanceChannelStore.handleDeleteDeviceFunctionInstance(
      deviceFunctionInstanceStore.item
    );
    deviceFunctionInstanceStore.handleDelete();
    handleClose();
  };

  const onSubmit = (values: DeviceFunctionInstance) => {
    const deviceFunction = deviceFunctionStore.getItemFromList(
      values.deviceFunction.id
    );
    switch (deviceFunctionInstanceStore.actionType) {
      case ActionType.Edit:
        deviceFunctionInstanceStore.handleEdit(values, deviceFunction);
        break;
      case ActionType.New:
        deviceFunctionInstanceStore.handleInsert(values, deviceFunction);
        break;
    }
    sensorFunctionInstanceChannelStore.handleUpdateDeviceFunctionInstance(
      deviceFunctionInstanceStore.item
    );
    actuatorFunctionInstanceChannelStore.handleUpdateDeviceFunctionInstance(
      deviceFunctionInstanceStore.item
    );
    handleClose();
  };

  const deviceFunctionInstanceSchema = useMemo(
    () =>
      generateSchema(
        deviceFunctionInstanceStore.itemList,
        deviceFunctionInstanceStore.item,
        deviceSpecificationSensorSpecificationStore.itemList,
        deviceSpecificationActuatorSpecificationStore.itemList
      ),
    [
      deviceFunctionInstanceStore.item,
      deviceFunctionInstanceStore.itemList,
      deviceSpecificationActuatorSpecificationStore.itemList,
      deviceSpecificationSensorSpecificationStore.itemList,
    ]
  );

  const resolver = useYupValidationResolver(deviceFunctionInstanceSchema);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    setFocus,
  } = useForm({
    defaultValues: deviceFunctionInstanceStore.defaultItem,
    resolver,
  });

  useEffect(() => {
    autorun(async () => {
      reset(deviceFunctionInstanceStore.item);
    });
  }, [reset, deviceFunctionInstanceStore.item]);

  let deviceFunctionOptions = null;
  if (deviceFunctionStore.itemList) {
    const deviceFunctions = deviceFunctionStore.itemList;
    deviceFunctionOptions = deviceFunctions.map((deviceFunction) => {
      return {
        id: deviceFunction.id,
        value: deviceFunction.name,
      };
    });
  }

  const handleDeviceFunctionChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    await sensorFunctionInstanceChannelStore.handleDeviceFunctionChange(
      Number(event.target.value),
      deviceFunctionInstanceStore.item
    );
    await actuatorFunctionInstanceChannelStore.handleDeviceFunctionChange(
      Number(event.target.value),
      deviceFunctionInstanceStore.item
    );
    setValue("deviceFunction.id", event.target.value);
  };

  const classes = useStyles();

  const generateSensorInputArrayFields = (): any => {
    let index = 0;
    var rows: any = [];
    sensorFunctionInstanceChannelStore.itemList.forEach(
      (row: SensorFunctionInstanceChannel, i: number) => {
        if (row.action !== ActionType.Delete) {
          rows.push(
            <NumberInputField
              key={index}
              name={`sensors[${index}].channel`}
              displayName={`${row.sensorFunctionLine.sensorSpecification.manufacturer}-${row.sensorFunctionLine.sensorSpecification.type}-${row.sensorFunctionLine.sensorSpecification.model}`}
              errors={errors}
              control={control}
            />
          );
          setValue(`sensors[${i}].channel`, row.channel);
          index++;
        }
      }
    );
    return rows;
  };
  const generateActuatorInputArrayFields = (): any => {
    let index = 0;
    var rows: any = [];
    actuatorFunctionInstanceChannelStore.itemList.forEach(
      (row: ActuatorFunctionInstanceChannel, i: number) => {
        if (row.action !== ActionType.Delete) {
          rows.push(
            <NumberInputField
              key={index}
              name={`actuators[${index}].channel`}
              displayName={`${row.actuatorFunctionLine.actuatorSpecification.manufacturer}-${row.actuatorFunctionLine.actuatorSpecification.type}-${row.actuatorFunctionLine.actuatorSpecification.model}`}
              errors={errors}
              control={control}
            />
          );
          setValue(`actuators[${i}].channel`, row.channel);
          index++;
        }
      }
    );
    return rows;
  };

  return (
    <Dialog
      open={deviceFunctionInstanceStore.dialogOpen}
      onClose={handleClose}
      aria-labelledby="device-function-instance-form-dialog-title"
    >
      <DialogTitle id="device-function-instance-form-dialog-title">
        {deviceFunctionInstanceStore.actionType === ActionType.Edit &&
          "Modify "}
        {deviceFunctionInstanceStore.actionType === ActionType.New &&
          "Add a new "}
        Device Function Instance
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {deviceFunctionInstanceStore.actionType === ActionType.Edit &&
            "Modify device function instance "}
          {deviceFunctionInstanceStore.actionType === ActionType.New &&
            "Add device function instance "}
          for the device role
        </DialogContentText>
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
          <TextInputField
            name="name"
            displayName="Name"
            errors={errors}
            control={control}
          />
          <SelectInputFieldOnChange
            name="deviceFunction.id"
            options={deviceFunctionOptions}
            displayName="Device Function"
            errors={errors}
            control={control}
            handleChange={handleDeviceFunctionChange}
            readOnly={
              sensorFunctionInstanceChannelStore.itemList.length ? true : false
            }
          />
          {sensorFunctionInstanceChannelStore.itemList.length ? (
            <Typography
              className={classes.dialogHeading}
              variant="h6"
              component="h6"
            >
              Sensor Channels
            </Typography>
          ) : null}
          {sensorFunctionInstanceChannelStore.itemList.length
            ? generateSensorInputArrayFields()
            : null}
          {actuatorFunctionInstanceChannelStore.itemList.length ? (
            <Typography
              className={classes.dialogHeading}
              variant="h6"
              component="h6"
            >
              Actuator Channels
            </Typography>
          ) : null}
          {actuatorFunctionInstanceChannelStore.itemList.length
            ? generateActuatorInputArrayFields()
            : null}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {deviceFunctionInstanceStore.actionType === ActionType.Edit && (
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        )}
        <Button form={id} type="submit" color="primary">
          {deviceFunctionInstanceStore.actionType === ActionType.Edit &&
            "Update"}
          {deviceFunctionInstanceStore.actionType === ActionType.New && "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(DeviceFunctionInstanceDialogForm);
