import {
  actuatorFunctionInstanceChannelApi,
  actuatorFunctionLineApi,
  deviceConfigApi,
  deviceFunctionInstanceApi,
  deviceModeApi,
  deviceRoleModeApi,
  deviceRoleRuleApi,
  deviceRoleSenseApi,
  deviceRuleApi,
  deviceSenseApi,
  deviceSpecificationModeApi,
  deviceSpecificationRuleApi,
  deviceSpecificationSenseApi,
  sensorFunctionInstanceChannelApi,
  sensorFunctionLineApi,
  serviceAllocationApi,
  tbAssetApi,
  tbCustomerApi,
  tbDeviceApi,
  tbEntityRelationApi,
} from "../../api/api";
import { ActuatorFunctionInstanceChannelStore } from "../ActuatorFunctionInstanceChannel/ActuatorFunctionInstanceChannelStore";
import { ActuatorFunctionLineStore } from "../ActuatorFunctionLine/ActuatorFunctionLineStore";
import { ActuatorSpecificationStore } from "../ActuatorSpecification/ActuatorSpecificationStore";
import { AreaStore } from "../Area/AreaStore";
import { AreaDeviceFunctionInstanceDeviceStore } from "../AreaDeviceFunctionInstanceDevice/AreaDeviceFunctionInstanceDeviceStore";
import { AreaStatusStore } from "../AreaStatus/AreaStatusStore";
import { BuildingStore } from "../Building/BuildingStore";
import { CustomerStore } from "../Customer/CustomerStore";
import { DeviceStore } from "../Device/DeviceStore";
import { defaultDeviceMode } from "../DeviceConfig/DefaultDeviceMode";
import { DeviceConfigStore } from "../DeviceConfig/DeviceConfigStore";
import { DeviceDatabaseColumnNameStore } from "../DeviceDatabaseColumnName/DeviceDatabaseColumnNameStore";
import { DeviceDatabaseColumnTypeStore } from "../DeviceDatabaseColumnType/DeviceDatabaseColumnTypeStore";
import { DeviceFunctionStore } from "../DeviceFunction/DeviceFunctionStore";
import { DeviceFunctionInstanceStore } from "../DeviceFunctionInstance/DeviceFunctionInstanceStore";
import { DeviceFunctionInstanceDeviceStore } from "../DeviceFunctionInstanceDevice/DeviceFunctionInstanceDeviceStore";
import { DeviceModeStore } from "../DeviceMode/DeviceModeStore";
import { DeviceRoleModeStore } from "../DeviceMode/DeviceRoleModeStore";
import { DeviceSpecificationModeStore } from "../DeviceMode/DeviceSpecificationModeStore";
import { DeviceRoleStore } from "../DeviceRole/DeviceRoleStore";
import { DeviceRoleDatabaseDefinitionStore } from "../DeviceRoleDatabaseDefinition/DeviceRoleDatabaseDefinitionStore";
import { DeviceRoleRuleStore } from "../DeviceRule/DeviceRoleRuleStore";
import { DeviceRuleStore } from "../DeviceRule/DeviceRuleStore";
import { DeviceSpecificationRuleStore } from "../DeviceRule/DeviceSpecificationRuleStore";
import { DeviceRoleSenseStore } from "../DeviceSense/DeviceRoleSenseStore";
import { DeviceSenseStore } from "../DeviceSense/DeviceSenseStore";
import { DeviceSpecificationSenseStore } from "../DeviceSense/DeviceSpecificationSenseStore";
import { DeviceSpecificationStore } from "../DeviceSpecification/DeviceSpecificationStore";
import { DeviceSpecificationActuatorSpecificationStore } from "../DeviceSpecificationActuatorSpecification/DeviceSpecificationActuatorSpecificationStore";
import { DeviceSpecificationSensorSpecificationStore } from "../DeviceSpecificationSensorSpecification/DeviceSpecificationSensorSpecificationStore";
import { PeriodStore } from "../Period/PeriodStore";
import { PersonStore } from "../Person/PersonStore";
import { PersonAreaStore } from "../PersonArea/PersonAreaStore";
import { PersonRoomStore } from "../PersonRoom/PersonRoomStore";
import { PersonServiceAllocationStore } from "../PersonServiceAllocation/PersonServiceAllocationStore";
import { PersonServicePackageStore } from "../PersonServicePackage/PersonServicePackageStore";
import { PersonStatusStore } from "../PersonStatus/PersonStatusStore";
import { PersonTypeStore } from "../PersonType/PersonTypeStore";
import { PlatformSpecificationStore } from "../PlatformSpecification/PlatformSpecificationStore";
import { ResourceStore } from "../Resource/ResourceStore";
import { ResourceUnitStore } from "../ResourceUnit/ResourceUnitStore";
import { RoomStore } from "../Room/RoomStore";
import { RoomDeviceFunctionInstanceDeviceStore } from "../RoomDeviceFunctionInstanceDevice/RoomDeviceFunctionInstanceDeviceStore";
import { RoomStatusStore } from "../RoomStatus/RoomStatusStore";
import { RoomTypeStore } from "../RoomType/RoomTypeStore";
import { SensorFunctionInstanceChannelStore } from "../SensorFunctionInstanceChannel/SensorFunctionInstanceChannelStore";
import { SensorFunctionLineStore } from "../SensorFunctionLine/SensorFunctionLineStore";
import { SensorSpecificationStore } from "../SensorSpecification/SensorSpecificationStore";
import { ServiceAllocationStore } from "../ServiceAllocation/ServiceAllocationStore";
import { ServicePackageStore } from "../ServicePackage/ServicePackageStore";
import { TagStore } from "../Tag/TagStore";
import { TagModelStore } from "../TagModel/TagModelStore";
import { TagStatusStore } from "../TagStatus/TagStatusStore";
import { defaultTbAsset } from "../TbAsset/DefaultTbAsset";
import { TbAssetStore } from "../TbAsset/TbAssetStore";
import { defaultTbCustomer } from "../TbCustomer/DefaultTbCustomer";
import { TbCustomerStore } from "../TbCustomer/TbCustomerStore";
import { defaultTbDevice } from "../TbDevice/DefaultTbDevice";
import { TbDeviceStore } from "../TbDevice/TbDeviceStore";
import { defaultTbEntityRelation } from "../TbEntityRelation/DefaultTbEntityRelation";
import { TbEntityRelationStore } from "../TbEntityRelation/TbEntityRelationStore";
import { UserInterfaceStore } from "../UserInterface/UserInterfaceStore";

export class RootStore {
  areaStore = new AreaStore(this);
  areaDeviceFunctionInstanceDeviceStore =
    new AreaDeviceFunctionInstanceDeviceStore(this);
  areaStatusStore = new AreaStatusStore(this);
  userInterfaceStore = new UserInterfaceStore(this);
  platformSpecificationStore = new PlatformSpecificationStore(this);
  sensorSpecificationStore = new SensorSpecificationStore(this);
  sensorFunctionLineStore = new SensorFunctionLineStore(this);
  sensorFunctionInstanceChannelStore = new SensorFunctionInstanceChannelStore(
    this,
    sensorFunctionLineApi,
    deviceFunctionInstanceApi
  );
  actuatorSpecificationStore = new ActuatorSpecificationStore(this);
  actuatorFunctionLineStore = new ActuatorFunctionLineStore(this);
  actuatorFunctionInstanceChannelStore =
    new ActuatorFunctionInstanceChannelStore(
      this,
      actuatorFunctionLineApi,
      deviceFunctionInstanceApi
    );
  deviceFunctionStore = new DeviceFunctionStore(this);
  deviceRoleStore = new DeviceRoleStore(this);
  deviceDatabaseColumnNameStore = new DeviceDatabaseColumnNameStore(this);
  deviceDatabaseColumnTypeStore = new DeviceDatabaseColumnTypeStore(this);
  deviceRoleDatabaseDefinitionStore = new DeviceRoleDatabaseDefinitionStore(
    this
  );
  deviceStore = new DeviceStore(this);
  buildingStore = new BuildingStore(this);
  customerStore = new CustomerStore(this);
  deviceSpecificationStore = new DeviceSpecificationStore(this);
  deviceSpecificationActuatorSpecificationStore =
    new DeviceSpecificationActuatorSpecificationStore(this);
  deviceSpecificationSensorSpecificationStore =
    new DeviceSpecificationSensorSpecificationStore(this);
  deviceFunctionInstanceStore = new DeviceFunctionInstanceStore(
    this,
    sensorFunctionInstanceChannelApi,
    actuatorFunctionInstanceChannelApi
  );
  deviceFunctionInstanceDeviceStore = new DeviceFunctionInstanceDeviceStore(
    this,
    deviceFunctionInstanceApi
  );
  deviceConfigStore = new DeviceConfigStore(
    this,
    deviceConfigApi,
    "deviceConfig",
    "Device Config",
    "Device Configs",
    defaultDeviceMode,
    deviceModeApi,
    deviceRoleModeApi,
    deviceSpecificationModeApi,
    deviceSenseApi,
    deviceRoleSenseApi,
    deviceSpecificationSenseApi,
    deviceRuleApi,
    deviceRoleRuleApi,
    deviceSpecificationRuleApi
  );
  deviceModeStore = new DeviceModeStore(this);
  deviceRoleModeStore = new DeviceRoleModeStore(this);
  deviceSpecificationModeStore = new DeviceSpecificationModeStore(this);
  deviceSenseStore = new DeviceSenseStore(this);
  deviceRoleSenseStore = new DeviceRoleSenseStore(this);
  deviceSpecificationSenseStore = new DeviceSpecificationSenseStore(this);
  deviceRuleStore = new DeviceRuleStore(this);
  deviceRoleRuleStore = new DeviceRoleRuleStore(this);
  deviceSpecificationRuleStore = new DeviceSpecificationRuleStore(this);
  resourceStore = new ResourceStore(this);
  resourceUnitStore = new ResourceUnitStore(this);
  roomTypeStore = new RoomTypeStore(this);
  roomStore = new RoomStore(this);
  roomDeviceFunctionInstanceDeviceStore =
    new RoomDeviceFunctionInstanceDeviceStore(this);
  roomStatusStore = new RoomStatusStore(this);
  tagStore = new TagStore(this);
  periodStore = new PeriodStore(this);
  personStore = new PersonStore(this);
  personAreaStore = new PersonAreaStore(this);
  personRoomStore = new PersonRoomStore(this);
  personServiceAllocationStore = new PersonServiceAllocationStore(
    this,
    serviceAllocationApi
  );
  personServicePackageStore = new PersonServicePackageStore(this);
  personTypeStore = new PersonTypeStore(this);
  personStatusStore = new PersonStatusStore(this);
  serviceAllocationStore = new ServiceAllocationStore(this);
  servicePackageStore = new ServicePackageStore(this);
  tagModelStore = new TagModelStore(this);
  tagStatusStore = new TagStatusStore(this);
  tbAssetStore = new TbAssetStore(this, tbAssetApi, defaultTbAsset);
  tbDeviceStore = new TbDeviceStore(this, tbDeviceApi, defaultTbDevice);
  tbCustomerStore = new TbCustomerStore(this, tbCustomerApi, defaultTbCustomer);
  tbEntityRelationStore = new TbEntityRelationStore(
    this,
    tbEntityRelationApi,
    defaultTbEntityRelation
  );
}
