import { number, object, SchemaOf } from "yup";
import { DeviceRoleDatabaseDefinitionSchema } from "../../../../api/DeviceRoleDatabaseDefinition/DeviceRoleDatabaseDefinitionInterface";

export function generateSchema() {
  const schema: SchemaOf<DeviceRoleDatabaseDefinitionSchema> = object().shape({
    deviceDatabaseColumnName: object().shape({
      id: number().positive().required(),
    }),
    deviceDatabaseColumnType: object().shape({
      id: number().positive().required(),
    }),
    size: number().positive().required(),
    resource: object().shape({
      id: number(),
    }),
  });
  return schema;
}
