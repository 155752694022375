import { number, object, SchemaOf } from "yup";
import {
  PersonRoom,
  PersonRoomSchema,
} from "../../../../api/PersonRoom/PersonRoomInterface";
import { occurrencesInNumberArray } from "../../../../utils/occurrencesInArray";

export function generateSchema(personRoomList: PersonRoom[]) {
  const schema: SchemaOf<PersonRoomSchema> = object().shape({
    room: object().shape({
      id: number()
        .required()
        .positive()
        .test(
          "checkOneOfEachRoom",
          "Please select a valid room",
          (value: unknown): boolean => {
            if (typeof value !== "number") {
              return false;
            }
            if (value === 0) {
              return false;
            }
            if (!personRoomList.length) {
              return true;
            }
            const roomIdList = personRoomList.map((personRoom) =>
              Number(personRoom.room.id)
            );
            const occurrences = occurrencesInNumberArray(value, roomIdList);

            return occurrences < 1;
          }
        ),
    }),
  });
  return schema;
}
