import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect } from "react";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import Heading from "../../../Form/Heading/Heading";
import SubmitButton from "../../../Form/SubmitButton/SubmitButton";
import { PageType } from "../../types";
import BuildingForm from "./BuildingForm";

const BuildingPage: FC = () => {
  const router = useRouter();
  const { buildingStore, userInterfaceStore, tbCustomerStore } = useRootStore();

  useEffect(() => {
    autorun(async () => {
      await tbCustomerStore.getItemListAsync();
      switch (userInterfaceStore.pageType) {
        case PageType.View:
        case PageType.Existing:
          await buildingStore.getItemAsync(Number(router.query.id));
          break;
        case PageType.New:
          buildingStore.resetItem();
          break;
      }
    });
  }, [
    userInterfaceStore.pageType,
    buildingStore,
    router.query.id,
    tbCustomerStore,
  ]);

  const formId = "buildingForm";

  return (
    <Fragment>
      <Heading
        pageType={userInterfaceStore.pageType!}
        heading={buildingStore.singularDisplayName}
      />
      <BuildingForm id={formId} />
      {userInterfaceStore.pageType !== PageType.View && (
        <SubmitButton form={formId} pageType={userInterfaceStore.pageType!} />
      )}
    </Fragment>
  );
};

export default observer(BuildingPage);
