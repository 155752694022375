import { number, object, SchemaOf, string } from "yup";
import { ResourceSchema } from "../../../../api/Resource/ResourceInterface";
import { PageType } from "../../types";

export function generateSchema(type: PageType) {
  const schema: SchemaOf<ResourceSchema> = object().shape({
    name: string().required(),
    resourceUnit: object().shape({
      id: number().required().positive(),
    }),
  });
  return schema;
}
