import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import {
  ActuatorSpecification,
  ActuatorSpecificationInput,
  ActuatorSpecificationSchema,
} from "../../../../api/ActuatorSpecification/ActuatorSpecificationInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import calculateReadOnly from "../../../../utils/calculateReadOnly";
import deduplicateArray from "../../../../utils/deduplicateArray";
import ComboAutocompleteField from "../../../Form/ComboAutocompleteField/ComboAutocompleteField";
import NumberInputField from "../../../Form/NumberInputField/NumberInputField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./ActuatorSpecificationValidation";

const ActuatorSpecificationForm: FC<FormProps> = ({ id }: FormProps) => {
  const router = useRouter();
  const { actuatorSpecificationStore, userInterfaceStore } = useRootStore();

  const actuatorSpecificationSchema = useMemo(
    () => generateSchema(userInterfaceStore.pageType!),
    [userInterfaceStore.pageType]
  );

  const resolver = useYupValidationResolver(actuatorSpecificationSchema);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    reset,
  } = useForm({
    defaultValues: actuatorSpecificationStore.defaultItem,
    resolver,
  });

  const onSubmit = async (values: ActuatorSpecificationSchema) => {
    let actuatorSpecification: ActuatorSpecificationInput = {
      ...values,
      versionNumber: 0,
    };
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
        actuatorSpecification.id = Number(router.query.id);
        let updateResult = await actuatorSpecificationStore.updateItemAsync(
          actuatorSpecification
        );

        break;
      case PageType.New:
      case PageType.NewDuplicate:
        await actuatorSpecificationStore.insertItemAsync(actuatorSpecification);
        userInterfaceStore.setPageType(PageType.Inserted);
        router.history.push(
          `/${actuatorSpecificationStore.endpoint}/edit/${actuatorSpecificationStore.item.id}`
        );
        break;
    }
  };

  useEffect(() => {
    autorun(async () => {
      reset(actuatorSpecificationStore.item);
    });
  }, [actuatorSpecificationStore, reset]);

  let manufacturerOptions = null,
    typeOptions = null;

  if (actuatorSpecificationStore.itemList) {
    const rawManufacturerValues = actuatorSpecificationStore.itemList.map(
      (val) => {
        return val.manufacturer;
      }
    );
    manufacturerOptions = deduplicateArray(rawManufacturerValues).map((val) => {
      return { displayValue: val };
    });
    const rawTypeValues = actuatorSpecificationStore.itemList.map((val) => {
      return val.type;
    });

    typeOptions = deduplicateArray(rawTypeValues).map((val) => {
      return { displayValue: val };
    });
  }

  const readOnly = calculateReadOnly(userInterfaceStore.pageType);

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={actuatorSpecificationStore} />
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        {readOnly ? (
          <Fragment>
            <TextInputField
              name="manufacturer"
              displayName="Manufacturer"
              errors={errors}
              control={control}
              readOnly={readOnly}
            />
            <TextInputField
              name="type"
              displayName="Type"
              errors={errors}
              control={control}
              readOnly={readOnly}
            />
          </Fragment>
        ) : (
          <Fragment>
            <ComboAutocompleteField
              options={manufacturerOptions}
              name="manufacturer"
              displayName="Manufacturer"
              errors={errors}
              control={control}
              readOnly={readOnly}
            />
            <ComboAutocompleteField
              options={typeOptions}
              name="type"
              displayName="Type"
              errors={errors}
              control={control}
              readOnly={readOnly}
            />
          </Fragment>
        )}
        <TextInputField
          name="model"
          displayName="Model"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <NumberInputField
          name="voltage"
          displayName="Voltage (V)"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <NumberInputField
          name="current"
          displayName="Current (A)"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <NumberInputField
          name="levels"
          displayName="Levels"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <NumberInputField
          name="phases"
          displayName="Phases"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
      </form>
    </Fragment>
  );
};

export default observer(ActuatorSpecificationForm);
