import { TbDevice } from "../../api/TbDevice/TbDeviceInterface";

export const defaultTbDevice: TbDevice = {
  id: {
    entityType: "DEVICE",
    id: "",
  },
  createdTime: 0,
  additionalInfo: {},
  tenantId: {
    entityType: "",
    id: "",
  },
  customerId: {
    entityType: "",
    id: "",
  },
  name: "",
  type: "",
  label: "",
  deviceProfileId: {
    entityType: "",
    id: "",
  },
  deviceData: {
    configuration: {
      type: "",
    },
    transportConfiguration: {
      type: "",
    },
  },
};
