import { object, SchemaOf, string } from "yup";
import { TagModelSchema } from "../../../../api/TagModel/TagModelInterface";
import { PageType } from "../../types";

export function generateSchema(type: PageType) {
  const schema: SchemaOf<TagModelSchema> = object().shape({
    name: string().required(),
    modelNumber: string().required(),
    manufacturer: string().required(),
  });
  return schema;
}
