import { Grid } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import {
  PersonInput,
  PersonSchema,
} from "../../../../api/Person/PersonInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import calculateLocalFromUtc from "../../../../utils/calculateLocalFromUtc";
import calculateReadOnly from "../../../../utils/calculateReadOnly";
import calculateUtcFromLocal from "../../../../utils/calculateUtcFromLocal";
import DateField from "../../../Form/DateField/DateField";
import DateTimeField from "../../../Form/DateTimeField/DateTimeField";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./PersonValidation";

const PersonForm: FC<FormProps> = ({ id }: FormProps) => {
  const router = useRouter();
  const {
    personStore,
    tagStore,
    personTypeStore,
    personStatusStore,
    servicePackageStore,
    personServicePackageStore,
    personServiceAllocationStore,
    personAreaStore,
    personRoomStore,
    userInterfaceStore,
  } = useRootStore();

  const personSchema = useMemo(
    () => generateSchema(userInterfaceStore.pageType!, personStore.item),
    [personStore.item, userInterfaceStore.pageType]
  );

  const resolver = useYupValidationResolver(personSchema);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors, isDirty },
    reset,
  } = useForm({
    defaultValues: personStore.defaultItem,
    resolver,
  });

  const onSubmit = async (values: PersonSchema) => {
    let person: PersonInput = {
      personTypeId: values.personType.id,
      personStatusId: values.personStatus.id,
      firstName: values.firstName,
      lastName: values.lastName,
      dateOfBirth: values.dateOfBirth,
      emailAddress: values.emailAddress,
      employer: values.employer,
      startDate: values.startDate,
      endDate: values.endDate,
    };
    if (values.tag.id && values.tag.id !== 0) {
      person.tagId = values.tag.id;
    }
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
        person.id = Number(router.query.id);
        await personStore.updateItemAsync(person);
        break;
      case PageType.New:
      case PageType.NewDuplicate:
        await personStore.insertItemAsync(person);
        userInterfaceStore.setPageType(PageType.Inserted);
        router.history.push(
          `/${personStore.endpoint}/edit/${personStore.item.id}`
        );
        break;
    }
    await personServicePackageStore.handleUpdatePerson(personStore.item.id);
    await personServiceAllocationStore.handleUpdatePerson(personStore.item.id);
    await personAreaStore.handleUpdatePerson(personStore.item.id);
    await personRoomStore.handleUpdatePerson(personStore.item.id);
  };

  useEffect(() => {
    autorun(async () => {
      reset(personStore.item);
    });
  }, [personStore, reset]);
  const readOnly = calculateReadOnly(userInterfaceStore.pageType);

  const tags = tagStore.itemList;
  let tagOptions: { id: number; value: string }[] = [];
  if (tags.length) {
    tagOptions = tags.map((tag) => {
      return {
        id: tag.id,
        value: tag.tagInput,
      };
    });
  }
  const personTypes = personTypeStore.itemList;
  let personTypeOptions: { id: number; value: string }[] = [];
  if (personTypes.length) {
    personTypeOptions = personTypes.map((personType) => {
      return {
        id: personType.id,
        value: personType.type,
      };
    });
  }
  const personStatuses = personStatusStore.itemList;
  let personStatusOptions: { id: number; value: string }[] = [];
  if (personStatuses.length) {
    personStatusOptions = personStatuses.map((personStatus) => {
      return {
        id: personStatus.id,
        value: personStatus.status,
      };
    });
  }

  const handleChangeStartDateDefault = (date: MaterialUiPickersDate): void => {
    const utcDate = calculateUtcFromLocal(date!);
    setValue("startDate", date);
    setValue("startDateUtc", utcDate);
  };
  const handleChangeStartDateUtc = (date: MaterialUiPickersDate): void => {
    const localDate = calculateLocalFromUtc(date!);
    setValue("startDateUtc", date);
    setValue("startDate", localDate);
  };
  const handleChangeEndDateDefault = (date: MaterialUiPickersDate): void => {
    const utcDate = calculateUtcFromLocal(date!);
    setValue("endDate", date);
    setValue("endDateUtc", utcDate);
  };
  const handleChangeEndDateUtc = (date: MaterialUiPickersDate): void => {
    const localDate = calculateLocalFromUtc(date!);
    setValue("endDateUtc", date);
    setValue("endDate", localDate);
  };

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={personStore} />
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <SelectInputField
          name="personType.id"
          options={personTypeOptions}
          displayName="Person Type"
          errors={errors}
          control={control}
        />
        <SelectInputField
          name="personStatus.id"
          options={personStatusOptions}
          displayName="Person Status"
          errors={errors}
          control={control}
        />
        <SelectInputField
          name="tag.id"
          options={tagOptions}
          optional
          displayName="Tag"
          errors={errors}
          control={control}
        />
        <TextInputField
          name="firstName"
          displayName="First Name"
          errors={errors}
          control={control}
        />
        <TextInputField
          name="lastName"
          displayName="Last Name"
          errors={errors}
          control={control}
        />
        <DateField
          name="dateOfBirth"
          displayName="DOB"
          errors={errors}
          control={control}
        />
        <TextInputField
          name="emailAddress"
          displayName="Email Address"
          errors={errors}
          control={control}
        />
        <TextInputField
          name="employer"
          displayName="Employer"
          errors={errors}
          control={control}
        />{" "}
        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <DateTimeField
              name="startDateUtc"
              displayName="Start Date (UTC)"
              errors={errors}
              control={control}
              handleChange={handleChangeStartDateUtc}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <DateTimeField
              name="startDate"
              displayName="Start Date (Local)"
              errors={errors}
              control={control}
              handleChange={handleChangeStartDateDefault}
              readOnly={true}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <DateTimeField
              name="endDateUtc"
              displayName="End Date (UTC)"
              errors={errors}
              control={control}
              handleChange={handleChangeEndDateUtc}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <DateTimeField
              name="endDate"
              displayName="End Date (Local)"
              errors={errors}
              control={control}
              handleChange={handleChangeEndDateDefault}
              readOnly={true}
            />
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default observer(PersonForm);
