import { makeAutoObservable } from "mobx";
import { PageType } from "../../components/pages/types";
import { persistStore } from "../../utils/persist";
import { RootStore } from "../Root/RootStore";

export class UserInterfaceStore {
  root: RootStore;
  id: null | number | string = null;
  mouseX: null | number = null;
  mouseY: null | number = null;
  dialogOpen: boolean = false;
  pageType: PageType | null = null;

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);
    persistStore(
      this,
      ["id", "mouseX", "mouseY", "dialogOpen", "pageType"],
      "UserInterfaceStore"
    );
  }

  setMouseXMouseY(mouseX: null | number, mouseY: null | number) {
    this.mouseX = mouseX;
    this.mouseY = mouseY;
  }

  resetMouseXMouseY() {
    this.mouseX = null;
    this.mouseY = null;
  }

  setId(id: null | number | string) {
    this.id = id;
  }

  setDialogOpen(dialogOpen: boolean) {
    this.dialogOpen = dialogOpen;
  }

  setPageType(pageType: PageType) {
    this.pageType = pageType;
  }
}
