import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import {
  ServicePackageInput,
  ServicePackageSchema,
} from "../../../../api/ServicePackage/ServicePackageInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import calculateReadOnly from "../../../../utils/calculateReadOnly";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./ServicePackageValidation";

const ServicePackageForm: FC<FormProps> = ({ id }: FormProps) => {
  const router = useRouter();
  const {
    servicePackageStore,
    serviceAllocationStore,
    roomTypeStore,
    userInterfaceStore,
  } = useRootStore();

  const servicePackageSchema = useMemo(
    () => generateSchema(userInterfaceStore.pageType!),
    [userInterfaceStore.pageType]
  );

  const resolver = useYupValidationResolver(servicePackageSchema);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    reset,
  } = useForm({
    defaultValues: servicePackageStore.defaultItem,
    resolver,
  });

  const onSubmit = async (values: ServicePackageSchema) => {
    let servicePackage: ServicePackageInput = {
      roomTypeId: values.roomType.id,
      name: values.name,
      description: values.description,
    };
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
        servicePackage.id = Number(router.query.id);
        await servicePackageStore.updateItemAsync(servicePackage);
        break;
      case PageType.New:
      case PageType.NewDuplicate:
        await servicePackageStore.insertItemAsync(servicePackage);
        userInterfaceStore.setPageType(PageType.Inserted);
        router.history.push(
          `/${servicePackageStore.endpoint}/edit/${servicePackageStore.item.id}`
        );
        break;
    }
    serviceAllocationStore.handleUpdateServicePackage(
      servicePackageStore.item.id
    );
  };

  useEffect(() => {
    autorun(async () => {
      reset(servicePackageStore.item);
    });
  }, [servicePackageStore, reset]);
  const readOnly = calculateReadOnly(userInterfaceStore.pageType);

  const roomTypes = roomTypeStore.itemList;
  let roomTypeOptions: { id: number; value: string }[] = [];
  if (roomTypes.length) {
    roomTypeOptions = roomTypes.map((roomType) => {
      return {
        id: roomType.id,
        value: roomType.type,
      };
    });
  }

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={servicePackageStore} />
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <TextInputField
          name="name"
          displayName="Service Package Name"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <TextInputField
          name="description"
          displayName="Service Package Description"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <SelectInputField
          name="roomType.id"
          options={roomTypeOptions}
          displayName="Room Type"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
      </form>
    </Fragment>
  );
};

export default observer(ServicePackageForm);
