import { buildingApi } from "../../api/api";
import { Building, BuildingInput } from "../../api/Building/BuildingInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultBuilding } from "./DefaultBuilding";

export class BuildingStore extends MobXStore<Building, BuildingInput> {
  constructor(root: RootStore) {
    super(
      root,
      buildingApi,
      "building",
      "Building",
      "Buildings",
      defaultBuilding
    );
  }

  async getItemListByForeignKeyAsync(
    id: number | string,
    foreignKey: string
  ): Promise<void> {
    const result = await this.api.getByForeignKeyId!(id, foreignKey);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.resetApiResponse();
    this.setItemList(result.data);
  }
}
