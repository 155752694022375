import { AbstractAPI as AbstractApi } from "../abstractApi";
import { DeviceSense, DeviceRoleSenseInput } from "./DeviceSenseInterface";

export class DeviceRoleSenseApi extends AbstractApi<
  DeviceSense,
  DeviceRoleSenseInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "deviceRoleSense");
  }
}
