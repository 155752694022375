import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import {
  DeviceSpecification,
  DeviceSpecificationInput,
  DeviceSpecificationSchema,
} from "../../../../api/DeviceSpecification/DeviceSpecificationInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import calculateReadOnly from "../../../../utils/calculateReadOnly";
import deduplicateArray from "../../../../utils/deduplicateArray";
import ComboAutocompleteField from "../../../Form/ComboAutocompleteField/ComboAutocompleteField";
import NumberInputField from "../../../Form/NumberInputField/NumberInputField";
import SelectInputField from "../../../Form/SelectInputField/SelectInputField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./DeviceSpecificationValidation";

const DeviceFunctionForm: FC<FormProps> = ({ id }: FormProps) => {
  const router = useRouter();
  const {
    deviceSpecificationStore,
    deviceSpecificationSensorSpecificationStore,
    deviceSpecificationActuatorSpecificationStore,
    platformSpecificationStore,
    userInterfaceStore,
  } = useRootStore();

  const deviceFunctionSchema = useMemo(
    () => generateSchema(userInterfaceStore.pageType!),
    [userInterfaceStore.pageType]
  );

  const resolver = useYupValidationResolver(deviceFunctionSchema);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    defaultValues: deviceSpecificationStore.defaultItem,
    resolver,
  });

  useEffect(() => {
    autorun(async () => {
      reset(deviceSpecificationStore.item);
    });
  }, [deviceSpecificationStore.item, reset]);

  const onSubmit = async (values: DeviceSpecificationSchema) => {
    let deviceSpecification: DeviceSpecificationInput = {
      platformSpecificationId: values.platformSpecification.id,
      name: values.name,
      manufacturer: values.manufacturer,
      enclosure: values.enclosure,
      model: values.model,
      supplyVoltage: values.supplyVoltage,
      versionNumber: 0,
      token: values.token!,
    };
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
      case PageType.Inserted:
        deviceSpecification.id = Number(router.query.id);
        await deviceSpecificationStore.updateItemAsync(deviceSpecification);

        break;
      case PageType.New:
      case PageType.NewDuplicate:
        await deviceSpecificationStore.insertItemAsync(deviceSpecification);
        userInterfaceStore.setPageType(PageType.Inserted);
        router.history.push(
          `/${deviceSpecificationStore.endpoint}/edit/${deviceSpecificationStore.item.id}`
        );
        break;
    }
    await deviceSpecificationSensorSpecificationStore.handleUpdateDeviceSpecification(
      deviceSpecificationStore.item.id
    );
    await deviceSpecificationActuatorSpecificationStore.handleUpdateDeviceSpecification(
      deviceSpecificationStore.item.id
    );
  };

  const platformSpecificationList = platformSpecificationStore.itemList;
  let platformSpecificationOptions: { id: number; value: string }[] = [];
  if (platformSpecificationList.length) {
    platformSpecificationOptions = platformSpecificationList.map(
      (platformSpecification) => {
        return {
          id: platformSpecification.id,
          value: `${platformSpecification.platform}`,
        };
      }
    );
  }

  let manufacturerOptions = null;

  if (deviceSpecificationStore.itemList) {
    const rawManufacturerValues = deviceSpecificationStore.itemList.map(
      (val) => {
        return val.manufacturer;
      }
    );
    manufacturerOptions = deduplicateArray(rawManufacturerValues).map((val) => {
      return { displayValue: val };
    });
  }

  const readOnly = calculateReadOnly(userInterfaceStore.pageType);

  function renderTokenField(pageType: PageType) {
    if (pageType === PageType.New) {
      return;
    }

    if (pageType === PageType.NewDuplicate) {
      return;
    }

    return (
      <TextInputField
        name="token"
        displayName="Token"
        errors={errors}
        control={control}
        readOnly={true}
      />
    );
  }

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={deviceSpecificationStore} />
      <SnackbarAlert store={deviceSpecificationSensorSpecificationStore} />
      <SnackbarAlert store={deviceSpecificationActuatorSpecificationStore} />
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <TextInputField
          name="name"
          displayName="Name"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <SelectInputField
          name="platformSpecification.id"
          options={platformSpecificationOptions}
          displayName="Platform Specification"
          errors={errors}
          control={control}
        />
        <ComboAutocompleteField
          options={manufacturerOptions}
          name="manufacturer"
          displayName="Manufacturer"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <TextInputField
          name="enclosure"
          displayName="Enclosure"
          errors={errors}
          control={control}
          readOnly={false}
        />
        <TextInputField
          name="model"
          displayName="Model"
          errors={errors}
          control={control}
          readOnly={false}
        />
        <NumberInputField
          name="supplyVoltage"
          displayName="Supply Voltage (V)"
          errors={errors}
          control={control}
          readOnly={false}
        />
        {renderTokenField(userInterfaceStore.pageType!)}
      </form>
    </Fragment>
  );
};

export default observer(DeviceFunctionForm);
