import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { PersonServiceAllocation } from "../../../../api/PersonServiceAllocation/PersonServiceAllocationInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { ActionType } from "../../types";

const PersonServiceAllocationTable: FC = () => {
  const { personServiceAllocationStore } = useRootStore();

  function renderTableRow(row: PersonServiceAllocation) {
    if (row.action === ActionType.Delete) {
      return;
    }

    return (
      <TableRow key={row.localId}>
        <TableCell>{row.serviceAllocation.servicePackage.name}</TableCell>
        <TableCell>{`${row.serviceAllocation.resource.name} (${row.serviceAllocation.resource.resourceUnit.unit})`}</TableCell>
        <TableCell>{row.serviceAllocation.allocation}</TableCell>
        <TableCell>{row.balance}</TableCell>
        <TableCell>{String(row.lastUpdateTime)}</TableCell>
        <TableCell>{String(row.nextUpdateTime)}</TableCell>
      </TableRow>
    );
  }

  return (
    <Table aria-label="device function instance device">
      <TableHead>
        <TableRow>
          <TableCell>From</TableCell>
          <TableCell>Resource</TableCell>
          <TableCell>Allowance</TableCell>
          <TableCell>Remaining</TableCell>
          <TableCell>Last Update Time</TableCell>
          <TableCell>Next Update Time</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {personServiceAllocationStore.itemList.length
          ? personServiceAllocationStore.itemList.map(
              (row: PersonServiceAllocation) => {
                return renderTableRow(row);
              }
            )
          : null}
      </TableBody>
    </Table>
  );
};

export default observer(PersonServiceAllocationTable);
