import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect } from "react";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import Heading from "../../../Form/Heading/Heading";
import SubmitButton from "../../../Form/SubmitButton/SubmitButton";
import { PageType } from "../../types";
import AreaDeviceFunctionInstanceDeviceDialogForm from "../AreaDeviceFunctionInstanceDevice/AreaDeviceFunctionInstanceDeviceDialogForm";
import AreaDeviceFunctionInstanceDeviceTable from "../AreaDeviceFunctionInstanceDevice/AreaDeviceFunctionInstanceDeviceTable";
import AreaForm from "./AreaForm";

const AreaPage: FC = () => {
  const router = useRouter();
  const {
    areaStore,
    areaStatusStore,
    areaDeviceFunctionInstanceDeviceStore,
    deviceFunctionInstanceDeviceStore,
    userInterfaceStore,
  } = useRootStore();

  useEffect(() => {
    autorun(async () => {
      areaStatusStore.getItemListAsync();
      deviceFunctionInstanceDeviceStore.getItemListAsync();
      switch (userInterfaceStore.pageType) {
        case PageType.View:
        case PageType.Existing:
          await areaStore.getItemAsync(Number(router.query.id));
          areaDeviceFunctionInstanceDeviceStore.getItemListByForeignKeyAsync(
            areaStore.item.id,
            "area"
          );
          break;
        case PageType.New:
          areaStore.resetItem();
          areaDeviceFunctionInstanceDeviceStore.resetItemList();
          break;
      }
    });
  }, [
    userInterfaceStore.pageType,
    areaStore,
    router.query.id,
    areaStatusStore,
    areaDeviceFunctionInstanceDeviceStore,
    deviceFunctionInstanceDeviceStore,
  ]);

  const formId = "areaForm";
  const areaDeviceFunctionInstanceDeviceFormId =
    "areaDeviceFunctionInstanceDeviceForm";

  return (
    <Fragment>
      <Heading
        pageType={userInterfaceStore.pageType!}
        heading={areaStore.singularDisplayName}
      />
      <AreaForm id={formId} />
      <AreaDeviceFunctionInstanceDeviceDialogForm
        id={areaDeviceFunctionInstanceDeviceFormId}
      />
      <AreaDeviceFunctionInstanceDeviceTable />
      {userInterfaceStore.pageType !== PageType.View && (
        <SubmitButton form={formId} pageType={userInterfaceStore.pageType!} />
      )}
    </Fragment>
  );
};

export default observer(AreaPage);
