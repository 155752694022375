import { AbstractAPI as AbstractApi } from "../abstractApi";
import { DeviceDatabaseColumnName } from "./DeviceDatabaseColumnNameInterface";
export class DeviceDatabaseColumnNameApi extends AbstractApi<
  DeviceDatabaseColumnName,
  DeviceDatabaseColumnName
> {
  constructor(baseUrl: string) {
    super(baseUrl, "deviceDatabaseColumnName");
  }
}
