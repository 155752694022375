import { deviceSpecificationSenseApi } from "../../api/api";
import {
  DeviceSense,
  DeviceSpecificationSenseInput,
} from "../../api/DeviceSense/DeviceSenseInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDeviceSense } from "./DefaultDeviceSense";

export class DeviceSpecificationSenseStore extends MobXStore<
  DeviceSense,
  DeviceSpecificationSenseInput
> {
  constructor(root: RootStore) {
    super(
      root,
      deviceSpecificationSenseApi,
      "deviceSpecificationSense",
      "Device Specification Sense",
      "Device Specification Sense",
      defaultDeviceSense
    );
  }
}
