import { GridColumns } from "@material-ui/data-grid";
export const platformSpecificationListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "platform",
    headerName: "Platform",
    flex: 1,
  },
  {
    field: "mcu",
    headerName: "MCU",
    flex: 1,
  },
  {
    field: "cores",
    headerName: "Cores",
    type: "number",
    flex: 1,
  },
  {
    field: "flashInternal",
    headerName: "Flash (Internal)",
    type: "number",
    hide: true,
  },
  {
    field: "flashExternal",
    headerName: "Flash (External)",
    type: "number",
    hide: true,
  },
  {
    field: "sramInternal",
    headerName: "SRAM (Internal)",
    type: "number",
    hide: true,
  },
  {
    field: "sramExternal",
    headerName: "SRAM (External)",
    type: "number",
    hide: true,
  },
  {
    field: "clockDefault",
    headerName: "Clock (Default)",
    type: "number",
    hide: true,
  },
  {
    field: "clockMinimum",
    headerName: "Clock (Minimum)",
    type: "number",
    hide: true,
  },
  {
    field: "wifiAInd",
    headerName: "WiFi A Ind",
    type: "number",
    hide: true,
  },
  {
    field: "wifiBInd",
    headerName: "Wifi B Ind",
    type: "number",
    hide: true,
  },
  {
    field: "wifiGInd",
    headerName: "WiFi G Ind",
    type: "number",
    hide: true,
  },
  {
    field: "wifiNInd",
    headerName: "WiFi N Ind",
    type: "number",
    hide: true,
  },
  {
    field: "supplyVoltage",
    headerName: "Supply Voltage",
    type: "number",
    flex: 1,
  },
  {
    field: "versionNumber",
    headerName: "Version Number",
    type: "number",
    hide: true,
  },
];
