import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import { deviceRoleDatabaseDefinitionApi } from "../../../../api/api";
import {
  DeviceRoleInput,
  DeviceRoleSchema,
} from "../../../../api/DeviceRole/DeviceRoleInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import SelectInputFieldOnChange from "../../../Form/SelectInputFieldOnChange/SelectInputFieldOnChange";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./DeviceRoleValidation";

const DeviceRoleForm: FC<FormProps> = ({ id }: FormProps) => {
  const router = useRouter();
  const {
    deviceRoleStore,
    deviceSpecificationStore,
    deviceSpecificationActuatorSpecificationStore,
    deviceSpecificationSensorSpecificationStore,
    deviceRoleDatabaseDefinitionStore,
    deviceFunctionInstanceStore,
    sensorFunctionInstanceChannelStore,
    actuatorFunctionInstanceChannelStore,
    userInterfaceStore,
  } = useRootStore();

  const deviceRoleSchema = useMemo(
    () => generateSchema(userInterfaceStore.pageType!),
    [userInterfaceStore.pageType]
  );

  const resolver = useYupValidationResolver(deviceRoleSchema);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    defaultValues: deviceRoleStore.defaultItem,
    resolver,
  });

  useEffect(() => {
    autorun(async () => {
      reset(deviceRoleStore.item);
    });
  }, [deviceRoleStore.item, reset]);

  const onSubmit = async (values: DeviceRoleSchema) => {
    let deviceRole: DeviceRoleInput = {
      deviceSpecificationId: values.deviceSpecification.id,
      name: values.name,
      versionNumber: 0,
    };
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
      case PageType.Inserted:
        deviceRole.id = Number(router.query.id);
        await deviceRoleStore.updateItemAsync(deviceRole);

        break;
      case PageType.New:
      case PageType.NewDuplicate:
        await deviceRoleStore.insertItemAsync(deviceRole);

        userInterfaceStore.setPageType(PageType.Inserted);
        router.history.push(
          `/${deviceRoleStore.endpoint}/edit/${deviceRoleStore.item.id}`
        );
        break;
    }
    await deviceRoleDatabaseDefinitionStore.handleUpdateDeviceRole(
      deviceRoleStore.item.id
    );
    await deviceFunctionInstanceStore.handleUpdateDeviceRole(
      deviceRoleStore.item.id,
      sensorFunctionInstanceChannelStore.deviceFunctionInstanceMap,
      actuatorFunctionInstanceChannelStore.deviceFunctionInstanceMap
    );
  };

  const deviceSpecificationList = deviceSpecificationStore.itemList;
  let deviceSpecificationOptions: { id: number; value: string }[] = [];
  if (deviceSpecificationList.length) {
    deviceSpecificationOptions = deviceSpecificationList.map(
      (deviceSpecification) => {
        return {
          id: deviceSpecification.id,
          value: `${deviceSpecification.name}`,
        };
      }
    );
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    deviceSpecificationActuatorSpecificationStore.getItemListByForeignKeyAsync(
      Number(event.target.value),
      "deviceSpecification"
    );
    deviceSpecificationSensorSpecificationStore.getItemListByForeignKeyAsync(
      Number(event.target.value),
      "deviceSpecification"
    );
    sensorFunctionInstanceChannelStore.handleChangeDeviceSpecification(
      deviceFunctionInstanceStore.itemList
    );
    actuatorFunctionInstanceChannelStore.handleChangeDeviceSpecification(
      deviceFunctionInstanceStore.itemList
    );
    deviceFunctionInstanceStore.handleChangeDeviceSpecification();
    setValue("deviceSpecification.id", event.target.value);
  };

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={deviceRoleStore} />
      <SnackbarAlert store={deviceFunctionInstanceStore} />
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <TextInputField
          name="name"
          displayName="Name"
          errors={errors}
          control={control}
          readOnly={false}
        />
        <SelectInputFieldOnChange
          name="deviceSpecification.id"
          options={deviceSpecificationOptions}
          displayName="Device Specification"
          errors={errors}
          control={control}
          handleChange={handleChange}
        />
      </form>
    </Fragment>
  );
};

export default observer(DeviceRoleForm);
