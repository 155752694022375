import { GridColumns } from "@material-ui/data-grid";
import { Period } from "../../../../api/Period/PeriodInterface";

export const periodListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "name",
    headerName: "Period Name",
    flex: 1,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
  },
  {
    field: "duration",
    headerName: "Duration (Seconds)",
    flex: 1,
  },
];
