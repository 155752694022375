import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FC, Fragment, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";
import {
  TbCreateCustomerRequestBody,
  TbCustomerSchema,
  TbUpdateCustomerRequestBody,
} from "../../../../api/TbCustomer/TbCustomerInterface";
import { TbEntityType } from "../../../../api/TbEntityRelation/TbEntityRelationInterface";
import { useRootStore } from "../../../../hooks/useRootStore";
import { useRouter } from "../../../../hooks/useRouter";
import { useYupValidationResolver } from "../../../../hooks/useYupValidationResolver";
import calculateReadOnly from "../../../../utils/calculateReadOnly";
import deduplicateArray from "../../../../utils/deduplicateArray";
import removeNullItemsInArray from "../../../../utils/removeNullItemsInArray";
import ComboAutocompleteField from "../../../Form/ComboAutocompleteField/ComboAutocompleteField";
import TextInputField from "../../../Form/TextInputField/TextInputField";
import { FormProps } from "../../../Form/types";
import SnackbarAlert from "../../../SnackbarAlert/SnackbarAlert";
import { PageType } from "../../types";
import { generateSchema } from "./CustomerValidation";

const CustomerForm: FC<FormProps> = ({ id }: FormProps) => {
  const router = useRouter();
  const { customerStore, userInterfaceStore, tbCustomerStore } = useRootStore();

  const customerSchema = useMemo(
    () =>
      generateSchema(userInterfaceStore.pageType!, tbCustomerStore.itemList),
    [tbCustomerStore.itemList, userInterfaceStore.pageType]
  );

  const resolver = useYupValidationResolver(customerSchema);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    reset,
  } = useForm({
    defaultValues: tbCustomerStore.defaultItem,
    resolver,
  });

  const onSubmit = async (values: TbCustomerSchema) => {
    let customer: TbCreateCustomerRequestBody = {
      additionalInfo: {
        description: values.description,
      },
      address: values.address,
      address2: values.address2,
      city: values.city,
      country: values.country,
      email: values.email,
      phone: values.phone,
      state: values.state,
      title: values.title,
      zip: values.zip,
    };
    switch (userInterfaceStore.pageType) {
      case PageType.Existing:
        let updateCustomer: TbUpdateCustomerRequestBody = {
          ...customer,
          id: {
            entityType: TbEntityType.CUSTOMER,
            id: values.customerUuid,
          },
          createdTime: values.createdTime,
        };
        await tbCustomerStore.updateItemAsync(updateCustomer, values.id);
        break;
      case PageType.New:
      case PageType.NewDuplicate:
        await tbCustomerStore.insertItemAsync(customer);
        userInterfaceStore.setPageType(PageType.Inserted);
        router.history.push(
          `/${customerStore.endpoint}/edit/${tbCustomerStore.item.id}`
        );
        break;
    }
  };

  useEffect(() => {
    autorun(async () => {
      reset(tbCustomerStore.item);
    });
  }, [tbCustomerStore, reset]);
  const readOnly = calculateReadOnly(userInterfaceStore.pageType);

  let countryOptions = null,
    stateOptions = null,
    cityOptions = null;

  if (tbCustomerStore.itemList) {
    const rawCountryValues = tbCustomerStore.itemList.map((val) => {
      return val.country;
    });
    const filteredCountryValues = removeNullItemsInArray(rawCountryValues);
    countryOptions = deduplicateArray(filteredCountryValues).map((val) => {
      return { displayValue: val };
    });
    const rawStateValues = tbCustomerStore.itemList.map((val) => {
      return val.state;
    });
    const filteredStateValues = removeNullItemsInArray(rawStateValues);
    stateOptions = deduplicateArray(filteredStateValues).map((val) => {
      return { displayValue: val };
    });
    const rawCityValues = tbCustomerStore.itemList.map((val) => {
      return val.city;
    });
    const filteredCityValues = removeNullItemsInArray(rawCityValues);
    cityOptions = deduplicateArray(filteredCityValues).map((val) => {
      return { displayValue: val };
    });
  }

  return (
    <Fragment>
      <Prompt
        when={isDirty}
        message="You have unsaved changes which will be lost if you leave this page."
      />
      <SnackbarAlert store={customerStore} />
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <TextInputField
          name="title"
          displayName="Customer Name"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <ComboAutocompleteField
          options={countryOptions}
          name="country"
          displayName="Country"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <ComboAutocompleteField
          options={stateOptions}
          name="state"
          displayName="State"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <ComboAutocompleteField
          options={cityOptions}
          name="city"
          displayName="City"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <TextInputField
          name="address"
          displayName="Address Line 1"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <TextInputField
          name="address2"
          displayName="Address Line 2"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <TextInputField
          name="zip"
          displayName="Postcode"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <TextInputField
          name="phone"
          displayName="Phone Number"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <TextInputField
          name="email"
          displayName="Email Address"
          errors={errors}
          control={control}
          readOnly={readOnly}
        />
        <TextInputField
          name="description"
          displayName="Description"
          errors={errors}
          control={control}
          numberOfLines={5}
          readOnly={readOnly}
        />
      </form>
    </Fragment>
  );
};

export default observer(CustomerForm);
