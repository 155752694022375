import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { PageType } from "../../pages/types";

type HeadingProps = {
  pageType: PageType;
  heading: string;
};

const Heading: FC<HeadingProps> = ({ pageType, heading }: HeadingProps) => {
  return (
    <Typography variant="h3" component="h3">
      {pageType === PageType.New && "New "}
      {pageType === PageType.NewDuplicate && "New (from existing) "}
      {pageType === PageType.Existing && "Edit "}
      {pageType === PageType.Inserted && "Edit "}
      {pageType === PageType.View && "View "}
      {heading}
    </Typography>
  );
};

export default Heading;
