import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  ApiArrayResponseSuccess,
  ApiResponseFailure,
} from "../types/apiResponse";
import { axiosGetByForeignKeyIdRequest } from "../util/axiosRequest";
import {
  DeviceFunctionInstance,
  DeviceFunctionInstanceInput,
} from "./DeviceFunctionInstanceInterface";

export class DeviceFunctionInstanceApi extends AbstractApi<
  DeviceFunctionInstance,
  DeviceFunctionInstanceInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "deviceFunctionInstance");
  }

  public async getByForeignKeyId(
    id: number
  ): Promise<
    ApiArrayResponseSuccess<DeviceFunctionInstance> | ApiResponseFailure
  > {
    return axiosGetByForeignKeyIdRequest(
      this.baseUrl,
      this.endpoint,
      "deviceRole",
      id
    );
  }
}
