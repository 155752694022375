import { GridColumns } from "@material-ui/data-grid";
import { Resource } from "../../../../api/Resource/ResourceInterface";

function getResourceUnit(params: any) {
  const resource: Resource = params.row;
  if (resource.resourceUnit) {
    return resource.resourceUnit.unit;
  }
}
export const resourceListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "name",
    headerName: "Resource Name",
    flex: 1,
  },
  {
    field: "resourceUnit",
    headerName: "Resource Unit",
    valueGetter: getResourceUnit,
    flex: 1,
  },
];
