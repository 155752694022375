import { number, object, SchemaOf, string } from "yup";
import { deviceSpecificationApi } from "../../../../api/api";
import { DeviceSpecificationSchema } from "../../../../api/DeviceSpecification/DeviceSpecificationInterface";
import { occurrencesInStringArray } from "../../../../utils/occurrencesInArray";
import { PageType } from "../../types";

export function generateSchema(type: PageType) {
  const schema: SchemaOf<DeviceSpecificationSchema> = object().shape({
    name: string()
      .required()
      .test(
        "checkNameUnique",
        "The device function name already exists!",
        async (value: unknown): Promise<boolean> => {
          if (typeof value !== "string") {
            return false;
          }
          const deviceSpecificationList = await deviceSpecificationApi.getAll();
          if ("error" in deviceSpecificationList) {
            return false;
          }
          if (!deviceSpecificationList.data.length) {
            return true;
          }
          const deviceSpecifications = deviceSpecificationList.data.map(
            (deviceSpecification) => deviceSpecification.name
          );
          const occurrences = occurrencesInStringArray(
            value,
            deviceSpecifications
          );
          switch (type) {
            case PageType.New:
            case PageType.NewDuplicate:
              return occurrences < 1;
            case PageType.Existing:
            case PageType.Inserted:
              return occurrences < 2;
          }
          return false;
        }
      ),
    platformSpecification: object().shape({
      id: number().required().positive().integer(),
    }),
    manufacturer: string().required(),
    enclosure: string().required(),
    model: string().required(),
    supplyVoltage: number().positive().required(),
    token: string(),
  });
  return schema;
}
