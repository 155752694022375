import { number, object, SchemaOf } from "yup";
import {
  SensorFunctionLine,
  SensorFunctionLineSchema,
} from "../../../../api/SensorFunctionLine/SensorFunctionLineInterface";
import { occurrencesInNumberArray } from "../../../../utils/occurrencesInArray";

export function generateSchema(
  sensorList: SensorFunctionLine[],
  sensor: SensorFunctionLine
) {
  const schema: SchemaOf<SensorFunctionLineSchema> = object().shape({
    sensorSpecification: object().shape({
      id: number()
        .required()
        .test(
          "checkOnlyOneOfEachSensor",
          "Please select a valid sensor",
          (value: unknown): boolean => {
            if (typeof value !== "number") {
              return false;
            }
            if (value === 0) {
              return false;
            }
            if (!sensorList.length) {
              return true;
            }
            if (sensor.sensorSpecification.id === value) {
              return true;
            }
            const sensorSpecificationIdList = sensorList.map(
              (sensorItem) => sensorItem.sensorSpecification.id
            );
            const occurrences = occurrencesInNumberArray(
              value,
              sensorSpecificationIdList
            );

            return occurrences < 1;
          }
        ),
    }),
    quantity: number().positive().integer().required(),
  });
  return schema;
}
