import { action, makeObservable, observable, runInAction } from "mobx";
import { deviceSpecificationSensorSpecificationApi } from "../../api/api";
import {
  DeviceSpecificationSensorSpecification,
  DeviceSpecificationSensorSpecificationInput,
} from "../../api/DeviceSpecificationSensorSpecification/DeviceSpecificationSensorSpecificationInterface";
import { SensorSpecification } from "../../api/SensorSpecification/SensorSpecificationInterface";
import { ActionType } from "../../components/pages/types";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDeviceSpecificationSensorSpecification } from "./DefaultDeviceSpecificationSensorSpecification";

export class DeviceSpecificationSensorSpecificationStore extends MobXStore<
  DeviceSpecificationSensorSpecification,
  DeviceSpecificationSensorSpecificationInput
> {
  localIdCounter: number = 0;
  actionType?: ActionType = undefined;
  dialogOpen: boolean = false;

  constructor(root: RootStore) {
    super(
      root,
      deviceSpecificationSensorSpecificationApi,
      "deviceSpecificationSensorSpecification",
      "Device Specification Sensor Specification",
      "Device Specification Sensor Specifications",
      defaultDeviceSpecificationSensorSpecification
    );
    makeObservable(this, { dialogOpen: observable, setDialogOpen: action });
  }

  incrementLocalIdCounter() {
    this.localIdCounter++;
  }

  setActionType(actionType: ActionType) {
    this.actionType = actionType;
  }

  setDialogOpen(dialogOpen: boolean) {
    this.dialogOpen = dialogOpen;
  }

  setItemFromList(id: number) {
    const item = this.itemList.find(
      (element) => id === element.sensorSpecification.id
    );
    this.setItem(item!);
  }

  async getItemListAsync(): Promise<void> {
    const result = await this.api.getAll();
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: DeviceSpecificationSensorSpecification[] = [];
    if (result.data.length) {
      enrichedResults = result.data.map((item) => {
        item.localId = this.localIdCounter;
        this.incrementLocalIdCounter();
        return item;
      });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
  }

  async getItemListByForeignKeyAsync(
    id: number,
    foreignKey: string
  ): Promise<void> {
    const result = await this.api.getByForeignKeyId!(id, foreignKey);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    let enrichedResults: DeviceSpecificationSensorSpecification[] = [];
    if (result.data.length) {
      enrichedResults = result.data.map((item) => {
        item.localId = this.localIdCounter;
        this.incrementLocalIdCounter();
        return item;
      });
    }
    this.resetApiResponse();
    this.setItemList(enrichedResults);
  }

  handleInsert(
    deviceSpecificationSensorSpecification: DeviceSpecificationSensorSpecification,
    sensorSpecification: SensorSpecification
  ) {
    deviceSpecificationSensorSpecification.sensorSpecification =
      sensorSpecification;
    deviceSpecificationSensorSpecification.localId = this.localIdCounter;
    this.incrementLocalIdCounter();
    deviceSpecificationSensorSpecification.action = ActionType.New;
    let newItemList = [deviceSpecificationSensorSpecification];
    if (this.itemList.length) {
      newItemList = [...this.itemList, deviceSpecificationSensorSpecification];
    }
    this.setItemList(newItemList);
  }

  handleEdit(
    deviceSpecificationSensorSpecification: DeviceSpecificationSensorSpecification,
    sensorSpecification: SensorSpecification
  ) {
    const newItemList = this.itemList.map((item) => {
      if (deviceSpecificationSensorSpecification.localId !== item.localId) {
        return item;
      }

      deviceSpecificationSensorSpecification.sensorSpecification =
        sensorSpecification;
      if (deviceSpecificationSensorSpecification.action !== ActionType.New) {
        deviceSpecificationSensorSpecification.action = ActionType.Edit;
      }
      return deviceSpecificationSensorSpecification;
    });
    this.setItemList(newItemList);
  }

  handleDelete() {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach((itemInList, index) => {
      if (this.item.localId !== itemInList.localId) {
        return;
      }
      switch (itemInList.action) {
        case undefined:
        case ActionType.Edit:
          runInAction(() => {
            this.itemList[index].action = ActionType.Delete;
          });
          break;
        case ActionType.New:
          runInAction(() => {
            this.itemList.splice(index, 1);
          });
          break;
      }
    });
  }

  async handleUpdateDeviceSpecification(deviceSpecificationId: number) {
    if (!this.itemList.length) {
      return;
    }
    this.itemList.forEach(async (item) => {
      const deviceSpecificationSensorSpecificationInput: DeviceSpecificationSensorSpecificationInput =
        {
          id: item.id,
          sensorSpecificationId: item.sensorSpecification.id,
          deviceSpecificationId: deviceSpecificationId,
          quantity: item.quantity,
        };
      switch (item.action) {
        case ActionType.New: {
          let result = await deviceSpecificationSensorSpecificationApi.insert(
            deviceSpecificationSensorSpecificationInput
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Edit: {
          let result = await deviceSpecificationSensorSpecificationApi.update(
            deviceSpecificationSensorSpecificationInput
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            break;
          }
          runInAction(() => {
            item.action = undefined;
          });
          break;
        }
        case ActionType.Delete: {
          let result = await deviceSpecificationSensorSpecificationApi.delete(
            deviceSpecificationSensorSpecificationInput.id
          );
          if ("error" in result) {
            this.setAndShowMessage(JSON.stringify(result.error));
            this.setMessageSeverity("error");
            runInAction(() => {
              item.action = undefined;
            });
            break;
          }
          break;
        }
      }
    });
  }
}
