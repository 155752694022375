import { Color } from "@material-ui/lab";
import { action, makeAutoObservable, makeObservable, observable } from "mobx";
import { startPersist, stopPersist } from "mobx-persist-store";
import { DeviceConfigApi } from "../../api/DeviceConfig/DeviceConfig";
import { DeviceConfigRecord } from "../../api/DeviceConfig/DeviceConfigInterface";
import { DeviceModeApi } from "../../api/DeviceMode/DeviceMode";
import {
  DeviceMode,
  DeviceModeInput,
  DeviceRoleModeInput,
  DeviceSpecificationModeInput,
} from "../../api/DeviceMode/DeviceModeInterface";
import { DeviceRoleModeApi } from "../../api/DeviceMode/DeviceRoleMode";
import { DeviceSpecificationModeApi } from "../../api/DeviceMode/DeviceSpecificationMode";
import { DeviceRoleRuleApi } from "../../api/DeviceRule/DeviceRoleRule";
import { DeviceRuleApi } from "../../api/DeviceRule/DeviceRule";
import {
  DeviceRoleRuleInput,
  DeviceRule,
  DeviceRuleInput,
  DeviceSpecificationRuleInput,
} from "../../api/DeviceRule/DeviceRuleInterface";
import { DeviceSpecificationRuleApi } from "../../api/DeviceRule/DeviceSpecificationRule";
import { DeviceRoleSenseApi } from "../../api/DeviceSense/DeviceRoleSense";
import { DeviceSenseApi } from "../../api/DeviceSense/DeviceSense";
import {
  DeviceRoleSenseInput,
  DeviceSense,
  DeviceSenseInput,
  DeviceSpecificationSenseInput,
} from "../../api/DeviceSense/DeviceSenseInterface";
import { DeviceSpecificationSenseApi } from "../../api/DeviceSense/DeviceSpecificationSense";
import { RootStore } from "../Root/RootStore";

export class DeviceConfigStore {
  public readonly root: RootStore;
  public readonly api: DeviceConfigApi;
  public readonly endpoint: string;
  public readonly singularDisplayName: string;
  public readonly pluralDisplayName: string;
  public readonly defaultItem: DeviceMode;
  public readonly deviceModeApi: DeviceModeApi;
  public readonly deviceRoleModeApi: DeviceRoleModeApi;
  public readonly deviceSpecificationModeApi: DeviceSpecificationModeApi;
  public readonly deviceSenseApi: DeviceSenseApi;
  public readonly deviceRoleSenseApi: DeviceRoleSenseApi;
  public readonly deviceSpecificationSenseApi: DeviceSpecificationSenseApi;
  public readonly deviceRuleApi: DeviceRuleApi;
  public readonly deviceRoleRuleApi: DeviceRoleRuleApi;
  public readonly deviceSpecificationRuleApi: DeviceSpecificationRuleApi;
  public item: DeviceMode | DeviceSense | DeviceRule;
  public itemList: DeviceConfigRecord[];
  public message: string | null = null;
  public showMessage: boolean = false;
  public messageSeverity: Color | undefined = undefined;

  constructor(
    root: RootStore,
    api: DeviceConfigApi,
    endpoint: string,
    singularDisplayName: string,
    pluralDisplayName: string,
    defaultItem: DeviceMode,
    deviceModeApi: DeviceModeApi,
    deviceRoleModeApi: DeviceRoleModeApi,
    deviceSpecificationModeApi: DeviceSpecificationModeApi,
    deviceSenseApi: DeviceSenseApi,
    deviceRoleSenseApi: DeviceRoleSenseApi,
    deviceSpecificationSenseApi: DeviceSpecificationSenseApi,
    deviceRuleApi: DeviceRuleApi,
    deviceRoleRuleApi: DeviceRoleRuleApi,
    deviceSpecificationRuleApi: DeviceSpecificationRuleApi
  ) {
    this.root = root;
    this.api = api;
    this.endpoint = endpoint;
    this.singularDisplayName = singularDisplayName;
    this.pluralDisplayName = pluralDisplayName;
    this.defaultItem = defaultItem;
    this.deviceModeApi = deviceModeApi;
    this.deviceRoleModeApi = deviceRoleModeApi;
    this.deviceSpecificationModeApi = deviceSpecificationModeApi;
    this.deviceSenseApi = deviceSenseApi;
    this.deviceRoleSenseApi = deviceRoleSenseApi;
    this.deviceSpecificationSenseApi = deviceSpecificationSenseApi;
    this.deviceRuleApi = deviceRuleApi;
    this.deviceRoleRuleApi = deviceRoleRuleApi;
    this.deviceSpecificationRuleApi = deviceSpecificationRuleApi;
    this.item = defaultItem;
    this.itemList = [];
    makeAutoObservable(this);
  }

  async getDeviceModeItemAsync(id: number): Promise<void> {
    const result = await this.deviceModeApi.getOne(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setItem(result.data);
  }

  async insertDeviceModeItemAsync(item: DeviceModeInput): Promise<void> {
    const result = await this.deviceModeApi.insert(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully inserted!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }

  async updateDeviceModeItemAsync(item: DeviceModeInput): Promise<void> {
    const result = await this.deviceModeApi.update(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully updated!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }

  async deleteDeviceModeItemAsync(id: number): Promise<void> {
    const result = await this.deviceModeApi.delete(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully deleted!`
    );
    this.setMessageSeverity("success");
    this.getItemListAsync();
  }

  async getDeviceRoleModeItemAsync(id: number): Promise<void> {
    const result = await this.deviceRoleModeApi.getOne(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setItem(result.data);
  }

  async insertDeviceRoleModeItemAsync(
    item: DeviceRoleModeInput
  ): Promise<void> {
    const result = await this.deviceRoleModeApi.insert(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully inserted!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }

  async updateDeviceRoleModeItemAsync(
    item: DeviceRoleModeInput
  ): Promise<void> {
    const result = await this.deviceRoleModeApi.update(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully updated!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }

  async deleteDeviceRoleModeItemAsync(id: number): Promise<void> {
    const result = await this.deviceRoleModeApi.delete(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully deleted!`
    );
    this.setMessageSeverity("success");
    this.getItemListAsync();
  }

  async getDeviceSpecificationModeItemAsync(id: number): Promise<void> {
    const result = await this.deviceSpecificationModeApi.getOne(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setItem(result.data);
  }

  async insertDeviceSpecificationModeItemAsync(
    item: DeviceSpecificationModeInput
  ): Promise<void> {
    const result = await this.deviceSpecificationModeApi.insert(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully inserted!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }

  async updateDeviceSpecificationModeItemAsync(
    item: DeviceSpecificationModeInput
  ): Promise<void> {
    const result = await this.deviceSpecificationModeApi.update(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully updated!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }

  async deleteDeviceSpecificationModeItemAsync(id: number): Promise<void> {
    const result = await this.deviceSpecificationModeApi.delete(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully deleted!`
    );
    this.setMessageSeverity("success");
    this.getItemListAsync();
  }

  async getDeviceSenseItemAsync(id: number): Promise<void> {
    const result = await this.deviceSenseApi.getOne(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setItem(result.data);
  }

  async insertDeviceSenseItemAsync(item: DeviceSenseInput): Promise<void> {
    const result = await this.deviceSenseApi.insert(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully inserted!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }

  async updateDeviceSenseItemAsync(item: DeviceSenseInput): Promise<void> {
    const result = await this.deviceSenseApi.update(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully updated!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }

  async deleteDeviceSenseItemAsync(id: number): Promise<void> {
    const result = await this.deviceSenseApi.delete(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully deleted!`
    );
    this.setMessageSeverity("success");
    this.getItemListAsync();
  }

  async getDeviceRoleSenseItemAsync(id: number): Promise<void> {
    const result = await this.deviceRoleSenseApi.getOne(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setItem(result.data);
  }

  async insertDeviceRoleSenseItemAsync(
    item: DeviceRoleSenseInput
  ): Promise<void> {
    const result = await this.deviceRoleSenseApi.insert(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully inserted!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }

  async updateDeviceRoleSenseItemAsync(
    item: DeviceRoleSenseInput
  ): Promise<void> {
    const result = await this.deviceRoleSenseApi.update(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully updated!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }

  async deleteDeviceRoleSenseItemAsync(id: number): Promise<void> {
    const result = await this.deviceRoleSenseApi.delete(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully deleted!`
    );
    this.setMessageSeverity("success");
    this.getItemListAsync();
  }

  async getDeviceSpecificationSenseItemAsync(id: number): Promise<void> {
    const result = await this.deviceSpecificationSenseApi.getOne(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setItem(result.data);
  }

  async insertDeviceSpecificationSenseItemAsync(
    item: DeviceSpecificationSenseInput
  ): Promise<void> {
    const result = await this.deviceSpecificationSenseApi.insert(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully inserted!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }

  async updateDeviceSpecificationSenseItemAsync(
    item: DeviceSpecificationSenseInput
  ): Promise<void> {
    const result = await this.deviceSpecificationSenseApi.update(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully updated!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }

  async deleteDeviceSpecificationSenseItemAsync(id: number): Promise<void> {
    const result = await this.deviceSpecificationSenseApi.delete(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully deleted!`
    );
    this.setMessageSeverity("success");
    this.getItemListAsync();
  }

  async getDeviceRuleItemAsync(id: number): Promise<void> {
    const result = await this.deviceRuleApi.getOne(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setItem(result.data);
  }

  async insertDeviceRuleItemAsync(item: DeviceRuleInput): Promise<void> {
    const result = await this.deviceRuleApi.insert(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully inserted!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }

  async updateDeviceRuleItemAsync(item: DeviceRuleInput): Promise<void> {
    const result = await this.deviceRuleApi.update(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully updated!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }

  async deleteDeviceRuleItemAsync(id: number): Promise<void> {
    const result = await this.deviceRuleApi.delete(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully deleted!`
    );
    this.setMessageSeverity("success");
    this.getItemListAsync();
  }

  async getDeviceRoleRuleItemAsync(id: number): Promise<void> {
    const result = await this.deviceRoleRuleApi.getOne(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setItem(result.data);
  }

  async insertDeviceRoleRuleItemAsync(
    item: DeviceRoleRuleInput
  ): Promise<void> {
    const result = await this.deviceRoleRuleApi.insert(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully inserted!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }

  async updateDeviceRoleRuleItemAsync(
    item: DeviceRoleRuleInput
  ): Promise<void> {
    const result = await this.deviceRoleRuleApi.update(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully updated!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }

  async deleteDeviceRoleRuleItemAsync(id: number): Promise<void> {
    const result = await this.deviceRoleRuleApi.delete(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully deleted!`
    );
    this.setMessageSeverity("success");
    this.getItemListAsync();
  }

  async getDeviceSpecificationRuleItemAsync(id: number): Promise<void> {
    const result = await this.deviceSpecificationRuleApi.getOne(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setItem(result.data);
  }

  async insertDeviceSpecificationRuleItemAsync(
    item: DeviceSpecificationRuleInput
  ): Promise<void> {
    const result = await this.deviceSpecificationRuleApi.insert(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully inserted!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }

  async updateDeviceSpecificationRuleItemAsync(
    item: DeviceSpecificationRuleInput
  ): Promise<void> {
    const result = await this.deviceSpecificationRuleApi.update(item);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully updated!`
    );
    this.setMessageSeverity("success");
    this.setItem(result.data);
  }

  async deleteDeviceSpecificationRuleItemAsync(id: number): Promise<void> {
    const result = await this.deviceSpecificationRuleApi.delete(id);
    if ("error" in result) {
      this.setAndShowMessage(JSON.stringify(result.error));
      this.setMessageSeverity("error");
      return;
    }
    this.setAndShowMessage(
      `${this.singularDisplayName} has been successfully deleted!`
    );
    this.setMessageSeverity("success");
    this.getItemListAsync();
  }

  setItem(item: DeviceMode | DeviceSense | DeviceRule) {
    this.item = item;
  }
  resetItem() {
    this.item = this.defaultItem;
  }
  setItemList(itemList: DeviceConfigRecord[]) {
    this.itemList = itemList;
  }
  resetItemList() {
    this.itemList = [];
  }
  setAndShowMessage(response: string) {
    console.log("how many calls tho");
    this.message = response;
    this.showMessage = true;
  }
  setShowMessage(value: boolean) {
    this.showMessage = value;
  }
  setMessageSeverity(color: Color) {
    this.messageSeverity = color;
  }
  resetApiResponse() {
    this.message = null;
  }

  stopPersist() {
    stopPersist(this);
  }
  startPersist() {
    startPersist(this);
  }
  async getItemListAsync(): Promise<void> {
    const result = await this.api.getAll();
    this.resetApiResponse();
    this.setItemList(result);
  }
}
