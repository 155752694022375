import { GridColumns } from "@material-ui/data-grid";
import { TbCustomer } from "../../../../api/TbCustomer/TbCustomerInterface";

function getFormattedTimeFromUtc(params: any) {
  const tbCustomer: TbCustomer = params.row;
  if (tbCustomer.createdTime) {
    return new Date(tbCustomer.createdTime);
  }
}

export const customerListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "customerUuid",
    headerName: "Customer UUID",
    flex: 1,
    hide: true,
  },
  {
    field: "title",
    headerName: "Customer Title",
    flex: 1,
  },
  {
    field: "country",
    headerName: "Country",
    flex: 1,
  },
  {
    field: "state",
    headerName: "State",
    flex: 1,
  },
  {
    field: "city",
    headerName: "City",
    flex: 1,
  },
  {
    field: "address",
    headerName: "Address",
    flex: 1,
  },
  {
    field: "address2",
    headerName: "Address Line 2",
    flex: 1,
    hide: true,
  },
  {
    field: "zip",
    headerName: "Postcode",
    flex: 1,
  },
  {
    field: "phone",
    headerName: "Phone Number",
    flex: 1,
  },
  {
    field: "email",
    headerName: "Email Address",
    flex: 1,
  },
  {
    field: "createdTime",
    headerName: "Created Time",
    valueGetter: getFormattedTimeFromUtc,
    flex: 1,
  },
];
