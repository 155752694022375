import { TbCustomer } from "../../api/TbCustomer/TbCustomerInterface";

export const defaultTbCustomer: TbCustomer = {
  id: 0,
  customerUuid: "",
  createdTime: 0,
  additionalInfo: {},
  country: "",
  state: "",
  city: "",
  address: "",
  address2: "",
  zip: "",
  phone: "",
  email: "",
  title: "",
  tenantId: {
    entityType: "",
    id: "",
  },
  name: "",
};
