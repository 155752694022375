import { deviceFunctionApi } from "../../api/api";
import {
  DeviceFunction,
  DeviceFunctionInput,
} from "../../api/DeviceFunction/DeviceFunctionInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDeviceFunction } from "./DefaultDeviceFunction";

export class DeviceFunctionStore extends MobXStore<
  DeviceFunction,
  DeviceFunctionInput
> {
  constructor(root: RootStore) {
    super(
      root,
      deviceFunctionApi,
      "deviceFunction",
      "Device Function",
      "Device Functions",
      defaultDeviceFunction
    );
  }
}
