import { AbstractAPI as AbstractApi } from "../abstractApi";
import {
  SensorSpecification,
  SensorSpecificationInput,
} from "./SensorSpecificationInterface";

export class SensorSpecificationApi extends AbstractApi<
  SensorSpecification,
  SensorSpecificationInput
> {
  constructor(baseUrl: string) {
    super(baseUrl, "sensorSpecification");
  }
}
