import { boolean, number, object, SchemaOf } from "yup";
import { ServiceAllocationSchema } from "../../../../api/ServiceAllocation/ServiceAllocationInterface";

export function generateSchema() {
  const schema: SchemaOf<ServiceAllocationSchema> = object().shape({
    resource: object().shape({
      id: number().required().positive(),
    }),
    period: object().shape({
      id: number().required().positive(),
    }),
    allocation: number().integer().required().positive(),
    carryOver: boolean().required(),
  });
  return schema;
}
