import { number, object, SchemaOf, string } from "yup";
import { PeriodSchema } from "../../../../api/Period/PeriodInterface";
import { PageType } from "../../types";

export function generateSchema(type: PageType) {
  const schema: SchemaOf<PeriodSchema> = object().shape({
    name: string().required(),
    description: string().required(),
    duration: number().required().moreThan(-1, "Duration must be 0 or higher"),
  });
  return schema;
}
