import { Typography } from "@material-ui/core";
import React, { FC, Fragment } from "react";

const HomePage: FC = () => {
  return (
    <Fragment>
      <Typography variant="h3">IRMACOS Homepage</Typography>
      <p>Welcome</p>
    </Fragment>
  );
};

export default HomePage;
