import { defaultTagModel } from "../TagModel/DefaultTagModel";
import { defaultTagStatus } from "../TagStatus/DefaultTagStatus";

export const defaultTag = {
  id: 0,
  tagModel: defaultTagModel,
  tagStatus: defaultTagStatus,
  activationDate: null,
  activationDateUtc: null,
  tagInput: "",
  tagValue: 0,
};
