import { GridColumns } from "@material-ui/data-grid";
export const actuatorSpecificationListTableColumns: GridColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "number",
    hide: true,
  },
  {
    field: "manufacturer",
    headerName: "Manufacturer",
    flex: 1,
  },
  {
    field: "type",
    headerName: "Type",
    flex: 1,
  },
  {
    field: "model",
    headerName: "Model",
    flex: 1,
  },
  {
    field: "voltage",
    headerName: "Voltage",
    type: "number",
  },
  {
    field: "current",
    headerName: "Current",
    type: "number",
  },
  {
    field: "levels",
    headerName: "Levels",
    type: "number",
  },
  {
    field: "phases",
    headerName: "Phases",
    type: "number",
  },
  {
    field: "versionNumber",
    headerName: "Version Number",
    type: "number",
    hide: true,
  },
];
