import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { observer } from "mobx-react";
import React, { FC, Fragment } from "react";
import { useRootStore } from "../../hooks/useRootStore";
import { MobXStore } from "../../state/types";
import SnackbarAlert from "../SnackbarAlert/SnackbarAlert";

type DataTableDeleteProps = {
  store: MobXStore<any, any>;
};

const DataTableDelete: FC<DataTableDeleteProps> = ({
  store,
}: DataTableDeleteProps) => {
  const { userInterfaceStore } = useRootStore();

  const handleClose = () => {
    userInterfaceStore.setDialogOpen(false);
  };

  const handleDelete = () => {
    userInterfaceStore.setDialogOpen(false);
    store.deleteItemAsync(Number(userInterfaceStore.id));
  };

  return (
    <Fragment>
      <SnackbarAlert store={store} />
      <Dialog
        open={userInterfaceStore.dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Delete ${store.singularDisplayName}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const DateTableDeleteObserver = observer(DataTableDelete);
export default DateTableDeleteObserver;
