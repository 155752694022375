import { number, object, SchemaOf } from "yup";
import {
  PersonArea,
  PersonAreaSchema,
} from "../../../../api/PersonArea/PersonAreaInterface";
import { occurrencesInNumberArray } from "../../../../utils/occurrencesInArray";

export function generateSchema(personAreaList: PersonArea[]) {
  const schema: SchemaOf<PersonAreaSchema> = object().shape({
    area: object().shape({
      id: number()
        .required()
        .positive()
        .test(
          "checkOneOfEachArea",
          "Please select a valid area",
          (value: unknown): boolean => {
            if (typeof value !== "number") {
              return false;
            }
            if (value === 0) {
              return false;
            }
            if (!personAreaList.length) {
              return true;
            }
            const areaIdList = personAreaList.map((personArea) =>
              Number(personArea.area.id)
            );
            const occurrences = occurrencesInNumberArray(value, areaIdList);

            return occurrences < 1;
          }
        ),
    }),
  });
  return schema;
}
