import { action, makeObservable, observable } from "mobx";
import { AccessTokenApi } from "../../api/AccessToken/AccessToken";
import { accessTokenApi, deviceApi } from "../../api/api";
import { Device, DeviceInput } from "../../api/Device/DeviceInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDevice } from "./DefaultDevice";

export class DeviceStore extends MobXStore<Device, DeviceInput> {
  accessTokenApi: AccessTokenApi;
  accessToken: string = "";
  constructor(root: RootStore) {
    super(root, deviceApi, "device", "Device", "Devices", defaultDevice);
    this.accessTokenApi = accessTokenApi;
    makeObservable(this, {
      accessToken: observable,
      setAccessToken: action,
      generateAccessToken: action,
    });
  }

  setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }

  async generateAccessToken() {
    const accessToken = await this.accessTokenApi.generateAccessToken();
    this.setAccessToken(accessToken);
  }
}
