import { deviceRuleApi } from "../../api/api";
import {
  DeviceRule,
  DeviceRuleInput,
} from "../../api/DeviceRule/DeviceRuleInterface";
import { RootStore } from "../Root/RootStore";
import { MobXStore } from "../types";
import { defaultDeviceRule } from "./DefaultDeviceRule";

export class DeviceRuleStore extends MobXStore<DeviceRule, DeviceRuleInput> {
  constructor(root: RootStore) {
    super(
      root,
      deviceRuleApi,
      "deviceRule",
      "Device Rule",
      "Device Rule",
      defaultDeviceRule
    );
  }
}
